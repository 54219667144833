import React from 'react';
import Container from 'react-bootstrap/Container';

import i18n from 'I18n';

import TransactionDetailContent from 'component/records/TransactionDetailContent';

import './TransactionHistoryDetail.scss';

const TransactionHistoryDetail = ({ match }) => {
  const transactionID = match?.params?.id;

  return (
    <div className={'transaction_history'}>
      <div className={'title title-small'}>
        {i18n.t('records:transaction_detail_title')}
      </div>
      <TransactionDetailContent transactionID={transactionID} />
    </div>
  );
};

export default TransactionHistoryDetail;
