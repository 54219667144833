import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import i18n from 'I18n';
import QRCode from 'qrcode.react';

import {
  BARCODE_METHOD,
  COUPON_FORMATS,
  COUPON_REDEMPTION_METHOD,
} from 'utils/CustomEnums';
import { refreshQRCode } from 'utils/QrCodeUtils';
import { getOuterSiteOrigin } from 'utils';
import { SHOPIFY_API_HOST as ENV_SHOPIFY_API_HOST } from 'utils/Config';

import './CouponQRCode.scss';

const BarCode = require('react-barcode');
const SHOPIFY_API_HOST = getOuterSiteOrigin(ENV_SHOPIFY_API_HOST);

@connect(({ users }) => ({ isOpenInOtherSite: users.isOpenInOtherSite }))
class CouponQRCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBarcodeModal: false,
      codeString: 'code',
      intervalID: '',
    };
  }

  componentDidMount() {
    if (
      this.props.baseInfo?.redemptionMethod ===
      COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE
    ) {
      this.getQRCode();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  validDate = () => {
    if (!this.props.baseInfo.displayDate) {
      return i18n.t('coupon:alwaysActive');
    }

    if (typeof this.props.baseInfo.displayDate === 'string') {
      return this.props.baseInfo.displayDate;
    }

    return '';

    // const words = this.props.baseInfo.displayDate.split(" ");
    // if (words.length < 3) {
    //   return;
    // } else {
    //   return words.slice(3).join(" ");
    // }
  };
  getQRCode = () => {
    const codeContent = {
      couponSerialNumber: this.props.baseInfo.serialNumber,
    };
    const { codeString, intervalID } = refreshQRCode(codeContent, (qrCode) =>
      this.setState({ ...qrCode }),
    );
    this.setState({ codeString, intervalID });
  };

  clickCopyCode = () => {
    navigator.clipboard.writeText(this.props.baseInfo.code || '');
    this.props.dispatch({
      type: 'navBars/updateState',
      payload: {
        toastShowing: {
          value: true,
          content: i18n.t('copied'),
          toastIcon: '/coupon/copyCodeSuccessIcon/check.svg',
        },
      },
    });
  };
  handleApplyCoupon = (couponCode) => {
    window.top.postMessage(
      { type: "APPLY_COUPON", value: couponCode },
      SHOPIFY_API_HOST
    )
  }

  render() {
    const { baseInfo } = this.props;
    const couponStateSectionClassName = (currentClassName) => {
      // let words = [];
      // if (this.props.baseInfo.displayDate) {
      //   words = this.props.baseInfo.displayDate.split(" ");
      // }
      if (
        // words.length <= 2 ||
        baseInfo.isUsed ||
        baseInfo.isExpired
      ) {
        return currentClassName + ' coupon_used_or_expired';
      }
      return currentClassName;
    };

    // const couponBackgroundUp = () => {
    //   if (window.innerWidth < 1024) {
    //     return (
    //       <img
    //         alt="background"
    //         src="/coupon/qrcodeBackground/mobile/couponBackgroundUp.png"
    //         srcSet="/coupon/qrcodeBackground/mobile/couponBackgroundUp@2x.png 2x, /coupon/qrcodeBackground/mobile/couponBackgroundUp@3x.png 3x"
    //       />
    //     );
    //   } else {
    //     return (
    //       <img
    //         alt="background"
    //         src="/coupon/qrcodeBackground/desktop/couponBackgroundUp.png"
    //         srcSet="/coupon/qrcodeBackground/desktop/couponBackgroundUp@2x.png 2x, /coupon/qrcodeBackground/desktop/couponBackgroundUp@3x.png 3x"
    //       />
    //     );
    //   }
    // };

    // const couponBackgroundDown = () => {
    //   if (window.innerWidth < 1024) {
    //     return (
    //       <img
    //         alt="background"
    //         src="/coupon/qrcodeBackground/mobile/couponBackgroundDown.png"
    //         srcSet="/coupon/qrcodeBackground/mobile/couponBackgroundDown@2x.png 2x, /coupon/qrcodeBackground/mobile/couponBackgroundDown@3x.png 3x"
    //         className="coupon_background_down"
    //       />
    //     );
    //   } else {
    //     return (
    //       <img
    //         alt="background"
    //         src="/coupon/qrcodeBackground/desktop/couponBackgroundDown.png"
    //         srcSet="/coupon/qrcodeBackground/desktop/couponBackgroundDown@2x.png 2x, /coupon/qrcodeBackground/desktop/couponBackgroundDown@3x.png 3x"
    //         className="coupon_background_down"
    //       />
    //     );
    //   }
    // };

    const showQRCodeOrBarcode = () => {
      if (!baseInfo.showScanableCouponCode || baseInfo.showMerchantScanCode) {
        return null;
      }
      if (baseInfo?.couponCodeFormat === COUPON_FORMATS.COUPON_BAR_CODE) {
        return (
          <>
            <div className="coupon_qrcode_message_block">
              <div className="coupon_qrcode_message field-text">
                {i18n.t('coupon:couponBarcodeMessage')}
              </div>
            </div>
            <div className="coupon_qrcode_block coupon_barcode_block">
              <BarCode
                value={baseInfo.code}
                format={BARCODE_METHOD[baseInfo.barcodeFormat]}
              />
            </div>
          </>
        );
      }
      return (
        <>
          <div className="coupon_qrcode_message_block">
            <div className="coupon_qrcode_message field-text">
              {i18n.t('coupon:couponQrcodeMessage')}
            </div>
          </div>
          <div className="coupon_qrcode_block">
            {baseInfo?.code && <QRCode value={baseInfo?.code} renderAs="svg" />}
          </div>
        </>
      );
    };

    const showCouponCode = () => {
      if (baseInfo?.showCopiableString) {
        return (
          <>
            <div className="coupon_code field-text">
              <div>{baseInfo.code}</div>
              {baseInfo?.isExpired || baseInfo?.isUsed ? null : (
                <>
                  {this.props?.isOpenInOtherSite && baseInfo.usageMedium === "ONLINE" ?
                    <button
                      className="copy_code_btn apply_code_btn button-large"
                      onClick={() => { this.handleApplyCoupon(baseInfo.code) }}>
                      {"Apply Coupon"}
                    </button> : null
                  }
                  <button
                    className="copy_code_btn button-large"
                    onClick={() => {
                      this.clickCopyCode();
                    }}
                  >
                    {i18n.t('coupon:copyCode')}
                  </button>
                  <div>{i18n.t('coupon:copyCouponCodeMessage')}</div>
                </>
              )}
            </div>
          </>
        );
      }
      if (
        !baseInfo.showMerchantScanQRCode &&
        baseInfo.showScanableCouponCode &&
        baseInfo?.couponCodeFormat === COUPON_FORMATS.COUPON_BAR_CODE
      ) {
        return (
          <>
            <div className="coupon_code field-text">
              <div>{baseInfo.code}</div>
              <div className="barcode_message">
                {i18n.t('coupon:view_large_barcode_1')}
                <p
                  className="view_large_barcode"
                  onClick={() => {
                    this.setState({ showBarcodeModal: true });
                  }}
                >
                  {i18n.t('coupon:view_large_barcode_2')}
                </p>
              </div>
            </div>
          </>
        );
      }
      return (
        <div className="coupon_code field-text">
          <div>
            {baseInfo.showScanableCouponCode
              ? baseInfo.code
              : baseInfo.serialNumber}
          </div>
        </div>
      );
    };
    const showMerchantScanCode = () => {
      if (!baseInfo.showMerchantScanQRCode) {
        return null;
      }
      return (
        <>
          <div className="coupon_qrcode_message_block">
            <div className="coupon_qrcode_message field-text">
              {i18n.t('coupon:couponQrcodeMessage')}
            </div>
          </div>
          <div className="coupon_qrcode_block">
            <QRCode value={this.state.codeString} renderAs="svg" />
          </div>
        </>
      );
    };

    return (
      <div className="coupon_qrcode_container">
        {/* {couponBackgroundUp()} */}
        {/* {couponBackgroundDown()} */}
        <div className={couponStateSectionClassName('coupon_state_section')}>
          <div
            className={couponStateSectionClassName(
              'coupon_text coupon_vaild_message button-large',
            )}
          >
            {this.validDate()}
          </div>
        </div>
        {baseInfo?.isVirtualCoupon ? null : (
          <>
            <div className="dividing_line"></div>
            {showMerchantScanCode()}
            {showQRCodeOrBarcode()}
            {showCouponCode()}
            <Modal
              show={this.state.showBarcodeModal}
              onHide={() => this.setState({ showBarcodeModal: false })}
              scrollable={false}
              className="use_coupon_modal"
              centered
            >
              <Modal.Body className="use_coupon_modal_content d-flex-column">
                <p className="use_coupon_title title-medium-1">
                  {i18n.t('coupon:viewBarcode')}
                </p>
                {baseInfo.code && <BarCode
                  value={baseInfo.code}
                  format={BARCODE_METHOD[baseInfo.barcodeFormat]}
                />}
                <div className="button_group">
                  <button
                    className="right_btn button-large"
                    onClick={() => {
                      this.setState({ showBarcodeModal: false });
                    }}
                  >
                    {i18n.t('campaign_ok')}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    );
  }
}

export default CouponQRCode;
