import React, { useState } from 'react';
import './MonthPicker.scss';
import i18n from 'I18n';

export const CustomDatePickerInput = ({ value, onClick, showBirthMonthOptions }) => (
  <div
    className="custom-date-picker-div"
    onClick={onClick}
  >
    <label
      className={`account-input-with-downarrow field-text ${value ? '' : 'account-input-with-downarrow-placeholder'}`}
    >
      {value ?? i18n.t("account_submit_birth_reminder")}
    </label>
    {
      showBirthMonthOptions ?
        <img
          alt="up"
          src="/myAccount/up-arrow.png"
          srcSet="/myAccount/up-arrow@2x.png 2x, /myAccount/up-arrow@3x.png 3x"
          className="account-down-arrow"
        /> :
        <img
          alt="down"
          src="/myAccount/down-arrow.png"
          srcSet="/myAccount/down-arrow@2x.png 2x, /myAccount/down-arrow@3x.png 3x"
          className="account-down-arrow"
        />
    }
  </div >
);

const getYearAndMonth = (date) => {
  const tempDate = date ? new Date(date) : new Date();
  return {
    year: tempDate.getFullYear(),
    month: tempDate.getMonth() + 1,
  }
};

const getYearList = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const yearList = [];
  for (let i = 0; i <= 150; i++) {
    yearList.push(currentYear - i);
  };
  return yearList
};

const getMonthDate = ({ year, month }) => {
  const monthLong = month < 10 ? `0${month}` : month;
  return new Date(`${year}-${monthLong}-01`)
}

const getMonthNames = (locale = 'en', format = 'short') => {
  const formatter = new Intl.DateTimeFormat(locale, {
    month: format,
    timeZone: 'UTC',
  });
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
    const monthLong = month < 10 ? `0${month}` : month;
    return new Date(`2023-${monthLong}-01T00:00:00+00:00`);
  });
  return months.map((date) => formatter.format(date));
};


const MonthPicker = ({
  show,
  lang,
  selected,
  onChange,
}) => {
  const [month, setMonth] = useState(getYearAndMonth(selected).month);
  const [checkYear, setCheckYear] = useState(getYearAndMonth(selected).year);
  const [year, setYear] = useState(getYearAndMonth(selected).year);
  const [yearDropDown, setYearDropDown] = useState(false);

  const yearList = getYearList();
  const maxDateInfo = getYearAndMonth();
  const maxMonthDate = getMonthDate({ year: maxDateInfo.year, month: maxDateInfo.month });

  const changeYear = (value) => {
    setYear(value);
  };

  const changeMonth = (value) => {
    setMonth(value);
    setCheckYear(year);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className='picker-container'
      onClick={() => {
        if (yearDropDown) {
          setYearDropDown(false);
        };
      }}
    >
      <div className='year-container'>
        <button
          className='birthday-button'
          aria-label='Previous Year'
          onClick={(e) => changeYear(year - 1)}
        >
          <img
            alt=""
            className='birthday-arrow rotate'
            src={"/profile/right-arrow.png"}
            srcSet="/profile/right-arrow@2x.png 2x, /profile/right-arrow@3x.png 3x"
          />
        </button>
        <span
          aria-label='Year selected'
          className='title-medium-1'
          onClick={() => {
            setYearDropDown(prevState => {
              return !prevState
            });
          }}
        >
          {year}
        </span>
        <button
          className='birthday-button'
          aria-label='Next Year'
          onClick={(e) => {
            if (year + 1 > maxDateInfo.year) {
              return;
            }
            changeYear(year + 1)
          }}
        >
          <img
            alt=""
            src={"/profile/right-arrow.png"}
            srcSet="/profile/right-arrow@2x.png 2x, /profile/right-arrow@3x.png 3x"
          />
        </button>
      </div>
      <div className='month-container'>
        {getMonthNames(lang).map((monthName, index) => {
          return (
            <button
              key={index}
              className={`month birthday-button ${index + 1 === month && checkYear === year
                ? 'active'
                : ''
                } field-text`}
              onClick={(e) => changeMonth(index + 1)}
            >
              {monthName}
            </button>
          );
        })}
      </div>
      <div className='button-container'>
        <button
          className='birthday-button birthday-confirm-button'
          aria-label='Done'
          onClick={() => {
            const birthday = getMonthDate({ year: checkYear, month: month });
            onChange(maxMonthDate < birthday ? maxMonthDate : birthday)
          }}
        >
          <span
            className='button-large'
          >
            {i18n.t('birthday_done')}
          </span>
        </button>
        <button
          className='birthday-button birthday-confirm-button'
          aria-label='Cancel'
          onClick={() => onChange()}
        >
          <span
            className='button-large'
          >
            {i18n.t('birthday_cancel')}
          </span>
        </button>
      </div>
      {
        yearDropDown
          ? <div className='year-dropdown'>
            {yearList.map((item, index) => (
              <div key={index}>
                <span
                  className='year title-medium-1'
                  onClick={() => {
                    setYear(item);
                  }}
                >
                  {item}
                </span>
              </div>
            ))}
          </div>
          : null
      }
    </div>
  );
}


export default MonthPicker
