import React from "react";
import { connect } from 'react-redux';
import { Button } from "react-bootstrap";

import i18n from "I18n";
import "./FilterActionBar.scss";


const mapStateToProps = state => {
  return {
    language: state.users.language,
  };
};

function FilterActionBar({
  language,
  onReset,
  onApply,
}) {
  return (
    <div className={'filter_action'}>
      <Button
        className="button-large"
        variant="secondary"
        onClick={() => onReset()} >
        {i18n.t('store:Reset')}
      </Button>
      <div className={'middle_space'} />
      <Button
        className="button-large"
        variant="primary"
        onClick={() => onApply()} >
        {i18n.t('store:Apply')}
      </Button>
    </div>
  );
}

export default connect(mapStateToProps)(FilterActionBar);
