import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import i18n from 'I18n';

import Brand from 'component/campaign/Brand';
import DetailDescription from 'component/campaign/DetailDescription';
import ImageSlider from 'component/campaign/ImageSlider';
import Instruction from 'component/campaign/Instruction';
import CouponQRCode from 'component/coupon/CouponQRCode';
import PickupStore from 'component/coupon/PickUpStoreSection';
import SelectedStore from 'component/coupon/SelectedStore';
import StaffUse from 'component/coupon/StaffUse';

import { createAction, getOuterSiteOrigin } from 'utils';
import { SHOPIFY_API_HOST as ENV_SHOPIFY_API_HOST } from 'utils/Config';

import './CouponDetail.scss';
import { isMobile, isPadOrMobile } from 'utils/ScreenUtils';

const SHOPIFY_API_HOST = getOuterSiteOrigin(ENV_SHOPIFY_API_HOST);

@connect(({ myCoupon, users }) => ({
  detailData: myCoupon.detailDataMap,
  applicableStoreMap: myCoupon.couponApplicableStoreMap,
  selectedStore: myCoupon.selectedStore,
  selectedUsageMedium: myCoupon.selectedUsageMedium,
  ...myCoupon,
  language: users.language,
  isOpenInOtherSite: users.isOpenInOtherSite,
}))
class CouponDetail extends Component {
  constructor(props) {
    super(props);
    this.couponID = props.match.params?.id;
    this.coverPhotoUrl = props.location.state?.coverPhotoUrl;
    this.state = {
      applicableStore: [],
    };
  }

  componentDidMount() {
    this.prepareData();
    if (this.props.isOpenInOtherSite) {
      window.addEventListener('message', this.handleMessage);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.couponID = this.props.match.params?.id;
      this.coverPhotoUrl = this.props.location.state?.coverPhotoUrl;
      this.prepareData();
      this.setState({ applicableStore: [] });
    }
    if (this.props.language !== prevProps.language) {
      this.prepareData();
    }
    if (
      this.state.applicableStore?.length < 1 &&
      this.props.applicableStoreMap?.[this.couponID]?.length > 0
    ) {
      this.setState({
        applicableStore: this.props.applicableStoreMap?.[this.couponID],
      });
    }
  }

  componentWillUnmount() {
    if (this.props.isOpenInOtherSite) {
      window.removeEventListener('message', this.handleMessage);
    }
  }

  prepareData = () => {
    if (this.couponID) {
      this.props.dispatch(
        createAction('myCoupon/prepareDetailData')({ couponID: this.couponID }),
      );
      this.props.dispatch(
        createAction('myCoupon/getCouponApplicableStores')({
          couponID: this.couponID,
        }),
      );
    }
  };

  handleMessage = (e) => {
    const { origin, data } = e;
    if (window.self === window.top || origin !== SHOPIFY_API_HOST) {
      return;
    }
    if (data?.type === 'APPLY_COUPON' && data?.status === 'SUCCESS') {
      this.props.dispatch({
        type: 'navBars/updateState',
        payload: {
          toastShowing: {
            value: true,
            content: i18n.t('coupon_applied_successfully'),
            toastIcon: '/coupon/copyCodeSuccessIcon/check.svg',
          },
        },
      });
    }
  };

  showBrand = () => {
    if (
      this.props.detailData[this.couponID].brand &&
      this.props.detailData[this.couponID].brand.shown
    ) {
      return <Brand brand={this.props.detailData[this.couponID]?.brand} />;
    }
  };

  showSelectedStore = () => {
    if (
      this.props.selectedStore &&
      this.props.detailData[this.couponID].store.shown
    ) {
      return (
        <>
          <Row className="dividing store-top-dividing" />
          <SelectedStore store={this.props.detailData[this.couponID].store} />
        </>
      );
    }
  };

  showImageSlider = () => {
    const imagesList = this.props.detailData[this.couponID].base.images || [
      this.coverPhotoUrl,
    ];
    return (
      <ImageSlider images={imagesList} className={'coupon-detail-img-slider'} />
    );
  };

  renderMask = () => {
    return isMobile();
  };

  showCouponUsedtag = (isMobile) => {
    if (this.props.detailData[this.couponID].base.isUsed) {
      return (
        <Row>
          <div className={isMobile ? 'mobile_coupon_used_tag tag-menu-2' : 'used_tag tag-menu-2'}>
            {i18n.t('coupon:used')}
          </div>
        </Row>
      );
    }
    if (this.props.detailData[this.couponID].base.isExpired) {
      return (
        <Row>
          <div
            className={isMobile ? 'mobile_coupon_expired_tag tag-menu-2' : 'expired_tag tag-menu-2'}
          >
            {i18n.t('coupon:expired')}
          </div>
        </Row>
      );
    }
    return;
  };

  render() {
    const couponDetail = this.props.detailData?.[this.couponID];
    const baseInfo = couponDetail?.base;
    const isVirtualCoupon = baseInfo?.isVirtualCoupon;

    const showStorePickSction = () => {
      if (!isVirtualCoupon) {
        return;
      }

      return (
        <PickupStore
          defaultSelectedFirst={false}
          stores={this.state.applicableStore}
          couponID={this.couponID}
          couponInfo={couponDetail}
        />
      );
    };

    const showBottomBar = () => {
      if (isVirtualCoupon) {
        return (
          <div className="staff_use_bottom_bar row">
            <Container className="staff_use_container">
              <div
                className="bottom-select-store-btn"
                onClick={() => {
                  this.props.history.push(
                    `/coupon/${this.couponID}/select_store`,
                  );
                }}
              >
                <div className='button-large'>{i18n.t('store:select_store')}</div>
              </div>
            </Container>
          </div>
        );
      }

      if (!baseInfo.isUsed && !baseInfo.isExpired) {
        return (
          <div className="staff_use_bottom_bar row">
            <StaffUse />
          </div>
        );
      }
    };

    const showLeftSection = () => {
      if (couponDetail) {
        return (
          <Col className="d-md-block left_section">
            <div className="mobile_left_section">
              <Row>{this.showImageSlider()}</Row>
              {this.showCouponUsedtag()}
              <Row>
                <div className={`offer_title coupon_title ${isPadOrMobile() ? "headline" : "title-large"}`}>{baseInfo.name}</div>
              </Row>
              {this.showCouponUsedtag(true)}
              <div className="coupon_qrcode_section">
                <CouponQRCode baseInfo={baseInfo} />
              </div>
            </div>
            {this.showSelectedStore()}
            <Row className="dividing"></Row>
            {this.showBrand()}
            <Row hidden={!couponDetail.instruction.shown}>
              <Instruction
                renderMask={this.renderMask()}
                instructions={couponDetail.instruction}
              />
            </Row>
            {couponDetail.instruction.shown && couponDetail.detail.shown && (
              <div className="coupon_detail_dividing" />
            )}
            <Row hidden={!couponDetail.detail.shown}>
              <DetailDescription
                renderMask={this.renderMask()}
                description={couponDetail.detail}
              />
            </Row>
            {showBottomBar()}
          </Col>
        );
      }
    };

    const showRightSection = () => {
      if (couponDetail) {
        return (
          <div className="float_right_section">
            {showStorePickSction()}
            {!isVirtualCoupon && <CouponQRCode baseInfo={baseInfo} />}
            {!isVirtualCoupon && !baseInfo.isUsed && !baseInfo.isExpired && (
              <StaffUse />
            )}
            {/* <SharedSection message={""} /> */}
          </div>
        );
      }
    };

    return (
      <div className="user_container_background">
        <Container className="campaign_detail_container">
          <Row className="nav_row button-large">
            <Link to="/">{i18n.t('home_home')}</Link>
            <div>{'>'}</div>
            <Link to="/user/myCoupon">{i18n.t('profile_my_coupons')}</Link>
            <div>{'>'}</div>
            <span className="nav_row_span">{i18n.t('coupon_detail')}</span>
          </Row>
          <Row className="background_color_g6">
            {showLeftSection()}

            <Col className="col-md-4 right_section">{showRightSection()}</Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(CouponDetail);
