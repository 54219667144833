import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import i18n from 'I18n';

import GenderPicker from 'component/account/GenderPicker';
import LoginMethodContactModal from 'component/account/LoginMethodContactModal';
import CustomAlert from 'component/base/CustomAlert';
import CustomButton from 'component/base/CustomButton';
import CustomSwitchButton from 'component/base/CustomSwitchButton';
import MonthPicker, {
  CustomDatePickerInput,
} from 'component/profile/MonthPicker';

import { createAction } from 'utils';
import {
  CUSTOMER_SIGN_UP_METHOD,
  GenderType,
  ProfileEditState,
  ProfileEditType,
} from 'utils/CustomEnums';
import {
  getMyAccountUserBirthdayForDisplay,
  getMyAccountUserBirthdayForUpdate,
} from 'utils/DateTimeUtils';

import './MyAccountInfoSection.scss';

const EditButton = ({ state, editClick }) => {
  const onClick = () => {
    if (state === ProfileEditState.Normal) {
      editClick(true);
    } else if (state === ProfileEditState.Editing) {
      editClick(false);
    }
  };
  return (
    <span
      className={
        state === ProfileEditState.Disable
          ? 'personal_edit_button inactive'
          : 'personal_edit_button'
      }
      onClick={onClick}
    >
      {state === ProfileEditState.Editing
        ? i18n.t('message_cancel')
        : i18n.t('message_edit')}
    </span>
  );
};

const mapStateToProps = ({ userInfoTemp, users }) => {
  return {
    ...userInfoTemp,
    language: users.language,
    signUpMethod: users.userInfo.signUpMethod,
    allowedEditFields: users.userInfo.allowedEditFields,
    countryCode: users.userInfo.countryCode,
    phoneNumber: users.userInfo.phoneNumber,
    email: users.userInfo.emailAddress,
    userInfo: users.userInfo,
  };
};

const MyAccountInfoSection = (props) => {
  const genderList = [
    {
      name: i18n.t('personal_info_male'),
      pk: GenderType.Male,
    },
    {
      name: i18n.t('personal_info_female'),
      pk: GenderType.Female,
    },
    {
      name: i18n.t('personal_info_not_disclosed'),
      pk: GenderType.NotDisclosed,
    },
  ];
  console.log('props@25', props);
  const {
    language,
    signUpMethod,
    allowedEditFields,
    countryCode,
    phoneNumber,
    email,
    userInfo,
  } = props;
  const dispatch = useDispatch();
  const [showLoginMethodModal, setShowLoginMethodModal] = useState(false);

  const displayGender = useMemo(() => {
    const userGender = userInfo.gender;
    if (userGender === GenderType.Female) {
      return i18n.t('personal_info_female');
    }
    if (userGender === GenderType.Male) {
      return i18n.t('personal_info_male');
    }
    if (userGender === GenderType.NotDisclosed) {
      return i18n.t('personal_info_not_disclosed');
    }
    return '-';
  }, [userInfo.gender]);

  const [editType, setEditType] = useState(null);
  const firstNameEditState = useMemo(() => {
    if (editType === ProfileEditType.FirstName) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const lastNameEditState = useMemo(() => {
    if (editType === ProfileEditType.LastName) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const genderEditState = useMemo(() => {
    if (editType === ProfileEditType.Gender) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);
  const birthdayEditState = useMemo(() => {
    if (editType === ProfileEditType.Birthday) {
      return ProfileEditState.Editing;
    }
    if (editType) {
      return ProfileEditState.Disable;
    }
    return ProfileEditState.Normal;
  }, [editType]);

  const countryCodeAndPhoneNumber = useMemo(
    () => `+${countryCode} ${phoneNumber}`,
    [signUpMethod],
  );
  const showMobile = useMemo(
    () =>
      [
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
      ].includes(signUpMethod),
    [signUpMethod],
  );
  const showEmail = useMemo(
    () =>
      [
        CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
      ].includes(signUpMethod),
    [signUpMethod],
  );
  const showAddAccountButton = useMemo(
    () =>
      [
        CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS,
        CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
      ].includes(signUpMethod),
    [signUpMethod],
  );
  const showSecondLoginMethodModal = () => {
    setEditType(ProfileEditType.LoginAndContact);
    setShowLoginMethodModal(true);
  };
  const hideSecondLoginMethodModal = () => {
    setEditType(null);
    setShowLoginMethodModal(false);
  };

  const [firstName, setFirstName] = useState(userInfo.firstName);
  const [lastName, setLastName] = useState(userInfo.lastName);
  const [birthday, setBirthday] = useState(userInfo.birthday);
  const [showBirthMonthOptions, setShowBirthMonthOptions] = useState(false);
  const [selectedGender, setSelectedGender] = useState(userInfo.gender);
  const [emailAddress, setEmailAddress] = useState(props.emailAddress?.value);
  useEffect(() => {
    setFirstName(userInfo.firstName);
    setLastName(userInfo.lastName);
    setBirthday(userInfo.birthday);
    setSelectedGender(userInfo.gender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editType]);

  useEffect(() => {
    return () => {
      dispatch(createAction('userInfoTemp/clearState')());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="common-shadow-section-container account-info-section">
      <div className="common-section-title title-medium-2">
        {props.t('account_information')}
      </div>
      <Row>
        <Col className="common-account-col">
          <div className="common-account-item">
            <div className="common-item-title subheading">
              {props.t('personal_info_first_name')}
              {allowedEditFields?.includes('first_name') && (
                <EditButton
                  state={firstNameEditState}
                  editClick={(startEdit) => {
                    if (startEdit) {
                      setEditType(ProfileEditType.FirstName);
                    } else {
                      setEditType(null);
                    }
                  }}
                />
              )}
            </div>
            {firstNameEditState === ProfileEditState.Editing ? (
              <div className="common-item-value field-text">
                <input
                  className="account-input-with-downarrow field-text"
                  defaultValue={firstName}
                  onChange={(e) => {
                    const text = e.target.value;
                    setFirstName(text);
                  }}
                />
                <CustomButton
                  className="account-input-save-button"
                  btnClicked={!!firstName}
                  disabled={!firstName}
                  text={i18n.t('save')}
                  btnClicked={() => {
                    dispatch(
                      createAction('userInfoTemp/updateUserInfoOneField')({
                        serverKey: 'first_name',
                        modelKey: 'firstName',
                        value: firstName,
                        successCallback: () => {
                          setEditType(null);
                        },
                      }),
                    );
                  }}
                />
              </div>
            ) : (
              <div className="common-item-value field-text">
                {userInfo.firstName || '-'}
              </div>
            )}
          </div>
        </Col>
        <Col className="common-account-col">
          <div className="common-account-item">
            <div className="common-item-title subheading">
              {props.t('personal_info_last_name')}
              {allowedEditFields?.includes('last_name') && (
                <EditButton
                  state={lastNameEditState}
                  editClick={(startEdit) => {
                    if (startEdit) {
                      setEditType(ProfileEditType.LastName);
                    } else {
                      setEditType(null);
                    }
                  }}
                />
              )}
            </div>
            {lastNameEditState === ProfileEditState.Editing ? (
              <div className="common-item-value field-text">
                <input
                  className="account-input-with-downarrow field-text"
                  defaultValue={lastName}
                  onChange={(e) => {
                    const text = e.target.value;
                    setLastName(text);
                  }}
                />
                <CustomButton
                  className="account-input-save-button"
                  btnClicked={!!lastName}
                  disabled={!lastName}
                  text={i18n.t('save')}
                  btnClicked={() => {
                    dispatch(
                      createAction('userInfoTemp/updateUserInfoOneField')({
                        serverKey: 'last_name',
                        modelKey: 'lastName',
                        value: lastName,
                        successCallback: () => {
                          setEditType(null);
                        },
                      }),
                    );
                  }}
                />
              </div>
            ) : (
              <div className="common-item-value field-text">
                {userInfo.lastName || '-'}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="common-account-col">
          <div className="common-account-item">
            <div className="common-item-title subheading">
              {props.t('account_for_login_and_contact') +
                props.t('personal_info_optional')}
            </div>
            {showEmail && (
              <div className="common-item-value field-text">{email || '-'}</div>
            )}
            {showMobile && (
              <div className="common-item-value field-text">
                {countryCodeAndPhoneNumber || '-'}
              </div>
            )}
            {showAddAccountButton && (
              <button
                className="add-another-btn button-small subheading"
                onClick={() => showSecondLoginMethodModal()}
              >
                {i18n.t('settings_login_add_second_method')}
              </button>
            )}
          </div>
        </Col>
        <Col className="common-account-col">
          <div className="common-account-item">
            <div className="common-item-title subheading">
              {props.t('personal_info_gender') +
                props.t('personal_info_optional')}
              {allowedEditFields?.includes('gender') && (
                <EditButton
                  state={genderEditState}
                  editClick={(startEdit) => {
                    if (startEdit) {
                      setEditType(ProfileEditType.Gender);
                    } else {
                      setEditType(null);
                    }
                  }}
                />
              )}
            </div>
            {genderEditState === ProfileEditState.Editing ? (
              <div className="common-item-value field-text">
                <GenderPicker
                  customClass={'gender-picker-box'}
                  sections={genderList}
                  value={userInfo.gender}
                  selectValue={(value) => {
                    setSelectedGender(value);
                  }}
                />
                <CustomButton
                  className="account-input-save-button"
                  btnClicked={!!selectedGender}
                  disabled={!selectedGender}
                  text={i18n.t('save')}
                  btnClicked={() => {
                    dispatch(
                      createAction('userInfoTemp/updateUserInfoOneField')({
                        serverKey: 'gender',
                        modelKey: 'gender',
                        value: selectedGender,
                        successCallback: () => {
                          setEditType(null);
                        },
                      }),
                    );
                  }}
                />
              </div>
            ) : (
              <div className="common-item-value field-text">
                {displayGender}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="common-account-col">
          <div className="common-account-item">
            <div className="common-item-title subheading">
              {props.t('account_birth_month_year') +
                props.t('personal_info_optional')}
              {allowedEditFields?.includes('date_of_birth') && (
                <EditButton
                  state={birthdayEditState}
                  editClick={(startEdit) => {
                    if (startEdit) {
                      setEditType(ProfileEditType.Birthday);
                    } else {
                      setEditType(null);
                    }
                  }}
                />
              )}
            </div>
            {birthdayEditState !== ProfileEditState.Editing ? (
              <div className="common-item-value field-text">
                {userInfo.birthday
                  ? getMyAccountUserBirthdayForDisplay(userInfo.birthday)
                  : '-'}
              </div>
            ) : (
              <div className="common-item-value field-text">
                <CustomDatePickerInput
                  value={
                    birthday
                      ? getMyAccountUserBirthdayForDisplay(birthday)
                      : null
                  }
                  onClick={() => {
                    setShowBirthMonthOptions(!showBirthMonthOptions);
                  }}
                  showBirthMonthOptions={showBirthMonthOptions}
                />
                <MonthPicker
                  show={showBirthMonthOptions}
                  lang={language}
                  selected={birthday}
                  onChange={(date) => {
                    if (!!date) {
                      setBirthday(date);
                    }
                    setShowBirthMonthOptions(false);
                  }}
                />
                <CustomButton
                  className="account-input-save-button"
                  btnClicked={!!birthday}
                  disabled={!birthday}
                  text={i18n.t('save')}
                  btnClicked={() => {
                    dispatch(
                      createAction('userInfoTemp/updateUserInfoOneField')({
                        serverKey: 'date_of_birth',
                        modelKey: 'birthday',
                        value: getMyAccountUserBirthdayForUpdate(birthday),
                        successCallback: () => {
                          setEditType(null);
                        },
                      }),
                    );
                  }}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      <LoginMethodContactModal
        show={showLoginMethodModal}
        onClose={() => hideSecondLoginMethodModal()}
      />
    </Container>
  );
};

export default connect(mapStateToProps)(
  withTranslation()(MyAccountInfoSection),
);
