import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import { findDOMNode } from "react-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./HomeBanner.scss"
import { BANNER_TYPES } from "../../services/CampaignAPIHelper";

@connect(({ campaignList, users }) => ({ ...campaignList, users: users }))
class HomeBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  _activateCarouselControl = () => {
    let carouselObj = document.getElementById("home_banner_slider");
    if (carouselObj && this.props.homeBanner.length < 2) {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevControlShow",
        "none"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextControlShow",
        "none"
      );
    } else {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextControlShow",
        "flex"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextButtonEvent",
        "auto"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevControlShow",
        "flex"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevButtonEvent",
        "auto"
      );
    }
  };

  componentDidUpdate() {
    this._activateCarouselControl();
  }

  clickBannerImg = (item) => {
    if (item?.targetType === BANNER_TYPES.TYPE_CAMPAIGN && item?.targetID) {
      this.props.history.push(`/campaign/${item.targetID}`)
    }
  }
  render() {
    return (
      <div className="home_banner_container">
        <Carousel 
          id="home_banner_slider"
          indicators={this.props.homeBanner.length < 2 ? false : true}
          nextIcon={
            <span aria-hidden="true">
              <div className="slider_prev_next_icon">
                <img
                  alt="next"
                  src="/campaignDetail/nextBtn/btn-next.png"
                  srcSet="/campaignDetail/nextBtn/btn-next@2x.png 2x, /campaignDetail/nextBtn/btn-next@3x.png 3x"
                  className="detail_carousel_next_icon"
                />
              </div>
            </span>
          }
          prevIcon={
            <span aria-hidden="true">
              <div className="slider_prev_next_icon">
                <img
                  alt="next"
                  src="/campaignDetail/previousBtn/btn-previous.png"
                  srcSet="/campaignDetail/previousBtn/btn-previous@2x.png 2x, /campaignDetail/previousBtn/btn-previous@3x.png 3x"
                  className="detail_cauousel_prev_icon"
                />
              </div>
            </span>
          }
        >
          {this.props.homeBanner.map((item)=>(
            <Carousel.Item>
              <div onClick={() => this.clickBannerImg(item)}>
                <img className="banner_img" alt="" src={item?.url} />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }
}
export default withRouter(withTranslation()(HomeBanner));
