import axios from "axios";
// import { USING_FAKE_API } from '../config/Config';

const USING_FAKE_API = false;
const TEST_DATA_BASE_URL = "http://manb.no-ip.net:9280/aillia/v1/";
const CMS_DATA_BASE_URL =
  process.env.REACT_APP_DOMAIN || "https://aillia.hkdev.motherapp.com";
const BASEURL = USING_FAKE_API ? TEST_DATA_BASE_URL : CMS_DATA_BASE_URL;
export const TAB_INDEX_PARAMS_MAP = [
  "?sort=-date_of_acquisition,expiry_date,-id&filter[is_available]=true&page[size]=20&page[number]=1",
  "?sort=-date_of_acquisition,expiry_date,-id&filter[is_unavailable]=true&page[size]=20&page[number]=1",
  "?sort=-date_of_use_or_expiration,usage_record,-id&filter[is_used_or_expired]=true&page[size]=20&page[number]=1",
];
export const TEST_TAB_INDEX_MAP = ["available", "unavailable", "used_expired"];

const couponAPIinstance = axios.create({
  baseURL: BASEURL,
  timeout: 3000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
class APIHelper {
  constructor() {
    couponAPIinstance.interceptors.request.use(
      (config) => {
        if (
          this.authorizationToken !== undefined &&
          this.authorizationToken != null
        ) {
          config.headers.Authorization = `Bearer ${this.authorizationToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  addAuthorization = (token) => {
    this.authorizationToken = token;
  };

  removeAuthorization = () => {
    this.authorizationToken = undefined;
  };

  getMyCoupon = (testCaseID, tabIndex, customerID, pageLink = null) => {
    if (USING_FAKE_API) {
      return couponAPIinstance
        .get(`Coupon/${testCaseID}/${TEST_TAB_INDEX_MAP[tabIndex]}.json`)
        .catch((error) => error);
    }
    if (pageLink !== null && pageLink !== "") {
      return couponAPIinstance.get(pageLink).catch((error) => error);
    }
    const params = TAB_INDEX_PARAMS_MAP[tabIndex];
    return couponAPIinstance
      .get(`/customers/${customerID}/coupons${params}`)
      .catch((error) => error);
  };

  getAllMyCoupons = (customerID) => {
    return couponAPIinstance
      .get(
        `/customers/${customerID}/coupons?sort=-date_of_use_or_expiration,usage_record,-id`
      )
      .catch((error) => error);
  };

  getCouponDetail = (testCaseID, customerID, couponID) => {
    if (USING_FAKE_API) {
      return couponAPIinstance
        .get(`Coupon/${testCaseID}/CouponDetail/${couponID}.json`)
        .catch((error) => error);
    }
    return couponAPIinstance
      .get(`/customers/${customerID}/coupons/${couponID}`)
      .catch((error) => error);
  };

  getCouponApplicableStores = (customerID, couponID) => {
    return couponAPIinstance
      .get(`/customers/${customerID}/coupons/${couponID}/applicable_stores`)
      .catch((error) => error);
  }

  convertCouponVirtualToReal = (couponID, storeID, usageMedium) => {
    return couponAPIinstance
      .post(`/coupons/${couponID}/virtual_convert`, {
        store_id: storeID,
        usage_medium: usageMedium
      })
      .catch((error) => error);
  }

  useCoupon = (couponID, staffCode, qrCode) =>
    couponAPIinstance.post(`/coupons/${couponID}/usage_record`, {
      store_qr_code: qrCode,
      staff_code: staffCode,
    });
}
export const CouponAPIHelper = new APIHelper();
