import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import OfferFloatActionButton from 'component/offer/OfferFloatActionButton';
import ActivityLogSection from 'component/profile/ActivityLogSection';
import CurrentPointsSection from 'component/profile/CurrentPointsSection';
import LevelBenefit from 'component/profile/LevelBenefit';
import LevelRuleSection from 'component/profile/LevelRuleSection';
import LevelSectionV2 from 'component/profile/LevelSectionV2';
import PointAndCashSection from 'component/profile/PointAndCashSection';
import RetainMembershipSection from 'component/profile/RetainMembershipSection';
import RewardStatusSection from 'component/profile/RewardStatusSection';
import TransactionHistorySection from 'component/profile/TransactionHistorySection';
import { NO_LEVEL, NO_TRANSACTION_HISTORY } from 'utils/Config';

import './MyProfileContentSection.scss';
import NoLevelSection from './NoLevelSection';
import { isPadOrMobile } from 'utils/ScreenUtils';

@connect(({ users }) => ({
  isShowLevel: users.isShowLevel,
  displayName: users.displayName,
  levelConfig: users.levelConfig,
  currentLevel: users.level,
  renewDate: users.renewDate,
  TPE: users.TPE,
  showLevelRetianProgress: users.showLevelRetianProgress,
}))
class MyProfileContentSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedLevelView: 0,
      isMobile: isPadOrMobile(),
    };
    this.showLevel = !NO_LEVEL
  }

  updateWindowDimensions = () => {
    this.setState({ isMobile: isPadOrMobile() });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  MyProfileContentDesktop = (showRetainMembershipSection) => {
    return (
      <>
        <div className="my-profile-content-section">
          {this.showLevel ? <LevelSectionV2 /> : <NoLevelSection />}
          <div className={`my-profile-content-right-area ${this.showLevel ? "" : "no-level"}`}>
            <PointAndCashSection />
            {this.showLevel &&
              <div className="level-info-area">
                <LevelRuleSection />
                <LevelBenefit />
              </div>
            }
          </div>
        </div>
        {showRetainMembershipSection && (
          <Row className="my-profile-content-section">
            <RetainMembershipSection />
          </Row>
        )}
        <ActivityLogSection />
        {NO_TRANSACTION_HISTORY ? null : <TransactionHistorySection />}
        <RewardStatusSection />
      </>
    );
  };

  MyProfileContentMobile = (showRetainMembershipSection) => {
    return (
      <div className="my-profile-content-section mobile-view">
        <div className="level-and-point">
          {this.showLevel ? <LevelSectionV2 /> : <NoLevelSection />}
          {showRetainMembershipSection && <RetainMembershipSection />}
          <PointAndCashSection />
          {this.showLevel &&
            <div className="level-rule-and-benefit">
              <LevelRuleSection />
              <LevelBenefit />
            </div>
          }
        </div>
        <ActivityLogSection />
        {NO_TRANSACTION_HISTORY ? null : <TransactionHistorySection />}
        <RewardStatusSection />
      </div>
    );
  };

  render() {
    const showRetainMembershipSection = this.props.showLevelRetianProgress;
    return (
      <>
        {this.state.isMobile
          ? this.MyProfileContentMobile(showRetainMembershipSection)
          : this.MyProfileContentDesktop(showRetainMembershipSection)}
        <OfferFloatActionButton />
      </>
    );
  }
}

export default MyProfileContentSection;
