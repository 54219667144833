import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./ImageSlider.scss";
import { Modal } from "react-bootstrap";
import { Carousel as CustomCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { findDOMNode } from "react-dom";

class ImageSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
    };
  }

  imageClicked = () => {
    this.setState({ modalShow: true });
  };

  activityCarousel = () => {
    const carouselObj = document.getElementById("detail_general_slider");
    if (this.props.images.length < 2) {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevControlShow",
        "none"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextControlShow",
        "none"
      );
    } else {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextControlShow",
        "flex"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextButtonEvent",
        "auto"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevControlShow",
        "flex"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevButtonEvent",
        "auto"
      );
    }
  };

  componentDidUpdate() {
    this.activityCarousel();
  }

  dismissIcon = (index) => {
    let carouselObj = document.getElementById("detail_general_slider");
    if (index === 0) {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevButtonEvent",
        "none"
      );
    } else {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevControlShow",
        "flex"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevButtonEvent",
        "auto"
      );
    }
    if (index === this.props.images.length - 1) {
      // findDOMNode(carouselObj).style.setProperty("--carouselNextButtonEvent","none");
    } else {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextControlShow",
        "flex"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextButtonEvent",
        "auto"
      );
    }
  };

  carouselTouchable = (index) => {
    console.log("@57");
    if (index === this.props.images.length - 1) {
      return false;
    }
    return true;
  };

  render() {
    return (
      <div
        className={this.props.className ?
          `campaign_detail_image_slider ${this.props.className}` :
          'campaign_detail_image_slider'}>
        <Carousel
          className="slider"
          id="detail_general_slider"
          indicators={this.props.images.length < 2 ? false : true}
          nextIcon={
            <span aria-hidden="true">
              <div className="slider_prev_next_icon">
                <img
                  alt="next"
                  src="/campaignDetail/nextBtn/btn-next.png"
                  srcSet="/campaignDetail/nextBtn/btn-next@2x.png 2x, /campaignDetail/nextBtn/btn-next@3x.png 3x"
                  className="detail_carousel_next_icon"
                />
              </div>
            </span>
          }
          prevIcon={
            <span aria-hidden="true">
              <div className="slider_prev_next_icon">
                <img
                  alt="next"
                  src="/campaignDetail/previousBtn/btn-previous.png"
                  srcSet="/campaignDetail/previousBtn/btn-previous@2x.png 2x, /campaignDetail/previousBtn/btn-previous@3x.png 3x"
                  className="detail_cauousel_prev_icon"
                />
              </div>
            </span>
          }
          onSlide={this.dismissIcon}
          //   wrap={this.carouselTouchable || false}
        >
          {this.props.images.map((image, index) => (
            <Carousel.Item
              className="campaign_item"
              onClick={this.imageClicked}
              key={index}
            >
              <img
                src={image}
                alt="campagin detail"
                className="campaign_img w-100"
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          scrollable={false}
          className="campaign_detail_image_modal"
          // centered
        >
          <button
            className="modal_close_btn"
            onClick={() => this.setState({ modalShow: false })}
          >
            <img
              alt="close"
              src="/coupon/closeBtn/close-2.png"
              srcSet="/coupon/closeBtn/close-2@2x.png 2x, /coupon/closeBtn/close-2@3x.png 3x"
            />
          </button>
          <div className="carousel-wrapper campaign_detail_image_carousel">
            <CustomCarousel
              controls={false}
              showIndicators={false}
              showStatus={false}
              showArrows={false}
              autoPlay
              infiniteLoop={this.props.images.length > 1}
              swipeable={this.props.images.length < 2 ? false : true}
            >
              {this.props.images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt="campaign" />
                </div>
              ))}
            </CustomCarousel>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ImageSlider;
