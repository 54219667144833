import React from 'react';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';

import CheckBoxImage, { CHECKBOX_INIT, CHECKBOX_CHECKED } from "component/common/CheckBoxImage";
import { createAction } from 'utils';

import './InboxListItem.scss';

@connect(({ inboxMessages }) => ({ ...inboxMessages }))
class InboxListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  messageItemDidClicked = () => {
    this.props.clickAction();
    if (!this.props.messageData.isMessageRead) {
      this.props.dispatch(createAction('inboxMessages/markMessageRead')({
        message: this.props.messageData,
      }));
    }
  }

  render() {
    const hasSelected = this.props.inboxCheckedList.includes(this.props.messageData.messageId);
    return (
      <div>
      {
      this.props.hasSeparateLine &&
      <div className="inbox_list_separateLine" />
      }
      <div
        className={
          this.props.isActive
          ? "inbox_list_messageItem active"
          : "inbox_list_messageItem"
        }
        onClick={this.messageItemDidClicked}>
        {
        !!this.props.inboxIsEditing
        ? (
        <div className="messageItem_checkbox">
          <CheckBoxImage
            customClassName={"message_checkbox p2_color_image"}
            status={hasSelected ? CHECKBOX_CHECKED : CHECKBOX_INIT} />
        </div>
        )
        : null
        }
        <div className="messageItem_container">
          <div className="messageItem_title_area">
            {
            !this.props.messageData.isMessageRead &&
            <div className="messageItem_redDot">
              <Image className="redDot_image" src="/inbox/redDot.png" roundedCircle={true}/>
            </div>
            }
            <div className="messageItem_title subheading">
              {this.props.messageData.messageTitle}
            </div>
          </div>
          <div className="messageItem_content_area">
            {
            !!this.props.messageData.messagePhotoUrl &&
            <Image
              className="message_photo"
              src={this.props.messageData.messagePhotoUrl}
              placeholder="/inbox/placeholder_image.png"
            />
            }
            <div
              className={
              !this.props.messageData.messagePhotoUrl
              ? "messageItem_content_text"
              : "messageItem_content_text has_photo"
              }>
              <div className="messageItem_content field-text">
                {this.props.messageData.messageContent}
              </div>
              <div className="messageItem_date body-1">
                {this.props.messageData.messageDate}
              </div>
            </div>
          </div>
        </div>
        {
        !this.props.inboxIsEditing
        ? (
        <div className="messageItem_chevron">
          <Image
            className="chevron_right_image p2_color_image rotate_90"
            src="/action/chevron.png"
            srcSet="/action/chevron@2x.png 2x, /action/chevron@3x.png 3x"/>
        </div>
        )
        : null
        }
      </div>
    </div>
    );
  }
}

export default  InboxListItem;

