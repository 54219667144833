import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { Container, Button } from 'react-bootstrap';

import CustomAlert from "component/base/CustomAlert";
import { createAction } from 'utils';
import i18n from "I18n";

import './InboxListTitle.scss';

const mapStateToProps = state => {
  return {
    language: state.users.language,
    messageList: state.inboxMessages.messageList,
    inboxIsEditing: state.inboxMessages.inboxIsEditing,
    inboxCheckedList: state.inboxMessages.inboxCheckedList,
  };
};

function InboxListTitle ({
  language,
  messageList,
  inboxIsEditing,
  inboxCheckedList,
}) {
  const [modalShow, setModalShow] = useState(false);

  const dispatch = useDispatch();

  const onHidden = () => {
    setModalShow(false);
  };
  const leftBtnAction = () => {
    setModalShow(false);
  };
  const rightBtnAction = () => {
    setModalShow(false);
    dispatch(createAction("inboxMessages/removeSelectedMessages")());
  };
  const actionButtonList = () => {
    if (!inboxIsEditing) {
      return (
        <Button
          className="title_edit button-large"
          disabled={!(messageList?.length > 0)}
          onClick={() => {
            dispatch(createAction("inboxMessages/updateState")({
              inboxIsEditing: true,
            }))
          }}>
          {i18n.t('message_edit')}
        </Button>
      );
    }
    return (<>
      <Button
        className="title_delete button-large"
        disabled={!(inboxCheckedList?.length > 0)}
        onClick={() => setModalShow(true)}>
        {i18n.t('message_delete')}
      </Button>
      <Button
        className="title_cancel button-large"
        onClick={() => {
          dispatch(createAction("inboxMessages/updateState")({
            inboxIsEditing: false,
          }));
          dispatch(createAction("inboxMessages/clearMessageChecked")());
        }}>
        {i18n.t('message_cancel')}
      </Button>
    </>);
  };

  return (
    <Container className="inbox_title_container">
      <CustomAlert
        show={modalShow}
        onHide={onHidden}
        message={i18n.t('message_delete_confirm')}
        leftbtn={i18n.t('close')}
        leftAction={leftBtnAction}
        rightbtn={i18n.t('message_delete')}
        rightAction={rightBtnAction}
        isLoading={false}
      />
      <div className="title_inbox title-medium-2">
        {i18n.t("home_inbox")}
      </div>
      <div className="title_action">
        {actionButtonList()}
      </div>
    </Container>
  );
}

export default connect(mapStateToProps)(InboxListTitle);
