import i18n from 'I18n';
import axios from 'axios';
import queryString from 'query-string';

const CMS_DATA_BASE_URL =
  process.env.REACT_APP_DOMAIN || 'https://aillia.hkdev.motherapp.com';
const BASEURL = CMS_DATA_BASE_URL;

const transactionAPIinstance = axios.create({
  baseURL: BASEURL,
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

class APIHelper {
  constructor() {
    this.isFakeEnvLogin = false;
    transactionAPIinstance.interceptors.request.use(
      (config) => {
        if (
          this.authorizationToken !== undefined &&
          this.authorizationToken != null
        ) {
          config.headers.Authorization = `Bearer ${this.authorizationToken}`;
          config.headers.language = i18n.language;
          config.headers['Accept-Language'] = i18n.language;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  addAuthorization = (token) => {
    this.authorizationToken = token;
  };

  removeAuthorization = () => {
    this.authorizationToken = undefined;
  };

  getRewardClaimList = ({ userId, pageLink }) => {
    if (pageLink) {
      return transactionAPIinstance.get(pageLink).catch((error) => error);
    }
    if (!userId) {
      return null;
    }
    return transactionAPIinstance
      .get(`/customers/${userId}/transactions?page[number]=1&page[size]=50`)
      .catch((error) => error);
  };

  getRewardClaimDetail = ({ userId, transactionId }) => {
    return transactionAPIinstance
      .get(`/customers/${userId}/transactions/${transactionId}`)
      .catch((error) => error);
  };

  createRewardClaim = ({ userId, input }) => {
    return transactionAPIinstance
      .post(`/customers/${userId}/transactions`, input)
      .catch((error) => error);
  };

  deleteRewardClaim = ({ userId, transactionId }) => {
    return transactionAPIinstance
      .delete(`/customers/${userId}/transactions/${transactionId}`)
      .catch((error) => error);
  };

  getStoreList = ({ pageLink, searchName, language }) => {
    if (pageLink) {
      return transactionAPIinstance.get(pageLink).catch((error) => error);
    }
    return transactionAPIinstance
      .get(
        `/stores?sort=name&page[number]=1&page[size]=10${
          searchName ? '&name=' + searchName : ''
        }${
          language ? '&language=' + language : ''
        }`,
      )
      .catch((error) => error);
  };

  getTransactionDetail = ({ customerID, transactionID }) => {
    const link = `/customers/${customerID}/transaction_history/${transactionID}`;
    return transactionAPIinstance.get(link).catch((error) => error);
  };

  getTransactionList = ({ pageLink, customerID }) => {
    if (pageLink) {
      return transactionAPIinstance.get(pageLink).catch((error) => error);
    }
    const query = queryString.stringify({
      'page[number]': 1,
      'page[size]': 10,
      sort: '-date',
    });
    const link = `/customers/${customerID}/transaction_history?${query}`;
    return transactionAPIinstance.get(link).catch((error) => error);
  };
}
export const TransactionAPIHelper = new APIHelper();
