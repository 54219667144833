import queryString from 'query-string';

import BaseAPIHelper from 'services/BaseAPIHelper';

export const getCustomerSubscribeDetail = (token) => {
  const url = `/customers/subscribe/subscribe-email?token=${token}`;
  return BaseAPIHelper.get({ link: url });
};

export const updateCustomerSubscribeEmail = (input) => {
  const url = `/customers/subscribe/subscribe-email`;
  return BaseAPIHelper.post({
    link: url,
    input,
  });
};

export const getRequireOTP = ({ uid, paramDict }) => {
  const query = queryString.stringify(paramDict, {
    encode: true,
  });
  const link = `/customers/${uid}/update-login-method?${query}`;
  return BaseAPIHelper.get({ link });
};

export const verifyOTPAndUpdateLoginMethod = ({ uid, paramDict }) => {
  const link = `/customers/${uid}/update-login-method`;
  return BaseAPIHelper.post({ link, input: paramDict });
};
