import React from "react";
import "./error.scss";
import BrowserImage from "../../assets/images/browser.svg";
import SafariIcon from "../../assets/images/icn-safari.png";
import ChromeIcon from "../../assets/images/icn-chrome.png";
import FirefoxIcon from "../../assets/images/icn-firefox.png";
import IEIcon from "../../assets/images/icn-ie.png";
import i18n from "../../I18n";

export default function BrowserNotSupport() {
  const browsers = () => {
    return (
      <>
        <a
          href="https://www.google.com/chrome/browser/desktop/index.html"
          className="a-no-href"
        >
          Chrome
        </a>
        ,{" "}
        <a
          href="https://www.mozilla.org/en-US/firefox/new/"
          className="a-no-href"
        >
          Firefox
        </a>
        ,{" "}
        <a
          href="https://safari.en.softonic.com/mac/download"
          className="a-no-href"
        >
          Safari
        </a>
        , {i18n.t("or")}{" "}
        <a
          href="https://www.microsoft.com/en-hk/download/internet-explorer.aspx"
          className="a-no-href"
        >
          Internet Explorer
        </a>
      </>
    );
  };

  return (
    <div className="nothing-area">
      <img alt="" src={BrowserImage} />

      <label className="nothing-title title-medium-2">{i18n.t("browser_support")}</label>

      <label className="nothing-content field-text">
        {i18n.language === "en" ? (
          <>
            Aillia cannot provide a good experience to your browser. To use this
            site, please upgrade to latest version of {browsers()}
          </>
        ) : i18n.language === "zh-Hant" ? (
          <>
            Aillia在您的瀏覽器上不能提供一個很好的體驗。請使用{browsers()}
            瀏覽，或者升級到最新版本
          </>
        ) : (
          <>
            Aillia在您的浏览器上不能提供一个很好的体验。请使用{browsers()}
            浏览，或者升级到最新版本
          </>
        )}
      </label>

      <div className="browser-icon-area">
        <img alt="" src={SafariIcon} />
        <img className="browser-icon-space" alt="" src={ChromeIcon} />
        <img className="browser-icon-space" alt="" src={FirefoxIcon} />
        <img className="browser-icon-space" alt="" src={IEIcon} />
      </div>
    </div>
  );
}
