import React, { Component } from "react";
import "./CustomModal.scss";
import { withTranslation } from "react-i18next";
import { Modal, TabContainer, TabPane, Nav, TabContent } from "react-bootstrap";

class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    const tabs = this.props.tabs || [];
    const activeTab = this.props.activeTab || this.props.tabs?.[0]?.name;
    const show = this.props.show || false;
    const onClose = this.props.onClose || {};
    const hideTab = this.props.hideTab || false;
    const title = this.props.title || "";
    const onTabChange = this.props.onTabChange;
    const extendStyle = this.props.extendStyle || '';
    return (
      <Modal
        show={show}
        onHide={onClose}
        scrollable={false}
        className={`home-profile-custom-modal ${this.props.className || ""}`}
        centered
      >
        <Modal.Body className={`home-profile-custom-body ${extendStyle}`}>
          <div className="home-profile-custom-title title-medium-1">
            <div>{title}</div>
            <div className="close-icon" onClick={onClose}>
              <img
                alt="close"
                src="/profile/close.png"
                srcSet="/profile/close@2x.png 2x, /profile/close@3x.png 3x"
              />
            </div>
          </div>
          {hideTab ?
            tabs?.[0]?.content :
            <TabContainer
              activeKey={activeTab}
              onSelect={(key) => { onTabChange(key); }}
            >
              <Nav className="home-profile-custom-tab">
                {tabs.map((tab, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link className="subheading" eventKey={tab.key}>
                      {tab.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <TabContent>
                {tabs.map((tab, index) => (
                  <TabPane eventKey={tab.key} key={index}>
                    {tab.content}
                  </TabPane>
                ))}
              </TabContent>
            </TabContainer>
          }
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(CustomModal);
