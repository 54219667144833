import React, { Component } from "react";
import { connect } from "react-redux";
import "./RewardStatusMobile.scss";
import { withTranslation } from "react-i18next";
import { getLevelRenewTime } from "../../utils/DateTimeUtils";
import { createAction } from "../../utils";
import { Container, Row, Col } from "react-bootstrap";
import { TabContainer, TabPane, Nav, TabContent } from "react-bootstrap";
import RewardStatusTableView, { parseCouponListData, parsePointsStatus, parseStampStatus, rewardStatusModalTab } from "./RewardStatusTableView";
import { NO_LEVEL } from "utils/Config";

@connect(({ users, myCoupon, pullrefreshandloadmore }) => ({
  levelName: users.levelName,
  levelExpiryDate: users.renewDate,
  pointStatus: users.pointStatus,
  isRefreshingPoints: users.isRefreshingPointsStatus || false,
  pointStatusNextLink: users.pointStatusNextLink,
  couponNextLink: myCoupon.nextLink,
  availableCoupons: myCoupon.coupons,
  ...pullrefreshandloadmore,
  language: users.language,
  isRefreshingStamp: users.isRefreshingStamp,
  totalEarnedStamps: users.totalEarnedStamps,
  stampStatus: users.stampStatus,
  stampStatusNextLink: users.stampStatusNextLink,
}))
class RewardStatusMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: NO_LEVEL ? rewardStatusModalTab.points : rewardStatusModalTab.level,
    };
  }

  componentDidMount() {
    this.props.dispatch(createAction("users/fetchRewardStatus")());
  }

  componentWillUnmount() {
    this.props.dispatch(createAction("users/clearRewardStatus")());
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(createAction("users/fetchRewardStatus")());
    }
  }

  loadMore = () => {
    if (this.state.activeTab === rewardStatusModalTab.coupon && this.props.couponNextLink) {
      this.props.dispatch(
        createAction("pullrefreshandloadmore/updateState")({
          MyCouponIsLoadingMore: true,
        })
      );
      this.props.dispatch(createAction("myCoupon/loadMoreCoupon")());
    }
    if (this.state.activeTab === rewardStatusModalTab.points && this.props.pointStatusNextLink) {
      this.props.dispatch(
        createAction("pullrefreshandloadmore/updateState")({
          PointStatusIsLoadingMore: true,
        })
      );
      this.props.dispatch(createAction("users/loadMorePointStatus")());
    }
    if (this.state.activeTab === rewardStatusModalTab.stamp && this.props.stampStatusNextLink) {
      this.props.dispatch(
        createAction("pullrefreshandloadmore/updateState")({
          StampStatusIsLoadingMore: true,
        })
      );
      this.props.dispatch(createAction("users/loadMoreStampStatus")());
    }
  }

  render() {
    const tabs = [
      ...(NO_LEVEL ? [] : [{
        key: rewardStatusModalTab.level,
        name: this.props.t("profile_level"),
        content: (
          <Row >
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.level}
                title={this.props.t("profile_level")}
                itemList={[
                  {
                    name: this.props.levelName,
                    date: `${getLevelRenewTime(
                      this.props.levelExpiryDate
                    )}`,
                  },
                ]}
              />
            </Col>
          </Row>
        )
      }]),
      {
        key: rewardStatusModalTab.points,
        name: this.props.t("profile_points"),
        content: (
          <Row >
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.points}
                title={this.props.t("profile_points")}
                itemList={parsePointsStatus(this.props.pointStatus)}
                isLoading={this.props.isRefreshingPoints}
                isLoadingMore={this.props.PointStatusIsLoadingMore || false}
                loadMore={this.loadMore}
              />
            </Col>
          </Row>
        )
      },
      {
        key: rewardStatusModalTab.coupon,
        name: this.props.t("profile_coupons"),
        content: (
          <Row >
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.coupon}
                title={this.props.t("profile_coupons")}
                itemList={parseCouponListData(this.props.availableCoupons)}
                isLoading={this.props.MyCouponIsRefreshing || false}
                isLoadingMore={this.props.MyCouponIsLoadingMore || false}
                loadMore={this.loadMore}
              />
            </Col>
          </Row>
        )
      },
      {
        key: rewardStatusModalTab.stamp,
        name: this.props.t("profile_stamps"),
        content: (
          <Row >
            <Col>
              <RewardStatusTableView
                type={rewardStatusModalTab.stamp}
                title={this.props.t("profile_stamps")}
                itemList={parseStampStatus(this.props.stampStatus)}
                isLoading={this.props.isRefreshingStamp || false}
                isLoadingMore={this.props.StampStatusIsLoadingMore || false}
                loadMore={this.loadMore}
                totalEarnedStamps={this.props.totalEarnedStamps}
              />
            </Col>
          </Row>
        )
      },
    ]
    return (
      <Container className="reward-status-mobile-page-container">
        <TabContainer
          activeKey={this.state.activeTab}
          onSelect={(key) => this.setState({ activeTab: key })}
        >
          <Nav className="home-profile-custom-tab">
            {tabs.map((tab, index) => (
              <Nav.Item key={index}>
                <Nav.Link className="subheading" eventKey={tab.key}>
                  {tab.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <TabContent>
            {tabs.map((tab, index) => (
              <TabPane eventKey={tab.key} key={index}>
                {tab.content}
              </TabPane>
            ))}
          </TabContent>
        </TabContainer>
      </Container>)
  }
}

export default withTranslation()(RewardStatusMobile);
