import React, { Component } from "react";
import { connect } from "react-redux";
import "./RetainMembershipSection.scss";
import { withTranslation } from "react-i18next";
import { getLevelRenewTime } from "../../utils/DateTimeUtils";
import { enLocaleNumberFormatter } from "../../utils";
import { Progress } from "antd";
import { Modal } from "react-bootstrap";
import { isPadOrMobile } from "utils/ScreenUtils";

@connect(({ users }) => ({
  level: users.level,
  TPE: users.TPE,
  renewDate: users.renewDate,
  levelConfig: users.levelConfig,
  levelRetainProgress: users.levelRetainProgress,
  pointsToRetainLevel: users.pointsToRetainLevel,
  currentSpendingToRetain: users.currentSpendingToRetain
}))
class RetainMembershipSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isMobile = isPadOrMobile();
  }
  render() {
    const retainTPE = this.props.pointsToRetainLevel;
    const retainPercentage = this.props.levelRetainProgress; 
    const RetainModal = () => {
      return (
        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          scrollable={false}
          className={`retain-modal ${this.isMobile ? "mobile-view" : ""}`}
          centered
        >
          <Modal.Body className="retain-modal-body">
            <div className={`retain-modal-title ${this.isMobile ? "subheading" : "title-medium-1"}`}>{this.props.t("retainTheMembership")}</div>
            <div className={`retain-modal-content ${this.isMobile ? "body-1" : "field-text"}`}>{this.props.t("retaindescription")}</div>
            <div className="got-it-btn">
              <button
                className="right_btn button-large"
                onClick={() => {
                  this.setState({ showModal: false })
                }}
              >
                {this.props.t("gotIt")}
              </button>
            </div>
          </Modal.Body>
        </Modal>)
    }
    return (
      <div className="home-retain-membership-section d-flex flex-column">
        <div className="retain-title subheading">
          <div>{this.props.t("retainTheMembership")}</div>
          <div onClick={() => this.setState({ showModal: true })}>
            <img alt="icon" src={"/profile/retainLevel/info.png"} />
          </div>
        </div>
        <div className="retain-tpe-progress body-3">
          {this.props.t("HKD", { TPE: this.props.currentSpendingToRetain })}
          <Progress
            className="retain-progress"
            percent={retainPercentage * 100}
            showInfo={false}
            strokeWidth={8}
          />
        </div>
        <div className="spend-to-retain-text tag-menu-1">
          {`${this.props.t('retainSpend')} `}
          <p className="tag-menu-2">{`${this.props.t("HKD", { TPE: enLocaleNumberFormatter(retainTPE) })}`}</p>
          {` ${this.props.t('more')} `}
          <p className="tag-menu-2">{this.props.t('retainDue', { renewDate: getLevelRenewTime(this.props.renewDate) })}</p>
          {this.props.t('toRetain', { retainLevelName: this.props.t(`level${this.props.level || ''}`) })}
        </div>
        <RetainModal />
      </div>
    );
  }
}

export default withTranslation()(RetainMembershipSection);
