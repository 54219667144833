import queryString from 'query-string';

import BaseAPIHelper from 'services/BaseAPIHelper';


export const STORE_LIST_KEY = 'Stores';

export const getStoreCategories = () => {
  const search = queryString.stringify({
    'sort': 'display_priority,-id',
    'page[size]': 200,
    'page[number]': 1,
  }, {
    encode: false,
  });
  const url = `/store_categories?${search}`;
  return BaseAPIHelper.get({ link: url });
};

export const getStoreSubcategories = (categoryIds) => {
  const requests = categoryIds.map((categoryId) => {
    const search = queryString.stringify({
      'sort': 'display_priority',
    }, {
      encode: false,
    });
    const link = `/store_categories/${categoryId}/store_subcategories?${search}`;
    return BaseAPIHelper.get({ link });
  });
  return BaseAPIHelper.all(requests);
};

export const getStores = ({
  isAll,
  categoryId,
  subcategoryIds,
  regionId,
  districtIds,
  brandIds,
  pageLink,
}) => {
  if (pageLink) {
    return BaseAPIHelper.get({ link: pageLink });
  }

  const data = {
    'sort': 'display_priority',
    'page[number]': 1,
    'page[size]': 18,
  };

  if (isAll) {
    const allSearch = queryString.stringify(
      data,
      { encode: false, }
    );
    const link = `/stores?${allSearch}`;
    return BaseAPIHelper.get({ link });
  }

  if (categoryId) {
    data['filter[category]'] = categoryId;
  }
  if (subcategoryIds) {
    data['filter[subcategories]'] = subcategoryIds;
  }

  if (brandIds) {
    data['filter[brand]'] = brandIds;
  }

  if (regionId) {
    data['filter[region]'] = regionId;
  }
  if (districtIds) {
    data['filter[district]'] = districtIds;
  }

  const search = queryString.stringify(
    data,
    { encode: false, }
  );
  const link = `/stores?${search}`;
  return BaseAPIHelper.get({ link });
};

export const getAllBrands = () => {
  const link = '/brands?page[number]=1&page[size]=10000&sort=-id';
  return BaseAPIHelper.get({ link });
};

export const getRegions = () => {
  const link = '/regions?page[number]=1&page[size]=200';
  return BaseAPIHelper.get({ link });
};

export const getRegionDistricts = (regionIds) => {
  const requests = regionIds.map((id) => {
    const link = `/regions/${id}/districts`;
    return BaseAPIHelper.get({ link });
  });
  return BaseAPIHelper.all(requests);
};

export const getOneStore = (storeId) => {
  const link = `/stores/${storeId}`;
  return BaseAPIHelper.get({ link });
};


