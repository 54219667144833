import React, {useEffect } from "react";
import { connect } from "react-redux";
import { ssoAPIHelper } from "services/SSOAPIHelper";

const mapStateToProps = (state) => {
  return {
    language: state.users.language,
    isLogin: state.users.isLogin,
  };
};

const AuthRequiredView = ({
  language,
  isLogin,
  children,
}) => {
  useEffect(()=> {
    if (!isLogin) {
      if (!isLogin) {
        window.location.href = ssoAPIHelper.getLoginWebUri(
          language || 'en'
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLogin])

  return <>{isLogin && children}</>
}
export default connect(mapStateToProps)(AuthRequiredView);
