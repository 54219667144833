import React, { Component } from 'react';
import Markdown from 'react-markdown';
import { isPadOrMobile } from 'utils/ScreenUtils';

class ShortDescription extends Component {
  render() {
    return (
      <div className={`short_description ${isPadOrMobile() ? "body-1" : "field-text"}`}>
        <Markdown>{this.props.description}</Markdown>
      </div>
    );
  }
}

export default ShortDescription;
