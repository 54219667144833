import axios from "axios";
import i18n from "I18n";

const CMS_DATA_BASE_URL =
  process.env.REACT_APP_DOMAIN || "https://aillia.hkdev.motherapp.com";
const BASEURL = CMS_DATA_BASE_URL;


const baseAPIinstance = axios.create({
  baseURL: BASEURL,
  timeout: 5000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

class APIHelper {
  constructor() {
    this.isFakeEnvLogin = false;
    baseAPIinstance.interceptors.request.use(
      (config) => {
        if (
          this.authorizationToken !== undefined &&
          this.authorizationToken != null
        ) {
          config.headers.Authorization = `Bearer ${this.authorizationToken}`;
          config.headers.language = i18n.language;
          config.headers['Accept-Language'] = i18n.language;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  addAuthorization = (token) => {
    this.authorizationToken = token;
  };

  removeAuthorization = () => {
    this.authorizationToken = undefined;
  };

  get = ({ link, header = {} }) => {
    return baseAPIinstance
      .get(link, {
        headers: header,
      })
      .catch((error) => error.response);
  };

  post = ({ link, input, header = {} }) => {
    return baseAPIinstance
      .post(link, input, {
        headers: header,
      })
      .catch((error) => error.response);
  };

  patch = ({ link, input, header = {} }) => {
    return baseAPIinstance
      .patch(link, input, {
        headers: header,
      })
      .catch((error) => error.response);
  };

  put = ({ link, input, header = {} }) => {
    return baseAPIinstance
      .put(link, input, {
        headers: header,
      })
      .catch((error) => error.response);
  };

  delete = ({ link, header = {} }) => {
    return baseAPIinstance
      .delete(link, {
        headers: header,
      })
      .catch((error) => error.response);
  };

  deleteWithInput = ({ link, input, header = {} }) => {
    return baseAPIinstance
      .delete(link, { data: input }, {
        headers: header,
      })
      .catch((error) => error.response);
  };

  all = (requests) => {
    return Promise.all(requests)
      .then((response) => {
        if (!response?.length) {
          return { status: 404 };
        }
        const successList = [];
        response.forEach((item) => {
          if (item.status < 300) {
            successList.push(item);
          }
        });
        if (successList.length > 0) {
          return successList;
        }
        return { status: 404 };
      })
      .catch(() => {
        return { status: 404 };
      });
  };
}

const BaseAPIHelper = new APIHelper();

export default BaseAPIHelper;
