export const switchOneOfSelectedList = (selectedIDs, switchID) => {
  if (!selectedIDs.includes(switchID)) {
    const newList = [].concat(selectedIDs);
    newList.push(switchID);
    return newList;
  }
  const newList = selectedIDs.filter((id) => id !== switchID);
  return newList;
};

export const addAllToSelectedList = (selectedIDs, addIDs) => {
  const filteredIDs = selectedIDs.filter((selectedID) => {
    const notFound = !addIDs.includes(selectedID);
    return notFound;
  });
  const newList = filteredIDs.concat(addIDs);
  return newList;
};

export const removeAllFromSelectedList = (selectedIDs, removeIDs) => {
  const newList = selectedIDs.filter((selectedID) => {
    const notFound = !removeIDs.includes(selectedID);
    return notFound;
  });
  return newList;
};
