import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CustomAlert from 'component/base/CustomAlert';

import { createAction } from 'utils';

import './MyAccountDeleteAccount.scss';

@connect(({ users }) => ({
  deleteAccountSuccess: users.deleteAccountSuccess,
  deleteAccountError: users.deleteAccountError,
  isLoading: users.deleteAccountLoading,
}))
class MyAccountDeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      deleteButtonDisable: true,
      password: null,
      alertData: {},
      language: this.props.i18n.language,
    };
  }

  componentDidMount() {
    this.setAlertDefaultData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.i18n.language !== this.state.language) {
      this.setAlertDefaultData();
    }
    if (prevProps.deleteAccountSuccess !== this.props.deleteAccountSuccess) {
      this.setState({ deleteButtonDisable: true });
    }
    if (prevProps.isLoading !== this.props.isLoading && !this.props.isLoading) {
      if (!this.props.deleteAccountSuccess && this.props.deleteAccountError) {
        this.setState({
          alertData: {
            ...this.state.alertData,
            title: this.props.t(this.props.deleteAccountError),
            message: '',
            leftbtn: '',
            rightbtn: this.props.t('account_ok'),
            rightAction: () => {
              this.onHidden();
              this.setAlertDefaultData();
            },
          },
        });
      } else {
        this.setState({
          alertData: {
            ...this.state.alertData,
            onHide: () => {
              this.onHidden();
              this.props.dispatch(createAction('users/logout')());
              this.props.history.push('/home');
            },
            title: this.props.t('account_account_has_been_deleted'),
            message: '',
            leftbtn: '',
            rightbtn: this.props.t('account_ok_and_logout'),
            rightAction: () => {
              this.onHidden();
              this.props.dispatch(createAction('users/logout')());
              this.props.history.push('/home');
            },
          },
        });
      }
    }
  }

  setAlertDefaultData = () => {
    this.setState({
      password: null,
      deleteButtonDisable: true,
      language: this.props.i18n.language,
      alertData: {
        onHide: this.onHidden,
        title: this.props.t('account_confirm_to_delete'),
        message: this.modalBody(),
        leftbtn: this.props.t('account_confirm_delete'),
        leftAction: this.delete,
        rightbtn: this.props.t('account_cancel'),
        rightAction: this.onHidden,
      },
    });
  };

  delete = () => {
    if (this.state.password) {
      this.setState({
        alertData: {
          ...this.state.alertData,
          title: '',
        },
      });
      this.props.dispatch(
        createAction('users/deleteUserAccount')({
          password: this.state.password,
        }),
      );
      this.setState({password: null});
    }
  };

  onHidden = () => {
    this.setState({ modalShow: false });
  };

  modalBody = () => (
    <div className="account-model-body-section">
      <div className="modal-description">
        {this.props.t('account_confirm_description')}
      </div>
      <input
        type="password"
        className="common-item-value password-input field-text"
        value={this.state.password}
        placeholder={this.props.t('account_password_placeholder')}
        onChange={(e) => {
          this.setState({
            password: e.target.value,
          });
          if (e.target.value) {
            this.setState({
              deleteButtonDisable: false,
            });
          } else {
            this.setState({
              deleteButtonDisable: true,
            });
          }
        }}
      />
    </div>
  );

  render() {
    return (
      <Container className="common-shadow-section-container account-delete-account-section">
        <div className="common-section-title title-medium-2">
          {this.props.t('account_delete_account_lower')}
        </div>

        <button
          className="delete-button button-large"
          onClick={() => this.setState({ modalShow: true })}
        >
          {this.props.t('account_delete')}
        </button>

        <CustomAlert
          show={this.state.modalShow}
          onHide={this.state.alertData?.onHide}
          title={this.state.alertData?.title}
          message={this.state.alertData?.message}
          leftbtn={this.state.alertData?.leftbtn}
          leftAction={this.state.alertData?.leftAction}
          leftDisabled={this.state.deleteButtonDisable}
          rightbtn={this.state.alertData?.rightbtn}
          rightAction={this.state.alertData?.rightAction}
          isLoading={this.props.isLoading}
        />
      </Container>
    );
  }
}

export default withRouter(withTranslation()(MyAccountDeleteAccount));
