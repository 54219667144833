import React from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadMoreSpinner from "component/common/LoadMoreSpinner";
import TransactionListItem from "component/records/TransactionListItem";
import TransactionContent from "component/records/TransactionContent";
import OfferFloatActionButton from "component/offer/OfferFloatActionButton";
import NothingPage from "component/error/Nothing";
import { createAction } from "utils";
import {
  getScrollTop,
  getDocumentHeight,
  getWindowHeight,
  getScreenWidth,
  isMobile,
} from "utils/ScreenUtils";
import i18n from "I18n";

import "./TransactionList.scss";


@connect(({ transaction, pullrefreshandloadmore, users }) => ({
  ...transaction,
  ...pullrefreshandloadmore,
  language: users.language,
}))
class TransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTransactionId: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(createAction("transaction/refreshRewardClaimList")());
    this.transactionListNode.addEventListener(
      "scroll",
      this.bindHandleListScroll
    );
    window.addEventListener("scroll", this.bindHandleWindowScroll);
    if (!isMobile() && this.props.rewardClaimList?.length > 0) {
      this.setState({
        activeTransactionId: this.props.rewardClaimList[0].id,
      });
    }
  }

  componentWillUnmount() {
    this.transactionListNode.removeEventListener(
      "scroll",
      this.bindHandleListScroll
    );
    window.removeEventListener("scroll", this.bindHandleWindowScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(createAction("transaction/refreshRewardClaimList")());
    }
    if (this.props.rewardClaimList !== prevProps.rewardClaimList) {
      if (
        !isMobile() &&
        !(prevProps.rewardClaimList?.length > 0) &&
        this.props.rewardClaimList?.length > 0
      ) {
        const transactionData = this.props.rewardClaimList[0];
        this.setState({
          activeTransactionId: transactionData.id,
        });
      }
    }
  }

  shouldLoadMore = () => {
    return (
      this.props.TransactionListHasMoreData &&
      !(
        this.props.TransactionListIsRefreshing ||
        this.props.TransactionListIsLoadingMore
      )
    );
  };

  bindHandleListScroll = (event) => {
    let screenWidth = getScreenWidth();
    if (screenWidth >= 769) {
      const clientHeight = event.target.clientHeight;
      const scrollHeight = event.target.scrollHeight;
      const scrollTop = event.target.scrollTop;
      const isLoadArea = clientHeight + scrollTop > scrollHeight - 50;
      if (isLoadArea && this.shouldLoadMore()) {
        this.props.dispatch(createAction("transaction/loadMoreRewardClaimList")());
      }
    }
  };

  bindHandleWindowScroll = () => {
    let screenWidth = getScreenWidth();
    if (screenWidth < 769) {
      let scrollTopLength = getScrollTop();
      let windowHeight = getWindowHeight();
      let documentHeight = getDocumentHeight();
      let isLoadArea = scrollTopLength + windowHeight > documentHeight - 50;
      if (isLoadArea && this.shouldLoadMore()) {
        this.props.dispatch(createAction("transaction/loadMoreRewardClaimList")());
      }
    }
  };

  transactionItemDidClicked = (transactionId) => {
    this.setState({
      activeTransactionId: transactionId,
    });
    let screenWidth = getScreenWidth();
    if (screenWidth < 769) {
      this.props.history.push(`/transactionDetail/${transactionId}`);
    }
  };

  getTransactionItems = () => {
    if (this.props.rewardClaimList?.length > 0) {
      return this.props.rewardClaimList.map((transactionItemData, index) => (
        <TransactionListItem
          key={index}
          transactionData={transactionItemData}
          hasseparateLine={index === 0 ? false : true}
          isActive={
            this.state.activeTransactionId === transactionItemData.id
              ? true
              : false
          }
          clickAction={() =>
            this.transactionItemDidClicked(transactionItemData.id)
          }
        />
      ));
    }

    if (
      !this.props["TransactionListIsRefreshing"] &&
      !this.props["TransactionListIsLoadingMore"]
    )
      return <NothingPage />;
  };

  render() {
    return (
      <div>
        <Container className="transaction_container">
          <div className="transaction_list">
            <div className="transaction_list_title title-small">
              {i18n.t("records:reward_claim_tab")}
            </div>
            <div className="transaction_list_separateLine" />
            <div
              ref={(node) => (this.transactionListNode = node)}
              // className="transaction_list_listArea"
              className={`transaction_list_listArea ${
                !this.props.rewardClaimList?.length > 0 &&
                !this.props["TransactionListIsRefreshing"] &&
                !this.props["TransactionListIsLoadingMore"]
                  ? "with-nothing-area"
                  : ""
              }`}
            >
              {this.getTransactionItems()}
              <div className="transaction_list_loadmoreArea">
                {(this.props["TransactionListIsRefreshing"] ||
                  this.props["TransactionListIsLoadingMore"]) && (
                  <LoadMoreSpinner />
                )}
              </div>
            </div>
            <div className="transaction_list_button">
              <Button
                className="content_button btn_upload_receipt button-large"
                onClick={() => this.props.history.push("/transaction/create")}
              >
                {i18n.t("records:record_upload_receipt")}
              </Button>
            </div>
          </div>
          {this.state.activeTransactionId && (
            <TransactionContent
              transactionId={this.state.activeTransactionId}
              setEmptyActiveTransactionId={() => {
                this.setState({
                  activeTransactionId: null,
                });
              }}
            />
          )}
        </Container>
        <OfferFloatActionButton customClassName="transaction_list" />
      </div>
    );
  }
}

export default withRouter(TransactionList);
