import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Progress } from "antd";

import { createAction } from "utils";
import { getActionButonText } from "utils/CampaignUtil";
import { isMobile } from "utils/ScreenUtils";
import i18n from "I18n";

import "./OnlyForYouMobileCard.scss";
import stampGift from "assets/images/stampGift.png";

@connect(({ campaignDetail }) => ({}))
class OnlyForYouMobileCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _btndidClick = (event) => {
    if (isMobile()) {
      if (this.props.history) {
        this.props.dispatch(
          createAction("campaignDetail/getCampaignDetailAndGoRedeemPage")({
            campaignID: this.props.data.campaignId,
            callBackFunction: this._goRedeemPage,
          })
        );
      }
    } else {
      this._cardDidClick();
    }
    event.stopPropagation();
  };

  _goRedeemPage = (data) => {
    this.props.history.push("/campaign/detail", {
      campaign: data,
      campaignID: this.props.data.campaignId,
    });
  };

  _cardDidClick = () => {
    if (this.props.history) {
      // this.props.history.push("/campaign/" + this.props.data.campaignId);
      this.props.history.push("/campaign/" + this.props.data.campaignId);
    }
  };

  render() {
    let itemClassName = this.props.lastItem
      ? "onlyForYou_mobile_slider_item last_item"
      : "onlyForYou_mobile_slider_item";
    const earnedStamps = this.props.data?.earnedStamps || 0;
    const totalStamps = this.props.data?.totalStamps || 1;
    const campaginActionButtonText = getActionButonText(
      this.props.data.campaignType,
      this.props.data.requiredPoints,
      this.props.data.campaignStartDate
    )
    return (
      <div className={itemClassName} onClick={this._cardDidClick}>
        <div className="slider_item_image_container">
          <div className={`slider_item_image_dimmed_container ${this.props.data.outOfStock ? "slider_item_dimmed" : ""}`}>
            <Image
              src={this.props.data.campaignSquarePhoto}
              className="slider_item_image"
            />
            {this.props.data.brandImage && (
              <Image
                src={this.props.data.brandImage}
                className="slider_item_avatar"
                roundedCircle={true}
              ></Image>
            )}
            {this.props.data.leftDays && (
              <div className="slider_item_tag tag-menu-2">{this.props.data.leftDays}</div>
            )}
          </div>
          {this.props.data.outOfStock && (
            <div className="slider_item_tag campaign_card_out_of_stock_tag_class">
              <label className="slider_item_out_of_stock_tag_label tag-menu-2">
                {i18n.t("campaignDetail:campaign_card_out_of_stock_tag")}
              </label>
            </div>
          )}
        </div>
        <div className="slider_item_describe subheading">
          {this.props.data.campaignDescription}
        </div>
        {this.props.data.campaignActionButtonAvaiable &&
          campaginActionButtonText && (
            <Button className="slider_item_button button-small" onClick={this._btndidClick} disabled={!!this.props.data.outOfStock}>
              {campaginActionButtonText}
            </Button>
          )}
        {this.props.data.totalStamps && (
          <div className="slider_item_progress_container">
            <label className="body-2">{i18n.t('campaignDetail:campaignProgress')}</label>
            <div className="slider_item_progress">
              <Progress
                percent={Math.round((earnedStamps * 100) / totalStamps)}
                showInfo={false}
              />
              <div className="slider_item_progress_gift_icon">
                <img
                  src={stampGift}
                  className="slider_item_progress_image"
                  alt={"Gift"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(OnlyForYouMobileCard);
