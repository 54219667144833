import axios from "axios";

const CMS_DATA_BASE_URL =
  process.env.REACT_APP_DOMAIN || "https://aillia.hkdev.motherapp.com";
const BASEURL = CMS_DATA_BASE_URL;

const onlyForYouRefreshUrl =
  "/campaigns?sort=-display_start_date,-start_date,end_date,name,-id&filter[is_only_for_you]=true&page[number]=1&page[size]=20";
const campaignCategoriesUrl = "campaign_categories?sort=display_priority,-id&page[number]=1&page[size]=100";
const homeFeatureListRefreshUrl =
  "/campaigns?sort=display_priority&filter[is_featured]=true&page[number]=1&page[size]=20";
const latestOfferUrl =
  "campaigns?sort=-display_start_date,-start_date,end_date,name,-id&page[number]=1&page[size]=20";
const categoryCampaignUrl = (id) =>
  `/campaign_categories/${id}/campaigns?sort=-display_start_date,-start_date,end_date,name,-id&page[number]=1&page[size]=20`;
const homeBannersUrl = 
  "homepage_banners?sort=display_priority,-id"

export const BANNER_TYPES = {
  TYPE_CAMPAIGN: 'CAMPAIGN',
  TYPE_PRODUCT: 'PRODUCT',
};

const campaignAPIinstance = axios.create({
  baseURL: BASEURL,
  timeout: 5000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

class APIHelper {
  constructor() {
    this.isFakeEnvLogin = false;
    campaignAPIinstance.interceptors.request.use(
      (config) => {
        if (
          this.authorizationToken !== undefined &&
          this.authorizationToken != null
        ) {
          config.headers.Authorization = `Bearer ${this.authorizationToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  addAuthorization = (token) => {
    this.authorizationToken = token;
  };

  removeAuthorization = () => {
    this.authorizationToken = undefined;
  };

  getCampaignDetail = (campaignID) => {
    return campaignAPIinstance
      .get(`/campaigns/${campaignID}`)
      .catch((error) => error);
  };

  getCampaignBlackout = (campaignID) => {
    return campaignAPIinstance
      .get(`/campaigns/${campaignID}/blackout_check`)
      .catch((error) => error);
  }


  participateCampaign = (customerID, payload) => {
    return campaignAPIinstance.post(
      `/customers/${customerID}/campaign_participation_records`,
      payload
    ).catch((error) => error.response);
  };

  checkAvailableLink = (link, pageLink) => {
    let url = pageLink === null || pageLink === undefined ? link : pageLink;
    return url;
  };

  getHomeOnlyForYouData = () => {
    return campaignAPIinstance
      .get(onlyForYouRefreshUrl)
      .catch((error) => error);
  };

  getHomeFeaturedData = ({ pageLink }) => {
    const homeFeaturesLink = this.checkAvailableLink(
      homeFeatureListRefreshUrl,
      pageLink
    );
    return campaignAPIinstance.get(homeFeaturesLink).catch((error) => error);
  };

  getOfferCategories = () => {
    return campaignAPIinstance
      .get(campaignCategoriesUrl)
      .catch((error) => error);
  };

  getLatestOffer = ({ pageLink }) => {
    const latestOfferLink = this.checkAvailableLink(latestOfferUrl, pageLink);
    return campaignAPIinstance.get(latestOfferLink).catch((error) => error);
  };

  getOfferWithCategory = ({ pageLink, offerCategoryId }) => {
    let offerCategoryCampaignLink = this.checkAvailableLink(
      categoryCampaignUrl(offerCategoryId),
      pageLink
    );
    return campaignAPIinstance
      .get(offerCategoryCampaignLink)
      .catch((error) => error);
  };

  getHomeBanners = () => {
    return campaignAPIinstance.get(homeBannersUrl).catch((error) => error);
  };

  getEarningRulesForStamp = (campaignID) => {
    return campaignAPIinstance
      .get(`/stamp_campaigns/${campaignID}/link_earning_rules`)
      .catch((error) => error);
  };
  
  getRewardsForStampCampaign = (campaignID) => {
    return campaignAPIinstance
      .get(`/stamp_campaigns/${campaignID}/stamp_rewards`)
      .catch((error) => error);
  };
  
  customerAcquireStampReward = (campaignID, payload) => {
    return campaignAPIinstance
      .post(`/stamp_campaigns/${campaignID}/stamp_rewards/acquire`,
      payload
    ).catch((error) => error);
  };

}

export const CampaignAPIHelper = new APIHelper();

export const CAMPAIGN_TYPE_COUPON = "COUPON_CAMPAIGN";
export const CAMPAIGN_TYPE_EARNING = "EARNING_CAMPAIGN";
export const CAMPAIGN_TYPE_GENERAL_MESSAGE = "GENERAL_MESSAGE_CAMPAIGN";
export const CAMPAIGN_TYPE_STAMP = 'STAMP_CAMPAIGN';
export const EARNING_RULE_QR_CODE = "QR_CODE_SCANNING";
export const EARNING_RULE_GPS = "GPS_CHECK_IN";
export const EARNING_RULE_REFER = "MEMBER_REFERRAL";
export const EARNING_RULE_PURCHASE = "GENERAL_PURCHASE";
export const EARNING_RULE_BIRTH = "BIRTHDAY";
export const EARNING_RULE_NEW_MEMBER = "NEW_MEMBER";
export const EARNING_RULE_LEVEL_UPGRADE = "LEVEL_UPGRADE";
