import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";

import MultiChoiceWithSubList from "component/store/MultiChoiceWithSubList";
import FilterActionBar from "component/store/FilterActionBar";
import { updateQueryString } from "utils/UrlUtils";

import "./StoreCategoryDropdown.scss";


const mapStateToProps = state => {
  return {
    language: state.users.language,
    storeCategoryList: state.store.storeCategoryList,
    storeSubCategoryList: state.store.storeSubCategoryList,
    storeSelectedSubCategoryIDs: state.store.storeSelectedSubCategoryIDs,
  };
};

function StoreCategoryDropdown({
  language,
  storeCategoryList,
  storeSubCategoryList,
  storeSelectedSubCategoryIDs,
  onHide,
}) {
  const [newCategoryID, setNewCategoryID] = useState([]);
  const [newSubCategoryIDs, setNewSubCategoryIDs] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const history = useHistory();
  const location = useLocation();

  const updateCategoryFilter = (newCategoryID, newSubCategoryIDs) => {
    const queryDict = {
      'sf_category': !!newCategoryID ? newCategoryID : null,
      'sf_sub_category': newSubCategoryIDs?.length > 0 ? newSubCategoryIDs : null,
    };
    const newUrl = updateQueryString(location, queryDict);
    history.push(newUrl);
  };

  useEffect(() => {
    if (
      storeCategoryList?.length > 0
      && storeSubCategoryList?.length > 0
    ) {
      const items = [];
      for (const category of storeCategoryList) {
        const item = {
          id: category.id,
          name: category.name,
          subItems: [],
        };
        for (const x of storeSubCategoryList) {
          if (Number(x.categoryId) !== Number(category.id)) {
            continue;
          }
          for (const subCategory of x.subcategories) {
            const subItem = {
              id: subCategory.id,
              name: subCategory.name,
            };
            item.subItems.push(subItem);
          }
        }
        items.push(item);
      }
      setCategoryData(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeCategoryList, storeSubCategoryList]);

  return (
    <Card className="filter_dropdown_content">
      <Card.Body>
        <MultiChoiceWithSubList
          itemsData={categoryData}
          selectedSubItemIDs={storeSelectedSubCategoryIDs}
          onSelectedSubItemsChange={(mainID, subIDs) => {
            setNewCategoryID(mainID);
            setNewSubCategoryIDs(subIDs);
          }}/>
      </Card.Body>
      <Card.Footer>
        <FilterActionBar
          onReset={() => {
            updateCategoryFilter(null, []);
            onHide();
          }}
          onApply={() => {
            updateCategoryFilter(newCategoryID, newSubCategoryIDs);
            onHide();
          }}
        />
      </Card.Footer>
    </Card>
  );
}

export default connect(mapStateToProps)(StoreCategoryDropdown);

