import axios from "axios";
import i18n from "../I18n";

const CMS_DATA_BASE_URL =
  process.env.REACT_APP_DOMAIN || "https://aillia.hkdev.motherapp.com";
const BASEURL = CMS_DATA_BASE_URL;

const uploadFileAPIinstance = axios.create({
  baseURL: BASEURL,
  timeout: 5000,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data; charset=utf-8;",
  },
});

class APIHelper {
  constructor() {
    this.isFakeEnvLogin = false;
    uploadFileAPIinstance.interceptors.request.use(
      (config) => {
        if (
          this.authorizationToken !== undefined &&
          this.authorizationToken != null
        ) {
          config.headers.Authorization = `Bearer ${this.authorizationToken}`;
          config.headers.language = i18n.language;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  addAuthorization = (token) => {
    this.authorizationToken = token;
  };

  uploadFile = ({ input }) => {
    return uploadFileAPIinstance
      .post(`/customer-app/files`, input)
      .catch((error) => error);
  };
}
export const UploadFileAPIHelper = new APIHelper();

export const getFileData = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return formData;
};
