import React from "react";
import { useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import { Row, Image } from "react-bootstrap";
import "./SharedSection.scss";
import { withTranslation } from "react-i18next";
import shareImage from 'assets/images/share.png';

const SharedSection = ({
  t,
  title,
  message,
}) => {
  const dispatch = useDispatch();

  const shareAction = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: message,
      })
    } else {
      navigator.clipboard.writeText(message);
      dispatch({
        type: "navBars/updateState",
        payload: {
          toastShowing: {
            value: true,
            content: t("copySuccessfully"),
            toastIcon: "/coupon/copyCodeSuccessIcon/check.svg",
          },
        },
      });
    }
  }

  return (
    <Container className="shared_container">
      <Row className="shared">
        <button
          className="share-button"
          onClick={shareAction}
        >
          <Image
            src={shareImage}
            className="share-image"
          />
          <label className="share-label button-large">
            {navigator.share ? t("share") : t("copy_to_share")}
          </label>
        </button>
      </Row>
    </Container>
  );
}

export default withTranslation()(SharedSection);
