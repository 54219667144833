import { createAction } from "../utils";
import { CampaignAPIHelper } from "../services/CampaignAPIHelper";
import {
  campaignValidation,
  campaignCheck,
} from "../services/CampaignValidation";
import { validateApi } from "../utils/ValidateApi";
import { subHeaderLink, assembleCampaign, campaignListWithUpdatedEarnedStamp } from "../utils/CampaignUtil";
import { hasMoreData, getDataWithDelay } from "../utils/PullAndRefreshUtil";

export default {
  namespace: "offerList",
  state: {
    offerDataMap: {},
    offerLinkMap: {},
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    offerListRefreshWithAssemble(state, { payload }) {
      const offerList = assembleCampaign(payload.offers);
      return {
        ...state,
        offerDataMap: {
          ...state.offerDataMap,
          [payload.storeDataKey]: offerList,
        },
        offerLinkMap: {
          ...state.offerLinkMap,
          [payload.storeDataKey]: payload.nextLink,
        },
      };
    },
    offerListLoadMoreWithAssemble(state, { payload }) {
      const newOfferList = assembleCampaign(payload.offers);
      const offerList = state.offerDataMap[payload.storeDataKey];
      return {
        ...state,
        offerDataMap: {
          ...state.offerDataMap,
          [payload.storeDataKey]: [...offerList, ...newOfferList],
        },
        offerLinkMap: {
          ...state.offerLinkMap,
          [payload.storeDataKey]: payload.nextLink,
        },
      };
    },
    updateStampCampaignEarnedStampCount(state, { payload }) {
      const oldOfferDataMap = state.offerDataMap;
      let newOfferDataMap = {};
      for (const offerKey in oldOfferDataMap) {
        newOfferDataMap[offerKey] = campaignListWithUpdatedEarnedStamp(oldOfferDataMap[offerKey], payload);
      }
      return {
        ...state,
        offerDataMap: newOfferDataMap
      }
    },
  },
  effects: {
    *loadOfferList({ payload }, { put }) {
      const { categoryId, storeDataKey } = payload;

      let services = CampaignAPIHelper.getOfferWithCategory;
      if (storeDataKey === "LATEST") {
        services = CampaignAPIHelper.getLatestOffer;
      }

      const data = yield getDataWithDelay(
        services,
        { offerCategoryId: categoryId },
        `offer${storeDataKey}`,
        false
      );
      let nextLink = "";
      if (data?.status === 200) {
        nextLink = subHeaderLink(data.headers.link);
        const offers = validateApi(
          data.data,
          campaignValidation,
          campaignCheck
        );

        yield put(
          createAction("offerListRefreshWithAssemble")({
            offers,
            storeDataKey,
            nextLink,
          })
        );
      }
      yield hasMoreData(`offer${storeDataKey}`, nextLink);
    },

    *offerListLoadMore({ payload }, { put, select }) {
      const { categoryId, storeDataKey } = payload;
      const offerLinkMap = yield select(
        (state) => state.offerList.offerLinkMap
      );
      const offerLink = offerLinkMap[storeDataKey];
      let service = CampaignAPIHelper.getOfferWithCategory;
      if (storeDataKey === "LATEST") {
        service = CampaignAPIHelper.getLatestOffer;
      }

      const data = yield getDataWithDelay(
        service,
        { pageLink: offerLink, offerCategoryId: categoryId },
        `offer${storeDataKey}`,
        true
      );
      let nextLink = "";
      if (data?.status === 200) {
        nextLink = subHeaderLink(data.headers.link);
        const offers = validateApi(
          data.data,
          campaignValidation,
          campaignCheck
        );
        yield put(
          createAction("offerListLoadMoreWithAssemble")({
            offers,
            storeDataKey,
            nextLink,
          })
        );
      }
      yield hasMoreData(`offer${storeDataKey}`, nextLink);
    },
  },
};
