import React from "react";
import "./error.scss";
import ErrorImage from "../../assets/images/error.svg";
import { useHistory } from "react-router-dom";
import i18n from "../../I18n";

export default function NotFoundPage() {
  const history = useHistory();

  return (
    <div className="nothing-area">
      <img alt="" src={ErrorImage} />

      <label className="nothing-title title-medium-2">{i18n.t("page_not_found")}</label>

      <label className="nothing-content field-text">{i18n.t("cannnot_find_page")}</label>

      <button
        className="back-home-button button-large"
        onClick={() => {
          history.push("/home");
        }}
      >
        <label>{i18n.t("back_to_home")}</label>
      </button>
    </div>
  );
}
