import {
  getCustomerSubscribeDetail,
  updateCustomerSubscribeEmail,
} from 'services/CustomerAPIHelper';

import { createAction } from 'utils';

export default {
  namespace: 'unsubscribe',
  state: {
    unsubscribeCustomerDetail: {},
    unsubscribeCustomerDetailLoading: false,
    unsubscribeCustomerDetailError: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    assembleUnsubscribeEmailDetail(state, { payload }) {
      const { data } = payload;
      const phoneNumber =
        Number(data?.mobile_phone_number_country_code) &&
        Number(data?.mobile_phone_number_subscriber_number)
          ? `+${data.mobile_phone_number_country_code} ${data.mobile_phone_number_subscriber_number}`
          : null;
      const unsubscribeCustomerDetail = {
        uid: data?.sso_uid,
        signUpMethod: data?.sign_up_method,
        email: data?.email_address,
        phoneNumber: phoneNumber,
        directMarketingViaEmail: data?.direct_marketing_via_email,
      };
      return {
        ...state,
        unsubscribeCustomerDetail,
        unsubscribeCustomerDetailLoading: false,
        unsubscribeCustomerDetailError: false,
      };
    },

    resetSubscribeEmail(state, { payload }) {
      return {
        ...state,
        unsubscribeCustomerDetail: {},
        unsubscribeCustomerDetailLoading: false,
        unsubscribeCustomerDetailError: false,
      };
    },
  },

  effects: {
    getCustomerSubscribeEmailDetail: [
      function* ({ payload }, { call, put, select }) {
        const { token } = payload;
        yield put(
          createAction('updateState')({
            unsubscribeCustomerDetail: {},
            unsubscribeCustomerDetailLoading: true,
            unsubscribeCustomerDetailError: false,
          }),
        );
        const response = yield call(getCustomerSubscribeDetail, token);
        console.log('response.status', response.status);
        if (response.status === 200) {
          yield put(
            createAction('assembleUnsubscribeEmailDetail')({
              data: response.data,
            }),
          );
        } else {
          yield put(
            createAction('updateState')({
              unsubscribeCustomerDetailLoading: false,
              unsubscribeCustomerDetailError: true,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    updateCustomerSubscribeEmail: [
      function* ({ payload }, { call, put, select }) {
        const { token, directMarketingViaEmail, resultCallback } = payload;
        const input = {
          direct_marketing_via_email: directMarketingViaEmail,
          token,
        };
        const response = yield call(updateCustomerSubscribeEmail, input);
        if (response.status === 200) {
          yield put(
            createAction('assembleUnsubscribeEmailDetail')({
              data: response.data,
            }),
          );
          if (resultCallback) {
            yield resultCallback();
          }
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
