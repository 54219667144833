import {
  createAction,
  getTranslationData,
  arrayEqual,
} from 'utils';
import {
  hasMoreData,
  getDataWithDelay,
  refresh,
} from "utils/PullAndRefreshUtil";
import { subHeaderLink, assembleCampaign } from "utils/CampaignUtil";
import {
  getRegions,
  getRegionDistricts,
  getStoreCategories,
  getStoreSubcategories,
  getAllBrands,
  getStores,
  getOneStore,
  STORE_LIST_KEY,
} from "services/StoreAPIHelper";


function assembleCategory(data) {
  const result = data.map((category) => {
    const translation = getTranslationData(category);
    let { name } = category;
    if (translation) {
      name = translation.name;
    }
    return {
      displayPriority: category.display_priority,
      id: category.id,
      name,
      categoryId: category.category,
    };
  });
  return result.sort((a, b) =>
    a.displayPriority > b.displayPriority ? -1 : 1
  );
}

const assembleSubcategory = (data) => {
  if (!data) {
    return [];
  }
  return data.map((response) => {
    const subcategory = assembleCategory(response.data);
    const configList = response.config.url.split('/');
    return {
      categoryId: configList[2],
      subcategories: subcategory,
    };
  });
}

function assembleRegion(data) {
  return data.map((item) => {
    const translation = getTranslationData(item);
    let { name } = item;
    if (translation) {
      name = translation.name;
    }
    return {
      id: item.id,
      name,
      region: item.region,
    };
  });
}

function assembleDistricts(data) {
  return data?.map((response) => {
    const configList = response.config.url.split('/');
    return {
      region: configList[2],
      district: assembleRegion(response.data),
    };
  });
}

function getCampaigns(data) {
  return assembleCampaign(data);
}

function assembleStore(item) {
  let { name, address, description } = item;
  const translation = getTranslationData(item);
  if (translation) {
    name = translation.name;
    address = translation.address;
    description = translation.description;
  }
  const brandTranslation = getTranslationData(item.brand);
  const brandImage = brandTranslation?.icon_url || '';
  const campaigns = getCampaigns(item.campaigns);
  const campaignsWithBrandImage = campaigns.map(campaign => ({
    ...campaign,
    brandImage,
  }))

  const store = {
    address,
    brandId: item.brand?.id,
    brandName: brandTranslation?.name || item.brand?.name,
    creationDate: item.creation_date,
    description,
    displayPriority: item.display_priority,
    district: item.district,
    emailAddress: item.email_address,
    id: item.id,
    lastModifiedDate: item.last_modified_date,
    latitude: item.latitude,
    longitude: item.longitude,
    name,
    photoUrl: item.photo_url,
    subcategories: assembleCategory(item.subcategories),
    phoneNumberCountryCode: item.phone_number_country_code,
    subscriberNumber: item.phone_number_subscriber_number,
    campaigns: campaignsWithBrandImage,
  };
  return store;
}

function assembleStores(data) {
  const result = data.map((item) => {
    return assembleStore(item);
  });
  const uniqueStore = [];
  result.forEach((item) => {
    const exist = uniqueStore.some((unique) => unique.id === item.id);
    if (!exist) {
      uniqueStore.push(item);
    }
  });
  return uniqueStore;
}

const getDefault = () => {
  return {
    storeCategoryList: [],
    storeSubCategoryList: [],
    storeSelectedCategoryID: null,
    storeSelectedSubCategoryIDs: [],

    storeBrands: [],
    storeSelectedBrandIDs: [],

    storeRegions: [],
    storeDistricts: [],
    storeSelectedDistrictIDs: [],

    store: {},

    stores: [],
    storesLanguage: null,
    pageLink: '',

    detailRefreshing: false,
    campaignStore: [],
  };
};

export default {
  namespace: "store",
  state: {
    ...getDefault(),
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    assembleStore(state, { payload }) {
      const store = assembleStore(payload.store);

      return {
        ...state,
        store: {
          ...state.store,
          [store.id]: store,
        },
        detailRefreshing: false,
      };
    },

    assembleStoreRefresh(state, { payload }) {
      const stores = assembleStores(payload.stores);
      return {
        ...state,
        stores,
        storesLanguage: payload.language,
        pageLink: payload.nextLink,
      };
    },

    assembleStoreLoadmore(state, { payload }) {
      const stores = assembleStores(payload.stores);
      return {
        ...state,
        stores: [
          ...state.stores,
          ...stores
        ],
        pageLink: payload.nextLink,
      };
    },

    clearData(state, { payload }) {
      return {
        ...getDefault(),
      };
    },
  },

  effects: {
    storeInitData: [
      function*(action, { call, put, select, all }) {
        yield all([
          put(createAction('getStoreCategories')()),
          put(createAction('getBrands')()),
          put(createAction('getStoreRegions')()),
        ]);
      },
      { type: 'takeLatest' },
    ],

    updateFilter: [
      function*({ payload }, { call, put, select, all }) {
        const {
          storeSelectedCategoryID,
          storeSelectedSubCategoryIDs,
          storeSelectedBrandIDs,
          storeSelectedDistrictIDs,
        } = yield select((state) => state.store);

        const newStoreSelectedCategoryID = payload?.storeSelectedCategoryID;
        const newStoreSelectedSubCategoryIDs = payload?.storeSelectedSubCategoryIDs;
        const newStoreSelectedBrandIDs = payload?.storeSelectedBrandIDs;
        const newStoreSelectedDistrictIDs = payload?.storeSelectedDistrictIDs;

        const data = {};
        if (storeSelectedCategoryID !== newStoreSelectedCategoryID) {
          data.storeSelectedCategoryID = newStoreSelectedCategoryID;
        }
        if (!arrayEqual(storeSelectedSubCategoryIDs, newStoreSelectedSubCategoryIDs)) {
          data.storeSelectedSubCategoryIDs = newStoreSelectedSubCategoryIDs;
        }
        if (!arrayEqual(storeSelectedBrandIDs, newStoreSelectedBrandIDs)) {
          data.storeSelectedBrandIDs = newStoreSelectedBrandIDs;
        }
        if (!arrayEqual(storeSelectedDistrictIDs, newStoreSelectedDistrictIDs)) {
          data.storeSelectedDistrictIDs = newStoreSelectedDistrictIDs;
        }
        if (Object.keys(data).length > 0) {
          yield put(createAction('updateState')(data));
          yield put(createAction('getStoresWithRefresh')());
        }
      },
      { type: 'takeLatest' },
    ],

    getStoreRegions: [
      function*(action, { call, put, select, all }) {
        const response = yield call(getRegions);
        if (!response || response.status > 300) {
          return;
        }
        const regions = assembleRegion(response.data);
        const regionIds = regions.map((item) => item.id);
        const districtResponses = yield call(
          getRegionDistricts,
          regionIds
        );
        const districts = assembleDistricts(districtResponses);
        yield put(
          createAction('updateState')({
            storeRegions: regions,
            storeDistricts: districts,
          })
        );
      },
      { type: 'takeLatest' },
    ],


    getStoreCategories: [
      function* ({ payload }, { call, put, select }) {
        const categoryResponse = yield call(getStoreCategories);
        if (!categoryResponse || categoryResponse.status > 300) {
          return;
        }
        const categories = assembleCategory(categoryResponse.data);
        console.log("categories", categories);

        const categoryIds = categories.map((category) => category.id);
        const subCategoryResponses = yield call(
          getStoreSubcategories,
          categoryIds
        );
        if (!subCategoryResponses || subCategoryResponses.status > 300) {
          return;
        }
        const subcategories = assembleSubcategory(subCategoryResponses);
        // console.log('subcategories', subcategories);
        yield put(
          createAction('updateState')({
            storeCategoryList: categories,
            storeSubCategoryList: subcategories,
          })
        );
      },
      { type: "takeLatest" },
    ],

    getBrands: [
      function*(action, { call, put, select, all }) {
        const response = yield call(getAllBrands);
        if (!response || response.status > 300) {
          return;
        }
        const brands = response.data;
        const storeBrands = brands.map((brand, index) => {
          const translation = getTranslationData(brand);
          if (translation) {
            return {
              id: translation.id,
              name: translation.name,
              description: translation.description,
            };
          }
          return {
            id: brand.id,
            name: brand.name,
            description: brand.description,
          };
        });
        yield put(
          createAction('updateState')({
            storeBrands,
          })
        );
      },
      { type: 'takeLatest' },
    ],

    getStoresWithRefresh: [
      function*({ payload }, { call, put, select, all }) {
        const {
          stores,
          storeSelectedSubCategoryIDs,
          storeSelectedBrandIDs,
          storeSelectedDistrictIDs,
        } = yield select((state) => state.store);
        const language = yield select((state) => state.users.language);
        const hasOldData = stores && stores.length;
        yield put({
          type: 'updateState',
          payload: {
            stores: [],
            storesLanguage: null,
          },
        });

        let subcategoryIds = null;
        if (storeSelectedSubCategoryIDs?.length > 0) {
          subcategoryIds = storeSelectedSubCategoryIDs;
        }
        let districtIds = null;
        if (storeSelectedDistrictIDs?.length > 0) {
          districtIds = storeSelectedDistrictIDs;
        }
        let brandIds = null;
        if (storeSelectedBrandIDs?.length > 0) {
          brandIds = storeSelectedBrandIDs;
        }
        let isAll = false;

        if (
          !subcategoryIds &&
          !districtIds &&
          !brandIds
        ) {
          isAll = true;
        }
        const serviceWithArgs = [
          getStores,
          {
            isAll,
            categoryId: null,
            subcategoryIds,
            regionId: null,
            districtIds,
            brandIds,
            pageLink: '',
          },
        ];
        function* onGetDataSuccess(response, nextLink) {
          yield put(
            createAction('assembleStoreRefresh')({
              stores: response.data,
              language,
              nextLink,
            })
          );
          yield hasMoreData(STORE_LIST_KEY, nextLink);
        }
        function* onFailed() {
        }
        yield refresh(
          STORE_LIST_KEY,
          hasOldData,
          serviceWithArgs,
          onGetDataSuccess,
          onFailed
        );
      },
      { type: 'takeLatest' },
    ],

    getStoresWithFilterLoadMore: [
      function*({ payload }, { call, put, select, all }) {
        const pageLink = yield select((state) => state.store.pageLink);
        if (!pageLink) {
          return;
        }
        const response = yield getDataWithDelay(
          getStores,
          { pageLink },
          STORE_LIST_KEY,
          true,
        );
        let nextLink = '';
        if (response?.status === 200) {
          nextLink = subHeaderLink(response.headers.link);
          yield put(
            createAction('assembleStoreLoadmore')({
              stores: response.data,
              nextLink,
            })
          );
        }
        yield hasMoreData(STORE_LIST_KEY, nextLink);
      },
      { type: 'takeLatest' },
    ],

    getOneStore: [
      function*({ payload }, { call, put, select }) {
        const storeId = payload.id;
        const store = yield select((state) => state.store.store);
        yield put(
          createAction('updateState')({
            detailRefreshing: true,
          })
        );
        const response = yield call(getOneStore, storeId);
        if (response && response.status === 200) {
          yield put(
            createAction('assembleStore')({
              store: response.data,
            })
          );
          return;
        }
        yield put(
          createAction('updateState')({
            store: { ...store, [storeId]: {} },
            detailRefreshing: false,
          })
        );
      },
      { type: 'takeLatest' },
    ],

  },
};
