import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import i18n from 'I18n';

import RightArrow from 'assets/chevron-right.svg';

import './CouponCard.scss';
import { isPadOrMobile } from 'utils/ScreenUtils';

class CouponCard extends Component {
  cardBeClicked = () => {
    if (this.props.coupon.unavailable) {
      if (this.props.clickAction) {
        this.props.clickAction(
          i18n.t('coupon:unavailablePopUpTitle'),
          i18n.t('coupon:unavailablePopUpMessage', {
            date: this.props.coupon.displayDate,
          }),
        );
      }
    } else {
      this.props.history.push('/coupon/' + this.props.coupon.id, {
        couponId: this.props.coupon.id,
        coverPhotoUrl: this.props.coupon.coverPhotoUrl,
      });
    }
  };

  showUsedOrExpiredTag = () => {
    if (this.props.coupon.isUsed) {
      return <label className="coupon_used_tag tag-menu-2">Used</label>;
    } else if (this.props.coupon.isExpired) {
      return <label className="coupon_expired_tag tag-menu-2">Expired</label>;
    }
  };

  render() {
    const dimmedClass = (className) => {
      if (this.props.coupon.isUsed || this.props.coupon.isExpired) {
        const dimmedClassName = className + ' coupon_dimmed';
        return dimmedClassName;
      } else {
        return className;
      }
    };

    return (
      <Card className="coupon_card" onClick={() => this.cardBeClicked()}>
        <div className={dimmedClass('coupon_img')}>
          <img
            variant="top"
            alt="coupon"
            className={'card_img'}
            src={this.props.coupon.coverPhotoUrl}
          />
        </div>
        <div
          className="coupon_oval_container"
          hidden={!this.props.coupon.brandIconUrl}
        >
          <img
            alt="icon"
            className="coupon_oval_icon"
            src={this.props.coupon.brandIconUrl}
          ></img>
        </div>
        <div className="triangle" hidden={!this.props.coupon.isNew}></div>
        <label className="new_label tag-menu-2" hidden={!this.props.coupon.isNew}>
          {i18n.t('coupon:new')}
        </label>

        {/* <div className="coupon_image_border"></div> */}
        <Card.Body className="coupon_card_body">
          {this.props.coupon.isVirtualCoupon && (
            <div
              className="virtual_coupon_select"
              onClick={(event) => {
                if (isPadOrMobile()) {
                  event.stopPropagation();
                  this.props.history.push(
                    `/coupon/${this.props.coupon.id}/select_store`,
                  );
                }
              }}
            >
              <span className={window.innerWidth < 575 ? "caption" : "tag-menu-1"}>{i18n.t('select_pick_up_store')}</span>
              <img alt="select" src={RightArrow} />
            </div>
          )}
          {/* <div className={dimmedClass("coupon_expiry_date")}> */}
          <div
            className={dimmedClass(
              `${
                this.props.coupon.lessThan6Days
                  ? 'less_6_days_expiry_date tag-menu-2'
                  : 'coupon_expiry_date tag-menu-2'
              }`,
            )}
          >
            {/* {this.showUsedOrExpiredTag()} */}
            {this.props.coupon.unavailable
              ? i18n.t('coupon:startDate')
              : i18n.t('coupon:expireDate')}
          </div>
          <div
            className={dimmedClass(
              this.props.coupon.lessThan6Days
                ? 'less_6_days body-3'
                : 'available_status body-3',
            )}
          >
            {this.props.coupon.displayDate === null ||
            this.props.coupon.displayDate === ''
              ? i18n.t('coupon:alwaysActive')
              : this.props.coupon.displayDate}
          </div>
          <div className={dimmedClass('coupon_message body-2')}>
            {this.props.coupon.name}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default withRouter(CouponCard);
