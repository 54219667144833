const Ajv = require('ajv');

const ajv = new Ajv({
  useDefaults: true,
  coerceTypes: true,
  allErrors: true,
});
export const validateApi = (data, fillSchema, checkSschema) => {
  checkApiData(data, checkSschema);
  return fillDataWithDefault(data, fillSchema);
};

function fillDataWithDefault(data, fillSchema) {
  const validate = ajv.compile(fillSchema);
  validate(data);
  return data;
}

function checkApiData(data, checkSschema) {
  const validate = ajv.compile(checkSschema);
  const validateResult = validate(data);
  if (!validateResult) {
    // Toast.show(`Invalid: ${ajv.errorsText(validate.errors)}`);
    console.log(`Invalid: ${ajv.errorsText(validate.errors)}`);
  }
}
