import React, { Component } from "react";
import { Modal, Table } from "react-bootstrap";
import { connect } from "react-redux";
import i18n from "../../I18n";
import "./LevelRuleModal.scss";

@connect(({ users }) => ({
  levelConfig: users.levelConfig,
}))
class LevelRuleModal extends Component {
  render() {
    console.log("@@18: ", this.props.levelConfig);
    return (
      <Modal
        centered
        show={this.props.showLevelRule}
        onHide={this.props.handleOnHide}
        className="level-rule-modal"
      >
        <Modal.Header>
          <div className="level-rule-aillia-title">
            <img
              alt="logo"
              src="/logo/logo.png"
              srcSet="/logo/logo@2x.png 2x, /logo/logo@3x.png 3x"
            />
            <button
              className="level-rule-aillia-close-btn"
              onClick={this.props.handleOnHide}
            >
              <img
                alt="close"
                src="/menu/menuClose.png"
                srcSet="/menu/menuClose@2x.png 2x, /menu/menuClose@3x.png 3x"
              />
            </button>
          </div>
          <div className="level-rule-title title-medium-1">{i18n.t("levelRules")}</div>
          <button
            onClick={this.props.handleOnHide}
            className="level-rule-close-btn"
          >
            <img
              alt="close"
              src="/profile/close.png"
              srcSet="/profile/close@2x.png 2x, /profile/close@3x.png 3x"
            />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesIntroductionTitle")}
            </div>
            <div className="level-rule-detail-message body-1">
              {i18n.t("levelRulesIntroductionText")}
            </div>
          </div>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesValidityTitle")}
            </div>
            <div className="level-rule-detail-message body-1">
              {i18n.t("levelRulesValidityText")}
            </div>
          </div>
          <div className="level-rule-item-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesRetainDowngradeTitle")}
            </div>
            <div className="level-rule-detail-message body-1">
              {i18n
                .t("levelRulesRetainDowngradeText")
                .split("\n")
                .map((content, index) => (
                  <p key={index}>{content}</p>
                ))}
            </div>
          </div>
          <div className="level-rule-item-section last-section">
            <div className="level-rule-second-title subheading">
              {i18n.t("levelRulesTableTitle")}
            </div>
            {/* <div className="level-rule-detail-message body-1">
              {i18n.t("levelRulesTableText")}
            </div> */}
            <div className="level-rule-table">
              <Table bordered>
                <thead>
                  <tr>
                    <th className="tag-menu-2">{i18n.t("level")}</th>
                    <th className="tag-menu-2">{"Total spending"}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.levelConfig.map((levelRule) => {
                    return (
                      <tr key={levelRule.id}>
                        <td className="level-name tag-menu-1">{levelRule.name}</td>
                        <td className="tag-menu-1">{i18n.t("HKD",{TPE: levelRule.targetTPE})}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default LevelRuleModal;
