import { encode } from 'base-64';
import stringify from 'json-stable-stringify';

const QR_CODE_REFRESH_INTERVAL = 5 * 60 * 1000;

export const getQRCode = (object) => {
  const encodeOriginal = object;
  encodeOriginal.date = Date.now();
  const unEncodeString = stringify(encodeOriginal);
  const encoded = encode(unEncodeString);
  return { codeString: stringify({ code: encoded }) };
};

export const refreshQRCode = (object, interval) => {
  const intervalID = setInterval(() => {
    interval(getQRCode(object));
  }, QR_CODE_REFRESH_INTERVAL);
  return { ...getQRCode(object), id: intervalID };
};
