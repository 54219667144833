import React from "react";
import { connect } from "react-redux";
import { createAction } from "../../utils";
import Container from "react-bootstrap/Container";
import "./OfferContainer.scss";

import OfferList from "./OfferList";
import OfferNav from "./OfferNav";
import { Switch, Route } from "react-router-dom";
// import CodeInput from "../common/CodeInput";

@connect(({ offerContainer, offerList }) => ({
  ...offerContainer,
  ...offerList,
}))
class OfferContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(createAction("offerContainer/getOfferTabData")({}));
  }

  _getRoute = () => {
    if (this.props.tabList) {
      let routes = [];
      this.props.tabList.forEach((navItemData) => {
        let route = (
          <Route
            path={"/offer/" + navItemData.name}
            component={OfferList}
            key={navItemData.display_priority}
          />
        );
        routes.push(route);
      });
      return routes;
    }
  };

  render() {
    return (
      <Container className="offer_none_padding">
        <OfferNav tabData={this.props.tabList} />
        {/* <CodeInput
          fields={6}
          type="text"
          onChange={(text) => console.log("text")}
        /> */}
        <Container className="offer_none_padding">
          <Switch>
            <Route path="/offer/LATEST" component={OfferList} key={0} />
            {this._getRoute()}
          </Switch>
        </Container>
      </Container>
    );
  }
}

export default OfferContainer;
