import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import { withTranslation } from "react-i18next";
import OnlyForYouWeb from "../component/home/OnlyForYouWeb";
import OnlyForYouMobile from "../component/home/OnlyForYouMobile";
import HomeBanner from "../component/home/HomeBanner";

import { createAction } from "../utils";
import "./Home.scss";

import CampaignCardList from "../component/common/CampaignCardList";
import OfferFloatActionButton from "../component/offer/OfferFloatActionButton";
import { ssoAPIHelper } from "../services/SSOAPIHelper";
import { isMobile } from "utils/ScreenUtils";

@connect(({ campaignList, users }) => ({ ...campaignList, users: users }))
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.users.isLogin) {
      this.props.dispatch(
        createAction("campaignList/homeOnlyForYouListRefresh")({})
      );
    }
    this.props.dispatch(
      createAction("campaignList/homeFeatureListRefresh")({})
    );
    this.props.dispatch(
      createAction("campaignList/homeBannnerRefresh")({})
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.users.isLogin === true &&
      prevProps.users.isLogin === false
    ) {
      this.props.dispatch(
        createAction("campaignList/homeOnlyForYouListRefresh")({})
      );
    }

    if (
      this.props.users.isLogin &&
      this.props.users.language !== prevProps.users.language
    ) {
      this.props.dispatch(
        createAction("campaignList/homeOnlyForYouListRefresh")({})
      );
    }
    if (
      this.props.users.language !== prevProps.users.language
    ) {
      this.props.dispatch(
        createAction("campaignList/homeBannnerRefresh")({})
      );
    }

  }

  onlyForYouSection = () => {
    if (this.props.users.isLogin && this.props.homeOnlyForYouList.length > 0) {
      return (
        <div className={`home_only_for_you_section ${this.props.homeBanner?.length > 0 ? '' : 'no-banner'}`}>
          <OnlyForYouWeb
            pageName="home"
            categoryName="ONLYFORYOU"
            categoryId={null}
            data={this.props.homeOnlyForYouList}
          />
          <OnlyForYouMobile
            pageName="home"
            categoryName="ONLYFORYOU"
            categoryId={null}
            data={this.props.homeOnlyForYouList}
          />
        </div>
      );
    }
  };

  loginButtonDidClicked = () => {
    window.location.href = ssoAPIHelper.getLoginWebUri(
      this.props.i18n?.language || 'en'
    );
  }

  render() {
    return (
      <div className="home_page">
        {!this.props.users.isLogin && !this.props.users.isOpenInOtherSite &&
          <div className="home_login_banner">
            <Container className="home_container">
              <div className={`home_signup_title ${isMobile() ? "headline" : "title-small"}`}>{this.props.t("home_signup_text")}</div>
              <div
                className="nav_btn signup button-large"
                onClick={this.loginButtonDidClicked}
              >
                {this.props.t("sign_up_login")}
              </div>
            </Container>
          </div>
        }
        {this.props.homeBanner?.length > 0 &&
          <HomeBanner />
        }
        <div className="home_onboard_container">
          <Container className="home_container">
            {this.onlyForYouSection()}
          </Container>
        </div>
        <Container className="home_container">
          <Container className="home_card_container">
            <div
              className="home_title_container"
              onClick={this._login}
            >
              {this.props.t("feature")}
            </div>
            <CampaignCardList
              pageName="home"
              categoryName="FEATURE"
              categoryId={null}
              data={this.props.homeFeatureList}
            />
            <OfferFloatActionButton />
          </Container>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(Home);
