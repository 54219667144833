import React, { useEffect, useState } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from 'I18n';

import { ACTION_BUTTON_STATUS } from 'component/campaign/CheckInSection';
import Scanner from 'component/campaign/Scanner';
import StampCampaignActionButton from 'component/campaign/StampCampaignActionButton';
import StampCampaignCouponAlert from 'component/campaign/StampCampaignCouponAlert';
import CommonAnimatedIcon from 'component/common/CommonAnimatedIcon';
import {
  EARNING_RULE_GPS,
  EARNING_RULE_PURCHASE,
  EARNING_RULE_QR_CODE,
  EARNING_RULE_REFER,
} from 'services/CampaignAPIHelper';
import { ssoAPIHelper } from 'services/SSOAPIHelper';

import { createAction, detectIsMobile, getTranslationData } from 'utils';
import { checkIfExpired } from 'utils/CampaignUtil';

import giftAcquireJson from 'assets/animation/Badge_gift.json';
import badgeAcquiredJson from 'assets/animation/Badge_star.json';
import iconTask from 'assets/images/TaskIcon.png';
import giftSlotImage from 'assets/images/badgeGift.png';
import badgeSlotImage from 'assets/images/badgeIcon.png';

import './StampSection.scss';
import { isPadOrMobile } from 'utils/ScreenUtils';

const mapStateToProps = (state, ownProps) => {
  const { campaignID } = ownProps;
  const currentCampaign = state.campaignDetail[campaignID];
  const { actionBar, data } = currentCampaign || {};
  const { stampCampaigns } = data || {};

  return {
    tasks: stampCampaigns?.tasks,
    badges: stampCampaigns?.badges || [],
    campaignEndDate: actionBar?.endDate,
    earnedStamps: actionBar?.earnedStamps,
    redeemedRewards: actionBar?.redeemedRewards,
    stampBadge: actionBar?.stampBadge,
    membershipID: state.users.userInfo.memberShipID,
    isLogin: state.users.isLogin,
  };
};

const TaskCard = (props) => {
  const { title, cardType, rewardString, rewardStringSecond, badgeAcquired } = props;
  const isButton = cardType === 'button';

  const animationSource = badgeAcquired || badgeAcquiredJson;

  return (
    <div className="task-card-container">
      <div className="task-card-top-section">
        <Image className="task-card-icon" src={iconTask} />
        <label className="task-card-name subheading">{title}</label>
      </div>
      <div className="task-card-bottom-section">
        <span className="task-card-reward-number headline">{rewardString}</span>
        <CommonAnimatedIcon
          source={animationSource}
          className="task-card-reward-icon"
          useLottie={!badgeAcquired}
        />
        {
          rewardStringSecond
            ? <span className="task-card-reward-number-extra headline">{rewardStringSecond}</span>
            : null
        }
        {isButton && <StampCampaignActionButton {...props} />}
      </div>
    </div>
  );
};

const MyTask = ({
  data,
  onGetBadgeEventStart,
  campaignID,
  onTickFinished,
  badgeAcquired,
}) => {
  return (
    <div className="task-wrap">
      {data?.map((props, index) => {
        const key = `taskcard_${index}`;
        return (
          <TaskCard
            key={key}
            {...props}
            earningRuleType={props.earningRuleType}
            onGetBadgeEventStart={onGetBadgeEventStart}
            onTickFinished={onTickFinished}
            badgeAcquired={badgeAcquired}
            campaignID={campaignID}
          />
        );
      })}
    </div>
  );
};

const BadgeCard = ({
  text,
  style,
  icon,
  onPress,
  active,
  rowNumber,
  isGift,
  isGiftOpened,
  autoPlay,
  useLottie,
}) => {
  const isAcquireGift = active && isGift;

  return (
    <div className={`badge-card-container badge-card-container-${style}`}>
      <button
        className="badge-card-button"
        onClick={onPress}
        disabled={!isAcquireGift || isGiftOpened}
      >
        <div className={`badge-card-icon-container`}>
          <CommonAnimatedIcon
            className="badge-card-icon"
            source={icon}
            useLottie={useLottie}
            autoPlay={autoPlay || true}
          />
          {isAcquireGift && (
            <div
              className={`badge-card-acquire-container ${
                isGiftOpened ? 'acquired' : ''
              }`}
            >
              <span className="badge-card-acquire-text tag-menu-2">
                {isGiftOpened
                  ? i18n.t('campaignDetail:acquired')
                  : i18n.t('campaignDetail:acquire')}
              </span>
            </div>
          )}
        </div>
        <span className="badge-card-index body-2">{text}</span>
      </button>
      <div className={`badge-card-line badge-card-line-${style}`} />
    </div>
  );
};

const MyStamp = ({
  stampInOneLine,
  campaignID,
  badges,
  earnedStamps,
  redeemedRewards,
  stampBadge,
}) => {
  const dispatch = useDispatch();
  const [couponAlertShow, setCouponAlertShow] = useState(false);
  const [buttonRewardId, setButtonRewardId] = useState(null);
  const [buttonCouponName, setButtonCouponName] = useState(null);
  const [buttonCouponQuantity, setButtonCouponQuantity] = useState(null);

  const gifts = badges.map(
    ({ required_stamps: requiredBadges }) => requiredBadges,
  );
  const total = gifts[gifts.length - 1];
  const badgeGet = earnedStamps;

  const { badgeSlot, badgeAcquired, giftSlot, giftAcquire } = stampBadge;

  const data = [];
  let subList = [];
  for (let i = 0; i < total; i += 1) {
    if (i >= stampInOneLine && i % stampInOneLine === 0) {
      data.push(subList);
      subList = [];
    }
    subList.push(i + 1);
  }
  data.push(subList);
  return (
    <>
      <div className="badge-container">
        {data.map((aList, index1) => {
          let lineStyle = index1 % 2 === 0 ? 'left' : 'right';
          return (
            <div
              key={`badge-row-${index1}`}
              className={`badge-row-container ${
                index1 % 2 === 0 ? '' : 'badge-row-reverse'
              }`}
            >
              {aList.map((textNumber, index2) => {
                if (textNumber % stampInOneLine === 0) {
                  lineStyle = 'bottom';
                }
                const dataPropsFilter = badges.filter(
                  (aData) => aData.required_stamps === textNumber,
                );
                const dataProps = dataPropsFilter ? dataPropsFilter[0] : null;
                const active = textNumber <= badgeGet;
                const isGift = gifts.includes(textNumber);
                const rewardID = dataProps?.id;
                const isGiftOpened = redeemedRewards.includes(rewardID);

                const mBadgeSlot = badgeSlot || badgeSlotImage;
                const mBadgeAcquired = badgeAcquired || badgeAcquiredJson;
                const mGiftSlot = giftSlot || giftSlotImage;
                const mGiftAcquire = giftAcquire || giftAcquireJson;

                let badgeIcon = mBadgeSlot;
                let useLottie = false;
                if (isGift) {
                  badgeIcon = active ? mGiftAcquire : mGiftSlot;
                  useLottie = active && !giftAcquire;
                } else {
                  badgeIcon = active ? mBadgeAcquired : mBadgeSlot;
                  useLottie = active && !badgeAcquired;
                }

                if (textNumber === total) {
                  lineStyle = null;
                }

                return (
                  <BadgeCard
                    key={`badge-card-${index1}-${index2}`}
                    text={textNumber}
                    rowNumber={index1}
                    style={lineStyle}
                    icon={badgeIcon}
                    active={active}
                    isGift={isGift}
                    isGiftOpened={isGiftOpened}
                    useLottie={useLottie}
                    autoPlay={textNumber === badgeGet}
                    onPress={() => {
                      if (isGiftOpened) {
                        return;
                      }
                      const rewardType = dataProps?.reward_type;
                      if (rewardType === 'POINTS') {
                        dispatch({
                          type: 'campaignDetail/customerAcquireStampReward',
                          payload: {
                            campaignID,
                            rewardID,
                            stampRewardData: dataProps,
                          },
                        });
                      } else if (rewardType === 'COUPON') {
                        console.log('@@253', dataProps);
                        const baseCouponData = getTranslationData(
                          dataProps.coupon_template,
                        );
                        dispatch({
                          type: 'campaignDetail/updateState',
                          payload: {
                            actionButtonStatus: ACTION_BUTTON_STATUS.NORMAL,
                          },
                        });
                        setButtonRewardId(rewardID);
                        setButtonCouponName(baseCouponData.name);
                        setButtonCouponQuantity(dataProps.quantity);
                        setCouponAlertShow(true);
                      }
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      <StampCampaignCouponAlert
        show={couponAlertShow}
        onHide={() => {
          setCouponAlertShow(false);
        }}
        campaignID={campaignID}
        rewardID={buttonRewardId}
        couponName={buttonCouponName}
        quantity={buttonCouponQuantity}
      />
    </>
  );
};

function StampSection({
  campaignID,
  inBlackoutPeriod,
  tasks,
  badges,
  campaignEndDate,
  earnedStamps,
  redeemedRewards,
  stampBadge,
  membershipID,
  isLogin,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPadOrMobileView = isPadOrMobile();
  const [stampInOneLine, setStampInOneLine] = useState(
    isPadOrMobileView ? 4 : 3,
  );
  const [showScanQRCode, setShowScanQRCode] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalButtonIsRefer, setModalButtonIsRefer] = useState(false);
  const [buttonEarningRuleId, setButtonEarningRuleId] = useState(null);
  const [buttonRewardStampCount, setButtonRewardStampCount] = useState(null);
  const [qrCodeString, setQrCodeString] = useState(null);
  const isMobile = detectIsMobile();

  useEffect(() => {
    const windowResizeFunction = () => {
      setStampInOneLine(isPadOrMobileView ? 4 : 3);
    };
    window.addEventListener('resize', windowResizeFunction);
    return () => {
      window.removeEventListener('resize', windowResizeFunction);
    };
  }, []);

  useEffect(() => {
    if (qrCodeString) {
      dispatch({
        type: 'campaignDetail/verifyQrCode',
        payload: {
          qrCodeString: qrCodeString,
          campaignID,
          earningRuleID: buttonEarningRuleId,
          inStampCampaign: true,
          rewardStampCount: buttonRewardStampCount,
        },
      });
      setQrCodeString(null);
    }
  }, [dispatch, qrCodeString]);

  const getGeo = (earningRuleID, rewardStampCount) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch({
          type: 'campaignDetail/verifyGPS',
          payload: {
            earningRuleID,
            campaignID,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            inStampCampaign: true,
            rewardStampCount,
          },
        });
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
          case error.POSITION_UNAVAILABLE:
          case error.TIMEOUT:
          default:
            dispatch({
              type: 'navBars/updateState',
              payload: {
                toastShowing: {
                  value: true,
                  content: i18n.t('campaignDetail:invalidGPSLocation'),
                },
              },
            });
        }
      },
      {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  };

  const onGetBadgeEventStart = (
    earningRuleID,
    earningRuleType,
    rewardStampCount,
  ) => {
    if (inBlackoutPeriod) {
      dispatch(
        createAction('navBars/updateState')({
          toastShowing: {
            value: true,
            content: i18n.t('campaignDetail:blackoutLimit'),
          },
        }),
      );
      return;
    }
    if (checkIfExpired(campaignEndDate)) {
      dispatch({
        type: 'navBars/updateState',
        payload: {
          toastShowing: {
            value: true,
            content: i18n.t('campaignDetail:expireToast'),
          },
        },
      });
    } else if (!isLogin) {
      window.location.href = ssoAPIHelper.getLoginWebUri(
        i18n?.language || 'en',
      );
    } else if (earningRuleType === EARNING_RULE_PURCHASE) {
      history.push('/transaction');
    } else if (earningRuleType === EARNING_RULE_QR_CODE && isMobile) {
      setButtonEarningRuleId(earningRuleID);
      setButtonRewardStampCount(rewardStampCount);
      setShowScanQRCode(true);
    } else if (earningRuleType === EARNING_RULE_GPS && isMobile) {
      getGeo(earningRuleID, rewardStampCount);
    } else if (
      earningRuleType === EARNING_RULE_REFER &&
      isMobile &&
      navigator.share
    ) {
      navigator.share({
        title: '',
        text: i18n.t('referMessage', {
          link: `com.ka-pok.app://home/?referCode=${membershipID}`,
          code: membershipID,
        }),
      });
    } else {
      let modalDescriptionTemp = '';
      if (earningRuleType === EARNING_RULE_QR_CODE) {
        modalDescriptionTemp = i18n.t('campaignDetail:noCamera');
      } else if (earningRuleType === EARNING_RULE_GPS) {
        modalDescriptionTemp = i18n.t('campaignDetail:useMobileDevice');
      } else if (earningRuleType === EARNING_RULE_REFER) {
        modalDescriptionTemp = i18n.t('referMessage', {
          link: `com.ka-pok.app://home/?referCode=${membershipID}`,
          code: membershipID,
        });
      }
      setModalDescription(modalDescriptionTemp);
      setModalButtonIsRefer(earningRuleType === EARNING_RULE_REFER);
      setButtonEarningRuleId(earningRuleID);
      setButtonRewardStampCount(rewardStampCount);
      setShowActionModal(true);
    }
  };

  const onTickFinished = (earningRuleID, campaignID) => {
    setTimeout(() => {
      dispatch({
        type: 'campaignDetail/updateActionButtonStatus',
        payload: {
          earningRuleID,
          campaignID,
          inStampCampaign: true,
          status: ACTION_BUTTON_STATUS.DISABLE,
        },
      });
    }, 1000);
  };

  const actionModalButton = () => {
    if (modalButtonIsRefer) {
      return (
        <>
          <button
            className="left_btn button-large"
            onClick={() => {
              setShowActionModal(false);
            }}
          >
            {i18n.t('close')}
          </button>
          <button
            className="right_btn button-large"
            onClick={() => {
              navigator.clipboard.writeText(
                i18n.t('referMessage', {
                  link: `com.ka-pok.app://home/?referCode=${membershipID}`,
                  code: membershipID,
                }) || '',
              );
              setShowActionModal(false);
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  toastShowing: {
                    value: true,
                    content: i18n.t('copySuccessfully'),
                    toastIcon: '/coupon/copyCodeSuccessIcon/check.svg',
                  },
                },
              });
            }}
          >
            {i18n.t('copy')}
          </button>
        </>
      );
    }
    return (
      <>
        <button
          className="right_btn button-large"
          onClick={() => {
            dispatch({
              type: 'campaignDetail/updateActionButtonStatus',
              payload: {
                earningRuleID: buttonEarningRuleId,
                campaignID,
                inStampCampaign: true,
                status: ACTION_BUTTON_STATUS.DISABLE,
              },
            });
            setShowActionModal(false);
          }}
        >
          {i18n.t('campaign_ok')}
        </button>
      </>
    );
  };

  return (
    <>
      <div className="stamp-wrap">
        <div className="stamp-card-container">
          <label className="stamp-card-title headline">
            {i18n.t('campaignDetail:stampCard')}
          </label>
          <MyStamp
            stampInOneLine={stampInOneLine}
            campaignID={campaignID}
            badges={badges}
            earnedStamps={earnedStamps}
            redeemedRewards={redeemedRewards}
            stampBadge={stampBadge}
          />
        </div>
        <label className="task-card-title headline">
          {i18n.t('campaignDetail:tasks')}
        </label>
        <MyTask
          campaignID={campaignID}
          data={tasks?.parseList}
          badgeAcquired={stampBadge?.badgeAcquired}
          onGetBadgeEventStart={onGetBadgeEventStart}
          onTickFinished={onTickFinished}
        />
      </div>
      {isMobile && showScanQRCode ? (
        <Scanner
          show={showScanQRCode}
          handleClose={() => {
            setShowScanQRCode(false);
          }}
          setQRCodeString={(code) => setQrCodeString(code)}
          onError={(errorMessage) => {
            dispatch({
              type: 'navBars/updateState',
              payload: {
                toastShowing: {
                  value: true,
                  content: errorMessage,
                },
              },
            });
          }}
        />
      ) : (
        <Modal
          show={showActionModal}
          onHide={() => {
            setShowActionModal(false);
          }}
          className="use_coupon_modal"
          centered
        >
          <Modal.Body className="use_coupon_modal_content d-flex-column">
            <div>
              <p className="use_coupon_title title-medium-1">{modalDescription}</p>
            </div>
            <div className="button_group">{actionModalButton()}</div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default connect(mapStateToProps)(StampSection);
