import React from 'react';

import './DiscountTags.scss';

export const DiscountTagRow = ({
  extraStyle = '',
  discounts,
}) => {
  return (
    <div className={`discount ${extraStyle}`}>
      {discounts?.map((item) => (
        <div className={'discountSpan tag-menu-1'}>
          {item.code}
        </div>
      ))}
    </div>
  );
};

const DiscountTags = ({
  extraStyle = '',
  discounts,
}) => {
  return (
    <div className={`discountContainer ${extraStyle}`}>
      <DiscountTagRow discounts={discounts} />
    </div>
  );
}

export default DiscountTags;

