import React from 'react';
import Lottie from "lottie-react";
import i18n from '../../I18n';
import { getCampaignAvailableOnDate } from '../../utils/DateTimeUtils';
import { ACTION_BUTTON_STATUS } from './CheckInSection';


function StampCampaignActionButton({
  campaignID,
  text,
  buttonStatus,
  onGetBadgeEventStart,
  onTickFinished,
  earningRuleID,
  earningRuleType,
  rewardStampCount,
  isStarted,
  startDate,
  endDate,
}) {
  const renderButton = () => {
    console.log("render stamp task button - Init");
    if (buttonStatus === ACTION_BUTTON_STATUS.LOADING) {
      console.log("render stamp task button - Loading");
      return (
        <Lottie
          className='stamp-campaign-loading-jumping-balls'
          animationData={require('../../assets/animation/loading_jumping_balls.json')}
          loop
        />
      );
    }
    if (buttonStatus === ACTION_BUTTON_STATUS.TICK) {
      console.log("render stamp task button - Tick");
      return (
        <Lottie
          className='stamp-campaign-tick-green'
          animationData={require('../../assets/animation/tick_green.json')}
          loop={false}
          onComplete={
            () => {
              onTickFinished(earningRuleID, campaignID);
            }
          }
        />
      );
    }

    const actionText = isStarted ? text : i18n.t('campaignDetail:availableOn', {
      date: getCampaignAvailableOnDate(startDate),
    });

    return (
      <span className='bottom_btn task-card-button-text button-small'>
        {actionText}
      </span>
    )
  }

  console.log(`render button with status: ${buttonStatus}`);
  const disabled = buttonStatus === ACTION_BUTTON_STATUS.DISABLE || buttonStatus === ACTION_BUTTON_STATUS.UNAVAILABLE;
  return (
    <button
      onClick={() => {
        onGetBadgeEventStart(earningRuleID, earningRuleType, rewardStampCount)
      }}
      disabled={disabled}
      className={`btn_section task-card-button ${buttonStatus === ACTION_BUTTON_STATUS.TICK ? 'task-card-button-tick': ''}`}
    >
      {renderButton()}
    </button>
  )
}
export default StampCampaignActionButton;
