import React, {Component} from "react";
import {Carousel, Container, Image} from "react-bootstrap";
import "./OnlyForYouWeb.scss";

import OnlyForYouWebCard from "./OnlyForYouWebCard";
import {findDOMNode} from "react-dom";
import i18n from "../../I18n";

class OnlyForYouWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _activateCarouselControl = () => {
    let carouselObj = document.getElementById("home_carousel");
    if (carouselObj) {
      if (this.props.data.length < 2) {
        findDOMNode(carouselObj).style.setProperty(
          "--carouselPrevControlShow",
          "none"
        );
        findDOMNode(carouselObj).style.setProperty(
          "--carouselNextControlShow",
          "none"
        );
      } else {
        findDOMNode(carouselObj).style.setProperty(
          "--carouselNextControlShow",
          "flex"
        );
        findDOMNode(carouselObj).style.setProperty(
          "--carouselNextButtonEvent",
          "auto"
        );
      }
    }
  };

  componentDidUpdate() {
    this._activateCarouselControl();
  }

  _getOnlyForYouItems = () => {
    if (this.props.data) {
      return this.props.data.map((itemData, index) => (
        <Carousel.Item key={index}>
          <div className="onlyForYou_web_carousel_body">
            <OnlyForYouWebCard data={itemData}/>
          </div>
        </Carousel.Item>
      ));
    }
  };

  _dismissIcon = (index) => {
    let carouselObj = document.getElementById("home_carousel");
    if (index === 0) {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevControlShow",
        "none"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevButtonEvent",
        "none"
      );
    } else {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevControlShow",
        "flex"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselPrevButtonEvent",
        "auto"
      );
    }
    if (index === this.props.data.length - 1) {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextControlShow",
        "none"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextButtonEvent",
        "none"
      );
    } else {
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextControlShow",
        "flex"
      );
      findDOMNode(carouselObj).style.setProperty(
        "--carouselNextButtonEvent",
        "auto"
      );
    }
  };

  render() {
    return (
      <Container className="onlyForYou_web_container d-none d-md-flex">
        <div className="onlyForYou_web_background_blue" />
        <div className="onlyForYou_web_title">
          {i18n.t("home_only_for_you")}
        </div>
        <div className="onlyForYou_web_slider_container">
          <Carousel
            id="home_carousel"
            indicators={false}
            interval={null}
            controls={true}
            nextIcon={
              <span aria-hidden="true">
                <Image
                  src="/homePage/onlyForYou/circle-arrow-right.png"
                  className="onlyForYou_web_slider_rightArrow"
                />
              </span>
            }
            prevIcon={
              <span aria-hidden="true">
                <Image
                  src="/homePage/onlyForYou/circle-arrow-left.png"
                  className="onlyForYou_web_slider_leftArrow"
                />
              </span>
            }
            onSlide={this._dismissIcon}
            touch={false}
          >
            {this._getOnlyForYouItems()}
          </Carousel>
        </div>
      </Container>
    );
  }
}

export default OnlyForYouWeb;
