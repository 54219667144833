import React from 'react';
import OfferContainer from '../component/offer/OfferContainer';
import OfferFloatActionButton from '../component/offer/OfferFloatActionButton';
import Container from 'react-bootstrap/Container';
import "./Offer.scss";

class Offer extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      };
  }

  render() {
    return (
      <Container className="offer_container offer_none_padding">
        <OfferContainer />
        <OfferFloatActionButton />
      </Container>
    );
  }
}

export default Offer;
