import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import i18n from 'I18n';
import queryString from 'query-string';

import { ACTION_BUTTON_STATUS } from 'component/campaign/CheckInSection';
import ImageSlider from 'component/campaign/ImageSlider';
import SharedSection from 'component/campaign/SharedSection';
import ShortDescription from 'component/campaign/ShortDescription';
import CampaignCard from 'component/common/CampaignCard';
import OfferFloatActionButton from 'component/offer/OfferFloatActionButton';

import { createAction } from 'utils';
import { APP_NAME, WEB_DOMAIN } from 'utils/Config';
import { isMobile } from 'utils/ScreenUtils';

import './StoreDetail.scss';

@connect(({ users, store }) => ({
  language: users.language,
  membershipID: users.userInfo.memberShipID,
  isLogin: users.isLogin,
  storeDetailDict: store.store,
  storeDistricts: store.storeDistricts,
}))
class StoreDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // showScanQRCodeModal: false,
      qrCodeString: '',
      showScanner: false,
      storeDetail: {},
      storeDistrict: '',
      phoneNumber: '-',
      email: '-',
    };
  }

  componentDidMount() {
    this.storeID = this.props.match.params?.id;
    this.props.dispatch(
      createAction('store/getOneStore')({
        id: this.storeID,
      }),
    );
    this.props.dispatch(createAction('store/getStoreRegions')());
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(
        createAction('store/getOneStore')({
          id: this.storeID,
        }),
      );
    }
    if (this.props.storeDetailDict !== prevProps.storeDetailDict) {
      const storeDetail = this.props.storeDetailDict[this.storeID];
      console.log('storeDetail', storeDetail);
      let phoneNumber = '-';
      if (
        !!storeDetail?.phoneNumberCountryCode &&
        !!storeDetail?.subscriberNumber
      ) {
        phoneNumber = `+${storeDetail?.phoneNumberCountryCode} ${storeDetail?.subscriberNumber}`;
      }
      let email = '-';
      if (!!storeDetail?.emailAddress) {
        email = storeDetail?.emailAddress;
      }
      this.setState({
        storeDetail: storeDetail,
        phoneNumber,
        email,
      });
    }
    if (
      this.props.storeDetailDict !== prevProps.storeDetailDict ||
      this.props.storeDistricts !== prevProps.storeDistricts
    ) {
      const storeDetail = this.props.storeDetailDict[this.storeID];
      const storeDistricts = this.props.storeDistricts;

      const districtID = storeDetail?.district;
      if (storeDistricts?.length > 0) {
        for (const x of storeDistricts) {
          for (const district of x.district) {
            if (district.id === districtID) {
              const districtName = district.name;
              this.setState({
                storeDistrict: districtName,
              });
              return;
            }
          }
        }
      }

      this.setState({
        storeDistrict: '',
      });
    }
  }

  componentWillUnmount() {
    this.props.dispatch(
      createAction('campaignDetail/updateState')({
        actionButtonStatus: ACTION_BUTTON_STATUS.NORMAL,
      }),
    );
  }

  clickSubCategoryTab(subItem) {
    const newUrl = `/about/store?sf_category=${subItem.categoryId}&sf_sub_category[0]=${subItem.id}`;
    this.props.history.push(newUrl);
  }

  showImageSlider = () => {
    if (this.state.storeDetail?.photoUrl) {
      return <ImageSlider images={[this.state.storeDetail?.photoUrl]} />;
    } else {
      return <ImageSlider images={[]} />;
    }
  };

  subCategoryTags = () => {
    if (this.state.storeDetail?.subcategories?.length > 0) {
      let subcategories = this.state.storeDetail.subcategories;
      //      subcategories = subcategories
      //      .concat(subcategories).concat(subcategories).concat(subcategories)
      //      .concat(subcategories).concat(subcategories).concat(subcategories)
      //      .concat(subcategories).concat(subcategories).concat(subcategories)
      //      .concat(subcategories).concat(subcategories).concat(subcategories);
      const tags = subcategories?.map((subcategory, index) => {
        return (
          <span
            className={isMobile() ? "tag-menu-1" : "field-text"}
            key={subcategory.id}
            onClick={() => this.clickSubCategoryTab(subcategory)}
          >
            {subcategory.name}
          </span>
        );
      });
      return tags;
    }
    return <></>;
  };

  showDescription = () => {
    if (!!this.state.storeDetail?.description) {
      return (
        <>
          <Row>
            <div className={`store_detail_title ${isMobile() ? "subheading" : "title-small"}`}>
              {i18n.t('store:store_description')}
            </div>
          </Row>
          <Row>
            <ShortDescription
              description={this.state.storeDetail?.description}
            />
          </Row>
        </>
      );
    }
    return <></>;
  };

  showCampaignList = () => {
    if (this.state.storeDetail?.campaigns?.length > 0) {
      return (
        <div className="campaign_list">
          <div className={`campaign_list_title ${isMobile() ? "subheading" : "headline"}`}>
            {i18n.t('store:related_campaign')}
          </div>
          <div className="campaign_list_scroll_view">
            {this.state.storeDetail?.campaigns?.map((data, index) => (
              <CampaignCard
                cardData={data}
                key={index}
                shouldShowCampaignTag={true}
                inStoreDetail
              />
            ))}
          </div>
        </div>
      );
    }
    return <></>;
  };

  detectIsMobile = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const maxTouchPoints = navigator.maxTouchPoints;
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    const matchMobileResult = toMatch.some((matchItem) => {
      return userAgent.match(matchItem);
    });
    if (matchMobileResult || (platform === 'MacIntel' && maxTouchPoints > 1)) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const title = `${APP_NAME} - ${this.state.storeDetail?.name}`;
    const url = `${WEB_DOMAIN}/storeDetail/${this.storeID}?fromDeepLink=true`;
    const message = `Ailia - ${this.state.storeDetail?.name}. ${i18n.t(
      'campaignDetail:checkItNow',
    )} \n ${url} `;
    return (
      <div className="user_container_background">
        <Container className="store_detail_container">
          <Row className="nav_row button-large">
            <Link to="/">{i18n.t('home_home')}</Link>
            {'>'}
            <Link to="/about/store">{i18n.t('profile_store_directory')}</Link>
            {'>'}
            <span className="nav_row_span">{i18n.t('store:store_detail')}</span>
          </Row>
          <Row>
            <Col className="d-md-block left_section">
              <Row>{this.showImageSlider()}</Row>
              <Row>
                <div className={`offer_title ${isMobile() ? "headline" : "title-large"}`}>
                  {this.state.storeDetail?.name}
                </div>
              </Row>
              <Row>
                <div className={`sub_title ${isMobile() ? "tag-menu-2" : "headline"}`}>
                  {this.state.storeDetail?.brandName}
                </div>
              </Row>
              <Row>
                <div className="store_sub_category">
                  {this.subCategoryTags()}
                </div>
              </Row>
              <div className="custom_separate sub_dividing" />
              {!!this.state.storeDistrict ? (
                <Row>
                  <div className={`location ${isMobile() ? "body-3" : "headline"}`}>{this.state.storeDistrict}</div>
                </Row>
              ) : (
                <></>
              )}
              <Row>
                <div
                  className={'store_location body-1'}
                  onClick={() => {
                    const latitude = this.state.storeDetail?.latitude; // -90, 90
                    const longitude = this.state.storeDetail?.longitude; // -180, 180
                    if (latitude !== null && longitude !== null) {
                      const search = queryString.stringify(
                        {
                          api: '1',
                          query: `${latitude},${longitude}`,
                        },
                        {
                          encode: true,
                        },
                      );
                      const googleMapUrl =
                        'https://www.google.com/maps/search/?' + search;
                      window.open(googleMapUrl);
                    }
                  }}
                >
                  <Image
                    className={'g3_color_image'}
                    src="/action/location.png"
                    srcSet="/action/location@2x.png 2x, /action/location@3x.png 3x"
                  />
                  <span className="location_name">
                    {this.state.storeDetail?.address}
                  </span>
                </div>
              </Row>
              {
                this.state.phoneNumber !== '-' || this.state.email !== '-'
                  ? <Row>
                    <div className="contact">
                      <div
                        className="contact-phone"
                        onClick={() => {
                          if (
                            this.detectIsMobile() &&
                            this.state.phoneNumber !== '-'
                          ) {
                            window.open(`tel:${this.state.phoneNumber}`);
                          }
                        }}
                      >
                        <Image
                          className={'g3_color_image'}
                          src="/action/phone.png"
                          srcSet="/action/phone@2x.png 2x, /action/phone@3x.png 3x"
                        />
                        <div className="contact-phone-text">
                          <label className='body-3'>{i18n.t('store:phone')}</label>
                          <span className='body-1'>{this.state.phoneNumber}</span>
                        </div>
                      </div>
                      <div className="custom_separate" />
                      <div
                        className="contact-email"
                        onClick={() => {
                          window.open(
                            `mailto:${this.state.email}?subject=&body=`,
                          );
                        }}
                      >
                        <Image
                          className={'g3_color_image'}
                          src="/action/inbox.png"
                          srcSet="/action/inbox@2x.png 2x, /action/inbox@3x.png 3x"
                        />
                        <div className="contact-email-text">
                          <label className='body-3'>{i18n.t('settings_email')}</label>
                          <span className='body-1'>{this.state.email}</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  : <div className='contact-empty'/>
              }
              <Row className="dividing" />
              {this.showDescription()}
              {isMobile() ? (
                <>
                  <Row className="mobile_related_campaign">
                    {this.showCampaignList()}
                  </Row>
                  <Row className="share_block container">
                    <SharedSection title={title} message={message} />
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Col>

            <Col className=" col-md-4 right_section">
              <div className="float_right_section">
                {this.showCampaignList()}
                <SharedSection title={title} message={message} />
              </div>
            </Col>
          </Row>
          <OfferFloatActionButton />
        </Container>
      </div>
    );
  }
}

export default withRouter(StoreDetail);
