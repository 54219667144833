import React from "react";
import { Image } from "react-bootstrap";


export const CHECKBOX_INIT = -1;
export const CHECKBOX_SOME = 0;
export const CHECKBOX_CHECKED = 1;
export const CHECKBOX_TICK = 2;

function CheckBoxImage({
  customClassName,
  status,
}) {
  if (status === CHECKBOX_INIT) {
    return (
      <Image
        className={customClassName}
        src="/action/mulitSelect.png"
        srcSet="/action/mulitSelect@2x.png 2x, /action/mulitSelect@3x.png 3x" />
    );
  }
  if (status === CHECKBOX_SOME) {
    return (
      <Image
        className={customClassName}
        src="/action/mulitSelectSome.png"
        srcSet="/action/mulitSelectSome@2x.png 2x, /action/mulitSelectSome@3x.png 3x" />
    );
  }
  if (status === CHECKBOX_CHECKED) {
    return (
      <Image
        className={customClassName}
        src="/action/mulitSelectChecked.png"
        srcSet="/action/mulitSelectChecked@2x.png 2x, /action/mulitSelectChecked@3x.png 3x" />
    );
  }
  if (status === CHECKBOX_TICK) {
    return (
      <Image
        className={customClassName}
        src="/action/mulitSelectTick.png"
        srcSet="/action/mulitSelectTick@2x.png 2x, /action/mulitSelectTick@3x.png 3x" />
    );
  }
  return <></>;
}

export default CheckBoxImage;
