import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Progress } from "antd";


import { enLocaleNumberFormatter } from "../../utils";
import "./LevelSectionV2.scss"

@connect(({ users }) => ({
  userName: users.displayName,
  levelConfig: users.levelConfig,
  renewDate: users.renewDate,
  levelName: users.levelName,
  level: users.level,
  TPE: users.TPE,
  showLevelUpProgress: users.showLevelUpProgress,
  levelUpProgress: users.levelUpProgress,
  pointsToLevelUp: users.pointsToLevelUp,
}))
class LevelSection extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const isHighestLevel = this.props.level === this.props.levelConfig?.length;
    const percentage = this.props.levelUpProgress;
    const upgradeTPE = this.props.pointsToLevelUp; 
    return (
      <div className="profile-level-section">
        <div className={`level-bg level-${this.props.level}-color`}/>
        <div className={`level-section-content ${isHighestLevel ? 'max-level' : ''} headline`}>
          <div className="d-flex level-section-header">
            <div>
              <div>{`${ this.props.userName }`}</div>
              <div className="level-text body-3">{this.props.levelName}</div>
            </div>
            <div className="qrcode-icon" onClick={() => this.props.history.push("/user/myCard")}>
              <img alt="icon" src="/profile/level/qr-code.png"/>
            </div>
          </div>
          {this.props.showLevelUpProgress &&
            <div className="d-flex flex-column">
              <div className="subheading">
                {this.props.t("spend")}
                {this.props.t("HKD", { TPE: enLocaleNumberFormatter(this.props.TPE) })}
              </div>
              <div>
                <Progress
                  className="upgrade-progress"
                  percent={percentage * 100}
                  showInfo={false}
                  strokeWidth={8}
                />
              </div>
              <div className="spend-to-upgrade tag-menu-1">
                {this.props.t("spend")}
                <p className="subheading">{this.props.t("HKD", { TPE: enLocaleNumberFormatter(upgradeTPE) })}</p>
                {this.props.t("toUpgrade", { nextLevelName: this.props.levelConfig[this.props.level]?.name })}
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(withTranslation()(LevelSection));
