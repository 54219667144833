import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { numberFormatter } from "../../utils";
import "./CurrentPointsSection.scss"

@connect(({ users }) => ({ currentPoints: users.availablePoints }))
class CurrentPointsSection extends Component {
  render() {
    return (
      <div
        className="home-current-points-section"
      >
        <div className="point-icon">
          <img alt="icon"
            src={"/profile/currentPoint/point.png"}
            srcSet={"/profile/currentPoint/point@2x.png 2x, /profile/currentPoint/point@3x.png 3x"}
          />
        </div>
        <div className="common-profile-section-text d-flex flex-column">
          <div className="field-text">
            {this.props.t("my_current_points")}
          </div>
          <div className="current-point-text headline">
            {numberFormatter(this.props.currentPoints)}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CurrentPointsSection);
