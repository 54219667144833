import React from "react";
import { connect } from "react-redux";
import { createAction } from "../../utils";
import {
  getScrollTop,
  getDocumentHeight,
  getWindowHeight,
} from "../../utils/ScreenUtils";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import Masonry from "react-masonry-css";
import "./CampaignCardList.scss";

import CampaignCard from "./CampaignCard";
import LoadMoreSpinner from "./LoadMoreSpinner";
import NothingPage from "../error/Nothing";

@connect(({ offerList, campaignList, pullrefreshandloadmore, users }) => ({
  ...pullrefreshandloadmore,
  language: users.language,
}))
class CampaignCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log("Card list", props)
    this.isRefreshingName =
      this.props.pageName + this.props.categoryName + "IsRefreshing";
  }

  componentDidMount() {
    window.addEventListener("scroll", this._bindHandleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._bindHandleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      if (this.props.pageName === "home") {
        this.props.dispatch(
          createAction("campaignList/homeFeatureListRefresh")({})
        );
      } else {
        this.props.dispatch(
          createAction("offerList/loadOfferList")({
            categoryId: this.props.categoryId,
            storeDataKey: this.props.categoryName,
          })
        );
      }
    }
  }

  _bindHandleScroll = () => {
    let scrollTopLength = getScrollTop();
    let windowHeight = getWindowHeight();
    let documentHeight = getDocumentHeight();

    let hasMoreDataName =
      this.props.pageName + this.props.categoryName + "HasMoreData";
    let isRefreshingName =
      this.props.pageName + this.props.categoryName + "IsRefreshing";
    let isLoadMoreName =
      this.props.pageName + this.props.categoryName + "IsLoadingMore";

    let shouldLoad =
      this.props[hasMoreDataName] &&
      !this.props[isRefreshingName] &&
      !this.props[isLoadMoreName];
    let isLoadArea = scrollTopLength + windowHeight > documentHeight - 100;

    if (shouldLoad && isLoadArea) {
      if (this.props.pageName === "home") {
        this.props.dispatch(
          createAction("campaignList/homeFeatureListLoadMore")({})
        );
      } else {
        this.props.dispatch(
          createAction("offerList/offerListLoadMore")({
            categoryId: this.props.categoryId,
            storeDataKey: this.props.categoryName,
          })
        );
      }
    }
  };

  _showLoading = () => {
    let isRefreshingName =
      this.props.pageName + this.props.categoryName + "IsRefreshing";
    let isLoadMoreName =
      this.props.pageName + this.props.categoryName + "IsLoadingMore";

    if (this.props[isRefreshingName] || this.props[isLoadMoreName]) {
      return <LoadMoreSpinner />;
    }
  };

  _getCardList = () => {
    let shouldShowCampaignTag = this.props.pageName !== "home";
    if (this.props.data) {
      return this.props.data.map((data, index) => (
        <CampaignCard
          cardData={data}
          key={index}
          shouldShowCampaignTag={shouldShowCampaignTag}
        />
      ));
    }
  };

  render() {
    return (
      <Container className="campagin_card_container">
        {this.props.data?.length === 0 && !this.props[this.isRefreshingName] ? (
          <NothingPage />
        ) : (
          <Masonry
            breakpointCols={{ default: 2, 767: 1 }}
            className="campagin_card_masonry_grid"
            columnClassName="campagin_card_masonry_grid_column"
          >
            {this._getCardList()}
          </Masonry>
        )}

        <Row className="campagin_spinner_row">{this._showLoading()}</Row>
      </Container>
    );
  }
}

export default CampaignCardList;
