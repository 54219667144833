import axios from "axios";
// import { USING_FAKE_API } from '../config/Config';

const USING_FAKE_API = false;
const SSO_LOGIN_BASEURL = process.env.REACT_APP_AUTH || "https://sso.aillia.hkdev.motherapp.com";
const CMS_BASE_URL = process.env.REACT_APP_DOMAIN || "https://aillia.hkdev.motherapp.com";

const ssoAPIinstance = axios.create({
  baseURL: SSO_LOGIN_BASEURL,
  timeout: 10000,
});

class SSOAPIHelper {

  getAccessToken = (token) => {
    if (USING_FAKE_API) {
      return {
        data: {
          access_token: "access_token",
          expires_date_time: "expires_date_time",
        },
      };
    }
    const requestBody = {
      refresh: token,
    };

    return ssoAPIinstance
      .post("/auth/token", requestBody, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .catch((error) => {
        console.log("sso token", error)
        return error
      });
  };

  refreshToken = (refreshToken) => {
    const requestBody = {
      refresh: refreshToken,
    };
    console.log("@@65: ", requestBody);
    return ssoAPIinstance
      .post("/auth/token", requestBody, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .catch((error) => error);
  };

  revokeToken = (refreshToken) => {

    return ssoAPIinstance
      .get("/auth/revoke?token=refreshToken", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        withCredentials: true,
      })
      .catch((error) => error);
  };

  getLoginWebUri = (locale) =>
    `${SSO_LOGIN_BASEURL}/auth/login?redirect_url=${
      window.location.origin
    }/&locale=${locale}&sign_up_channel=WEBSITE`;

  getUserInfo = ({accessToken, uid, fakeApi = false}) => {
    let userInfoUrl = `${CMS_BASE_URL}/customers/${uid}`;
    if (fakeApi) {
      userInfoUrl = `https://82cdb1a2-3111-483b-a70b-25e7cd6430fb.mock.pstmn.io/customers`;
    }
    return ssoAPIinstance
      .get(userInfoUrl, {headers: {Authorization: `Bearer ${accessToken}`}})
      .catch((error) => error);
  };

  getGiftCardInfo = ({ accessToken, code }) => {
    return ssoAPIinstance
      .get(`${CMS_BASE_URL}/customers/gift_card/${code}`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .catch((error) => error);
  }
  
  redeemGiftCard = ({ accessToken ,uid, code}) => {
    return ssoAPIinstance
      .get(`${CMS_BASE_URL}/customers/${uid}/redeem_gift_card/${code}`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .catch((error) => error);
  }

  updateUserInfo = ({params, accessToken, uid}) =>
    ssoAPIinstance
      .patch(`${CMS_BASE_URL}/customers/${uid}`, params, {
        headers: {Authorization: `Bearer ${accessToken}`},
      })
      .catch((error) => error);

  resetPassword = ({params, accessToken, uid}) => {
    const url = `${CMS_BASE_URL}/users/${uid}/password`;
    return ssoAPIinstance
      .put(url, params, {headers: {Authorization: `Bearer ${accessToken}`}})
      .catch((error) => error);
  };

  getPointsStatus = ({accessToken, uid, fakeApi = false}) => {
    let pointsUrl = `${CMS_BASE_URL}/point-accounts/${uid}`;
    if (fakeApi) {
      pointsUrl = `https://82cdb1a2-3111-483b-a70b-25e7cd6430fb.mock.pstmn.io/point-accounts`;
    }
    return ssoAPIinstance
      .get(pointsUrl, {headers: {Authorization: `Bearer ${accessToken}`}})
      .catch((error) => error);
  };
  getPointStatus = (accessToken, uid, pageLink = null) => {
    let url = `${CMS_BASE_URL}/point-accounts/${uid}/point_statuses?page[number]=1&page[size]=20`;
    if (pageLink !== null && pageLink !== "") {
      return ssoAPIinstance
        .get(pageLink, {
          headers: {Authorization: `Bearer ${accessToken}`},
        })
        .catch((error) => error);
    }
    return ssoAPIinstance
      .get(url, {
        headers: {Authorization: `Bearer ${accessToken}`},
      })
      .catch((error) => error);
  };

  getTotalEarnedStamps = ({accessToken, uid}) => {
    let pointsUrl = `${CMS_BASE_URL}/customers/${uid}/stamps-count`;
    return ssoAPIinstance
      .get(pointsUrl, {headers: {Authorization: `Bearer ${accessToken}`}})
      .catch((error) => error);
  }

  getStampStatus = ({accessToken, uid, pageLink = null}) => {
    const firstLink = `${CMS_BASE_URL}/stamps/stamps_reward_status?page[number]=1&page[size]=20`;
    const link = pageLink || firstLink;
    return ssoAPIinstance
      .get(link, {
        headers: {Authorization: `Bearer ${accessToken}`},
      })
      .catch((error) => error);
  };

  getUserActivityLogs = ({ accessToken, uid, pageLink, levelRelated}) => {
    if (pageLink) {
      return ssoAPIinstance
        .get(pageLink, {
          headers: {
            "Accept-Language": "en",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .catch((error) => error);
    }
    let logsUrl = `${CMS_BASE_URL}/customers/${uid}/activity_logs?page[number]=1&page[size]=20&level_related=${!!levelRelated}`;
    return ssoAPIinstance
      .get(logsUrl, {
        headers: {
          "Accept-Language": "en",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch((error) => error);
  };

  registerApp = (payload) =>
    ssoAPIinstance
      .post(`${CMS_BASE_URL}/app_installations`, payload)
      .catch((error) => error);

  patchRegistedApp = (appInstallationID, payload) =>
    ssoAPIinstance
      .patch(`${CMS_BASE_URL}/app_installations/${appInstallationID}`, payload)
      .catch((error) => error);

  getLevelConfig = () => ssoAPIinstance.get(`${CMS_BASE_URL}/levels`);

  shopifyCreatePointToCash = (accessToken, cashDollar, uid) => ssoAPIinstance
    .post(`${CMS_BASE_URL}/customers/${uid}/shopify_point_to_cash`,
      {
        cash_dollar: cashDollar
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
    .catch((error) => error);

  createPointToCash = (accessToken, cashDollar, uid) => ssoAPIinstance
    .post(`${CMS_BASE_URL}/customers/${uid}/point_to_cash`,
      {
        cash_dollar: cashDollar
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
    .catch((error) => error);

  cancelPointToCash = (accessToken, uid, cashCodeKey) => ssoAPIinstance
    .post(`${CMS_BASE_URL}/customers/${uid}/point_to_cash/cancel`,
      {
        key: cashCodeKey
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
    .catch((error) => error);

  refreshPointToCash = (accessToken, uid, cashCodeKey) => ssoAPIinstance
    .post(`${CMS_BASE_URL}/customers/${uid}/point_to_cash/refresh`,
      {
        key: cashCodeKey
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
    .catch((error) => error);

  deleteUserAccount = ({ params, accessToken, uid }) => {
    const url = `${CMS_BASE_URL}/customers/${uid}`
    return ssoAPIinstance
      .delete(url, {
        data: params,
        headers: {Authorization: `Bearer ${accessToken}`},
      })
      .catch((error) => error);
  };
}

export const TIERS_MAP = {
  BRONZE: "BRONZE",
  SILVER: "SILVER",
  GOLD: "GOLD",
  PLATIUM: "PLATIUM",
};
export const ssoAPIHelper = new SSOAPIHelper();
