import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./PointAndCashSection.scss"

import CustomModal from "../base/CustomModal";
import { enLocaleNumberFormatter, createAction } from "utils";
import UseCashQRCodeModal from "./UseCashQRCodeModal";
import { isPadOrMobile } from "utils/ScreenUtils";

export const POINT_TO_CASH_CONVERT_RATE = 1000;

const mapStateToProps = state => {
  return {
    currentPoints: state.users.availablePoints,
    cashCodeKey: state.users.cashCodeKey,
  };
};

function PointAndCashSection({
  currentPoints,
  cashCodeKey,
  t
}) {
  const isMobile = isPadOrMobile();
  const maxCashDollar = parseInt(currentPoints / POINT_TO_CASH_CONVERT_RATE) || 0;

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [inputCashDollar, setInputCashDollar] = useState();
  const [showUseCashModal, setShowUseCashModal] = useState(false);
  const [cashDollarInputError, setCashDollarInputError] = useState();
  const dispatch = useDispatch();
  const payBtnRef = useRef();

  const isButtonDisabled = !inputCashDollar || inputCashDollar < 1;

  const onClickSection = () => {
    if (isMobile) {
      history.push("/use_cash_dollar");
      return;
    }
    if (!showModal) {
      setShowModal(true);
    }
  }

  const fetchCashCode = ({ successAction, afterAction = () => { } }) => {
    if (inputCashDollar) {
      dispatch(createAction("users/createPointToCash")({
        cashDollarAmount: parseInt(inputCashDollar),
        successAction,
        afterAction
      }))
    }
  }
  const cancelPointToCash = () => {
    if (cashCodeKey) {
      dispatch(createAction("users/cancelPointToCash")())
    }
  }

  const refreshPointToCashCode = ({ failedAction }) => {
    dispatch(createAction("users/refreshPointToCash")({
      failedAction
    }))
  }

  const validateInput = () => {
    if (inputCashDollar > maxCashDollar) {
      setCashDollarInputError("exceed_point_balance")
      return false
    }
    return true;
  }

  const handlePayCashDollar = () => {
    if (!validateInput()) {
      return;
    }
    payBtnRef.current.disabled = true;
    fetchCashCode({
      successAction: () => {
        setShowModal(false);
        setShowUseCashModal(true);
      },
      afterAction: () => {
        payBtnRef.current.disabled = isButtonDisabled;
      }
    });
  }

  const refreshQRCodeAction = () => {
    refreshPointToCashCode({
      failedAction: () => {
        cancelPointToCash()
        setShowUseCashModal(false)
      }
    })
  }
  const getErrorMsg = () => {
    if (cashDollarInputError === "exceed_point_balance") {
      return t("exceed_point_balance", {
        points: enLocaleNumberFormatter(inputCashDollar * POINT_TO_CASH_CONVERT_RATE)
      })
    }
    return "";
  }

  useEffect(() => {
    return () => {
      dispatch(createAction("users/updateState")({
        cashCodeKey: ""
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabs = [
    {
      content: (
        <>
          <div className="cash-modal-descrition body-1">{t("use_cash_dollar_description")}</div>
          <div className="use-cash-dollar-modal-content">
            <div>
              <div className="cash-modal-secondary-title subheading">{t("balance")}</div>
              <div className="cash-point-balance-text body-2">
                {t("cash_dollar_points", {
                  cashDollar: enLocaleNumberFormatter(maxCashDollar),
                  points: enLocaleNumberFormatter(currentPoints)
                })}
              </div>
              <div className="point-to-cash-rule tag-menu-1">
                {t("point_to_cash_rule", {
                  points: enLocaleNumberFormatter(POINT_TO_CASH_CONVERT_RATE),
                  cashDollar: 1
                })}
              </div>
            </div>
            <div>
              <div className="cash-modal-secondary-title subheading">{t("will_spend")}</div>
              <div>
                <div className="cash-dollar-input-area">
                  <input
                    className={ inputCashDollar ? "title-x-large" : "field-text"}
                    type="number"
                    placeholder={t("records:please_input_hints")}
                    min={0}
                    max={9999999999}
                    value={inputCashDollar}
                    inputMode="numeric"
                    onChange={(e) => {
                      let maxLengthValue = e.target.value;
                      if (maxLengthValue && maxLengthValue.length > 10) {
                        maxLengthValue = maxLengthValue.substring(0, 10);
                      }
                      const inputData = maxLengthValue > 0 ? parseInt(maxLengthValue) : "";
                      setInputCashDollar(inputData)
                      if (cashDollarInputError) {
                        setCashDollarInputError()
                      }
                    }}
                  />
                  <div className="cash-point-balance-text body-2" style={{ textTransform: "lowercase" }}>{t("cash_dollar")}</div>
                </div>
                <div
                  className={
                    cashDollarInputError ?
                      "cash-modal-tips error-field tag-menu-1" : "cash-modal-tips tag-menu-1"
                  }
                >
                  {cashDollarInputError ? getErrorMsg()
                    : inputCashDollar ? t("points_will_be_used", {
                      points: enLocaleNumberFormatter(inputCashDollar * POINT_TO_CASH_CONVERT_RATE)
                    }) : null}
                </div>
              </div>
            </div>
            <div>
              <button
                ref={payBtnRef}
                className="custom-primary-button button-large"
                disabled={isButtonDisabled}
                onClick={handlePayCashDollar}
              >
                {t("pay_now")}
              </button>
              <div className="cash-modal-tips tag-menu-1">{t("confirm_pay_cash_tips")}</div>
            </div>
          </div >
        </>

      )
    }
  ]

  return (
    <>
      <div className="use-cash-dollar-section" onClick={onClickSection}>
        <div className="background-icon" />
        <div className="cash-dollar-title subheading">{t("use_cash_dollar")}</div>
        <div className="use-cash-dollar-content">
          <div className="point-content">
            <img
              alt="points"
              src={"/profile/currentPoint/points.png"}
              srcSet={"/profile/currentPoint/points@2x.png 2x, /profile/currentPoint/points@3x.png 3x"}
            />
            <div>
              <div className="common-profile-section-text field-text">{t("my_points")}</div>
              <div className="cash-dollar-num-text headline">{enLocaleNumberFormatter(currentPoints)}</div>
            </div>
          </div>
          <div>{"="}</div>
          <div className="point-content">
            <img
              alt="cash"
              src="/profile/currentPoint/cash-dollar.png"
              srcSet={"/profile/currentPoint/cash-dollar@2x.png 2x, /profile/currentPoint/cash-dollar@3x.png 3x"}
            />
            <div>
              <div className="common-profile-section-text field-text">{t("cash_dollar")}</div>
              <div className="cash-dollar-num-text headline">{enLocaleNumberFormatter(maxCashDollar)}</div>
            </div>
          </div>
          <img alt="icon" src={"/profile/right-arrow.png"} />
        </div>
      </div>
      <CustomModal
        className="use-cash-dollar-modal"
        title={t("use_cash_dollar")}
        show={showModal}
        onClose={() => {
          setCashDollarInputError()
          setInputCashDollar()
          setShowModal(false)
        }}
        tabs={tabs}
        hideTab={true}
      />
      <UseCashQRCodeModal
        show={showUseCashModal}
        onHide={() => {
          setInputCashDollar()
          cancelPointToCash()
          setShowUseCashModal(false)
        }}
        cashDollar={inputCashDollar}
        code={cashCodeKey}
        refreshQRCodeAction={refreshQRCodeAction}
      />
    </>
  )
}
export default connect(mapStateToProps)(withTranslation()(PointAndCashSection));
