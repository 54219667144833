import { createAction } from '../utils';

export default {
  namespace: 'pullrefreshandloadmore',
  state: {
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    startRefresh(state, { payload }) {
      const { pageName } = payload;
      return {
        ...state,
        [`${pageName}IsRefreshing`]: true,
        [`${pageName}HasMoreData`]: true,
        [`${pageName}HasError`]: false,
      };
    },
    refreshFailed(state, { payload }) {
      const { pageName } = payload;
      return {
        ...state,
        [`${pageName}IsRefreshing`]: false,
        [`${pageName}HasMoreData`]: false,
        [`${pageName}HasError`]: true,
      };
    },
  },
  effects: {
    finishRefreshing: [function* ({ payload }, { put }) {
      yield put(createAction('updateState')({ ...payload }));
    }, { type: 'takeLatest' }],

    finishLoadingMore: [function* ({ payload }, { put, call }) {
      yield put(createAction('updateState')({ ...payload }));
    }, { type: 'takeLatest' }],
  },
};
