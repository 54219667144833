import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import { Image, Button, Modal } from "react-bootstrap";

import FilterActionBar from "component/store/FilterActionBar";
import MultiChoiceWithSubList from "component/store/MultiChoiceWithSubList";
import { updateQueryString } from "utils/UrlUtils";
import { switchOneOfSelectedList } from "utils/MultiChoiceUtils";
import i18n from "I18n";


import "./StoreFilter.scss";


const mapStateToProps = state => {
  return {
    language: state.users.language,

    storeRegions: state.store.storeRegions,
    storeDistricts: state.store.storeDistricts,
    storeSelectedBrandIDs: state.store.storeSelectedBrandIDs,

    storeBrands: state.store.storeBrands,
    storeSelectedDistrictIDs: state.store.storeSelectedDistrictIDs,
  };
};

function StoreFilter({
  language,
  storeRegions,
  storeDistricts,
  storeSelectedBrandIDs,
  storeBrands,
  storeSelectedDistrictIDs,
  onHide,
}) {
  const [showLocation, setShowLocation] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [newDistrictIDs, setNewDistrictIDs] = useState(storeSelectedDistrictIDs);

  const [showBrand, setShowBrand] = useState(false);
  const [newBrandIDs, setNewBrandIDs] = useState(storeSelectedBrandIDs);

  const history = useHistory();
  const location = useLocation();

  const updateDistrictBrandFilter = (newBrandIDs, newDistrictIDs) => {
    const queryDict = {
      'sf_brand': newBrandIDs?.length > 0 ? newBrandIDs : null,
      'sf_district': newDistrictIDs?.length > 0 ? newDistrictIDs : null,
    };
    const newUrl = updateQueryString(location, queryDict);
    history.push(newUrl);
  };

  const onBrandButtonClicked = (ID) => {
    const newList = switchOneOfSelectedList(newBrandIDs, ID);
    setNewBrandIDs(newList);
  };

  useEffect(() => {
    if (
      storeRegions?.length > 0
      && storeDistricts?.length > 0
    ) {
      const items = [];
      for (const region of storeRegions) {
        const item = {
          id: region.id,
          name: region.name,
          subItems: [],
        };
        for (const x of storeDistricts) {
          if (Number(x.region) !== Number(region.id)) {
            continue;
          }
          for (const district of x.district) {
            const subItem = {
              id: district.id,
              name: district.name,
            };
            item.subItems.push(subItem);
          }
        }
        items.push(item);
      }
      setLocationData(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeRegions, storeDistricts]);

  return (
    <Modal
      className={'store_filter_modal'}
      animation={false}
      show={true}
      onHide={() => onHide()}
      scrollable={false}>
      <div className={'store_filter_location_brand'}>
        <div className={'filter_title'}>
          <span className="title-small">{i18n.t('records:record_filter')}</span>
          <Image
            className={'close p2_color_image'}
            src="/action/close.png"
            srcSet="/action/close@2x.png 2x, /action/close@3x.png 3x"
            onClick={() => onHide()} />
        </div>
        <div
          className={'filter_content'}>
          <div className={'region_and_district'}>
            <div
              className={'filter_title'}
              onClick={() => setShowLocation(!showLocation)} >
              <span className="subheading">{i18n.t('store:Location')}</span>
              {
              showLocation
              ? (
              <Image
                className={'extend p2_color_image'}
                src="/action/minus.png"
                srcSet="/action/minus@2x.png 2x, /action/minus@3x.png 3x" />
              ) : (
              <Image
                className={'extend p2_color_image'}
                src="/action/plus.png"
                srcSet="/action/plus@2x.png 2x, /action/plus@3x.png 3x" />
              )
              }
            </div>
            {
            showLocation
            ? (
            <div className={'filter_location'}>
              <MultiChoiceWithSubList
                itemsData={locationData}
                selectedSubItemIDs={newDistrictIDs}
                onSelectedSubItemsChange={(_, IDs) => {
                  setNewDistrictIDs(IDs);
                }}/>
            </div>
            ) : <></>
            }
          </div>
          <div className={'filter_separate custom_separate'} />
          <div className={'brand'}>
            <div
              className={'filter_title'}
              onClick={() => setShowBrand(!showBrand)} >
              <span className="subheading">{i18n.t('store:Brand')}</span>
              {
              showBrand
              ? (
              <Image
                className={'extend p2_color_image'}
                src="/action/minus.png"
                srcSet="/action/minus@2x.png 2x, /action/minus@3x.png 3x" />
              ) : (
              <Image
                className={'extend p2_color_image'}
                src="/action/plus.png"
                srcSet="/action/plus@2x.png 2x, /action/plus@3x.png 3x" />
              )
              }
            </div>
            {
            showBrand
            ? (
            <div className={'filter_brand'}>
              {storeBrands.map((brand, index) => {
                console.log('brand', brand);
                return (
                  <Button
                    className="button-large"
                    key={brand.id}
                    variant={newBrandIDs.includes(brand.id) ? 'primary' : 'outline-secondary'}
                    onClick={() => onBrandButtonClicked(brand.id)} >
                    {brand.name}
                  </Button>
                );
              })}
            </div>
            ) : <></>
            }
          </div>
        </div>
        <div className='custom_separate action_bar_border_top' />
        <FilterActionBar
          onReset={() => {
            updateDistrictBrandFilter([], []);
            onHide();
          }}
          onApply={() => {
            updateDistrictBrandFilter(newBrandIDs, newDistrictIDs);
            onHide();
          }}
        />
      </div>
    </Modal>
  );
}

export default connect(mapStateToProps)(StoreFilter);

