import { createAction, delay } from '../utils';
import { CampaignAPIHelper } from '../services/CampaignAPIHelper';
import i18next from "i18next";


export const COUPON_CONFIRM_BUTTON_STATUS = {
  CONFIRM: 0,
  LOADING: 1,
  SUCCESS: 2,
  CALL_API_ERROR: 3,
  COUPON_REACH_LIMIT: 4,
  FAIL: 5
}

export default {
  namespace: 'buyCoupon',
  state: {
    confirmButtonStatus: COUPON_CONFIRM_BUTTON_STATUS.CONFIRM, // 0: confirm, 1: loading, 2: success, 3: false, 4: reach limit
    errorMesage: ""
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetCouponModal(state) {
      return {
        confirmButtonStatus: COUPON_CONFIRM_BUTTON_STATUS.CONFIRM,
        errorMesage: ""
      };
    },
  },
  effects: {
    buyCoupon: [
      function* ({ payload }, { call, put, select }) {
        const { campaignID, desiredStore, desiredQuantity } = payload;
        const startTime = Date.now();
        yield put(createAction('updateState')({
          confirmButtonStatus: COUPON_CONFIRM_BUTTON_STATUS.LOADING,
        }));
        const customerID = yield select((state) => state.users.userInfo.uid);
        try {
          const response = yield call(CampaignAPIHelper.participateCampaign, customerID, {
            campaign: campaignID,
            desired_coupon_quantity: desiredQuantity,
            desired_coupon_store: desiredStore,
          });
          const endTime = Date.now();
          if (endTime - startTime < 1000) {
            yield call(delay, 1000 - endTime + startTime);
          }
          if (response.status < 300) {
            yield put(createAction('updateState')({
              confirmButtonStatus: COUPON_CONFIRM_BUTTON_STATUS.SUCCESS,
            }));
          } else {
            const errorDetail = response?.data?.detail;
            let errorMessage = errorDetail;
            if (errorDetail === 'Desired coupons are sold out.') {
              errorMessage = i18next.t('campaignDetail:couponOutOfStock');
            } else if (errorDetail?.includes('Exceed campaign')) {
              errorMessage = i18next.t('campaignDetail:reachLimit');
            } else if (errorDetail?.includes('not available today')) {
              errorMessage = i18next.t('campaignDetail:blackoutLimit');
            } else if (errorDetail === 'Coupon expired.') {
              errorMessage = i18next.t('coupon:couponHasExpired');
            }
            // yield put({
            //   type: "navBars/updateState",
            //   payload: {
            //     toastShowing: {
            //       value: true,
            //       content: errorMessage,
            //     },
            //   },
            // });
            yield put(createAction('updateState')({
              confirmButtonStatus: COUPON_CONFIRM_BUTTON_STATUS.FAIL,
              errorMessage
            }));
          }
        } catch (error) {
          const endTime = Date.now();
          if (endTime - startTime < 1000) {
            yield call(delay, 1000 - endTime + startTime);
          }
          yield put(createAction('updateState')({
            confirmButtonStatus: COUPON_CONFIRM_BUTTON_STATUS.CALL_API_ERROR,
          }));
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
