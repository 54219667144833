import React from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import LoadMoreSpinner from "../common/LoadMoreSpinner";
import { enLocaleNumberFormatter } from "utils";
import { getLevelRenewTime, getRewardStatusExpireDate } from "utils/DateTimeUtils";

export const rewardStatusModalTab = {
  level: "level",
  coupon: "coupon",
  points: "points",
  stamp: "stamp",
}

export const parsePointsStatus = (pointStatus) => {
  let parsedPointsList = [];
  if (pointStatus && pointStatus.length > 0) {
    parsedPointsList = pointStatus.map((item) => {
      return {
        name: enLocaleNumberFormatter(item.points),
        date: `${getLevelRenewTime(
          item.expiration_date
        )}`,
      };
    });
  }
  return parsedPointsList;
};

export const parseCouponListData = (availableCoupons) => {
  return availableCoupons.map((coupon) => {
    const couponCardInfo = coupon.card;
    let couponExpiryDate = couponCardInfo.displayDate;
    if (coupon.original.effective_date) {
      couponExpiryDate = `${couponExpiryDate}`;
    }
    return {
      name: couponCardInfo.name,
      date: couponExpiryDate,
    };
  });
};

export const parseStampStatus = (stampStatus) => {
  let parsedStampList = [];
  if (stampStatus && stampStatus.length > 0) {
    parsedStampList = stampStatus.map((item) => {
      return {
        name: item.name,
        stampCampaignId: item.stampCampaignId,
        earnedStamps: item.earnedStamps,
        date: `${getRewardStatusExpireDate(
          item.expirationDate
        )}`,
      };
    });
  }
  return parsedStampList;
};


const navigateToStampCampaign = (history, isStamp, stampCampaignId) => {
  if (history && isStamp && stampCampaignId) {
    history.push(`/campaign/${stampCampaignId}`);
  }
}

const getNoRecordMessage = (t, type) => {
  let message = "to_earn_points";
  if (type === rewardStatusModalTab.coupon) {
    message = "to_redeem_coupon";
  } else if (type === rewardStatusModalTab.stamp) {
    message = "to_earn_stamp";
  }
  return t(message);
}


const RenderTableData = ({ t, type, title, dataList, isLoading = false, isLoadingMore = false }) => {
  const history = useHistory();
  const isStamp = type === rewardStatusModalTab.stamp;

  if (isLoading) {
    return (
      <tbody>
        <tr>
          <td>
            <LoadMoreSpinner />
          </td>
        </tr>
      </tbody>
    )
  }
  if (dataList && dataList.length > 0) {
    return (
      <tbody>
        {dataList.map((item, index) => {
          return (
            <tr
              key={index + item}
              className={isStamp ? "tr-stamp" : ""}
              onClick={() => {
                navigateToStampCampaign(history, isStamp, item.stampCampaignId)
              }}
            >
              <th className={`item_name ${isStamp ? "stamp-status" : ""} body-3`}>
                {
                  isStamp
                    ? <div className="earned_stamps">{item.earnedStamps}</div>
                    : null
                }
                <div className={isStamp ? "stamp_campaign_name" : ""}>
                  <label>
                    {item.name}
                  </label>
                  {
                    isStamp
                      ? <img
                        alt=""
                        src={"/profile/right-arrow.png"}
                        srcSet="/profile/right-arrow@2x.png 2x, /profile/right-arrow@3x.png 3x"
                      />
                      : null
                  }
                </div>
              </th>
              <th className="item_date body-1">{item.date}</th>
            </tr>
          );
        })}
        {isLoadingMore && <tr><LoadMoreSpinner /></tr>}
      </tbody>
    )
  }
  return (
    <tbody>
      <tr className="no-record-tr no_record_message subheading">
        <td>
          <p className="no_record_message subheading">
            {t("no_record_click")}
            <Link to="/offer/LATEST">{" " + t("here") + " "}</Link>
            {getNoRecordMessage(t, type)}
          </p>
        </td>
      </tr>
    </tbody>
  )
};


const RewardStatusTableView = ({
  t,
  type,
  title,
  itemList,
  isSimple = false,
  isLoading = false,
  isLoadingMore = false,
  loadMore = () => { },
  totalEarnedStamps = "",
}) => {
  const displayItemList = isSimple && itemList?.length > 5 ? itemList.slice(0, 5) : itemList;
  return (
    <div className="reward_status_table_view">
      <div className="reward_status_table_head">
        <div className="reward_status_table_head_item subheading">{`${title}${type === rewardStatusModalTab.stamp ? ` (${totalEarnedStamps})` : ""}`}</div>
        <div className="reward_status_table_head_item subheading">
          {t("account_expiry_date")}
        </div>
      </div>
      <div
        className={`reward-status-table-body ${isSimple ? "" : "scroll-table"}`}
        onScroll={(event) => {
          if (
            event.nativeEvent.target.scrollTop +
            event.nativeEvent.target.clientHeight >
            event.nativeEvent.target.scrollHeight * 0.9
            && !isLoadingMore
          ) {
            loadMore();
          }
        }}>
        <Table className="reward-status-table" borderless>
          <RenderTableData
            t={t}
            type={type}
            title={title}
            dataList={displayItemList}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
          />
        </Table>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(RewardStatusTableView));