import React from "react";
import { Button, Image } from "react-bootstrap";

import "./OfferFloatActionButton.scss";

class OfferFloatActionButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      floatButtonIsVisible: false,
    };
  }

  componentDidMount() {
    this._toggleVisibility();
    window.addEventListener("scroll", this._toggleVisibility);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._toggleVisibility);
  }

  _toggleVisibility = () => {
    if (window.pageYOffset > 0) {
      this.setState({
        floatButtonIsVisible: true,
      });
    } else {
      this.setState({
        floatButtonIsVisible: false,
      });
    }
  };

  _scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      this.state.floatButtonIsVisible && (
        <div
          className={`offer_floatButton_container ${this.props.customClassName}`}
        >
          <Button id="offer_floatButton" onClick={this._scrollToTop}>
            <Image
              className="offer_floatButton_image"
              src="/offer/icn-back-to-top.svg"
            />
          </Button>
        </div>
      )
    );
  }
}

export default OfferFloatActionButton;
