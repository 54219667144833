import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import InboxList from 'container/InboxList';
import InboxListWithDetail from 'container/InboxListWithDetail';

import { createAction } from 'utils';
import {
  getDocumentHeight,
  getScrollTop,
  getWindowHeight,
  isMobile,
} from 'utils/ScreenUtils';

const mapStateToProps = (state) => {
  return {
    language: state.users.language,
    messageList: state.inboxMessages.messageList,
  };
};
function Inbox({ language, messageList }) {
  const [showMobilePage, setShowMobilePage] = useState(isMobile());

  const dispatch = useDispatch();
  const history = useHistory();

  const updateWindowDimensions = () => {
    setShowMobilePage(isMobile());
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (messageList?.length > 0) {
      // not refresh message list when back from inbox detail page
      return;
    }
    // refresh messages when list empty
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    dispatch(createAction('inboxMessages/refreshMessages')());
  }, []);

  if (showMobilePage) {
    return <InboxList />;
  }
  return <InboxListWithDetail />;
}

export default connect(mapStateToProps)(Inbox);
