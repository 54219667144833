import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import LoadMoreSpinner from 'component/common/LoadMoreSpinner';
import { TransactionHistoryKey } from 'models/Records';

import { createAction } from 'utils';
import {
  getDocumentHeight,
  getScrollTop,
  getWindowHeight,
} from 'utils/ScreenUtils';

import RightArrow from 'assets/chevron-right.svg';

import './TransactionHistoryListMobile.scss';

@connect(({ users, transaction, pullrefreshandloadmore }) => ({
  language: users.language,

  transactionOriginList: transaction.transactionOriginList,
  transactionNetWorkError: transaction.transactionNetWorkError,
  transactionNextLink: transaction.transactionNextLink,

  hasMoreData: pullrefreshandloadmore[`${TransactionHistoryKey}HasMoreData`],
  isRefreshing: pullrefreshandloadmore[`${TransactionHistoryKey}IsRefreshing`],
  isLoadingMore:
    pullrefreshandloadmore[`${TransactionHistoryKey}IsLoadingMore`],
}))
class TransactionHistoryListMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(
      createAction('transaction/refreshTransactionHistory')({
        onlyLoadNotRefresh: true,
      }),
    );
    window.addEventListener('scroll', this.bindHandleWindowScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.bindHandleWindowScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.props.dispatch(
        createAction('transaction/refreshTransactionHistory')(),
      );
    }
  }

  transactionItemDidClicked = (id) => {
    if (id) {
      this.props.history.push(`/transaction_history_detail/${id}`);
    }
  };

  shouldLoadMore = () => {
    return (
      this.props.hasMoreData &&
      !(this.props.isRefreshing || this.props.isLoadingMore)
    );
  };

  bindHandleWindowScroll = () => {
    const scrollTopLength = getScrollTop();
    const windowHeight = getWindowHeight();
    const documentHeight = getDocumentHeight();
    const isLoadArea = scrollTopLength + windowHeight > documentHeight - 100;
    if (isLoadArea && this.shouldLoadMore()) {
      this.props.dispatch(
        createAction('transaction/loadMoreTransactionHistory')(),
      );
    }
  };

  parseTransaction = () => {
    if (
      this.props.transactionOriginList &&
      this.props.transactionOriginList.length > 0
    ) {
      const parsedList = this.props.transactionOriginList.map((item) => {
        return {
          id: item.id,
          name: item.receiptNumber,
          date: item.receiptDateTime,
          content: item.receiptAmount,
        };
      });
      return parsedList;
    }
    return [];
  };

  renderTableData = ({
    dataList,
    isLoading = false,
    isLoadingMore = false,
  }) => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <LoadMoreSpinner />
          </tr>
        </tbody>
      );
    }
    if (dataList && dataList.length > 0) {
      return (
        <tbody>
          {dataList.map((item, index) => {
            return (
              <tr key={index + item}>
                <th className="item_name">
                  <div onClick={() => this.transactionItemDidClicked(item.id)}>
                    <div className="item-title body-3">{item.name}</div>
                    <img
                      className={'right-arrow'}
                      alt="select"
                      src={RightArrow}
                    />
                  </div>
                  <div className="item-sub-title tag-menu-1">
                    {`${this.props.t('records:transaction_ordered')} ${
                      item?.date
                    }`}
                  </div>
                </th>
                <th className="item-content field-text">
                  <div>{item?.content}</div>
                </th>
              </tr>
            );
          })}
          {isLoadingMore && (
            <tr>
              <LoadMoreSpinner />
            </tr>
          )}
        </tbody>
      );
    }
    return (
      <tbody>
        <tr className="no-record-tr">
          <td>
            <p className="no_record_message subheading">{this.props.t('no_record')}</p>
          </td>
        </tr>
      </tbody>
    );
  };

  tableView = (itemList, isLoading = false, isLoadingMore = false) => {
    return (
      <div className="reward_status_table_view transaction-history-table-view">
        <div className="reward_status_table_head">
          <div className="reward_status_table_head_item long_invoice_id subheading">
            {this.props.t('records:transaction_invoice_id')}
          </div>
          <div className="reward_status_table_head_item total subheading">
            {this.props.t('records:transaction_total')}
          </div>
        </div>
        <div className="reward-status-table-body transaction-history-table-content scroll-table">
          <Table className="reward-status-table activity-log-table" borderless>
            {this.renderTableData({
              dataList: itemList,
              isLoading,
              isLoadingMore,
            })}
          </Table>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Container className="transaction-history-mobile-page-container reward-status-mobile-page-container">
        <Row>
          <Col>
            {this.tableView(
              this.parseTransaction(),
              this.props.isRefreshing,
              this.props.isLoadingMore || false,
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(TransactionHistoryListMobile));
