import React from "react";
import { connect } from "react-redux";
import { Button, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Progress } from 'antd';

import BuyCouponModal from "component/campaign/BuyCouponModal";
import { CAMPAIGN_TYPE_COUPON } from "services/CampaignAPIHelper";
import { createAction } from "utils";
import { isMobile } from "utils/ScreenUtils";
import i18n from "I18n";

import stampGift from 'assets/images/stampGift.png'
import "./CampaignCard.scss";
import { checkIfExpired, getActionButonText } from "utils/CampaignUtil";

@connect(({ campaignDetail }) => ({}))
class CampaignCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBuyCouponModal: false
    };
  }

  _btndidClick = (event) => {
    if (this.props.cardData.campaignActionButtonAvaiable) {
      if (checkIfExpired(this.props.cardData.campaignEndDate)) {
        this.props.dispatch(createAction("navBars/updateState")(
          {
            toastShowing: {
              value: true,
              content: i18n.t('campaignDetail:expireToast'),
            },
          }
        ))
        event.stopPropagation();
        return;
      }
      if (isMobile()) {
        if (this.props.history) {
          this.props.dispatch(
            createAction("campaignDetail/getCampaignDetail")({
              campaignID: this.props.cardData.campaignId,
            })
          );
          this._goRedeemPage()
        }
      } else if (this.props.cardData?.campaignType === CAMPAIGN_TYPE_COUPON) {
        this.props.dispatch(
          createAction("campaignDetail/getCampaignBlackout")({
            campaignID: this.props.cardData.campaignId,
            onSuccess: () => {
              this.setState({ showBuyCouponModal: true });
            },
            onFailure: () => {
              this.props.dispatch(
                createAction('navBars/updateState')({
                  toastShowing: {
                    value: true,
                    content: i18n.t('campaignDetail:blackoutLimit'),
                  },
                }),
              );
            },
          })
        );
      }
    }
    event.stopPropagation();
  };

  _goRedeemPage = (data) => {
    this.props.history.push("/campaign/detail", {
      // campaign: data,
      goBackOnSuccess: true,
      campaignID: this.props.cardData.campaignId,
    });
  };

  _cardDidClick = () => {
    if (this.props.history) {
      // this.props.dispatch(createAction('campaignDetail/setCampaignID')(this.props.cardData.campaignId))
      this.props.history.push("/campaign/" + this.props.cardData.campaignId);
    }
  };

  render() {
    const earnedStamps = this.props.cardData.earnedStamps;
    const totalStamps = this.props.cardData.totalStamps || 1;
    const campaginActionButtonText = getActionButonText(
      this.props.cardData.campaignType,
      this.props.cardData.requiredPoints,
      this.props.cardData.campaignStartDate
    )
    const getCampaignCardTagClass = () => {
      if (this.props.cardData.campaignIsOnlyForYou) {
        return "campagin-card-tag campaign-tag-isOnlyForYou tag-menu-2"
      }
      if (this.props.cardData.campaignIsFeatured) {
        return "campagin-card-tag  campaign-tag-isFeatured tag-menu-2"
      }
      return "campagin-card-tag tag-menu-2";
    }
    return (<>
      <div className="campagin_card" onClick={this._cardDidClick}>
        <div className="campagin_card_image_area">
          <div className={this.props.cardData.outOfStock ? "campaign_card_dimmed_image_area" : ""}>
            <div className="campagin_card_mainImage_container">
              <Image
                src={this.props.cardData.campaignPhoto}
                className="campagin_card_mainImage"
              />
            </div>
            <div className="campagin_card_avatarImage_container">
              {this.props.cardData.brandImage && (
                <Image
                  src={this.props.cardData.brandImage}
                  className="campagin_card_avatarImage"
                  roundedCircle="true"
                />
              )}
            </div>
            {this.props.cardData.campaignTag &&
              this.props.shouldShowCampaignTag && (
                <div className={getCampaignCardTagClass()}>
                  {this.props.cardData.campaignTag}
                </div>
              )}
            {this.props.cardData.leftDays && (
              <div className="campagin_card_tag_leftDay tag-menu-2">
                {this.props.cardData.leftDays}
              </div>
            )}
          </div>
          {this.props.cardData.outOfStock && (
            <div className="campagin_card_tag_leftDay campaign_card_out_of_stock_tag_class">
              <label className="campaign_card_out_of_stock_label tag-menu-2">
                {i18n.t("campaignDetail:campaign_card_out_of_stock_tag")}
              </label>
            </div>
          )}
        </div>
        <div className={`campagin_card_describe_area ${isMobile() || this.props.inStoreDetail ? "subheading" : "title-medium-1"}`}>
          {this.props.cardData.campaignDescription}
        </div>
        {campaginActionButtonText && (
          <div className="campagin_card_button_area">
            <div className="campagin_card_button_container">
              <Button
                className={`campagin_card_button shadow-none ${isMobile() ? "button-small" : "button-large"}`}
                onClick={this._btndidClick}
                disabled={this.props.cardData.outOfStock || !this.props.cardData.campaignActionButtonAvaiable}
              >
                {campaginActionButtonText}
              </Button>
            </div>
          </div>
        )}
        {this.props.cardData.totalStamps && (
          <div className="campagin_card_button_area">
            <div className='campaign_card_process_container'>
              <label className={isMobile() ? "body-2" : "subheading"}>{i18n.t('campaignDetail:campaignProgress')}</label>
              <Progress
                style={{
                  flex: 5,
                  marginTop: "-8px",
                }}
                percent={Math.round(earnedStamps * 100 / totalStamps)}
                strokeWidth={14}
                showInfo={false}
              />
              <div className="stamp_gift_icon">
                <img src={stampGift} alt={"Gift"} />
              </div>
            </div>

          </div>
        )}
      </div>
      {
        this.state.showBuyCouponModal &&
        <BuyCouponModal
          show={this.state.showBuyCouponModal}
          campaignID={this.props.cardData.campaignId}
          onHide={() => {
            this.setState({ showBuyCouponModal: false })
          }}
        />
      }
    </>);
  }
}

export default withRouter(CampaignCard);
