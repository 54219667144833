import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Image, ListGroup } from "react-bootstrap";

import CheckBoxImage, { CHECKBOX_INIT, CHECKBOX_SOME, CHECKBOX_CHECKED } from "component/common/CheckBoxImage";
import {
  addAllToSelectedList,
  removeAllFromSelectedList,
} from "utils/MultiChoiceUtils";
import i18n from 'I18n';

import "./MultiChoiceWithSubList.scss";


const mapStateToProps = state => {
  return {
    language: state.users.language,
  };
};

function MultiChoiceWithSubList({
  language,
  itemsData,
  selectedSubItemIDs,
  onSelectedSubItemsChange,
}) {
  const [activeItem, setActiveItem] = useState(null);
  const [activeSubItems, setActiveSubItems] = useState([]);
  const [subListStatus, setSubListStatus] = useState(CHECKBOX_INIT);
  const [allSelectedID, setAllSelectedID] = useState(null);
  const [selectedSubIDs, setSelectedSubIDs] = useState(selectedSubItemIDs);

  const addSubItems = (itemID, subItemID) => {
    const newList = addAllToSelectedList(selectedSubIDs, [subItemID]);
    const filteredList = [];
    for (const item of itemsData) {
      if (item.id !== itemID) {
        continue;
      }
      for (const subItem of item?.subItems) {
        if (newList.includes(subItem.id)) {
          filteredList.push(subItem.id);
        }
      }
      setSelectedSubIDs(filteredList);
      return;
    }
    setSelectedSubIDs([]);
  };

  const removeSubItems = (subItemID) => {
    const newList = removeAllFromSelectedList(selectedSubIDs, [subItemID]);
    setSelectedSubIDs(newList);
  };

  useEffect(() => {
    for (const item of itemsData) {
      if (selectedSubItemIDs?.length > 0) {
        for (const subItem of item?.subItems) {
          if (selectedSubItemIDs.includes(subItem.id)) {
            setActiveItem(item);
            return;
          }
        }
      } else {
        setActiveItem(item);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsData]);

  useEffect(() => {
    if (itemsData?.length > 0) {
      if (selectedSubIDs?.length > 0) {
        let allSelectID = null;
        for (const item of itemsData) {
          if (allSelectID !== null) {
            break;
          }
          if (item?.subItems?.length > 0) {
            allSelectID = item.id;
            for (const subItem of item?.subItems) {
              if (!selectedSubIDs.includes(subItem.id)) {
                allSelectID = null;
                break;
              }
            }
          }
        }
        setAllSelectedID(allSelectID);
      } else {
        setAllSelectedID(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubIDs]);

  useEffect(() => {
    onSelectedSubItemsChange(allSelectedID, selectedSubIDs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelectedID, selectedSubIDs]);

  useEffect(() => {
    let hasAdded = false;
    let notAdded = false;
    if (activeItem?.subItems?.length > 0) {
      for (const subItem of activeItem?.subItems) {
        if (!selectedSubIDs.includes(subItem.id)) {
          notAdded = true;
        } else {
          hasAdded = true;
        }
        if (notAdded && hasAdded) {
          break;
        }
      }
    }
    if (hasAdded && notAdded) {
      setSubListStatus(CHECKBOX_SOME);
    }
    if (!hasAdded && notAdded) {
      setSubListStatus(CHECKBOX_INIT);
    }
    if (hasAdded && !notAdded) {
      setSubListStatus(CHECKBOX_CHECKED);
    }
    if (!hasAdded && !notAdded) {
      // empty list
      setSubListStatus(CHECKBOX_INIT);
    }
    const subItems = activeItem?.subItems?.map((subItem, index) => {
      subItem['checked'] = selectedSubIDs.includes(subItem.id);
      return subItem;
    });
    setActiveSubItems(subItems);
  }, [activeItem, selectedSubIDs]);

  return (
    <div className="multi_choice_with_sub_list">
      <div className={'scroll_list'}>
        <ListGroup className={'category'} as="ul">
          {itemsData.map((item, index) => {
            return (
              <ListGroup.Item
                key={`category_${item.id}`}
                as="li"
                active={item.id === activeItem?.id}
                // disabled={storeSubCategory[`category-${item.id}`]?.length > 0}
                onClick={() => {
                  setActiveItem(item);
                }}>
                <span className={item.id === activeItem?.id ? "subheading" : "field-text"}>{item.name}</span>
                <Image
                  className={"chevron g4_color_image rotate_90"}
                  src="/action/chevron.png"
                  srcSet="/action/chevron@2x.png 2x, /action/chevron@3x.png 3x" />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>
      <div className={'list_separate custom_separate'} />
      <div className={'scroll_list'}>
        <ListGroup className={'sub_category'} as="ul">
          <ListGroup.Item
            key={"sub_category_0"}
            as="li"
            onClick={() => {
              const subItemIDs = activeItem?.subItems?.map((subItem) => subItem.id);
              if (subListStatus === CHECKBOX_CHECKED) {
                setSelectedSubIDs([]);
              } else {
                setSelectedSubIDs(subItemIDs);
              }
            }}>
            <span className="field-text">{i18n.t("store:All")}</span>
            <CheckBoxImage
              customClassName={"checkbox p2_color_image"}
              status={subListStatus} />
          </ListGroup.Item>
          {activeSubItems?.map((item, index) => {
            return (
              <ListGroup.Item
                key={`sub_category_${item.id}`}
                as="li"
                onClick={() => {
                  if (item.checked) {
                    removeSubItems(item.id);
                  } else {
                    addSubItems(activeItem.id, item.id);
                  }
                }}>
                <span className="field-text">{item.name}</span>
                <CheckBoxImage
                  customClassName={"checkbox p2_color_image"}
                  status={item.checked ? CHECKBOX_CHECKED : CHECKBOX_INIT} />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(MultiChoiceWithSubList);
