import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PickupStore from "component/coupon/PickUpStoreSection";
import { createAction } from "utils";

@connect(({ myCoupon, users, pullrefreshandloadmore }) => ({
  detailData: myCoupon.detailDataMap,
  applicableStoreMap: myCoupon.couponApplicableStoreMap,
  applicableStoreIsLoading: pullrefreshandloadmore.couponApplicableStoreIsLoading,
  language: users.language,
  ...myCoupon,
}))
class CouponSelectStore extends Component {
  constructor(props) {
    super(props);
    this.couponID = props.match.params?.id;
    this.state = {
      applicableStore: [],
    }
  }

  componentDidMount() {
    this.props.dispatch(
      createAction("myCoupon/prepareDetailData")({ couponID: this.couponID })
    );
    this.props.dispatch(
      createAction("myCoupon/getCouponApplicableStores")({ couponID: this.couponID })
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(
        createAction("myCoupon/prepareDetailData")({ couponID: this.couponID })
      );
      this.props.dispatch(
        createAction("myCoupon/getCouponApplicableStores")({ couponID: this.couponID })
      );
    }
    if (this.state.applicableStore?.length < 1
      && this.props.applicableStoreMap?.[this.couponID]?.length > 0
    ) {
      this.setState({ applicableStore: this.props.applicableStoreMap?.[this.couponID] })
    }
  }
  render() {
    const couponDetail = this.props.detailData?.[this.couponID]
    return (
      <Container className="mobile-view-pick-up-store-container">
        <PickupStore
          defaultSelectedFirst={false}
          stores={this.state.applicableStore}
          couponID={this.couponID}
          couponInfo={couponDetail}
          isMobileView={true}
        />
      </Container>
    )
  }


};

export default withRouter(CouponSelectStore);
