import {enLocaleNumberFormatter} from "utils";
import {
  getCampaignLeftDays,
  getCurrentDate,
  convertDateToStamp,
  getCampaignAvailableOnDate,
} from "utils/DateTimeUtils";
import {checkLanguages} from "utils/LanguageCheckUtil";
import {
  CAMPAIGN_TYPE_COUPON,
  CAMPAIGN_TYPE_EARNING,
  CAMPAIGN_TYPE_GENERAL_MESSAGE,
} from "services/CampaignAPIHelper";
import i18n from "I18n";

export const checkIfStarted = (startDate) => {
  if (startDate === null || startDate === undefined || startDate === "") {
    return true;
  }
  const now = getCurrentDate();
  return now >= convertDateToStamp(startDate);
};
export const checkIfExpired = (endDate) => {
  if (endDate === null || endDate === undefined || endDate === "") {
    return false;
  }
  const now = getCurrentDate();
  return now >= convertDateToStamp(endDate);
};

function getTranslation(campaign) {
  let campaignDescription = campaign.name;
  let campaignPhoto = campaign.cover_photo_url;
  let campaignSquarePhoto = campaign.square_cover_photo_url;
  const {translations} = campaign;
  if (translations === null) {
    return {campaignDescription, campaignPhoto, campaignSquarePhoto};
  }
  const currentLanguage = i18n.language;
  for (let index = 0; index < translations.length; index++) {
    const translation = translations[index];
    const checkTranslationLanguage = checkLanguages(translation.language);

    if (currentLanguage === checkTranslationLanguage) {
      campaignDescription = translation.name || campaignDescription;
      campaignPhoto = translation.cover_photo_url || campaignPhoto;
      campaignSquarePhoto = translation.square_cover_photo_url || campaignPhoto;
    }
  }
  return {campaignDescription, campaignPhoto, campaignSquarePhoto};
}

function getBrandImage(brandSection) {
  if (brandSection === null) {
    return "";
  }
  // if (!brandSection.should_show_brand_section) {
  //   return "";
  // }
  return brandSection?.icon_url ?? "";
}

export function getActionButonText(campaignType, couponRequiredPoints, startDate) {
  if (
    campaignType === CAMPAIGN_TYPE_GENERAL_MESSAGE ||
    campaignType === CAMPAIGN_TYPE_EARNING
  ) {
    return null;
  }
  if (campaignType === CAMPAIGN_TYPE_COUPON) {
    if (!checkIfStarted(startDate)) {
      return i18n.t("campaignDetail:availableOn", {
        date: getCampaignAvailableOnDate(startDate),
      });
    }
    if (couponRequiredPoints === 0) {
      return i18n.t("home_get");
    }
    // let formatedPoints = i18n.toNumber(couponRequiredPoints);
    // const formatedPoints = numberFormatter(couponRequiredPoints);
    const originalString = i18n.t("home_redeem_with_points");
    return originalString.replace("4000", enLocaleNumberFormatter(couponRequiredPoints));
  }
  return null;
}

function getCampaignTag(campaign) {
  if (campaign.is_only_for_you) {
    return i18n.t("only_for_you_tag");
  }
  if (campaign.is_featured) {
    return i18n.t("featured_tag");
  }
  return null;
}

export const getBuyCouponData = (campaign) => {
  const data = {
    campaignID: campaign.id,
    enableStoreSelection: false,
    stores: [],
    title: "",
    points: 0,
    limit: 1,
  };
  const couponTemplate = campaign.coupon_campaign_type_coupon_template;
  if (couponTemplate) {
    data.enableStoreSelection = couponTemplate.should_use_in_store;
    data.stores = couponTemplate.applicable_stores;
    data.title = couponTemplate.name;
    data.points = campaign.coupon_campaign_type_required_points;
    data.limit = campaign.coupon_campaign_type_per_head_limit;
    const currentLanguage = i18n.locale;
    if (couponTemplate.translations) {
      couponTemplate.translations.forEach((item, index) => {
        const checkTranslationLanguage = checkLanguages(item.language);
        if (currentLanguage === checkTranslationLanguage) {
          data.title = item.name;
        }
      });
    }
  }
  return data;
};

function getCampaign(campaign) {
  const translation = getTranslation(campaign);
  const {
    campaignDescription,
    campaignPhoto,
    campaignSquarePhoto,
  } = translation;
  const leftDays = getCampaignLeftDays(campaign.end_date);
  const buyCouponData = getBuyCouponData(campaign);

  return {
    campaignId: campaign.id,
    campaignType: campaign.type,
    campaignDescription,
    campaignPhoto,
    campaignSquarePhoto,
    campaignIsOnlyForYou: campaign.is_only_for_you,
    campaignIsFeatured: campaign.is_featured,
    leftDays,
    outOfStock: !!(campaign.coupon_campaign_type_coupon_stock === 0),
    campaignTag: getCampaignTag(campaign),
    brandImage: getBrandImage(campaign.brand),
    requiredPoints: campaign.coupon_campaign_type_required_points,
    campaignActionButtonAvaiable: checkIfStarted(campaign.start_date),
    campaignStartDate: campaign.start_date,
    campaignEndDate: campaign.end_date,
    buyCouponData,
    earnedStamps: campaign.earned_stamps,
    totalStamps: campaign.total_stamps,
  };
}

export const subHeaderLink = (headerLink) => {
  if (headerLink === null || headerLink === undefined) {
    return "";
  }
  const firstindex = headerLink.indexOf("<") + 1;
  const lastIndex = headerLink.indexOf(">");
  const link = headerLink.substring(firstindex, lastIndex);
  return link;
};

export const assembleCampaign = (campaigns) =>
  campaigns.map((campaign) => getCampaign(campaign));

export const errorMessageIfFailedToGetRewards = (response, error) => {
  let errorMessage = i18n.t('campaignDetail:reachLimit');
  const errorDetail =
    error?.response?.data?.detail ||
    error?.data?.detail ||
    response?.data?.detail;
  console.log('errorDetail:', errorDetail);
  if (
    errorDetail === 'Check-in location is not within designated radius.'
  ) {
    errorMessage = i18n.t('campaignDetail:invalidGPSLocation');
  } else if (errorDetail === 'Given qr code not valid.') {
    errorMessage = i18n.t('campaignDetail:invalidQRCode');
  } else if (errorDetail === 'Desired coupons are sold out.') {
    errorMessage = i18n.t('campaignDetail:couponOutOfStock');
  } else if (errorDetail?.includes('Exceed campaign')) {
    errorMessage = i18n.t('campaignDetail:reachLimit');
  } else if (errorDetail?.includes('not available today')) {
    errorMessage = i18n.t('campaignDetail:blackoutLimit');
  }
  return errorMessage;
};

export const campaignListWithUpdatedEarnedStamp = (campaignList, payload) => {
  // payload should have campaignId and earnedCount
  const { campaignID, rewardStampCount } = payload;
  return campaignList.map((campaign) => {
    if (campaign.campaignId === campaignID) {
      return {
        ...campaign,
        earnedStamps:
          campaign.earnedStamps + rewardStampCount,
      };
    }
    return campaign;
  });
};
