import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CreateRewardClaim.scss";
import i18n from "../I18n";
import { createAction } from "../utils";
import zhs from "date-fns/locale/zh-CN"; // the locale you want
import zht from "date-fns/locale/zh-TW";
import TenantPicker, {
  Receipts,
} from "../component/records/CreateRewardClaimItem";
import OfferFloatActionButton from "component/offer/OfferFloatActionButton";
import { ssoAPIHelper } from "../services/SSOAPIHelper";
import { getMyAccountUserBirthdayForUpdate } from "../utils/DateTimeUtils";
registerLocale("zh-Hans", zhs); // register it with the name you want
registerLocale("zh-Hant", zht);

@connect(({ transaction, pullrefreshandloadmore, users }) => ({
  ...transaction,
  ...pullrefreshandloadmore,
  language: users.language,
  users: users,
}))
class CreateRewardClaim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTenant: null,
      receiptNumber: "",
      receiptIssueDate: null,
      receiptAmount: "",
      receiptImage: null,
      creditImage: null,
      clickedTenantInput: false,
    };
  }

  componentDidMount() {
    if (!this.props.users?.isLogin) {
      window.location.href = ssoAPIHelper.getLoginWebUri(
        this.props.i18n?.language || "en"
      );
    }
    this.props.dispatch(createAction("transaction/refreshStoreList")());
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(createAction("transaction/refreshStoreList")());
    }
  }

  SubmitReceipt = () => {
    this.props.dispatch(
      createAction("transaction/createRewardClaim")({
        data: {
          date:
            getMyAccountUserBirthdayForUpdate(this.state.receiptIssueDate) +
            " 00:00:00",
          store: this.state.selectedTenant.id,
          total_value: parseFloat(this.state.receiptAmount),
          invoice_id: this.state.receiptNumber,
          receipt_image: this.state.receiptImage,
          credit_card_slip_image: this.state.creditImage,
        },
        onSuccess: () => {
          this.props.history.push("/transaction");
        },
      })
    );
  };

  render() {
    const disabledSubmit = !(
      this.state.selectedTenant &&
      this.state.receiptNumber &&
      this.state.receiptIssueDate &&
      this.state.receiptAmount &&
      this.state.receiptAmount !== '.' &&
      this.state.receiptImage &&
      this.state.creditImage
    );
    const CustomDatePickerInput = ({ value, onClick }) => (
      <div className="custom-date-picker-div div-with-downarrow">
        <input
          type="text"
          className="input-with-downarrow field-text"
          placeholder={i18n.t("records:please_select")}
          onClick={onClick}
          defaultValue={value}
          readOnly
        />
        <img
          alt="down"
          src="/records/calendar/calendar.png"
          srcSet="/records/calendar/calendar@2x.png 2x, /records/calendar/calendar@3x.png 3x"
          className="down-arrow"
          onClick={onClick}
        />
      </div>
    );

    return (
      <div>
        <Container className="create_transaction_breadcrumbs">
          <Row className="nav_row button-large">
            <Link to="/">{i18n.t('home_home')}</Link>
            {'>'}
            <Link to="/transaction">{i18n.t('records:home_rewards')}</Link>
            {'>'}
            <span className="nav_row_span">{i18n.t('records:record_create_rewards_claim')}</span>
          </Row>
        </Container>
        <Container className="create_transaction_container">
          <div className="create_transaction_content">
            <div className="row">
              <div className="col">
                <div className="content_item tenant">
                  <div className="content_item_title subheading">
                    {i18n.t("records:tenant")}
                  </div>
                  <div className="content_item_value field-text">
                    <TenantPicker
                      storeList={this.props.storeList}
                      selectedTenantChange={(id) =>
                        this.setState({ selectedTenant: id })
                      }
                      setRefNode={(node) => (this.StoreListNode = node)}
                      props={this.props}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="content_item receipt_number">
                  <div className="content_item_title subheading">
                    {i18n.t("records:receipt_number")}
                  </div>
                  <div className="content_item_value field-text">
                    <input
                      type="text"
                      className="common-item-value field-text"
                      placeholder={i18n.t("records:please_input_filed")}
                      value={this.state.receiptNumber}
                      onChange={(e) => {
                        const value = e.target.value
                          ? e.target.value.trimLeft().trimEnd()
                          : "";
                        this.setState({ receiptNumber: value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="content_item receipt_issue_date">
                  <div className="content_item_title subheading">
                    {i18n.t("records:receipt_issue_date")}
                  </div>
                  <div className="content_item_value field-text">
                    <DatePicker
                      wrapperClassName="custom-date-picker"
                      locale={this.props.language}
                      dateFormat="MMMM d, yyyy"
                      maxDate={new Date()}
                      selected={this.state.receiptIssueDate}
                      onChange={(date) => {
                        this.setState({
                          receiptIssueDate: date,
                        });
                      }}
                      customInput={<CustomDatePickerInput />}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="content_item receipt_amount">
                  <div className="content_item_title subheading">
                    {i18n.t("records:receipt_amount")}
                  </div>
                  <div className="content_item_value field-text">
                    <input
                      type="text"
                      className="common-item-value field-text"
                      placeholder={i18n.t("records:please_input_filed")}
                      value={this.state.receiptAmount}
                      onChange={(e) => {
                        const value = e.target.value?.match(/(\d*[.])?\d*/, "")
                          ? e.target.value.match(/(\d*[.])?\d*/, "")[0]
                          : "";
                        e.target.value = value;
                        this.setState({ receiptAmount: value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="content_item receipt_images">
                  <div className="content_item_title subheading">
                    {i18n.t("records:record_upload_receipt")}
                  </div>
                  <div className="content_item_value field-text">
                    <Receipts
                      receiptChange={(r) => this.setState({ receiptImage: r })}
                      creditChange={(c) => this.setState({ creditImage: c })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="content_item receipt_submit">
                  <div
                    className={`content_item btn_submit_receipt ${
                      disabledSubmit ? "disabled" : ""
                    } button-large`}
                    onClick={() => {
                      if (!disabledSubmit) this.SubmitReceipt();
                    }}
                  >
                    {i18n.t("records:submit")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <OfferFloatActionButton />
      </div>
    );
  }
}

export default CreateRewardClaim;
