import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ClaimType } from "models/Records";
import CustomAlert from "component/base/CustomAlert";
import { createAction } from "utils";
import i18n from "I18n";

import "./TransactionContent.scss";


@connect(({ transaction, users }) => ({
  ...transaction,
  language: users.language,
}))
class TransactionContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: null,
      validatedData: null,
      modalShow: false,
      isLoading: false,
      showPreview: false,
      previewImage: null,
    };
  }

  componentDidMount() {
    if (this.props.data && this.props.rewardClaimList) {
      this.updateData();
    } else {
      this.props.dispatch(createAction("transaction/refreshRewardClaimList")());
    }
  }

  componentDidUpdate(preProps) {
    if (
      preProps.transactionId !== this.props.transactionId ||
      !(preProps.data && preProps.rewardClaimList)
    ) {
      this.updateData();
    }
    if (
      preProps.rewardClaimList !== this.props.rewardClaimList ||
      preProps.language !== this.props.language
    ) {
      this.updateData();
    }
  }

  updateData = () => {
    let transactionId = this.props.transactionId;

    if (this.props.isDetailPage) {
      transactionId = Number(this.props.match.params.id);
    }

    (this.props.data || []).forEach((rowData) => {
      if (rowData.id === transactionId) {
        this.setState({
          rowData: rowData,
        });
      }
    });

    this.props.rewardClaimList.forEach((validatedData) => {
      if (validatedData.id === transactionId) {
        this.setState({
          validatedData: validatedData,
        });
      }
    });
  };

  showAlert = (showState) => {
    this.setState({
      modalShow: showState,
    });
  };

  onHidden = () => {
    this.showAlert(false);
  };

  leftBtnAction = () => {
    this.onHidden();
  };
  rightBtnAction = () => {
    this.setState({
      isLoading: true,
    });
    this.props.dispatch(
      createAction("transaction/deleteRewardClaim")({
        transactionId: this.state.validatedData?.id,
        onDone: () => {
          this.onHidden();
          if (this.props.isDetailPage) {
            this.props.history.push("/transaction");
          } else {
            this.props.setEmptyActiveTransactionId();
          }
        },
      })
    );
  };

  item = (title, content) => {
    return (
      <div className="content_item">
        <div className="content_item_title subheading">{title}</div>
        <div className="content_item_value field-text">{content}</div>
      </div>
    );
  };

  handlePreviewImage = (previewImage) => {
    if (!previewImage) return;
    this.setState({
      showPreview: true,
      previewImage: previewImage,
    });
  };

  receiptImage = (customClassName, source, defaultSource, text) => {
    return (
      <div className={`image_container ${customClassName}`}>
        <div className="image">
          <img
            src={source || defaultSource}
            alt=""
            onClick={() => this.handlePreviewImage(source)}
          />
        </div>
        <div className="image_text field-text">{text}</div>
      </div>
    );
  };

  getRewardItem = (value, campaigns, earningRules) => {
    return (
      <>
        <div className="reward_value subheading">{value}</div>
        {campaigns?.length > 0
        ? (
        <div className="reward_campaigns">
          <div className="reward_campaigns_label body-2">
            {i18n.t("records:record_from_campaign")}
          </div>
          <div className="reward_campaigns_names">
            {campaigns.map((campaign, index) => (
              <div
                key={`campaign_name_${index}`}
                className="reward_campaigns_name body-2"
              >
                {campaign}
              </div>
            ))}
          </div>
        </div>
        )
        : <></>
        }
        {earningRules?.length > 0
        ? (
        <div className="reward_campaigns">
          <div className="reward_campaigns_label body-2">
            {i18n.t("records:record_from_earning_rule")}
          </div>
          <div className="reward_campaigns_names">
            {earningRules.map((earningRule, index) => (
              <div
                key={`earning_rule_name_${index}`}
                className="reward_campaigns_name body-2"
              >
                {earningRule}
              </div>
            ))}
          </div>
        </div>
        )
        : <></>
        }
      </>
    );
  };

  getReward = () => {
    const pointsRewards = this.state.validatedData?.pointsRewards;
    const couponRewards = this.state.validatedData?.couponRewards;
    const stampRewards = this.state.validatedData?.stampRewards;
    if (!pointsRewards && !couponRewards) {
      return null;
    }
    return (
      <div className="content_item">
        <div className="content_item_title subheading">
          {i18n.t("records:claim_detail_create_reward")}
        </div>
        <div className="content_item_value field-text">
          {pointsRewards?.count && this.getRewardItem(
            i18n.t("records:record_points", {
              count: pointsRewards?.count,
            }),
            pointsRewards?.campaignList,
            pointsRewards?.earningRuleList,
          )}
          {couponRewards?.count && this.getRewardItem(
            i18n.t("records:record_coupons", {
              count: couponRewards?.count,
            }),
            couponRewards?.campaignList,
            couponRewards?.earningRuleList,
          )}
          {stampRewards?.count && this.getRewardItem(
            i18n.t("records:record_stamps", {
              count: stampRewards?.count,
            }),
            stampRewards?.campaignList,
            stampRewards?.earningRuleList,
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div
        className={
          this.props.isDetailPage
            ? "transaction_detail_content"
            : "transaction_content"
        }
      >
        {this.item(
          i18n.t("records:claim_detail_create_date"),
          this.state.validatedData?.createDate
        )}
        {this.item(
          i18n.t("records:claim_detail_status"),
          `${i18n.t(this.state.validatedData?.status)}(${
            this.state.validatedData?.updateDate || "-"
          })`
        )}
        {this.item(i18n.t("records:tenant"), this.state.validatedData?.tenant)}
        {this.item(
          i18n.t("records:receipt_number"),
          this.state.validatedData?.receiptNumber
        )}
        {this.item(
          i18n.t("records:receipt_issue_date"),
          this.state.validatedData?.receiptDate
        )}
        {this.item(
          i18n.t("records:receipt_amount"),
          this.state.validatedData?.receiptAmount
        )}
        <div className="content_item">
          <div className="content_item_title subheading">
            {i18n.t("records:record_upload_receipt")}
          </div>
          <div className="content_item_value receipt_images field-text">
            {this.receiptImage(
              "receipt_image_container",
              this.state.validatedData?.receiptImage,
              "",
              i18n.t("records:merchants_receipts")
            )}
            {this.receiptImage(
              "credit_image_container",
              this.state.validatedData?.creditImage,
              "",
              i18n.t("records:credit_slip")
            )}
          </div>
        </div>
        {this.getReward()}
        {this.state.validatedData?.status !==
        ClaimType.WAITING_FOR_APPROVAL ? null : (
          <div
            className="content_item btn_delete_receipt button-large"
            onClick={() => this.showAlert(true)}
          >
            {i18n.t("message_delete")}
          </div>
        )}
        <CustomAlert
          show={this.state.modalShow}
          onHide={this.onHidden}
          message={i18n.t("records:confirm_delete_title")}
          leftbtn={i18n.t("message_cancel")}
          leftAction={this.leftBtnAction}
          rightbtn={i18n.t("records:confirm_delete_delete_2")}
          rightAction={this.rightBtnAction}
          isLoading={this.state.isLoading}
        />
        {this.state.showPreview ? (
          <div className="preview_image">
            <img
              alt=""
              className="preview_image_image"
              src={this.state.previewImage}
            />
            <img
              alt="close"
              src="/records/closeBtn/closeBtn.png"
              srcSet="/records/closeBtn/closeBtn@2x.png 2x, /records/closeBtn/closeBtn@3x.png 3x"
              className="close-btn"
              onClick={() => this.setState({ showPreview: false })}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(TransactionContent);
