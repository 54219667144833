import React, { useRef, useState } from "react";
import { Row, Nav, Container } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter, useLocation } from "react-router-dom";
import queryString from 'query-string';

import "./OfferNav.scss";
import CustomNavDropdown from "component/common/CustomNavDropdown";
import { QUERY_STRING_CONFIG } from "utils/UrlUtils";

const SCROLL_DISTANCE = 600;

function OfferNav(props) {
  const { language } = useSelector((state) => ({
    language: state.users.language,
  }));

  let webNavbar = useRef();
  const location = useLocation();
  const parsed = queryString.parse(location.search, QUERY_STRING_CONFIG);
  const currentId = parsed?.id;
  const [currentNavbarPosition, setCurrentNavbarPosition] = useState(0);

  const _getNavDropdownConfig = () => {
    const navDropdownConfig = [
      {
        title: _getNavTagLatestTranslationName(),
        activeKey: 'LATEST',
        pathname: `/offer/LATEST`
      },
      ...(props.tabData?.map((dropDownItemData) => {
        return {
          title: _getTranslationName(dropDownItemData),
          activeKey: _getDropDownItemKey(dropDownItemData),
          pathname: `/offer/${dropDownItemData.name}?id=${dropDownItemData.id}`
        }
      }))
    ]
    return navDropdownConfig
  }

  const _getDropdownCurrentActiveKey = () => {
    const pathName = props.location.pathname;
    const urlPageName = pathName.split("/").slice(2).join("/");
    if (urlPageName === "LATEST") {
      return "LATEST"
    }
    return `${currentId}-${props.location.pathname?.split("/")?.slice(2)}`
  }

  const _getDropDownItemKey = (itemData) => `${itemData.id}-${itemData.name}`

  const _getNavItem = () => {
    if (props.tabData) {
      let navItems = [];
      props.tabData.forEach((navItemData) => {
        let navItem = (
          <LinkContainer
            to={{
              pathname: "/offer/" + navItemData.name,
              search: "id=" + navItemData.id,
            }}
            isActive={(match, location) => {
              return (`/offer/${navItemData.name}` === location.pathname && currentId === navItemData.id)
            }}
          >
            <Nav.Item className="offer_nav_item subheading">
              {_getTranslationName(navItemData)}
            </Nav.Item>
          </LinkContainer >
        );
        navItems.push(navItem);
      });
      return navItems;
    }
  };

  const _getTranslationName = (itemData) => {
    let translateNames = {
      en: itemData.name,
    };
    itemData.translations.forEach((languageItem) => {
      translateNames[languageItem.language] = languageItem.name;
    });

    return translateNames[language] ? translateNames[language] : itemData.name;
  };

  const _getNavTagLatestTranslationName = () => {
    return props.t("offer_latest");
  };

  const _getCurrentPageName = () => {
    let currentPageName = "";
    let pathName = props.location.pathname;
    let urlPageName = pathName.split("/").slice(2).join("/");

    if (urlPageName === "LATEST") {
      currentPageName = _getNavTagLatestTranslationName();
    } else if (props.tabData) {
      props.tabData.forEach((navItemData) => {
        if (navItemData.name === urlPageName) {
          currentPageName = _getTranslationName(navItemData);
        }
      });
    }

    return currentPageName;
  };
  // console.log("@@135: ", webNavbar.current);
  return (
    props.tabData && (
      <>
        <Container className="offer_nav_container d-none d-md-flex">
          <Row className="offer_web_nav_row  nav_bar">
            {currentNavbarPosition !== 0 &&
              <div className="offer_web_nav_left_arrow">
                <img
                  alt=""
                  src={"/profile/right-arrow.png"}
                  srcSet="/profile/right-arrow@2x.png 2x, /profile/right-arrow@3x.png 3x"
                  hidden={currentNavbarPosition === 0}
                  onClick={() => {
                    if (webNavbar) {
                      const scrollTo =
                        currentNavbarPosition - SCROLL_DISTANCE < 0
                          ? 0
                          : currentNavbarPosition - SCROLL_DISTANCE;
                      // webNavbar.current.scrollLeft = scrollTo;
                      webNavbar.current.scrollTo({
                        top: 0,
                        left: scrollTo,
                        behavior: "smooth",
                      });
                      setCurrentNavbarPosition(scrollTo);
                    }
                  }}
                />
              </div>
            }
            <Nav className="offer_nav_bar" ref={webNavbar}>
              <LinkContainer to={{ pathname: "/offer/LATEST" }}>
                <Nav.Item key={0} className="offer_nav_item subheading">
                  {_getNavTagLatestTranslationName()}
                </Nav.Item>
              </LinkContainer>
              {_getNavItem()}
            </Nav>
            {(currentNavbarPosition !==
              webNavbar.current?.scrollWidth - webNavbar.current?.offsetWidth) &&
              <div className="offer_web_nav_right_arrow">
                <img
                  alt=""
                  src={"/profile/right-arrow.png"}
                  srcSet="/profile/right-arrow@2x.png 2x, /profile/right-arrow@3x.png 3x"
                  hidden={
                    currentNavbarPosition ===
                    webNavbar.current?.scrollWidth - webNavbar.current?.offsetWidth
                  }
                  onClick={() => {
                    if (webNavbar) {
                      console.log("@@155: ", webNavbar.current);
                      const scrollTo =
                        currentNavbarPosition +
                          SCROLL_DISTANCE +
                          webNavbar.current.offsetWidth >
                          webNavbar.current.scrollWidth
                          ? webNavbar.current.scrollWidth -
                          webNavbar.current.offsetWidth
                          : currentNavbarPosition + SCROLL_DISTANCE;

                      // webNavbar.current.scrollLeft = scrollTo;
                      webNavbar.current.scrollTo({
                        top: 0,
                        left: scrollTo,
                        behavior: "smooth",
                      });
                      // webNavbar.current.style.transform = `translateX(${-scrollTo}px)`;
                      setCurrentNavbarPosition(scrollTo);
                    }
                  }}
                />
              </div>}
          </Row>
        </Container>
        <CustomNavDropdown
          className="offer-nav-dropown d-md-none"
          activeKey={_getDropdownCurrentActiveKey()}
          navDropdownTitle={_getCurrentPageName()}
          menuList={_getNavDropdownConfig()}
        />
      </>
    )
  );
}

export default withRouter(withTranslation()(OfferNav));
