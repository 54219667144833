import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import queryString from 'query-string';

import { navDropdownContainerHeight, pageTopAppBannerHeight } from 'component/common/CustomNavDropdown';
import LoadMoreSpinner from 'component/common/LoadMoreSpinner';
import NothingPage from 'component/error/Nothing';
import StoreCategoryDropdown from 'component/store/StoreCategoryDropdown';
import StoreFilter from 'component/store/StoreFilter';
import StoreCard from 'component/store/StoreCard';
import { createAction } from 'utils';
import { QUERY_STRING_CONFIG } from 'utils/UrlUtils';
import { STORE_LIST_KEY } from "services/StoreAPIHelper";
import {
  getScrollTop,
  getDocumentHeight,
  getWindowHeight,
  isMobile,
} from 'utils/ScreenUtils';
import i18n from 'I18n';

import './StoreList.scss';

const mapStateToProps = (state) => {
  return {
    language: state.users.language,
    showOpenAppBanner: state.users.showOpenAppBanner,
    storeCategoryList: state.store.storeCategoryList,
    storeSubCategoryList: state.store.storeSubCategoryList,
    storeSelectedCategoryID: state.store.storeSelectedCategoryID,
    storeSelectedSubCategoryIDs: state.store.storeSelectedSubCategoryIDs,
    storeSelectedBrandIDs: state.store.storeSelectedBrandIDs,
    storeSelectedDistrictIDs: state.store.storeSelectedDistrictIDs,
    stores: state.store.stores,
    storesLanguage: state.store.storesLanguage,
    isRefreshing: state.pullrefreshandloadmore[`${STORE_LIST_KEY}IsRefreshing`],
    isLoadingMore: state.pullrefreshandloadmore[`${STORE_LIST_KEY}IsLoadingMore`],
  };
};

function StoreList({
  language,
  showOpenAppBanner,
  storeCategoryList,
  storeSubCategoryList,
  storeSelectedCategoryID,
  storeSelectedSubCategoryIDs,
  storeSelectedBrandIDs,
  storeSelectedDistrictIDs,
  stores,
  storesLanguage,
  isRefreshing,
  isLoadingMore,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownTextButton, setDropdownTextButton] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [showDot, setShowDot] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [storeFilterStyleTop, setStoreFilterStyleTop] = useState(0);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const parsed = queryString.parse(location.search, QUERY_STRING_CONFIG);
    const categoryValue = parsed?.sf_category;
    const subCategoryValue = parsed?.sf_sub_category;
    const brandValue = parsed?.sf_brand;
    const districtValue = parsed?.sf_district;
    const data = {
      storeSelectedCategoryID: null,
      storeSelectedSubCategoryIDs: [],
      storeSelectedBrandIDs: [],
      storeSelectedDistrictIDs: [],
    };
    if (!!categoryValue) {
      data.storeSelectedCategoryID = Number(categoryValue);
    }
    if (subCategoryValue?.length > 0) {
      data.storeSelectedSubCategoryIDs = subCategoryValue.map((item) =>
        Number(item),
      );
    }
    if (brandValue?.length > 0) {
      data.storeSelectedBrandIDs = brandValue.map((item) => Number(item));
    }
    if (districtValue?.length > 0) {
      data.storeSelectedDistrictIDs = districtValue.map((item) => Number(item));
    }
    dispatch(createAction('store/updateFilter')(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    window.addEventListener('scroll', bindHandleWindowScroll);
    return () => {
      // dispatch(createAction('store/clearData')());
      window.removeEventListener('scroll', bindHandleWindowScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scrollToBottom && !(isRefreshing || isLoadingMore)) {
      dispatch(createAction('store/getStoresWithFilterLoadMore')());
    }
    if (isRefreshing || isLoadingMore) {
      setScrollToBottom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToBottom, isRefreshing, isLoadingMore]);

  useEffect(() => {
    if (language !== storesLanguage) {
      dispatch(createAction('store/storeInitData')());
      dispatch(createAction('store/getStoresWithRefresh')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (storeSelectedCategoryID !== null) {
      for (const x of storeCategoryList) {
        if (x.id === storeSelectedCategoryID) {
          setDropdownTextButton(x.name);
          return;
        }
      }
    }
    if (storeSelectedSubCategoryIDs?.length > 0) {
      const subcategoryNames = [];
      for (const x of storeSubCategoryList) {
        for (const subCategory of x.subcategories) {
          if (storeSelectedSubCategoryIDs.includes(subCategory.id)) {
            subcategoryNames.push(subCategory.name);
          }
        }
      }
      setDropdownTextButton(subcategoryNames.join(','));
      return;
    }
    setDropdownTextButton(null);
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    storeSubCategoryList,
    storeSelectedCategoryID,
    storeSelectedSubCategoryIDs,
  ]);

  useEffect(() => {
    if (
      storeSelectedBrandIDs?.length > 0 ||
      storeSelectedDistrictIDs?.length > 0
    ) {
      setShowDot(true);
    } else {
      setShowDot(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeSelectedBrandIDs, storeSelectedDistrictIDs]);

  useEffect(()=> {
    if (showOpenAppBanner || !isMobile()) {
      return;
    }
    if ( storeFilterStyleTop > 0 ) {
      setStoreFilterStyleTop((oldState => oldState - pageTopAppBannerHeight))
    }
  }, [showOpenAppBanner])

  const showCategoriesDropdown = () => {
    setShowDropdown(true);
  };
  const hideCategoriesDropdown = () => {
    setShowDropdown(false);
  };
  const showFilterModal = () => {
    setShowDropdown(false);
    setShowModal(true);
  };
  const hideFilterModal = () => {
    setShowModal(false);
  };
  const bindHandleWindowScroll = () => {
    const scrollTopLength = getScrollTop();
    const windowHeight = getWindowHeight();
    const documentHeight = getDocumentHeight();
    const isLoadArea = scrollTopLength + windowHeight > documentHeight - 100;
    setScrollToBottom(isLoadArea);
    if (isMobile()) {
      setStoreFilterStyleTop(
        parseFloat(document.getElementsByClassName("about-page-nav-dropdown")?.[0]?.style?.top || 0)
      )
    }
  };

  return (
    <>
      <div
        style={storeFilterStyleTop ?
          { top: navDropdownContainerHeight + storeFilterStyleTop } : {}
        }
        className="store_list_filter">
        <Container className="store_list_filter_container">
          <div className="store_filter">
            <div
              className="filter_dropdown_button not_selectable"
              onClick={() => {
                if (showDropdown) {
                  hideCategoriesDropdown();
                } else {
                  showCategoriesDropdown();
                }
              }}
            >
              <span className='button-small'>
                {dropdownTextButton
                  ? dropdownTextButton
                  : i18n.t('store_categories')}
              </span>
              <Image
                className={`chevron g4_color_image ${
                  showDropdown ? 'rotate_0' : 'rotate_180'
                }`}
                src="/action/chevron.png"
                srcSet="/action/chevron@2x.png 2x, /action/chevron@3x.png 3x"
              />
            </div>
            <div className="filter_dropdown_separate custom_separate" />
            <div
              className={`filter_dropdown_button not_selectable ${
                showDot ? 'show_dot' : ''
              }`}
              onClick={() => {
                showFilterModal();
              }}
            >
              <span className='button-small'>{i18n.t('records:record_filter')}</span>
            </div>
          </div>
          <div className="filter_bottom_border custom_separate"></div>
          {showDropdown ? (
            <StoreCategoryDropdown onHide={() => hideCategoriesDropdown()} />
          ) : null}
        </Container>
      </div>
      <Container className="store_list_container">
        {stores?.length > 0 ? (
          <Masonry
            breakpointCols={{ default: 3, 1023: 2, 767: 1 }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {stores.map((itemData, index) => {
              return (
                <StoreCard key={`store_${itemData.id}`} storeData={itemData} />
              );
            })}
          </Masonry>
        ) : (
          !isRefreshing && <NothingPage />
        )}
        {isRefreshing || isLoadingMore && (<LoadMoreSpinner />)}
      </Container>
      {showModal && (<StoreFilter onHide={() => hideFilterModal()} />)}
    </>
  );
}

export default connect(mapStateToProps)(StoreList);
