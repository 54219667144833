import i18n from 'I18n';
import moment from 'moment';

import {
  getRequireOTP,
  verifyOTPAndUpdateLoginMethod,
} from 'services/CustomerAPIHelper.js';
import { ssoAPIHelper } from 'services/SSOAPIHelper';

import { createAction } from 'utils';
import { CUSTOMER_SIGN_UP_METHOD, GenderType } from 'utils/CustomEnums';
import {
  getMyAccountUserBirthdayForDisplay,
  getMyAccountUserBirthdayForUpdate,
} from 'utils/DateTimeUtils';

const allNumber = (data) => {
  const isNum = `${data}`.match(/^[0-9]+$/);
  return isNum;
};

function validateEmail(email) {
  if (!email) {
    return false;
  }
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const getInitialState = () => {
  return {
    verifyChallenge: null,
    verifyError: null,
    alreadyRegisteredContact: false,
    firstName: {
      error: false,
      errorMessage: '',
    },
    lastName: {
      error: false,
      errorMessage: '',
    },
    loginMethodContact: {
      error: false,
      errorMessage: '',
    },
    gender: {
      error: false,
      errorMessage: '',
    },
    birthday: {
      error: false,
      errorMessage: '',
    },
  };
};

export default {
  namespace: 'userInfoTemp',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    invalidContractFormat(state) {
      return {
        ...state,
        loginMethodContact: {
          error: true,
          errorMessage: i18n.t('setting_account_format_error'),
        },
        alreadyRegisteredContact: false,
      };
    },
    duplicateContactError(state) {
      return {
        ...state,
        loginMethodContact: {
          error: true,
          errorMessage: i18n.t('setting_account_contact_duplicate_error'),
        },
        alreadyRegisteredContact: true,
      };
    },
    overSendLimitError(state) {
      return {
        ...state,
        loginMethodContact: {
          error: true,
          errorMessage: i18n.t('account_verify_over_send_limit_error'),
        },
        alreadyRegisteredContact: false,
      };
    },
    failSendOTP(state) {
      return {
        ...state,
        loginMethodContact: {
          error: true,
          errorMessage: i18n.t('account_verify_fail_error'),
        },
        alreadyRegisteredContact: false,
      };
    },
    clearState(state, { payload }) {
      return getInitialState();
    },
  },

  effects: {

    updateSuccessToast: [
      function* ({ payload }, { call, put, select, all }) {
        yield put(
          createAction('navBars/updateState')({
            toastShowing: {
              value: true,
              content: i18n.t('successfullyUpdated'),
            },
          }),
        );
      },
      { type: 'takeLatest' },
    ],

    noNetworkToast: [
      function* ({ payload }, { call, put, select, all }) {
        yield put(
          createAction('navBars/updateState')({
            toastShowing: {
              value: true,
              content: i18n.t('ticket_no_internet_connection'),
            },
          }),
        );
      },
      { type: 'takeLatest' },
    ],

    updateUserInfoOneField: [
      function* ({ payload }, { call, put, select, all }) {
        const { serverKey, modelKey, value, successCallback } = payload;
        if (!serverKey || !modelKey || !value) {
          return;
        }
        const uid = yield select((state) => state.users.userInfo.uid);
        const accessToken = yield select((state) => state.users.accessToken);
        const params = {};
        params[serverKey] = value;
        const response = yield call(ssoAPIHelper.updateUserInfo, {
          params,
          accessToken,
          uid,
        });
        console.log('updateUserInfoOneField', response.status, response.data);
        if (response?.status === 200) {
          yield put(createAction('users/setNewUser')({ user: response.data }));
          yield put(createAction('updateSuccessToast')());
          if (successCallback) {
            successCallback();
          }
        } else if (response?.status === 400) {
          const stateDict = {};
          stateDict[modelKey] = {
            error: true,
            errorMessage: response?.data?.[serverKey]?.[0],
          };
          yield put(createAction('updateState')(stateDict));
        } else {
          yield put(createAction('noNetworkToast')());
        }
      },
      { type: 'takeLatest' },
    ],

    requireOTPForUpdateLoginMethod: [
      function* ({ payload }, { call, put, select, all }) {
        const { email, countryCode, nationalNumber, method, failureCallback } =
          payload;
        const mobile = `+${countryCode}${nationalNumber}`;
        const uid = yield select((state) => state.users.userInfo.uid);
        yield put(createAction('clearState')());
        const data = {
          method,
        };
        if (method === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
          if (!allNumber(nationalNumber)) {
            yield put(createAction('invalidContractFormat')());
            return;
          }
          data.mobile = mobile;
        } else if (method === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS) {
          if (!validateEmail(email)) {
            yield put(createAction('invalidContractFormat')());
            return;
          }
          data.email = email;
        } else {
          console.log('requireOTPForUpdateLoginMethod method', method);
          return;
        }
        console.log('requireOTPForUpdateLoginMethod', data);
        const response = yield call(getRequireOTP, {
          paramDict: data,
          uid,
        });
        console.log(
          'requireOTPForUpdateLoginMethod',
          response.status,
          response.data,
        );
        if (response.status === 201) {
          const verifyChallenge = response.data.result.verify_challenge;
          console.log('requireOTPForUpdateLoginMethod', verifyChallenge);
          yield put(
            createAction('updateState')({
              verifyChallenge: verifyChallenge,
              alreadyRegisteredContact: false,
            }),
          );
        } else {
          const { result } = response.data;
          // "over_send_limit_error", "user_already_exist", "send_verify_code_failed"
          if (result === 'user_already_exist') {
            yield put(createAction('duplicateContactError')());
          } else if (result === 'over_send_limit_error') {
            yield put(createAction('overSendLimitError')());
          } else if (result === 'send_verify_code_failed') {
            yield put(createAction('failSendOTP')());
          } else {
            yield put(
              createAction('updateState')({
                loginMethodContact: {
                  error: true,
                  errorMessage: result,
                },
                alreadyRegisteredContact: false,
              }),
            );
          }
          if (failureCallback) {
            failureCallback(result);
          }
        }
      },
      { type: 'takeLatest' },
    ],

    verifyOTPAndUpdateLoginMethod: [
      function* ({ payload }, { call, put, select, all }) {
        const {
          method,
          email,
          countryCode,
          nationalNumber,
          verifyCode,
          successCallback,
          failureCallback,
        } = payload;
        const mobile = `+${countryCode}${nationalNumber}`;
        const uid = yield select((state) => state.users.userInfo.uid);
        const verifyChallenge = yield select(
          (state) => state.userInfoTemp.verifyChallenge,
        );
        const data = {
          method,
        };
        if (method === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
          data.mobile = mobile;
        } else if (method === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS) {
          data.email = email;
        } else {
          console.log('verifyOTPAndUpdateLoginMethod', method);
          return;
        }
        data.verify_code = verifyCode;
        data.verify_challenge = verifyChallenge;
        console.log('verifyOTPAndUpdateLoginMethod', data);
        const response = yield call(verifyOTPAndUpdateLoginMethod, {
          paramDict: data,
          uid,
        });
        console.log(
          'verifyOTPAndUpdateLoginMethod',
          response.status,
          response.data,
        );
        if (response.status === 200) {
          yield put(createAction('users/fetchUserInfo')());
          yield put(createAction('updateSuccessToast')());
          if (successCallback) {
            successCallback();
          }
        } else {
          const { result } = response.data;
          // "update_login_method_already_registered", "error_challange_code", "error_verify_code"
          if (result === 'update_login_method_already_registered') {
            yield put(createAction('duplicateContactError')());
          } else if (result === 'error_challange_code' || result === 'error_verify_code') {
            if (method === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
              yield put(
                createAction('updateState')({
                  verifyError: i18n.t('setting_account_mobile_otp_error'),
                })
              );
            } else {
              yield put(
                createAction('updateState')({
                  verifyError: i18n.t('setting_account_email_otp_error'),
                })
              );
            }
          } else {
            yield put(
              createAction('updateState')({
                verifyError: result,
              })
            );
          }
          if (failureCallback) {
            failureCallback(result);
          }
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
