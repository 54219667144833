import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import LevelRuleModal from "./LevelRuleModal";
import { isPadOrMobile } from "utils/ScreenUtils";

class LevelRuleSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    }
    this.isMobile = isPadOrMobile();
  }

  handleClick = () => {
    if (this.isMobile) {
      this.props.history.push("/level_rule")
    } else {
      this.setState({ showModal: true })
    }
  }
  render() {
    return (
      <>
        <div className="home-level-rule-section d-flex" onClick={this.handleClick}>
          <div className="common-profile-section-text field-text">
            <img
              alt="icon"
              src={"/profile/levelRule/level_rule.png"}
              srcSet="/profile/levelRule/level_rule@2x.png 2x, /profile/levelRule/level_rule@3x.png 3x"
            />
            {this.props.t("levelRules")}
          </div>
          <div className="arrow-icon">
            <img alt="icon" src={"/profile/right-arrow.png"} />
          </div>
        </div>
        <LevelRuleModal
          showLevelRule={this.state.showModal}
          handleOnHide={() => {
            this.setState({ showModal: false })
          }}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(LevelRuleSection));
