import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import i18n from 'I18n';

import { COUPON_CONFIRM_BUTTON_STATUS } from 'models/BuyCoupon';

import { ACTION_BUTTON_STATUS } from './CheckInSection';
import CustomAlert from '../base/CustomAlert';

import { createAction, enLocaleNumberFormatter } from 'utils';

import Addition from 'assets/images/addition.svg';
import Subtraction from 'assets/images/subtraction.svg';

import './PointRequiredSection.scss';
import { checkIfExpired } from 'utils/CampaignUtil';

@connect(({ campaignDetail, buyCoupon, users }) => ({
  ...campaignDetail,
  ...buyCoupon,
  availablePoints: users.availablePoints,
  errorMessage: buyCoupon.errorMessage,
}))
class PointRequiredSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponNum: 1,
      totalPoint: this.props.coupon?.requiredPoints * 1 || 0,
      modalShow: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      !prevProps.coupon?.requiredPoints &&
      this.props.coupon?.requiredPoints
    ) {
      this.setState({ totalPoint: this.props.coupon?.requiredPoints * 1 });
    }
  }

  addCouponNum = (currentNum) => {
    if (
      this.state.couponNum < this.props.coupon?.perLimit ||
      this.props.coupon?.perLimit === null
    ) {
      this.setState({
        couponNum: currentNum + 1,
        totalPoint: this.props.coupon?.requiredPoints * (currentNum + 1),
      });
    }
  };

  reduceCouponNum = (currentNum) => {
    if (this.state.couponNum > 1) {
      this.setState({
        couponNum: currentNum - 1,
        totalPoint: this.props.coupon?.requiredPoints * (currentNum - 1),
      });
    }
  };

  balance = () => {
    return this.props.availablePoints - this.state.totalPoint;
  };

  showAlert = (showState) => {
    this.setState({
      modalShow: showState,
    });
  };

  modalMessage = () => {
    switch (this.props.confirmButtonStatus) {
      case COUPON_CONFIRM_BUTTON_STATUS.CONFIRM:
        return i18n.t('points_redeem_coupons', {
          points: enLocaleNumberFormatter(this.state.totalPoint),
          couponCount: this.state.couponNum,
        });
      case COUPON_CONFIRM_BUTTON_STATUS.SUCCESS:
        return i18n.t('successfull_redeemed');
      case COUPON_CONFIRM_BUTTON_STATUS.CALL_API_ERROR: //api failed
        return i18n.t('api_failed');
      case COUPON_CONFIRM_BUTTON_STATUS.FAIL:
        return i18n.t('failedAcquireCouponTitle');
      default:
        break;
    }
  };

  buyButtonDisable = () => {
    const campaignID = this.props.campaignID
    if (
      this.props[campaignID] &&
      !this.props[campaignID].data.basic.isActive
    ) {
      return true;
    }
    if (this.props[campaignID]?.data.basic.outOfStock) {
      return true;
    }
    if (this.balance() < 0) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    let text = i18n.t('campaignDetail:redeem');
    const status = this.props[this.props.campaignID]?.actionBar.status;
    if (status === ACTION_BUTTON_STATUS.GET) {
      text = i18n.t('campaignDetail:get');
    }
    const onHidden = () => {
      this.showAlert(false);
      // this.props.dispatch(createAction("buyCoupon/resetCouponModal")());
    };
    const leftBtn = () => {
      switch (this.props.confirmButtonStatus) {
        case COUPON_CONFIRM_BUTTON_STATUS.CONFIRM:
          return i18n.t('message_cancel');
        case COUPON_CONFIRM_BUTTON_STATUS.LOADING:
        case COUPON_CONFIRM_BUTTON_STATUS.CALL_API_ERROR:
        case COUPON_CONFIRM_BUTTON_STATUS.FAIL:
          return '';
        case COUPON_CONFIRM_BUTTON_STATUS.SUCCESS:
          return i18n.t('campaign_ok');
        default:
          break;
      }
    };
    const leftBtnAction = () => {
      switch (this.props.confirmButtonStatus) {
        case COUPON_CONFIRM_BUTTON_STATUS.CONFIRM:
          this.showAlert(false);
          break;
        case COUPON_CONFIRM_BUTTON_STATUS.SUCCESS:
          this.props.dispatch(createAction('users/fetchAvailablePoints')());
          if (this.props?.onClickSuccessBtn) {
            this.showAlert(false);
            this.props.onClickSuccessBtn();
          } else if (this.props.from === 'campaign') {
            this.showAlert(false);
          } else if (this.props.location.state?.fromPath) {
            this.props.history.push(this.props.location.state.fromPath);
          } else if (this.props.goBackOnSuccess) {
            this.props.history.goBack();
          } else {
            let pathname = '/campaign/' + this.props.campaignID;
            this.props.history.push(pathname);
          }
          break;
        default:
          this.showAlert(false);
          break;
      }
    };
    const rightBtn = () => {
      switch (this.props.confirmButtonStatus) {
        case COUPON_CONFIRM_BUTTON_STATUS.CONFIRM:
          return i18n.t('campaign_confirm');
        case COUPON_CONFIRM_BUTTON_STATUS.LOADING:
          return '';
        case COUPON_CONFIRM_BUTTON_STATUS.SUCCESS:
          return i18n.t('profile_my_coupons');
        case COUPON_CONFIRM_BUTTON_STATUS.CALL_API_ERROR:
        case COUPON_CONFIRM_BUTTON_STATUS.FAIL:
          return i18n.t('campaign_ok');
        default:
          break;
      }
    };
    const rightBtnAction = () => {
      let selectedStore;
      if (this.props.hasStore) {
        selectedStore = this.props.selectedStore.id;
      } else {
        selectedStore = null;
      }
      switch (this.props.confirmButtonStatus) {
        case COUPON_CONFIRM_BUTTON_STATUS.CONFIRM:
          this.props.dispatch(
            createAction('buyCoupon/buyCoupon')({
              campaignID: this.props.campaignID,
              desiredStore: selectedStore,
              desiredQuantity: this.state.couponNum,
            }),
          );
          break;
        case COUPON_CONFIRM_BUTTON_STATUS.SUCCESS:
          this.props.dispatch(createAction('users/fetchAvailablePoints')());
          this.props.history.push('/user/myCoupon');
          break;
        case COUPON_CONFIRM_BUTTON_STATUS.CALL_API_ERROR:
        case COUPON_CONFIRM_BUTTON_STATUS.FAIL:
          onHidden();
          break;
        default:
          break;
      }
    };

    const disableAddButton = () => {
      if (!this.props.coupon?.leftStock) {
        return true;
      }
      if (this.props.coupon?.perLimit && this.state.couponNum >= this.props.coupon?.perLimit) {
        return true;
      }
      if (this.state.couponNum >= this.props.coupon?.leftStock) {
        return true;
      }
      return false
    }
    return (
      <Container className="point_required_section">
        <Row className="reuired_points_section">
          <label className="block_label subheading">{i18n.t('point_required')}</label>
          <label className="required_points title-medium-2">
            {enLocaleNumberFormatter(this.props.coupon?.requiredPoints)}{' '}
            {i18n.t('points')}
          </label>
        </Row>
        <Row>
          <label className="block_label subheading">{i18n.t('campaign_quantity')}</label>
        </Row>
        <Row className="quantity_row">
          <button
            className="reduce_btn"
            onClick={() => this.reduceCouponNum(this.state.couponNum)}
            disabled={this.state.couponNum <= 1}
          >
            <img
              alt="minus"
              src={Subtraction}
              className="icon"
              // srcSet="/campaignDetail/calculateBtn/invalid-name@2x.png 2x, /campaignDetail/calculateBtn/invalid-name@3x.png 3x"
            ></img>
          </button>
          <label className="numbers title-large">{this.state.couponNum}</label>
          <button
            className="multiply_btn justify-content-end"
            onClick={() => this.addCouponNum(this.state.couponNum)}
            disabled={disableAddButton()}
          >
            <img
              alt="multiply"
              src={Addition}
              className="icon"
              // srcSet="/campaignDetail/calculateBtn/delete-copy@2x.png 2x, /campaignDetail/calculateBtn/delete-copy@3x.png 3x"
            />
          </button>
        </Row>
        <Row>
          <label className="block_label subheading">{i18n.t('Total')}</label>
          <label className="required_points title-medium-2">
            {enLocaleNumberFormatter(this.state.totalPoint)} {i18n.t('points')}
          </label>
        </Row>
        <Row className="calculate_section">
          <Row className="point_available_section">
            <label className="calculate_label first_label subheading">
              {i18n.t('point_avaliable')}
            </label>
            <label className="calculate_points total_points field-text">
              {enLocaleNumberFormatter(this.props.availablePoints)}{' '}
              {i18n.t('points')}
            </label>
          </Row>
          <Row className="required_points_section">
            <label className="calculate_label subheading">
              {i18n.t('campaign_required_points')}
            </label>
            <label className="calculate_points field-text">
              -{enLocaleNumberFormatter(this.state.totalPoint)}{' '}
              {i18n.t('points')}
            </label>
          </Row>
          <Row className="balance_section">
            <label
              className={`calculate_label result_points subheading ${
                this.balance() < 0 ? 'red_label' : ''
              }`}
            >
              {i18n.t('campaign_balance')}
            </label>
            <label
              className={`result_points field-text ${this.balance() < 0 ? 'red_label' : ''}`}
            >
              {enLocaleNumberFormatter(this.balance())} {i18n.t('points')}
            </label>
          </Row>
        </Row>
        <Row className="buy_section">
          <button
            className="buy_btn"
            disabled={this.buyButtonDisable()}
            onClick={() => {
              if (this.props.inBlackoutPeriod) {
                this.props.dispatch(
                  createAction('navBars/updateState')({
                    toastShowing: {
                      value: true,
                      content: i18n.t('campaignDetail:blackoutLimit'),
                    },
                  }),
                );
                return;
              }
              if (checkIfExpired(this.props[this.props.campaignID].apiResult.end_date)) {
                this.props.dispatch(createAction("navBars/updateState")(
                  {
                    toastShowing: {
                      value: true,
                      content: i18n.t('campaignDetail:expireToast'),
                    },
                  }
                ))
                return;
              }
              this.props.dispatch(createAction('buyCoupon/resetCouponModal')());
              this.showAlert(true);
            }}
          >
            <label className="buy_label button-large">{text}</label>
          </button>
        </Row>

        <CustomAlert
          show={this.state.modalShow}
          onHide={onHidden}
          title={this.modalMessage()}
          message={
            this.props.confirmButtonStatus === COUPON_CONFIRM_BUTTON_STATUS.FAIL
              ? this.props.errorMessage || ''
              : ''
          }
          leftbtn={leftBtn()}
          leftAction={leftBtnAction}
          rightbtn={rightBtn()}
          rightAction={rightBtnAction}
          isLoading={
            this.props.confirmButtonStatus ===
            COUPON_CONFIRM_BUTTON_STATUS.LOADING
              ? true
              : false
          }
        ></CustomAlert>
      </Container>
    );
  }
}

export default withRouter(PointRequiredSection);
