import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      // if (
      //   this._isCampaignDetail() ||
      //   this._isCouponDetail() ||
      //   this._isTransactionPage()
      // ) {
      //   window.scrollTo(0, 0);
      // }
    }
  }

  _isCampaignDetail = () => {
    if (this.props.location.pathname.indexOf("/campaign/") !== -1) {
      return true;
    } else {
      return false;
    }
  };

  _isCouponDetail = () => {
    if (this.props.location.pathname.indexOf("/coupon") !== -1) {
      return true;
    } else {
      return false;
    }
  };

  _isTransactionPage = () => {
    if (this.props.location.pathname.indexOf("/transaction") !== -1) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
