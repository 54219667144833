import React, { useEffect, useRef } from 'react';
import { Image } from "react-bootstrap";
import PropTypes from 'prop-types';
import Lottie from "lottie-react";

const CommonAnimatedIcon = ({ className, source, useLottie, autoPlay }) => {
  const lottieView = useRef(null);

  useEffect(() => {
    if (autoPlay && lottieView && lottieView.current) {
      lottieView.current.stop()
      lottieView.current.play()
    }
  }, [autoPlay]);

  if (useLottie) {
    const extraPros = { loop: false }
    if (!autoPlay) {
      extraPros.progress = 1
    }
    return (
      <Lottie
        lottieRef={lottieView}
        {...extraPros}
        className={className}
        animationData={source}
      />
    )
  }

  return (
    <Image
      className={className}
      src={source}
    />
  );
};


CommonAnimatedIcon.propType = {
  className: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  useLottie: PropTypes.bool,
  autoPlay: PropTypes.bool
};

export default CommonAnimatedIcon;
