import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import LoadMoreSpinner from 'component/common/LoadMoreSpinner';
import CustomModal from 'component/base/CustomModal';

import { createAction, enLocaleNumberFormatter } from 'utils';

import './ActivityLogSection.scss';
import { isPadOrMobile } from 'utils/ScreenUtils';

@connect(({ users, activityLogs, pullrefreshandloadmore }) => ({
  availablePoints: users.availablePoints,
  activityLogOriginList: activityLogs.activityLogOriginList,
  activityLogPageLink: activityLogs.activityLogPageLink,
  isLoadingLogs: activityLogs.isLoading,
  ...pullrefreshandloadmore,
  language: users.language,
}))
class ActivityLogSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.isMobile = isPadOrMobile();
  }

  componentDidMount() {
    this.props.dispatch(createAction('activityLogs/fetchUserActivityLogs')());
  }

  componentWillUnmount() {
    this.props.dispatch(createAction('activityLogs/clearLogs')());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.props.dispatch(createAction('activityLogs/fetchUserActivityLogs')());
    }
  }

  loadMore = () => {
    if (this.props.activityLogPageLink) {
      this.props.dispatch(
        createAction('pullrefreshandloadmore/updateState')({
          ActivityLogLoadingMore: true,
        }),
      );
      this.props.dispatch(
        createAction('activityLogs/uerActivityLogsLoadMore')(),
      );
    }
  };

  parseActicityLog = () => {
    let parsedActicityLogList = [];
    if (
      this.props.activityLogOriginList &&
      this.props.activityLogOriginList.length > 0
    ) {
      parsedActicityLogList = this.props.activityLogOriginList.map((item) => {
        let content = [];
        if (!!item.points) {
          content.push(
            item?.points > 0
              ? `+${enLocaleNumberFormatter(item.points)} pts`
              : `${enLocaleNumberFormatter(item.points)} pts`,
          );
        }
        if (!!item.tpe) {
          content.push(
            item?.tpe > 0
              ? `+${enLocaleNumberFormatter(item.tpe)} pp`
              : `${enLocaleNumberFormatter(item.tpe)} pp`,
          );
        }
        if (item?.coupon) {
          content.push(`+${item.coupon} ${this.props.t('home_coupons')}`);
        }
        if (item.stamps != null) {
          content.push(
            item?.stamps > 0
              ? `+${item?.stamps} stamps`
              : `${item?.stamps} stamps`,
          );
        }
        if (item?.level) {
          content.push(item?.level)
        }
        return {
          name: this.props.t(item.title),
          date: item.activityTime,
          content: content,
          icon: item.icon,
          srcSet: item?.srcSet,
          subTitle: item.subTitle,
        };
      });
    }
    return parsedActicityLogList;
  };

  renderTableData = ({
    title,
    dataList,
    isLoading = false,
    isLoadingMore = false,
  }) => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td>
              <LoadMoreSpinner />
            </td>
          </tr>
        </tbody>
      );
    }
    if (dataList && dataList.length > 0) {
      return (
        <tbody>
          {dataList.map((item, index) => {
            return (
              <tr key={index + item}>
                <th className="item_name">
                  <div className="type-icon">
                    {item?.icon && (
                      <img
                        alt="type-icon"
                        src={item?.icon}
                        srcSet={item?.srcSet}
                      />
                    )}
                  </div>
                  <div>
                    <div className="body-3">{item.name}</div>
                    {item?.subTitle && (
                      <div className={`item-sub-title ${this.isMobile ? "tag-menu-1" : "body-2"}`}>{item?.subTitle}</div>
                    )}
                    {this.isMobile && (
                      <div className="item-sub-title tag-menu-1">{item?.date}</div>
                    )}
                  </div>
                </th>
                {!this.isMobile && <th className="item_date body-1">{item.date}</th>}
                <th className="item-content field-text">
                  {item.content?.map((content, index) => {
                    return <div key={index}>{content}</div>;
                  })}
                </th>
              </tr>
            );
          })}
          {isLoadingMore && (
            <tr>
              <td>
                <LoadMoreSpinner />
              </td>
            </tr>
          )}
        </tbody>
      );
    }
    return (
      <tbody>
        <tr className="no-record-tr">
          <td>
            <p className="no_record_message subheading">
              {this.props.t('no_record_click')}{' '}
              <Link to="/offer/LATEST">{this.props.t('here')}</Link>{' '}
              {this.props.t('to_earn_points')}
            </p>
          </td>
        </tr>
      </tbody>
    );
  };

  tableView = ({
    title,
    itemList,
    isSimple = false,
    isLoading = false,
    isLoadingMore = false,
  }) => {
    const displayItemList =
      isSimple && itemList?.length > 5 ? itemList.slice(0, 5) : itemList;
    return (
      <div className="reward_status_table_view activity-log-table-view">
        <div className="reward_status_table_head">
          <div className="reward_status_table_head_item subheading">{title}</div>
          {!this.isMobile && (
            <div className="reward_status_table_head_item subheading">
              {this.props.t('ticket_date')}
            </div>
          )}
          <div className="reward_status_table_head_item subheading">
            {this.props.t('earn_burn')}
          </div>
        </div>
        <div
          className={`reward-status-table-body activity-log-table-content ${
            isSimple ? '' : 'scroll-table'
          }`}
          onScroll={(event) => {
            if (
              event.nativeEvent.target.scrollTop +
                event.nativeEvent.target.clientHeight >
                event.nativeEvent.target.scrollHeight * 0.9 &&
              !isLoadingMore
            ) {
              this.loadMore();
            }
          }}
        >
          <Table className="reward-status-table activity-log-table" borderless>
            {this.renderTableData({
              title,
              dataList: displayItemList,
              isLoading,
              isLoadingMore,
            })}
          </Table>
        </div>
      </div>
    );
  };

  handleClick = () => {
    if (this.isMobile) {
      this.props.history.push('/activity_log');
    } else {
      this.setState({ showModal: true });
    }
  };

  render() {
    const tabs = [
      {
        name: '',
        content: (
          <Row>
            <Col>
              {this.tableView({
                title: this.props.t('activity'),
                itemList: this.parseActicityLog(),
                isLoading: this.props.isLoadingLogs,
                isLoadingMore: this.props.ActivityLogLoadingMore,
              })}
            </Col>
          </Row>
        ),
      },
    ];

    return (
      <Container className={`reward-status-section ${this.isMobile ? 'mobile-view' : ''}`}>
        <div className="section-header headline">
          <div>{this.props.t('profile_activity_log')}</div>
          <div className="view-all button-small" onClick={this.handleClick}>
            {this.props.t('viewAll')}
          </div>
        </div>
        <Row>
          <Col>
            {this.tableView({
              title: this.props.t('activity'),
              itemList: this.parseActicityLog(),
              isSimple: true,
              isLoading: this.props.isLoadingLogs,
            })}
          </Col>
        </Row>
        {!this.isMobile && (
          <CustomModal
            title={this.props.t('profile_activity_log')}
            tabs={tabs}
            show={this.state.showModal}
            onClose={() => this.setState({ showModal: false })}
            hideTab={true}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(withTranslation()(ActivityLogSection));
