import React from 'react';

import { DiscountTagRow } from './DiscountTags';

import { createAction, enLocaleNumberFormatter } from 'utils';

import './ProductItem.scss';
import i18n from 'I18n';

const ProductItem = ({ extraStyle, data }) => {
  const showOriginPrice = Number(data.originalPrice) !== Number(data.value);
  const isRefunded = data.status === 'REFUNDED';

  return (
    <div className={`productContainer ${extraStyle}`}>
      <div className={'product'}>
        <div className={'productText body-1'}>{data.name}</div>
        <div className={'productText body-1'}>{`x${data.quantity}`}</div>
      </div>
      {data?.discounts?.length > 0 && (
        <DiscountTagRow extraStyle={'discount'} discounts={data?.discounts} />
      )}
      <div className={'price'}>
        {showOriginPrice && (
          <div className={'priceText priceDelete body-1'}>
            {`HK$ ${enLocaleNumberFormatter(data.originalPrice)}`}
          </div>
        )}
        {isRefunded ? (
          <div className={'priceText refundColor body-2'}>
            {`HK$ ${enLocaleNumberFormatter(Math.abs(data.value))} (${i18n.t("records:transaction_refunded")})`}
          </div>
        ) : (
          <div className={'priceText body-2'}>
            {`HK$ ${enLocaleNumberFormatter(Math.abs(data.value))}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductItem;
