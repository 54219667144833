import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Markdown from 'react-markdown';

import i18n from '../../I18n';
import { isPadOrMobile } from 'utils/ScreenUtils';

class DetailDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionFold: this.props.description?.content?.length > 200,
    };
  }

  render() {
    const isPadOrMobileView = isPadOrMobile();
    return (
      <Container className="more_or_detail_container">
        <div className={`details ${isPadOrMobileView ? "title-small" : "headline"}`}>
          <Markdown>{this.props.description.title}</Markdown>
        </div>
        <div>
          <p
            className={
              this.props.renderMask & this.state.descriptionFold
                ? 'detail_description detail_description_masked'
                : 'detail_description'
            }
          >
            <Markdown>{this.props.description.content}</Markdown>
          </p>
        </div>

        <div
          className={
            this.props.renderMask & this.state.descriptionFold ? 'mask' : ''
          }
        />
        {this.props.renderMask & this.state.descriptionFold ? (
          <>
            <div class="mask" />
            <span
              className={`read-more ${isPadOrMobileView ? "button-small" : "button-large"}`}
              onClick={() => {
                this.setState({ descriptionFold: false });
              }}
            >
              {i18n.t('readMore')}
            </span>
          </>
        ) : null}
      </Container>
    );
  }
}

export default DetailDescription;
