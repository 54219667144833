import { call, put } from 'redux-saga/effects';
import { createAction, delay } from 'utils';
import { subHeaderLink } from 'utils/CampaignUtil';

export function* getDataWithDelay(
  apiService,
  params = null,
  key = '',
  isLoadMore = true,
  delayMillins = 1000,
) {
  let updateKey = `${key}IsLoadingMore`;
  if (!isLoadMore) {
    updateKey = `${key}IsRefreshing`;
  }
  yield put(createAction('pullrefreshandloadmore/updateState')({ [updateKey]: true }))
  const startTime = Date.now();
  const data = yield call(apiService, params);
  const endTime = Date.now();
  if (endTime - startTime < delayMillins) {
    yield call(delay, delayMillins - endTime + startTime);
  }
  yield put(createAction('pullrefreshandloadmore/updateState')({ [updateKey]: false }));
  return data;
}

export function* hasMoreData(key, pageLink) {
  yield put(createAction('pullrefreshandloadmore/updateState')({ [`${key}HasMoreData`]: pageLink.length !== 0 }));
}

export function* refresh(
  pageName, hasOldData, apiServiceWithArgs,
  onSuccess, onFailed,
) {
  yield put(createAction('pullrefreshandloadmore/startRefresh')({ pageName }));

  const startTime = Date.now();
  const apiResult = yield call(...apiServiceWithArgs);
  const endTime = Date.now();
  if (endTime - startTime < 1500) {
    yield call(delay, 1500 - endTime + startTime);
  }
  if (apiResult && apiResult.status < 300) {
    yield put(createAction('pullrefreshandloadmore/updateState')({ [`${pageName}IsRefreshing`]: false }));
    const { headers } = apiResult;
    const nextLink = subHeaderLink(headers.link);
    if (nextLink) {
      yield put(createAction('pullrefreshandloadmore/updateState')({ [`${pageName}HasMoreData`]: true }));
    } else {
      yield put(createAction('pullrefreshandloadmore/updateState')({ [`${pageName}HasMoreData`]: false }));
    }
    yield onSuccess(apiResult, nextLink);
    return;
  }
  if (!hasOldData) {
    yield put(createAction('pullrefreshandloadmore/refreshFailed')({ pageName }));
  }
  if (onFailed) {
    yield onFailed(apiResult);
  }
}
