export function getScreenWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
    window.innerWidth,
  );
}

export function getScrollTop() {
  return Math.max(document.body.scrollTop, document.documentElement.scrollTop);
}

export function getDocumentHeight() {
  return Math.max(document.body.scrollHeight,document.documentElement.scrollHeight);
}

export function getWindowHeight(){
  return (document.compatMode === "CSS1Compat") ? document.documentElement.clientHeight : document.body.clientHeight;
}

export function isMobile() {
  const screenWidth = getScreenWidth();
  return screenWidth < 769;
}

export function isPadOrMobile() {
  const screenWidth = getScreenWidth();
  return screenWidth <= 1023;
}
