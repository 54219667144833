import React, { Component } from 'react';
import { Col, Container, Image, Row, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import LoadMoreSpinner from 'component/common/LoadMoreSpinner';
import CustomModal from 'component/base/CustomModal';
import TransactionDetailContent from 'component/records/TransactionDetailContent';

import { createAction, enLocaleNumberFormatter } from 'utils';

import LeftArrow from 'assets/back-btn.svg';
import RightArrow from 'assets/chevron-right.svg';

import './TransactionHistorySection.scss';
import { isPadOrMobile } from 'utils/ScreenUtils';

@connect(({ users, transaction, pullrefreshandloadmore }) => ({
  language: users.language,

  transactionOriginList: transaction.transactionOriginList,
  transactionNetWorkError: transaction.transactionNetWorkError,
  transactionNextLink: transaction.transactionNextLink,
  transactionLoading: transaction.transactionLoading,

  ...pullrefreshandloadmore,
}))
class TransactionHistorySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      transactionID: null,
    };
    this.isMobile = isPadOrMobile();
  }

  componentDidMount() {
    this.props.dispatch(
      createAction('transaction/clearTransactionList')()
    );
    this.props.dispatch(
      createAction('transaction/refreshTransactionHistory')(),
    );
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.props.dispatch(
        createAction('transaction/refreshTransactionHistory')(),
      );
    }
  }

  transactionClicked = (transactionID) => {
    if (!transactionID) {
      return;
    }
    if (!this.isMobile) {
      this.setState({
        showModal: true,
        transactionID,
      });
    } else {
      this.props.history.push(`/transaction_history_detail/${transactionID}`);
    }
  };

  loadMore = () => {
    if (this.props.transactionNextLink) {
      this.props.dispatch(
        createAction('transaction/loadMoreTransactionHistory')(),
      );
    }
  };

  parseTransaction = () => {
    if (
      this.props.transactionOriginList &&
      this.props.transactionOriginList.length > 0
    ) {
      const parsedList = this.props.transactionOriginList.map((item) => {
        return {
          id: item.id,
          name: item.receiptNumber,
          date: item.receiptDateTime,
          content: item.receiptAmount,
        };
      });
      return parsedList;
    }
    return [];
  };

  renderTableData = ({
    dataList,
    isLoading = false,
    isLoadingMore = false,
  }) => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td>
              <LoadMoreSpinner />
            </td>
          </tr>
        </tbody>
      );
    }
    if (dataList && dataList.length > 0) {
      return (
        <tbody>
          {dataList.map((item, index) => {
            return (
              <tr key={index + item}>
                <th className="item_name">
                  <div
                    className="detail_right_arrow"
                    onClick={() => this.transactionClicked(item.id)}
                  >
                    <div className="item-title body-3">{item.name}</div>
                    <Image
                      className={'right-arrow'}
                      alt="select"
                      src={RightArrow}
                    />
                  </div>
                  {this.isMobile && (
                    <div className="item-sub-title tag-menu-1">
                      {`${this.props.t('records:transaction_ordered')} ${item?.date}`}
                    </div>
                  )}
                </th>
                {!this.isMobile && <th className="item_date body-1">{item.date}</th>}
                <th className="item-content field-text">
                  <div key={index}>{item?.content}</div>
                </th>
              </tr>
            );
          })}
          {isLoadingMore && (
            <tr>
              <td>
                <LoadMoreSpinner />
              </td>
            </tr>
          )}
        </tbody>
      );
    }
    return (
      <tbody>
        <tr className="no-record-tr">
          <td>
            <p className="no_record_message subheading">
              {this.props.t('no_record')}
            </p>
          </td>
        </tr>
      </tbody>
    );
  };

  tableView = ({
    itemList,
    isSimple = false,
    isLoading = false,
    isLoadingMore = false,
  }) => {
    const displayItemList =
      isSimple && itemList?.length > 5 ? itemList.slice(0, 5) : itemList;
    return (
      <div className="reward_status_table_view transaction-history-table-view">
        <div className="reward_status_table_head">
          {!this.isMobile ? (
            <>
              <div className="reward_status_table_head_item invoice_id subheading">
                {this.props.t('records:transaction_invoice_id')}
              </div>
              <div className="reward_status_table_head_item ordered subheading">
                {this.props.t('records:transaction_ordered')}
              </div>
            </>
          ) : (
            <div className="reward_status_table_head_item long_invoice_id subheading">
              {this.props.t('records:transaction_invoice_id')}
            </div>
          )}
          <div className="reward_status_table_head_item total subheading">
            {this.props.t('records:transaction_total')}
          </div>
        </div>
        <div
          className={`reward-status-table-body transaction-history-table-content ${
            isSimple ? '' : 'scroll-table'
          }`}
          onScroll={(event) => {
            if (
              event.nativeEvent.target.scrollTop +
                event.nativeEvent.target.clientHeight >
                event.nativeEvent.target.scrollHeight * 0.9 &&
              !isLoadingMore
            ) {
              this.loadMore();
            }
          }}
        >
          <Table className="reward-status-table activity-log-table" borderless>
            {this.renderTableData({
              dataList: displayItemList,
              isLoading,
              isLoadingMore,
            })}
          </Table>
        </div>
      </div>
    );
  };

  handleClick = () => {
    if (this.isMobile) {
      this.props.history.push('/transaction_history');
    } else {
      this.setState({ showModal: true });
    }
  };

  render() {
    const tabs = [
      {
        name: '',
        content: (
          <Row>
            <Col>
              {this.tableView({
                itemList: this.parseTransaction(),
                isLoading: this.props.transactionLoading,
                isLoadingMore: this.props.TransactionHistoryLoadingMore,
              })}
            </Col>
          </Row>
        ),
      },
    ];

    const detailTabs = [
      {
        name: '',
        content: (
          <Row>
            <Col className={'transaction_detail_scroll_view'}>
              {!!this.state.transactionID && (
                <TransactionDetailContent
                  transactionID={this.state.transactionID}
                />
              )}
            </Col>
          </Row>
        ),
      },
    ];
    const detailTitle = (
      <div
        className={'transaction_detail_title'}
        onClick={() => {
          this.setState({
            transactionID: null,
          });
        }}
      >
        <div className="title_back_icon">
          <Image src={LeftArrow} />
        </div>
        <div className={'title title-medium-1'}>
          {this.props.t('records:transaction_detail_title')}
        </div>
      </div>
    );

    const showDetail = !!this.state.transactionID;

    return (
      <Container className={`reward-status-section ${this.isMobile ? 'mobile-view' : ''}`}>
        <div className="section-header headline">
          <div>{this.props.t('profile_transaction')}</div>
          <div className="view-all button-small" onClick={this.handleClick}>
            {this.props.t('viewAll')}
          </div>
        </div>
        <Row>
          <Col>
            {this.tableView({
              itemList: this.parseTransaction(),
              isSimple: true,
              isLoading: this.props.transactionLoading,
            })}
          </Col>
        </Row>
        {!this.isMobile && (
          <CustomModal
            extendStyle={showDetail ? 'transaction_detail_popup' : ''}
            title={
              showDetail ? detailTitle : this.props.t('profile_transaction')
            }
            tabs={showDetail ? detailTabs : tabs}
            show={this.state.showModal}
            onClose={() =>
              this.setState({
                showModal: false,
                transactionID: null,
              })
            }
            hideTab={true}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(withTranslation()(TransactionHistorySection));
