import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./LevelBenefitMobile.scss";
import { Container, TabContainer, Nav, TabContent, TabPane } from "react-bootstrap";
import { levelConfig } from "./LevelBenefit";

class LevelBenefitMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: levelConfig.level1
    }
  }

  getSingleBenefit = (item) => {
    return (
      <div className="single-benefit-section">
        <div className={`level-benefit-icon ${item.isActive ? 'active' : ''}`} >
          <div />
          <div />
        </div>
        <div>
          <div className="single-benefit-title subheading">{item?.title}</div>
          <div className="single-benefit-content body-2">{item?.content}</div>
        </div>
      </div>
    )
  }

  getLevelBenefit = (level=1) => {
    const itemList = [
      {
        title: this.props.t("levelBenfitsDiscountTitle"),
        content: this.props.t("levelBenfitsDiscountText"),
        isActive: level > 0
      },
      {
        title: this.props.t("levelBenfits7daysInsuredTitle"),
        content: this.props.t("levelBenfits7daysInsuredText"),
        isActive: level > 2
      },
      {
        title: this.props.t("levelBenfitsCustomerServiceTitle"),
        content: this.props.t("levelBenfitsCustomerServiceText"),
        isActive: level > 3
      },
    ]
    return (
      <div className="level-benefit-section">
        {itemList && itemList?.length > 0 &&
          itemList.map((item) => this.getSingleBenefit(item))
        }
      </div>
    )
  }

  render() {
    const tabs = [
      {
        key: levelConfig.level1,
        name: this.props.t(levelConfig.level1),
        content: this.getLevelBenefit(1)
      },
      {
        key: levelConfig.level2,
        name: this.props.t(levelConfig.level2),
        content: this.getLevelBenefit(2)
      },
      {
        key: levelConfig.level3,
        name: this.props.t(levelConfig.level3),
        content: this.getLevelBenefit(3)
      },
      {
        key: levelConfig.level4,
        name: this.props.t(levelConfig.level4),
        content: this.getLevelBenefit(4)
      },
    ]
    return (
      <Container className="level-benefit-mobile-page">
        <TabContainer
          activeKey={this.state.activeTab}
          onSelect={(key) => { this.setState({ activeTab: key }) }}
        >
          <Nav className="home-profile-custom-tab">
            {tabs.map((tab) => (
              <Nav.Item >
                <Nav.Link className="subheading" eventKey={tab.key}>
                  {tab.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <TabContent>
            {tabs.map((tab, index) => (
              <TabPane eventKey={tab.key} key={index}>
                {tab.content}
              </TabPane>
            ))}
          </TabContent>
        </TabContainer>
      </Container>
    );
  }
}

export default withTranslation()(LevelBenefitMobile);
