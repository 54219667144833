import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import "./StoreCard.scss";


const mapStateToProps = state => {
  return {
    language: state.users.language,
    storeDistricts: state.store.storeDistricts,
  };
};
function StoreCard({
  language,
  storeData,
  storeDistricts,
}) {
  const [categoryTags, setCategoryTags] = useState([]);
  const [offerTags, setOfferTags] = useState([]);
  const [districtName, setDistrictName] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (storeData?.subcategories?.length > 0) {
      const subcategories = storeData.subcategories;
      let hasMore = false;
      const tags = [];
      for (const subcategory of subcategories) {
        if (tags.length >= 2) {
          hasMore = true;
          break;
        }
        tags.push(<span key={subcategory.id} className="body-1">{subcategory.name}</span>);
      }
      if (hasMore) {
        tags.push(<span key={0} className='ellipsis body-1'>…</span>);
      }
      setCategoryTags(tags);
      return;
    }
    setCategoryTags([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  useEffect(() => {
    const districtID = storeData?.district;
    for (const x of storeDistricts) {
      for (const district of x.district) {
        if (district.id === districtID) {
          setDistrictName(district.name);
          return;
        }
      }
    }
    setDistrictName(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, storeDistricts]);

  useEffect(() => {
    if (storeData?.campaigns?.length > 0) {
      const campaigns = storeData.campaigns;
      const offers = [];
      for (const campaign of campaigns) {
        if (offers.length >= 2) {
          break;
        }
        offers.push(
          <span
            key={campaign?.campaignId}
            className={'store_offer body-1'}>
            {campaign?.campaignDescription}
          </span>
        );
      }
      setOfferTags(offers)
      return
    }
    setOfferTags([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);


  const cardBeClicked = () => {
    let pathname = "/storeDetail/" + storeData.id;
    history.push(pathname);
  };
  return (
    <>
      <Card className="store_card" onClick={() => cardBeClicked()}>
        <Card.Img
          className={""}
          variant="top"
          src={storeData?.photoUrl}
          alt="store"/>
        <Card.Body>
          <div className={'store_title'}>
            <Card.Title className={'store_name subheading'}>
              {storeData?.name}
            </Card.Title>
            <Card.Subtitle className={'store_brand body-2'}>
              {storeData?.brandName}
            </Card.Subtitle>
          </div>
          <div className={'store_sub_category'}>
            {categoryTags}
          </div>
          <div className={'store_location'}>
            {districtName
            ? <>
              <Image
                className={'p2_color_image'}
                src="/action/location.png"
                srcSet="/action/location@2x.png 2x, /action/location@3x.png 3x" />
              <span className="location_name tag-menu-2">{districtName}</span>
            </>
            : <></>
            }
            <span className="distance tag-menu-1"></span>
          </div>
          <div className='store_offers'>
            {offerTags}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default connect(mapStateToProps)(StoreCard);
