import i18n from 'I18n';
import axios from 'axios';

import BaseAPIHelper from 'services/BaseAPIHelper';

const CMS_DATA_BASE_URL =
  process.env.REACT_APP_DOMAIN || 'https://aillia.hkdev.motherapp.com';
const BASEURL = CMS_DATA_BASE_URL;

const messageAPIinstance = axios.create({
  baseURL: BASEURL,
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

class APIHelper {
  constructor() {
    this.isFakeEnvLogin = false;
    messageAPIinstance.interceptors.request.use(
      (config) => {
        if (
          this.authorizationToken !== undefined &&
          this.authorizationToken != null
        ) {
          config.headers.Authorization = `Bearer ${this.authorizationToken}`;
          config.headers.language = i18n.language;
          config.headers['Accept-Language'] = i18n.language;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  addAuthorization = (token) => {
    this.authorizationToken = token;
  };

  removeAuthorization = () => {
    this.authorizationToken = undefined;
  };

  markMessageRead = ({ userId, messageId }) =>
    messageAPIinstance
      .patch(`/customers/${userId}/inbox_messages/${messageId}`, {
        is_read: true,
      })
      .catch((error) => error);

  deleteMessages = ({ userId, messageIds }) =>
    messageAPIinstance
      .post(`/customers/${userId}/inbox_messages_deletion`, { ids: messageIds })
      .catch((error) => error);

  getMessages = ({ userId, pageLink }) => {
    if (pageLink) {
      return messageAPIinstance.get(pageLink).catch((error) => error);
    }
    if (!userId) {
      return null;
    }
    return messageAPIinstance
      .get(`/customers/${userId}/inbox_messages?page[number]=1&page[size]=50`)
      .catch((error) => error);
  };

  getMessageDetail = ({ userID, messageID }) => {
    const link = `/customers/${userID}/inbox_messages/${messageID}`;
    return messageAPIinstance.get(link).catch((error) => error);
  };

  getUnreadMessageCount = ({ userId }) =>
    messageAPIinstance
      // .get(`/customers/${userId}/unread_messages_count`)
      .get(`customers/unread_messages_count/?uid=${userId}`)
      .catch((error) => error);
}
export const MessageAPIHelper = new APIHelper();
