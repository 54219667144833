import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import LoadMoreSpinner from "component/common/LoadMoreSpinner";

import "./CustomAlert.scss";
import { isMobile } from "utils/ScreenUtils";

class CustomAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertStatus: props.status,
    };
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={
        this.props.customClass
        ? `custom_modal ${this.props.customClass}`
        : "custom_modal"
        }>
        <Modal.Body className="modal_message">
          <div className={`custom-modal-title ${isMobile() ? "subheading" : "title-medium-1"}`}>
            {this.props?.title || ""}
          </div>
          {this.props.content ? this.props.content :
            <div className={`custom_modal_content ${isMobile() ? "body-1" : "field-text"}`}>
              {this.props.isLoading ? (
                <div className="loadSpinner_container">
                  <LoadMoreSpinner />
                </div>
              ) : (
                this.props.message
              )}
            </div>}
        </Modal.Body>
        <Modal.Footer
          className={`modal_buttons ${this.props.leftbtn ? '' : 'only-one-button'}`}
          hidden={this.props.isLoading ? true : false}
        >
          <button
            onClick={this.props.leftAction}
            className="left_btn button-large"
            hidden={this.props.leftbtn === "" ? true : false}
            disabled={this.props.leftDisabled}
          >
            {this.props.leftbtn}
          </button>
          <button onClick={this.props.rightAction} className="right_btn button-large">
            {this.props.rightbtn}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CustomAlert;
