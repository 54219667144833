import React from 'react';
import { connect } from 'react-redux';
import { createAction } from '../../utils';
import { withRouter } from 'react-router-dom';
import url from 'url';
import './OfferList.scss';

import CampaignCardList from '../common/CampaignCardList';

@connect(({ offerList, pullrefreshandloadmore }) => ({ ...offerList, ...pullrefreshandloadmore }))
class OfferList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName:"",
            categoryName:"",
            categoryId:null,
        };
    }

    componentDidMount() {
        this._getPageInfoFromUrl();
    }

    _getPageInfoFromUrl = () => {
        let pathName = this.props.location.pathname;
        let pageName = pathName.split("/")[1];
        let categoryName = pathName.split("/")[2];
        let categoryId = null;
        if (categoryName === "LATEST") {
            this.props.dispatch(createAction('offerList/loadOfferList')({ categoryId: null, storeDataKey: "LATEST" }))
        } else {
            let urlSearch = this.props.location.search;
            categoryId = url.parse(urlSearch, true).query.id;
            this.props.dispatch(createAction('offerList/loadOfferList')({ categoryId: categoryId, storeDataKey: categoryName }));
        }

        this.setState({
            pageName: pageName,
            categoryName: categoryName,
            categoryId: categoryId,
        })
    }

    render() {
        return (
            <CampaignCardList
                pageName={this.state.pageName}
                categoryName={this.state.categoryName}
                categoryId={this.state.categoryId}
                data={this.props.offerDataMap[this.state.categoryName]}
            />
        );    

    }
}

export default withRouter(OfferList);

