
export const checkLanguages = (language) => {
  if (["zh-Hans", "zh_CN"].indexOf(language) >= 0) {
    return "zh-Hans";
  }
  if (["en"].indexOf(language) >= 0) {
    return "en";
  }
  return "zh-Hant";
}

export const getCMSLanguage = (language) => {
  if (["zh-Hans", "zh-Hant", "en"].includes(language)) {
    return language
  }
  if (language === "zh-cn") {
    return "zh-Hans";
  }
  if (language === "zh-hk") {
    return "zh-Hant";
  }
  return "en";
};
