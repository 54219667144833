import React, { Component } from 'react';
import { Collapse, Image, Media } from 'react-bootstrap';
import Markdown from 'react-markdown';
import { isPadOrMobile } from 'utils/ScreenUtils';

class Brand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  setOpened(openState) {
    this.setState({ opened: openState });
  }

  render() {
    const isPadOrMobileView = isPadOrMobile();
    return (
      <div className="brand_container">
        <Media className="align-self-center mr-3 store_section">
          <Image
            className="brand_image align-self-center mr-3"
            src={this.props.brand.iconUrl}
            roundedCircle
          ></Image>
          <Media.Body className="media_body">
            <p className={`brand_name ${isPadOrMobileView ? "subheading" : "headline"}`}>{this.props.brand.name}</p>
            <button
              onClick={() => this.setOpened(!this.state.opened)}
              aria-controls="store_collapse_section"
              aria-expanded={this.state.opened}
              className={this.state.opened ? 'up_arrow_btn' : 'down_arrow_btn'}
              hidden={this.props.brand.description?.length > 0 ? false : true}
            ></button>
          </Media.Body>
        </Media>

        <Collapse in={this.state.opened}>
          <div>
            <div className={`store_collapse_section ${isPadOrMobileView ? "body-1" : "field-text"}`}>
              <Markdown>{this.props.brand.description}</Markdown>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default Brand;
