import React from "react";
import { connect } from "react-redux";
import i18n from "I18n";
import "./error.scss";
// import EmptyImage from "../../assets/images/empty.svg";



const mapStateToProps = state => {
  return {
    language: state.users.language,
  };
};

function NothingPage({
  language,
}) {
  return (
    <div className="nothing-area">
      {/* <img alt="" src={EmptyImage} /> */}
      <label className="nothing-title title-medium-2">
        {i18n.t("campaign_nothing_here_yet")}
      </label>

      <label className="nothing-content field-text">
        {i18n.t("please_check_back_again_later")}
      </label>
    </div>
  );
}

export default connect(mapStateToProps)(NothingPage);
