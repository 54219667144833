import React, { Component } from "react";
import "./LevelBenefit.scss";
import { withTranslation } from "react-i18next";
import CustomModal from "../base/CustomModal"
import { withRouter } from "react-router-dom";
import { isPadOrMobile } from "utils/ScreenUtils";

export const levelConfig = {
  level1: "level1",
  level2: "level2",
  level3: "level3",
  level4: "level4",
}

class LevelBenefit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      activeTab: levelConfig.level1
    }
    this.isMobile = isPadOrMobile();
  }

  getSingleBenefit = (item, index) => {
    return (
      <div key={index} className="single-benefit-section">
        <div className={`level-benefit-icon ${item.isActive ? 'active' : ''}`} >
          <div />
          <div />
        </div>
        <div>
          <div className="single-benefit-title subheading">{item?.title}</div>
          <div className="single-benefit-content body-2">{item?.content}</div>
        </div>
      </div>
    )
  }

  getLevelBenefit = (level = 1) => {
    const itemList = [
      {
        title: this.props.t("levelBenfitsDiscountTitle"),
        content: this.props.t("levelBenfitsDiscountText"),
        isActive: level > 0
      },
      {
        title: this.props.t("levelBenfits7daysInsuredTitle"),
        content: this.props.t("levelBenfits7daysInsuredText"),
        isActive: level > 2
      },
      {
        title: this.props.t("levelBenfitsCustomerServiceTitle"),
        content: this.props.t("levelBenfitsCustomerServiceText"),
        isActive: level > 3
      },
    ]
    return (
      <div className="level-benefit-section">
        {itemList && itemList?.length > 0 &&
          itemList.map((item) => this.getSingleBenefit(item))
        }
      </div>
    )
  }

  handleClick = () => {
    if (this.isMobile) {
      this.props.history.push("/level_benefit")
    } else {
      this.setState({ showModal: true })
    }
  }

  render() {
    const tabs = [
      { 
        key: levelConfig.level1,
        name: this.props.t(levelConfig.level1),
        content: this.getLevelBenefit(1)
      },
      {
        key: levelConfig.level2,
        name: this.props.t(levelConfig.level2),
        content: this.getLevelBenefit(2)
      },
      {
        key: levelConfig.level3,
        name: this.props.t(levelConfig.level3),
        content: this.getLevelBenefit(3)
      },
      {
        key: levelConfig.level4,
        name: this.props.t(levelConfig.level4),
        content: this.getLevelBenefit(4)
      },
    ]
    return (
      <>
        <div className="home-level-benfient-section d-flex" onClick={this.handleClick}>
          <div className="common-profile-section-text field-text">
            <img
              alt="icon"
              src={"/profile/levelBenefit/gift.png"}
              srcSet={"/profile/levelBenefit/gift@2x.png 2x, /profile/levelBenefit/gift@3x.png 3x"}
            />
            {this.props.t("level_benefit")}
          </div>
          <div className="arrow-icon">
            <img alt="icon" src={"/profile/right-arrow.png"} />
          </div>

        </div>
        <CustomModal
          title={this.props.t("level_benefit")}
          show={this.state.showModal}
          tabs={tabs}
          activeTab={this.state.activeTab}
          onClose={() => { this.setState({ showModal: false }) }}
          onTabChange={(key) => { this.setState({ activeTab: key }) }}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(LevelBenefit));
