import { createAction } from "../utils";
import { CampaignAPIHelper } from "../services/CampaignAPIHelper";
import {
  offerCategoryValidation,
  checkOfferCategory,
} from "../services/OfferCategoryValidation";
import { validateApi } from "../utils/ValidateApi";

export default {
  namespace: "offerContainer",
  state: {
    tabList: [],
  },
  reducers: {
    updateTabList(state, { payload }) {
      if (payload === undefined || payload === null) {
        return state;
      }

      const activeTabList = payload.filter(
        (tabItem) => tabItem.is_active === true
      );
      return { ...state, tabList: activeTabList };
    },
  },

  effects: {
    *getOfferTabData({ payload }, { call, put }) {
      const data = yield call(CampaignAPIHelper.getOfferCategories);
      let validatedApiData = [];
      if (data?.status === 200) {
        validatedApiData = validateApi(
          data.data,
          offerCategoryValidation,
          checkOfferCategory
        );
        if (payload.callBackFunction) {
          payload.callBackFunction();
        }
      }
      yield put(createAction("updateTabList")(validatedApiData));
    },
  },
};
