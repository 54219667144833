import { createAction } from '../utils';
import { CampaignAPIHelper, BANNER_TYPES } from '../services/CampaignAPIHelper';
import { campaignValidation, campaignCheck } from '../services/CampaignValidation';
import { validateApi } from '../utils/ValidateApi';
import { subHeaderLink, assembleCampaign, campaignListWithUpdatedEarnedStamp } from '../utils/CampaignUtil';
import { hasMoreData, getDataWithDelay } from '../utils/PullAndRefreshUtil';
import i18n from '../I18n';

const USING_FAKE_API = false;

export default {
  namespace: 'campaignList',
  state: {
    homeOnlyForYouList: [],
    homeOnlyForYouListOriginData: [],
    homeFeatureList: [],
    homeFeaturesOriginData: [],
    homeFeaturelUrl: null,
    homeBanner: [],
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    homeOnlyForYouListRefreshWithAssemble(state, { payload }) {
      const homeOnlyForYouList = assembleCampaign(payload.onlyForYou);
      return {
        ...state,
        homeOnlyForYouList,
        homeOnlyForYouListOriginData: payload.onlyForYou,
      };
    },
    homeFeatureListRefreshWithAssemble(state, { payload }) {
      const homeFeatureList = assembleCampaign(payload.features);
      console.log("xbxbxbxxbxb",homeFeatureList)
      return {
        ...state,
        homeFeatureList,
        homeFeaturesOriginData: payload.features,
        homeFeaturelUrl: payload.featuresLink,
      };
    },
    homeFeatureListLoadMoreWithAssemble(state, { payload }) {
      const newFeatures = assembleCampaign(payload.features);
      return {
        ...state,
        homeFeatureList: [...state.homeFeatureList, ...newFeatures],
        homeFeaturesOriginData: [...state.homeFeaturesOriginData, ...payload.features],
        homeFeaturelUrl: payload.featuresLink,
      };
    },
    updateStampCampaignEarnedStampCount(state, { payload }) {
      return {
        ...state,
        homeOnlyForYouList: campaignListWithUpdatedEarnedStamp(state.homeOnlyForYouList, payload),
        homeFeatureList: campaignListWithUpdatedEarnedStamp(state.homeFeatureList, payload)
      }
    },
  },

  effects: {
    * homeOnlyForYouListRefresh(_action, { put }) {
      const homeOnlyForYouData = yield getDataWithDelay(CampaignAPIHelper.getHomeOnlyForYouData, {}, 'homeONLYFORYOU', false);
      if (homeOnlyForYouData?.status === 200) {
        const validatedApiData = validateApi(homeOnlyForYouData.data, campaignValidation, campaignCheck);
        yield put(createAction('homeOnlyForYouListRefreshWithAssemble')({ onlyForYou: validatedApiData }));
      }
    },
    * homeFeatureListRefresh(_action, { put }) {
      const featuresData = yield getDataWithDelay(CampaignAPIHelper.getHomeFeaturedData, {}, 'homeFEATURE', false);
      let nextLink = '';
      if (featuresData?.status === 200) {
        nextLink = subHeaderLink(featuresData.headers.link);
        const validatedApiData = validateApi(featuresData.data, campaignValidation, campaignCheck);
        yield put(createAction('homeFeatureListRefreshWithAssemble')({ features: validatedApiData, featuresLink: nextLink }));
      }      
      yield hasMoreData('homeFEATURE', nextLink);
    },

    *homeFeatureListLoadMore(_action, { put, select }) {
      const featuresLoadMoreUrl = yield select((state) => state.campaignList.homeFeaturelUrl);
      const featuresData = yield getDataWithDelay(CampaignAPIHelper.getHomeFeaturedData, { pageLink: featuresLoadMoreUrl }, 'homeFEATURE', true);
      let nextLink = '';
      if (featuresData?.status === 200) {
        nextLink = subHeaderLink(featuresData.headers.link);
        const validatedApiData = validateApi(featuresData.data, campaignValidation, campaignCheck);
        yield put(createAction('homeFeatureListLoadMoreWithAssemble')({ features: validatedApiData, featuresLink: nextLink }));
      }
      yield hasMoreData('homeFEATURE', nextLink);
    },

    * homeBannnerRefresh(_action, { put, call }) {
      const getTranslatedBanner = (banner) => {
        const { translations } = banner;
        let coverPhotoUrl = banner.cover_photo_url;
        if (translations !== null) {
          for (let index = 0; index < translations.length; index++) {
            const translation = translations[index];
            if (i18n.language === translation.language) {
              coverPhotoUrl = translation.cover_photo_url || banner.cover_photo_url;
              break;
            }
          }
        }
        return {
          url: coverPhotoUrl,
          targetType: banner.content_type,
          targetID: banner.content_reference_id,
          id: banner.id,
        };
      }
      const response = yield call(CampaignAPIHelper.getHomeBanners);
      if (response?.status === 200) {
        const banners = response.data.slice(0, 5).map((banner) => {
          if (USING_FAKE_API) {
            return {
              url: banner,
              targetType: BANNER_TYPES.TYPE_PRODUCT,
              targetID: null,
            };
          }
          return getTranslatedBanner(banner);
        });
        yield put(
          createAction('updateState')({
            homeBanner: banners,
          })
        );
      }
    },

  }
};
