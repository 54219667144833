import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import LoadMoreSpinner from 'component/common/LoadMoreSpinner';
import InboxListTitle from 'component/inbox/InboxListTitle';
import InboxListItem from 'component/inbox/InboxListItem';
import InboxContent from 'component/inbox/InboxContent';
import NothingPage from 'component/error/Nothing';
import OfferFloatActionButton from 'component/offer/OfferFloatActionButton';
import {
  getScrollTop,
  getDocumentHeight,
  getWindowHeight,
} from 'utils/ScreenUtils';
import { createAction } from 'utils';

import './InboxList.scss';

@connect(({ inboxMessages, pullrefreshandloadmore, users }) => ({
  ...inboxMessages,
  ...pullrefreshandloadmore,
  language: users.language,
}))
class InboxList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.bindHandleWindowScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.bindHandleWindowScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(createAction('inboxMessages/refreshMessages')());
    }
  }

  shouldLoadMore = () => {
    return (
      this.props.MessageListHasMoreData &&
      !(
        this.props.MessageListIsRefreshing ||
        this.props.MessageListIsLoadingMore
      )
    );
  };

  bindHandleWindowScroll = () => {
    const scrollTopLength = getScrollTop();
    const windowHeight = getWindowHeight();
    const documentHeight = getDocumentHeight();
    const isLoadArea = scrollTopLength + windowHeight > documentHeight - 100;
    if (isLoadArea && this.shouldLoadMore()) {
      this.props.dispatch(createAction('inboxMessages/messageLoadMore')());
    }
  };

  messageItemDidClicked = (messageId) => {
    if (this.props.inboxIsEditing) {
      this.props.dispatch(
        createAction('inboxMessages/switchMessageChecked')({
          messageId,
        }),
      );
      return;
    }
    this.toMessageDetail(messageId);
  };

  toMessageDetail = (messageId) => {
    this.props.history.push(`/inboxDetail/${messageId}`);
  };

  getMessageItems = () => {
    if (this.props.messageList?.length > 0) {
      const messageItemList = this.props.messageList.map(
        (messageItemData, index) => {
          return (
            <InboxListItem
              key={index}
              messageData={messageItemData}
              hasSeparateLine={index === 0 ? false : true}
              isActive={false}
              clickAction={() =>
                this.messageItemDidClicked(messageItemData.messageId)
              }
            />
          );
        },
      );
      return messageItemList;
    }

    if (
      !this.props['MessageListIsRefreshing'] &&
      !this.props['MessageListIsLoadingMore']
    ) {
      return <NothingPage />;
    }
    return null;
  };

  render() {
    return (
      <div>
        <div className="inbox_floating_title">
          <Container>
            <InboxListTitle />
            <div className="inbox_list_separateLine" />
          </Container>
        </div>
        <Container className="inbox_mobile_container">
          <div className="inbox_list">
            <div className="inbox_list_title"></div>
            <div
              className="inbox_list_listArea"
              ref={(node) => (this.inboxListNode = node)}
            >
              {this.getMessageItems()}
              <div className="inbox_list_loadmoreArea">
                {(this.props['MessageListIsRefreshing'] ||
                  this.props['MessageListIsLoadingMore']) && (
                  <LoadMoreSpinner />
                )}
              </div>
            </div>
          </div>
        </Container>
        <OfferFloatActionButton />
      </div>
    );
  }
}

export default withRouter(InboxList);
