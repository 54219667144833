import React, { Component } from "react";
import { Container, Nav } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { withTranslation } from "react-i18next";

import CouponList from "component/coupon/CouponList";
import MyCard from "container/MyCard";
import MyProfile from "container/MyProfile";
import MyAccount from "container/MyAccount";
import CustomNavDropdown from "./CustomNavDropdown";

import "./UserNavigator.scss";

class UserNavigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.menuList = [
      "my_profile",
      "my_card",
      "profile_my_coupons",
      "profile_my_account",
    ];
    this.menuEventKeyList = [
      "myProfile",
      "myCard",
      "myCoupon",
      "myAccount",
    ];
    this.menuConfigList = [
      {
        title: this.props.t("my_profile"),
        activeKey: "myProfile",
        pathname: "/user/myProfile",
      },
      {
        title: this.props.t("my_card"),
        activeKey: "myCard",
        pathname: "/user/myCard",
      },
      {
        title: this.props.t("profile_my_coupons"),
        activeKey: "myCoupon",
        pathname: "/user/myCoupon",
      },
      {
        title: this.props.t("profile_my_account"),
        activeKey: "myAccount",
        pathname: "/user/myAccount",
      },
    ]
  }

  activeKey = () => {
    const eventKey = this.props.match.params?.page;
    if (this.menuEventKeyList.includes(eventKey)) {
      return eventKey;
    }
    return "myCoupon";
  };

  dropdownNavTitle = () => {
    const eventKey = this.props.match.params?.page;
    const index = this.menuEventKeyList.indexOf(eventKey);
    if (index !== -1) {
      return this.menuList[index];
    }
    return "profile_my_coupons";
  };

  render() {
    return (
      <div className="user_container_background">
        <div className="user_nav_container">
          <Container>
            <Nav activeKey={this.activeKey()} className="user_nav">
              {this.menuList.map((navItem, index) => {
                const active = this.menuEventKeyList[index] === this.activeKey();
                const offerNavItemClassName = active ? "nav-link active subheading" : "nav-link subheading";
                return (
                  <Nav.Item key={index}>
                    <LinkContainer to={`/user/${this.menuEventKeyList[index]}`}>
                      <div className={offerNavItemClassName}>
                        {this.props.t(navItem)}
                      </div>
                    </LinkContainer>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Container>
        </div>
        <Container className="user_container">
          <CustomNavDropdown
            className="user-nav-dropdown"
            activeKey={this.activeKey()}
            navDropdownTitle={this.props.t(this.dropdownNavTitle())}
            menuList={this.menuConfigList}
          />
          <Switch>
            <Route path="/user/myProfile">
              <MyProfile />
            </Route>
            <Route path="/user/myCard">
              <MyCard />
            </Route>
            <Route path="/user/myCoupon">
              <CouponList />
            </Route>
            <Route path="/user/MyAccount">
              <MyAccount />
            </Route>
          </Switch>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(UserNavigator);
