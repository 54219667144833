import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import i18n from 'I18n';

import GenderPicker from 'component/account/GenderPicker';
import CustomAlert from 'component/base/CustomAlert';
import CustomButton from 'component/base/CustomButton';
import CustomSwitchButton from 'component/base/CustomSwitchButton';
import MonthPicker, {
  CustomDatePickerInput,
} from 'component/profile/MonthPicker';
import { ssoAPIHelper } from "services/SSOAPIHelper";

import { createAction } from 'utils';
import {
  CUSTOMER_SIGN_UP_METHOD,
  GenderType,
  ProfileEditState,
  ProfileEditType,
  SupportedCountryCodeList,
} from 'utils/CustomEnums';
import {
  getMyAccountUserBirthdayForDisplay,
  getMyAccountUserBirthdayForUpdate,
} from 'utils/DateTimeUtils';

import './LoginMethodContactModal.scss';

const sections = SupportedCountryCodeList.map((item) => ({
  name: item,
  pk: item,
}));

const mapStateToProps = ({ users, userInfoTemp }) => {
  return {
    language: users.language,
    signUpMethod: users.userInfo.signUpMethod,
    contactError: userInfoTemp.loginMethodContact.error,
    contactErrorMessage: userInfoTemp.loginMethodContact.errorMessage,
    verifyChallenge: userInfoTemp.verifyChallenge,
    verifyError: userInfoTemp.verifyError,
    alreadyRegisteredContact: userInfoTemp.alreadyRegisteredContact,
  };
};

const LoginMethodContactModal = ({
  language,
  signUpMethod,
  contactError,
  contactErrorMessage,
  verifyChallenge,
  verifyError,
  alreadyRegisteredContact,
  show,
  onClose = () => {},
}) => {
  const dispatch = useDispatch();

  const [countryCode, setCountryCode] = useState(SupportedCountryCodeList?.[0]);
  const [nationalNumber, setNationalNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [requiredOTP, setRequiredOTP] = useState(false);
  const [verifyCode, setVerifyCode] = useState(null);
  const [nextEnable, setNextEnable] = useState(true);

  const method = useMemo(() => {
    if (signUpMethod === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS) {
      return CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER;
    } else if (signUpMethod === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
      return CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS;
    }
    return null;
  }, [signUpMethod]);

  const verifyEnable = useMemo(() => {
    if (requiredOTP) {
      return false;
    }
    return true;
  }, [requiredOTP, method]);

  const confirmEnable = useMemo(() => {
    if (!nextEnable) {
      return false;
    }
    if (requiredOTP && verifyCode) {
      return true;
    }
    return false;
  }, [requiredOTP, verifyCode, countryCode, nationalNumber]);

  useEffect(() => {
    if (contactError) {
      setRequiredOTP(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactError]);

  useEffect(() => {
    setRequiredOTP(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, countryCode, nationalNumber]);

  useEffect(() => {
    setRequiredOTP(false);
    setCountryCode(SupportedCountryCodeList?.[0]);
    setNationalNumber(null);
    setVerifyCode(null);
    dispatch(createAction('userInfoTemp/clearState')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const callApiRequireOTP = () => {
    setRequiredOTP(true);
    dispatch(
      createAction('userInfoTemp/requireOTPForUpdateLoginMethod')({
        method,
        countryCode: countryCode,
        nationalNumber: nationalNumber,
        email,
        failureCallback: (code) => {
          console.log('kevin@208', code);
          setRequiredOTP(false);
        },
      }),
    );
  };

  const callApiUpdateLoginMethod = () => {
    setNextEnable(false);
    dispatch(
      createAction('userInfoTemp/verifyOTPAndUpdateLoginMethod')({
        method,
        countryCode: countryCode,
        nationalNumber: nationalNumber,
        email,
        verifyCode,
        successCallback: () => {
          onClose();
        },
        failureCallback: (code) => {
          setNextEnable(true);
        },
      }),
    );
  };

  const loginButtonDidClicked = () => {
    window.location.href = ssoAPIHelper.getLoginWebUri(
      i18n?.language || 'en'
    );
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onClose}
      scrollable={false}
      className={'login-method-modal'}
      centered
    >
      <Modal.Body className={'login-method-modal-body '}>
        <div className="home-profile-custom-title title-medium-1">
          <div>
            {i18n.t('settings_login_and_contact') +
              i18n.t('personal_info_optional')}
          </div>
          <div className="close-icon" onClick={onClose}>
            <img
              alt="close"
              src="/profile/close.png"
              srcSet="/profile/close@2x.png 2x, /profile/close@3x.png 3x"
            />
          </div>
        </div>
        <div className="account-error" />
        {method === CUSTOMER_SIGN_UP_METHOD.EMAIL_ADDRESS && (
          <div className={contactError ? 'has-error' : ''}>
            <div className="home-profile-custom-email bottom-line common-item-value field-text">
              <input
                placeholder={i18n.t('settings_email')}
                className="email-input field-text"
                onChange={(e) => {
                  const text = e.target.value;
                  setEmail(text);
                }}
              />
              <CustomButton
                className="button-small"
                btnClicked={!!verifyEnable}
                disabled={!verifyEnable}
                text={i18n.t('account_verify')}
                btnClicked={() => callApiRequireOTP()}
              />
            </div>
            <div className="account-common-prompt-information tag-menu-1">
              {i18n.t('settings_email_tip')}
            </div>
          </div>
        )}
        {method === CUSTOMER_SIGN_UP_METHOD.MOBILE_PHONE_NUMBER && (
          <div
            className={
              contactError
                ? 'home-profile-mobile has-error'
                : 'home-profile-mobile'
            }
          >
            <div className="home-profile-custom-mobile country-code bottom-line common-item-value field-text">
              <GenderPicker
                customClass={'country-code-picker-box'}
                sections={sections}
                value={countryCode}
                selectValue={(value) => {
                  setCountryCode(value);
                }}
              />
            </div>
            <div className="home-profile-custom-mobile national-number bottom-line common-item-value field-text">
              <input
                placeholder={i18n.t('account_mobile')}
                className="mobile-input field-text"
                onChange={(e) => {
                  const text = e.target.value;
                  setNationalNumber(text);
                }}
              />
              <CustomButton
                className="button-small"
                btnClicked={!!verifyEnable}
                disabled={!verifyEnable}
                text={i18n.t('account_verify')}
                btnClicked={() => callApiRequireOTP()}
              />
            </div>
          </div>
        )}
        <div className="account-error account-common-prompt-information change-password-error tag-menu-1">
          {contactError ? contactErrorMessage : null}
        </div>
        {alreadyRegisteredContact && (
          <div>
            <button
              class="login-btn button-small subheading"
              onClick={loginButtonDidClicked}
            >
              {i18n.t('account_login')}
            </button>
          </div>
        )}
        <div
          className={`home-profile-custom-verify-code bottom-line common-item-value field-text ${
            verifyError ? 'has-error' : ''
          }`}
        >
          <input
            placeholder={i18n.t('sign_up_verification_code')}
            className="field-text"
            onChange={(e) => {
              const text = e.target.value;
              setVerifyCode(text);
            }}
          />
        </div>
        <div className="account-error account-common-prompt-information change-password-error tag-menu-1">
          {verifyError || ''}
        </div>
        <CustomButton
          className="account-update-button"
          btnClicked={!!confirmEnable}
          disabled={!confirmEnable}
          text={i18n.t('account_update')}
          btnClicked={() => callApiUpdateLoginMethod()}
        />
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps)(LoginMethodContactModal);
