import React, { Component } from "react";
import { Container, Modal } from "react-bootstrap";

import QRCode from 'qrcode.react';

import "./DownloadQRcode.scss";
import { DOWNLOAD_APP_DOMAIN } from "../../utils/Config";
import i18n from "../../I18n";

class DownloadQRcode extends Component {
  render() {
    return (
      <Container>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="qrcode_modal"
        >
          <Modal.Body className="qrcode_body">
            <div className="qrcode_message subheading">
              {i18n.t("campaign_only_avaliable_in_and_scan_no_link")}
            </div>
            <div className="my_card_qrcode_view download_qrcode_view">
              <QRCode value={DOWNLOAD_APP_DOMAIN} renderAs="svg" />
            </div>
          </Modal.Body>
          <Modal.Footer className="store_btns">
            <button className="google_play_btn">
              <img
                alt="google play"
                src="/googlePlay/google-play.png"
                srcSet="/googlePlay/google-play@2x.png 2x, /googlePlay/google-play@3x.png 3x"
              ></img>
            </button>
            <button className="apple_store_btn">
              <img
                alt="apple store"
                src="/appleStore/appleStore.png"
                srcSet="/appleStore/appleStore@2x.png 2x, /appleStore/appleStore@3x.png 3x"
              />
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default DownloadQRcode;
