import React, { useState, createRef, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { createAction, getFileName } from "utils";
import NothingPage from "component/error/Nothing";
import LoadMoreSpinner from "component/common/LoadMoreSpinner";
import i18n from "I18n";

@connect(({ transaction, pullrefreshandloadmore, users }) => ({
  ...transaction,
  ...pullrefreshandloadmore,
  language: users.language,
}))
class TenantPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTenant: null,
      showTenantPicker: false,
      searchName: "",
      delaySearchName: "",
    };
    this.tenantPickerRef = createRef(null);
  }

  handleClickOutside = (event) => {
    if (
      this.tenantPickerRef &&
      this.tenantPickerRef.current &&
      !this.tenantPickerRef.current.contains(event.target)
    ) {
      this.setState({
        showTenantPicker: false,
      });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    if (this.tenantPickerRef && this.tenantPickerRef.current)
      this.tenantPickerRef.current.addEventListener(
        "scroll",
        this.bindHandleListScroll
      );
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    if (this.tenantPickerRef && this.tenantPickerRef.current)
      this.tenantPickerRef.current.removeEventListener(
        "scroll",
        this.bindHandleListScroll
      );
  }

  shouldLoadMore = () => {
    return (
      this.props.StoreListHasMoreData &&
      !(this.props.StoreListIsRefreshing || this.props.StoreListIsLoadingMore)
    );
  };

  bindHandleListScroll = (event) => {
    const clientHeight = event.target.clientHeight;
    const scrollHeight = event.target.scrollHeight;
    const scrollTop = event.target.scrollTop;
    const isLoadArea = clientHeight + scrollTop > scrollHeight - 50;
    if (isLoadArea && this.shouldLoadMore()) {
      this.props.dispatch(
        createAction("transaction/storeLoadMore")({
          searchName: this.state.searchName,
        })
      );
    }
  };

  getStoreItems = () => {
    if (this.props.storeList?.length > 0) {
      return this.props.storeList.map((store) => {
        return (
          <div key={`store-item-${store.id}`}>
            <div className="create_transaction_separateLine" />
            <div
              className="tenant-picker-item field-text"
              onClick={() => {
                this.setState({
                  selectedTenant: store,
                  showTenantPicker: false,
                });
                this.props.selectedTenantChange(store);
              }}
            >
              {store.name}
            </div>
          </div>
        );
      });
    }

    if (
      !this.props["StoreListIsRefreshing"] &&
      !this.props["StoreListIsLoadingMore"]
    )
      return <NothingPage />;
  };

  render() {
    return (
      <>
        <div className="div-with-downarrow">
          <input
            type="text"
            className="input-with-downarrow field-text"
            placeholder={i18n.t("records:please_select_tenant")}
            onClick={() => {
              this.setState({
                showTenantPicker: true,
              });
            }}
            value={this.state.selectedTenant?.name || ""}
            readOnly={true}
          />
          {this.state.showTenantPicker ? (
            <img
              alt="down"
              src="/myAccount/up-arrow.png"
              srcSet="/myAccount/up-arrow@2x.png 2x, /myAccount/up-arrow@3x.png 3x"
              className="down-arrow"
            />
          ) : (
            <img
              alt="down"
              src="/myAccount/down-arrow.png"
              srcSet="/myAccount/down-arrow@2x.png 2x, /myAccount/down-arrow@3x.png 3x"
              className="down-arrow"
              onClick={() => {
                this.setState({
                  showTenantPicker: true,
                });
              }}
            />
          )}
        </div>
        <div
          className="custom-tenant-picker-div"
          style={{ display: this.state.showTenantPicker ? "block" : "none" }}
          ref={this.tenantPickerRef}
        >
          <div className="tenant-picker-item search">
            <input
              type="text"
              className="tenant-picker-input field-text"
              placeholder={i18n.t("records:search_tenant")}
              value={this.state.searchName}
              onChange={(e) => {
                e.preventDefault();
                this.setState({
                  searchName: e.target.value,
                });
                this.props.dispatch(
                  createAction("transaction/refreshStoreList")({
                    searchName: e.target.value,
                  })
                );
              }}
            />
            <div
              hidden={!this.state.searchName}
              className="clear-input-btn"
              onClick={() => {
                this.setState({
                  searchName: "",
                });
                this.props.dispatch(
                  createAction("transaction/refreshStoreList")()
                );
              }}>
              <img
                alt="close"
                src="/profile/close.png"
                srcSet="/profile/close@2x.png 2x, /profile/close@3x.png 3x"
              />
            </div>
          </div>
          {this.getStoreItems()}
          <div className="store_list_loadmoreArea enant-picker-item">
            {(this.props["StoreListIsRefreshing"] ||
              this.props["StoreListIsLoadingMore"]) && <LoadMoreSpinner />}
          </div>
        </div>
      </>
    );
  }
}

export default TenantPicker;

const ReceiptImage = (props) => {
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const selectFileRef = useRef();
  const dispatch = useDispatch();

  const pickImage = (withPreview) => {
    if (withPreview) {
      // preview();
      return;
    }
    selectFileRef.current.click();
  };

  const selectFile = (event) => {
    if (!event.target.files[0]) {
      return;
    }
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    dispatch(
      createAction("transaction/uploadFile")({
        file: event.target.files[0],
        onDone: (imageURL) => {
          setLoading(false);
          const imageName = imageURL ? getFileName(imageURL) : null;
          props.onChange(imageName);
          if (!imageName) {
            setPreviewImage(null);
          }
        },
      })
    );
    setLoading(true);
  };

  return (
    <div className={`image_container ${props.customClassName}`}>
      <div className="image">
        {loading ? (
          <div className="loading_image">
            <LoadMoreSpinner />
          </div>
        ) : (
          <img
            src={previewImage || props.defaultSource}
            onClick={() => pickImage(previewImage)}
            alt=""
          />
        )}
      </div>
      <div className="image_text field-text">{props.text}</div>
      {previewImage ? (
        <div className="image_upload_button button-small" onClick={() => pickImage(false)}>
          {i18n.t("records:re_upload")}
        </div>
      ) : (
        <></>
      )}
      <input
        ref={selectFileRef}
        type="file"
        accept="image/*"
        onChange={selectFile}
      />
    </div>
  );
};

export const Receipts = (props) => {
  const receiptChange = props.receiptChange || (() => {});
  const creditChange = props.creditChange || (() => {});

  const [receiptImage, setReceiptImage] = useState(null);
  const [creditImage, setCreditImage] = useState(null);

  return (
    <>
      <ReceiptImage
        customClassName="receipt_image_container"
        source={receiptImage}
        onChange={(image) => {
          setReceiptImage(image);
          receiptChange(image);
        }}
        defaultSource={require("../../assets/images/uploadReceipt.png")}
        text={i18n.t("records:merchants_receipts")}
      />
      <ReceiptImage
        customClassName="credit_image_container"
        source={creditImage}
        onChange={(image) => {
          setCreditImage(image);
          creditChange(image);
        }}
        defaultSource={require("../../assets/images/uploadCredit.png")}
        text={i18n.t("records:credit_slip")}
      />
    </>
  );
};
