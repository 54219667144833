import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import i18n from 'I18n';

import DetailRedeem from 'component/campaign/DetailRedeem';
import AboutNavigator from 'component/common/AboutNavigator';
import AuthRequiredView from 'component/common/AuthRequiredView';
import CookieBar from 'component/common/CookieBar';
import { CustomToast } from 'component/common/CustomToast';
import Footer from 'component/common/Footer';
import Navigator from 'component/common/Navigator';
import OpenAppBanner from 'component/common/OpenAppBanner';
import ScrollToTop from 'component/common/ScrollToTop';
import UserNavigator from 'component/common/UserNavigator';
import CouponDetail from 'component/coupon/CouponDetail';
import CouponSelectStore from 'component/coupon/CouponSelectStore';
import UseCoupon from 'component/coupon/UseCoupon';
import BrowserNotSupport from 'component/error/BrowserNotSupport';
import NotFoundPage from 'component/error/NotFound';
import Login from 'component/login/Login';
import ActivityLogMobile from 'component/profile/ActivityLogMobile';
import LevelBenefitMobile from 'component/profile/LevelBenefitMobile';
import LevelRuleMobile from 'component/profile/LevelRuleMobile';
import RewardStatusMobile from 'component/profile/RewardStatusMobile';
import TransactionHistoryListMobile from 'component/profile/TransactionHistoryListMobile';
import UseCashDollar from 'component/profile/UseCashDollar';
import CampaignDetail from 'container/CampaignDetail';
import CreateRewardClaim from 'container/CreateRewardClaim';
import Home from 'container/Home';
import Inbox from 'container/Inbox';
import InboxDetail from 'container/InboxDetail';
import Offer from 'container/Offer';
import RedeemGiftCard from 'container/RedeemGiftCard';
import StoreDetail from 'container/StoreDetail';
import TransactionDetail from 'container/TransactionDetail';
import TransactionHistoryDetail from 'container/TransactionHistoryDetail';
import TransactionList from 'container/TransactionList';
import UnsubscribeEmail from 'container/UnsubscribeEmail';

import { createAction } from 'utils';

import './App.scss';

const PageRouteList = [
  {
    path: '/home',
    component: Home,
    isAuthProtected: false,
  },
  {
    path: '/about',
    component: AboutNavigator,
    isAuthProtected: false,
  },
  {
    path: '/activity_log',
    component: ActivityLogMobile,
    isAuthProtected: true,
  },
  {
    path: '/browser-support',
    component: BrowserNotSupport,
    isAuthProtected: false,
  },
  {
    path: '/campaign/detail',
    component: DetailRedeem,
    isAuthProtected: true,
  },
  {
    path: '/campaign/:id',
    component: CampaignDetail,
    isAuthProtected: false,
  },
  {
    path: '/coupon/:id/select_store',
    component: CouponSelectStore,
    isAuthProtected: true,
  },
  {
    path: '/coupon/:id',
    component: CouponDetail,
    isAuthProtected: true,
  },
  {
    path: '/inbox',
    component: Inbox,
    isAuthProtected: true,
  },
  {
    path: '/inboxDetail/:id',
    component: InboxDetail,
    isAuthProtected: true,
  },
  {
    path: '/level_benefit',
    component: LevelBenefitMobile,
    isAuthProtected: true,
  },
  {
    path: '/level_rule',
    component: LevelRuleMobile,
    isAuthProtected: true,
  },
  {
    path: '/login_callback',
    component: Login,
    isAuthProtected: false,
  },
  {
    path: '/offer/:page',
    component: Offer,
    isAuthProtected: false,
  },
  {
    path: '/reward_status',
    component: RewardStatusMobile,
    isAuthProtected: true,
  },
  {
    path: '/storeDetail/:id',
    component: StoreDetail,
    isAuthProtected: false,
  },
  {
    path: '/transaction/create',
    component: CreateRewardClaim,
    isAuthProtected: true,
  },
  {
    path: '/transaction',
    component: TransactionList,
    isAuthProtected: true,
  },
  {
    path: '/transactionDetail/:id',
    component: TransactionDetail,
    isAuthProtected: true,
  },
  {
    path: '/transaction_history',
    component: TransactionHistoryListMobile,
    isAuthProtected: true,
  },
  {
    path: '/transaction_history_detail/:id',
    component: TransactionHistoryDetail,
    isAuthProtected: true,
  },
  {
    path: '/use_cash_dollar',
    component: UseCashDollar,
    isAuthProtected: true,
  },
  {
    path: '/use_coupon',
    component: UseCoupon,
    isAuthProtected: true,
  },
  {
    path: '/user/:page',
    component: UserNavigator,
    isAuthProtected: true,
  },
  {
    path: '/unsubscribe_email',
    component: UnsubscribeEmail,
    isAuthProtected: false,
  },
  {
    path: '/',
    component: Login,
    isAuthProtected: false,
  },
];

function App() {
  const { language } = useSelector((state) => ({
    language: state.users.language,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.lang = language;
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    if (window.self !== window.top) {
      dispatch(
        createAction('users/updateState')({
          isOpenInOtherSite: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideNavigator =
    window.location.href?.indexOf('/redeem-gift-card') !== -1;

  const isTransactionHistoryDetailPage =
    window.location.href?.indexOf('/transaction_history_detail') !== -1;
  const g6Background = isTransactionHistoryDetailPage;

  const noNavigatorPage = () => {
    return (
      <Switch>
        <Route path="/redeem-gift-card" component={RedeemGiftCard} />
      </Switch>
    );
  };

  return (
    <Router>
      <CustomToast />
      <ScrollToTop>
        <div className="app_container">
          {hideNavigator ? (
            noNavigatorPage()
          ) : (
            <>
                <OpenAppBanner />
                <div
                className={`app_navigator_container ${
                  g6Background ? 'g6_background' : ''
                }`}
              >
                <Navigator />
              </div>
              <Container className="page_container">
                {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
                <Switch>
                  {PageRouteList?.map((item) => (
                    <Route
                      path={item?.path}
                      {...(item?.isAuthProtected
                        ? {
                            render: (props) => (
                              <AuthRequiredView>
                                <item.component {...props} />
                              </AuthRequiredView>
                            ),
                          }
                        : { component: item.component })}
                    />
                  ))}
                  <Route component={NotFoundPage} />
                </Switch>
              </Container>
              <CookieBar />
              <Footer />
            </>
          )}
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
