import React, { Component } from "react";
import { connect } from "react-redux";
import { createAction } from "../../utils";
import { withRouter } from "react-router-dom";

@connect(({ users }) => ({ ...users }))
class Login extends Component {
  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const code = params.get("token");
    console.log("@@13: ", params);
    if (code) {
      // this.props.cookies.remove('user')
      console.log(code);
      this.props.dispatch(createAction("users/login")({ code }));
    }
    this.props.history.push("/home");
  }

  render() {
    return (
      <div>
        {/* <pre id="content">
                    loading..
                </pre> */}
      </div>
    );
  }
}

export default withRouter(Login);
