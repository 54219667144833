import React, { Component } from "react";
import { Media } from "react-bootstrap";
import i18n from "../../I18n";
import "./SelectedStore.scss";

class SelectedStore extends Component {
  render() {
    return (
      <div className="selected_store_section">
        <div className="couopn_detail_store_title headline">
          {i18n.t("coupon:applicableStores")}
        </div>
        <div>
          <div className="select_store_name body-2">{this.props.store.name}</div>
          <span className={`badge badge-primary ${this.props.store?.isOnlineStore ? "online-store-label" : "offline-store-label"} caption`}>
            {this.props.store?.isOnlineStore ? i18n.t("product:Online store") : i18n.t("product:Physical store")}
          </span>
          {!this.props.store?.isOnlineStore ?
            <Media className="store_media">
              <Media.Body>
                <img
                  alt="local icon"
                  className="location_icon"
                  src="/campaignDetail/locationIcon/locationOn.png"
                  srcSet="/campaignDetail/locationIcon/locationOn@2x.png 2x, /campaignDetail/locationIcon/locationOn@3x.png 3x"
                ></img>
                <label className="location body-1">{this.props.store.address}</label>
              </Media.Body>
            </Media> : null}
        </div>
      </div>
    );
  }
}

export default SelectedStore;
