import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./NoLevelSection.scss"

const mapStateToProps = (state) => {
  return {
    userName: state.users.displayName,
  };
};

const NoLevelSection = ({ userName = "", history }) => {
  return (
    <div className="profile-no-level-section">
      <div className={`level-section-content`}>
        <div className="d-flex level-section-header">
          <div>
            <div>{`${userName}`}</div>
          </div>
          <div className="qrcode-icon" onClick={() => history.push("/user/myCard")}>
            <img alt="icon" src="/profile/level/qr-code.png" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default connect(mapStateToProps)(withRouter(NoLevelSection));
