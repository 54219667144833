import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { withRouter } from 'react-router-dom';

import InboxContent from 'component/inbox/InboxContent';
import OfferFloatActionButton from 'component/offer/OfferFloatActionButton';

import './InboxDetail.scss';

function InboxDetail({ match }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const messageID = Number(match.params.id);

  return (
    <Container>
      <div className="inbox_container">
        <InboxContent messageId={messageID} isDetailPage={true} />
      </div>
      <OfferFloatActionButton />
    </Container>
  );
}

export default withRouter(InboxDetail);
