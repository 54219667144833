import React, { Component } from 'react';
import { Container, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { connect } from 'react-redux';

import LoadMoreSpinner from 'component/common/LoadMoreSpinner';
import CouponCard from 'component/coupon/CouponCard';
import NothingPage from 'component/error/Nothing';
import OfferFloatActionButton from 'component/offer/OfferFloatActionButton';

import { createAction } from 'utils';
import {
  getDocumentHeight,
  getScrollTop,
  getWindowHeight,
  isMobile,
} from 'utils/ScreenUtils';

import './CouponList.scss';

@connect(({ myCoupon, pullrefreshandloadmore, users }) => ({
  coupons: myCoupon.coupons,
  currentTabIndex: myCoupon.currentTabIndex,
  nextLink: myCoupon.nextLink,
  ...pullrefreshandloadmore,
  // MyCouponIsRefreshing, MyCouponIsLoadingMore, MyCouponHasMoreData
  language: users.language,
}))
class CouponList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryList: [
        'coupon:available',
        'campaignDetail:unavailable',
        'coupon:usedAndExpired',
      ],
      categoryKeyList: ['available', 'unavailable', 'obsolete'],
      modalShow: false,
      modalContent: '',
      modalTitle: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.bindHandleScroll);

    this.props.dispatch(createAction('myCoupon/refreshMyCoupon')());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.bindHandleScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(createAction('myCoupon/refreshMyCoupon')());
    }
  }

  selectTab = (selectedKey) => {
    this.props.dispatch(
      createAction('myCoupon/changeTab')({
        index: this.state.categoryKeyList.indexOf(selectedKey),
      }),
    );
  };

  bindHandleScroll = () => {
    const scrollTopLength = getScrollTop();
    const windowHeight = getWindowHeight();
    const documentHeight = getDocumentHeight();
    const scrollToBottom =
      scrollTopLength + windowHeight > documentHeight - 100;
    const isApiLoading =
      this.props.MyCouponIsRefreshing || this.props.MyCouponIsLoadingMore;

    if (this.props.nextLink && scrollToBottom && !isApiLoading) {
      this.props.dispatch(
        createAction('pullrefreshandloadmore/updateState')({
          MyCouponIsLoadingMore: true,
        }),
      );
      this.props.dispatch(createAction('myCoupon/loadMoreCoupon')());
    }
  };

  showLoading = () => {
    let isRefreshingName = 'MyCouponIsRefreshing';
    let isLoadMoreName = 'MyCouponIsLoadingMore';

    if (this.props[isRefreshingName] || this.props[isLoadMoreName]) {
      return <LoadMoreSpinner />;
    }
  };

  render() {
    return (
      <>
        <Container className="user-section-container coupon-section-container">
          <Tab.Container
            defaultActiveKey={
              this.state.categoryKeyList[this.props.currentTabIndex]
            }
          >
            <Tab.Content
              className="coupon_list_container"
              hidden={this.props['MyCouponIsRefreshing']}
            >
              {this.props.coupons.length <= 0 &&
              !this.props.MyCouponIsRefreshing ? (
                <NothingPage />
              ) : (
                <Masonry
                  breakpointCols={{ default: 4, 1023: 2 }}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {this.props.coupons.map((coupon, index) => (
                    <CouponCard
                      coupon={coupon.card}
                      key={index}
                      clickAction={(title, content) => {
                        this.setState({
                          modalShow: true,
                          modalTitle: title,
                          modalContent: content,
                        });
                      }}
                    />
                  ))}
                </Masonry>
              )}
            </Tab.Content>
          </Tab.Container>
          <Row className="coupon_spinner_row">{this.showLoading()}</Row>
          <Nav
            className="coupon_list_tab_bar"
            onSelect={(selectedKey) => this.selectTab(selectedKey)}
            activeKey={this.state.categoryKeyList[this.props.currentTabIndex]}
          >
            <Nav.Item>
              <Nav.Link eventKey="available" className='tag-menu-2'>
                {this.props.t('coupon:available')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="unavailable" className='tag-menu-2'>
                {this.props.t('campaignDetail:unavailable')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="obsolete" className='tag-menu-2'>
                {this.props.t('coupon:usedAndExpired')}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <OfferFloatActionButton />
        </Container>
        {/* TODO: add system pop-up in mobile view */}
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          className="custom_modal use_coupon_status_modal"
          centered
        >
          <Modal.Body className="d-flex-column">
            <div>
              <Modal.Title>{this.state.modalTitle}</Modal.Title>
              <p className={`custom_modal_content ${isMobile() ? "body-1" : "field-text"}`}>{this.state.modalContent}</p>
            </div>
            <div className="d-flex flex-right">
              <button
                className="right_btn button-large"
                onClick={() => {
                  this.setState({ modalShow: false });
                }}
              >
                {this.props.t('campaign_ok')}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(CouponList);
