import React, { Component } from "react";
import { Image, Row } from "react-bootstrap";
import "./Stock.scss";
import i18n from "../../I18n";
import CouponIcon from "../../assets/images/coupon_icon.svg";
import { enLocaleNumberFormatter } from "../../utils";
import { isPadOrMobile } from "utils/ScreenUtils";

class Stock extends Component {
  render() {
    return (
      <div className="stock_container">
        <Row>
          <div>
            <Image className="coupon-icon" src={CouponIcon}/>
          </div>
          <div className="left_stock">
            <div className={`stock_title ${isPadOrMobile() ? "body-2" : "headline"}`}>
              {i18n.t("campaignDetail:couponLeft")}
            </div>
            <div className="update_time body-2">
              {`${i18n.t("campaignDetail:lastUpdated")} `}
              {this.props.coupon.lastUpdateTime}
            </div>
          </div>
          <div className="right_stock">
            <div className="left_num title-medium-2">{enLocaleNumberFormatter(this.props.coupon.leftStock)}</div>
          </div>
        </Row>
      </div>
    );
  }
}

export default Stock;
