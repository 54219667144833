import React, { Component } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "./Footer.scss";
import i18n from "../../I18n";
import { withTranslation } from "react-i18next";

const LANGUAGE_MAPPING = {
  en: "English",
  "zh-Hant": "繁體中文",
  "zh-Hans": "简体中文",
};

@connect(({ users }) => ({
  language: users.language,
}))
class Footer extends Component {
  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="toggle_btn"
    >
      {children}
      <div className="language_left_section">
        <img
          alt="icon"
          src="/languageIcon/1753854-200.png"
          srcSet="/languageIcon/1753854-200@2x.png 2x, /languageIcon/1753854-200@3x.png 3x"
          className="languabe_icon"
        ></img>
        <label className="language_label tag-menu-1">
          {LANGUAGE_MAPPING[this.props.language]}
        </label>
      </div>

      <img
        src="/campaignDetail/downBtn/btn-down-arrow.png"
        srcSet="/campaignDetail/downBtn/btn-down-arrow@2x.png 2x, /campaignDetail/downBtn/btn-down-arrow@3x.png 3x"
        alt="language"
        className="down_arrow_btn"
      ></img>
    </button>
  ));

  changeLanguage = (lang) => {
    i18n.changeLanguage(lang);

    this.props.dispatch({
      type: "users/updateState",
      payload: { language: lang },
    });
  };

  render() {
    const isSelectStorePage =
      this.props.location.pathname.indexOf("/select_store") !== -1;

    const isHomepage =
      this.props.location.pathname.indexOf("/home") !== -1;

    const isOfferPage =
      this.props.location.pathname.indexOf("/offer/") !== -1;
    const isUseCashDollarPage =
      this.props.location.pathname.indexOf("/use_cash_dollar") !== -1;
    const hiddleFooter = isSelectStorePage || isUseCashDollarPage

    const footerBackgroundColorG8 = isHomepage || isOfferPage
    const currentYear = new Date().getFullYear();
    return (
      <>
        {hiddleFooter ? null : (
          <Container
            className={footerBackgroundColorG8 ? "footer_container background-greyG8" : "footer_container"}
          >
            <footer className="page_footer container">
              <Dropdown className="footer_left" hidden>
                <Dropdown.Toggle
                  as={this.CustomToggle}
                  id="dropdown-basic"
                  className="tag-menu-1"
                ></Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    className="language tag-menu-1"
                    onClick={() => {
                      this.changeLanguage("en");
                    }}
                  >
                    English
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="language tag-menu-1"
                    onClick={() => {
                      this.changeLanguage("zh-Hant");
                    }}
                  >
                    繁體中文
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="language tag-menu-1"
                    onClick={() => {
                      this.changeLanguage("zh-Hans");
                    }}
                  >
                    简体中文
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="footer_right">
                <Link className="footer_link tag-menu-1" to={{pathname:`${process.env.REACT_APP_TERM_CONDITIONS_LINK}?lang=${this.props.language}`}} target="_blank" rel="noopener noreferrer">
                  {this.props.t("sign_up_terms_conditions")}
                </Link>
                <Link className="footer_link tag-menu-1" to={{pathname:`${process.env.REACT_APP_PRIVACY_LINK}?lang=${this.props.language}`}} target="_blank" rel="noopener noreferrer">
                  {this.props.t("sign_up_privacy_policy")}
                </Link>
                <label className="copyright tag-menu-1">
                  © 2024-{currentYear} Kapok. {this.props.t("all_rights_reserved")}
                </label>
              </div>
            </footer>
          </Container>)
        }
      </>
    );
  }
}

export default withRouter(withTranslation()(Footer));
