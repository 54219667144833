import React from "react";
import Container from "react-bootstrap/Container";
import "./TransactionDetail.scss";
import TransactionContent from "../component/records/TransactionContent";
import OfferFloatActionButton from "component/offer/OfferFloatActionButton";

class TransactionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Container className="transaction_breadcrumbs"></Container>
        <Container className="transaction_container">
          <TransactionContent isDetailPage={true} />
        </Container>
        <OfferFloatActionButton />
      </div>
    );
  }
}

export default TransactionDetail;
