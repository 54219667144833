export const offerCategoryValidation = {
  type: "array",
  default: [],
  items: {
    type: "object",
    default: {},
    required: ["id", "name", "is_active", "display_priority", "translations"],
    properties: {
      id: {
        type: "string",
        default: "",
      },
      name: {
        type: "string",
        default: "",
      },
      is_active: {
        type: "boolean",
        default: true,
      },
      display_priority: {
        type: "integer",
        default: 0,
      },
      translations: {
        type: "array",
        default: [],
      },
    },
  },
};

export const checkOfferCategory = {
  type: "array",
  items: {
    type: "object",
    required: ["id", "name", "is_active", "display_priority", "translations"],
    properties: {
      id: {
        type: "string",
      },
      name: {
        type: "string",
      },
      is_active: {
        type: "boolean",
      },
      display_priority: {
        type: "integer",
      },
      translations: {
        type: "array",
      },
    },
  },
};
