import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import i18n from 'I18n';
import LoadMoreSpinner from 'component/common/LoadMoreSpinner';

import { createAction } from 'utils';
import { getMessageTime } from 'utils/DateTimeUtils';
import { processDeepLink } from 'utils/UrlUtils';

import './InboxContent.scss';

@connect(({ inboxMessages, users }) => ({
  ...inboxMessages,
  language: users.language,
}))
class InboxContent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadDetail();
  }

  componentDidUpdate(preProps) {
    if (preProps.messageId !== this.props.messageId) {
      this.loadDetail();
    }
    if (preProps.language !== this.props.language && this.props.isDetailPage) {
      this.loadDetail();
    }
  }

  loadDetail() {
    const messageID = Number(this.props.messageId);
    this.props.dispatch(
      createAction('inboxMessages/getMessageDetail')({
        messageID,
      }),
    );
  }

  linkButtonDidClicked(link, clickTrackUrl) {
    console.log('link: ', link, 'click_track_url: ', clickTrackUrl);
    if (clickTrackUrl) {
      fetch(`${clickTrackUrl}?track_only=1`);
    }
    const re = /com.ka-pok.app:\/\//;
    if (re.exec(link)) {
      const webUrl = processDeepLink(link);
      console.log('webUrl', webUrl);
      if (webUrl !== '') {
        this.props.history.push(webUrl);
      }
    } else {
      window.location.href = link;
    }
  }

  render() {
    const messageDetail = this.props.messageDetail;
    const messageDetailLoading = this.props.messageDetailLoading;
    return (
      <div
        className={
          this.props.isDetailPage ? 'inbox_detail_content' : 'inbox_content'
        }
      >
        {messageDetailLoading && <LoadMoreSpinner />}
        <div className="content_title headline">{messageDetail?.title}</div>
        <div className="content_date body-1">
          {messageDetail?.creation_date
            ? getMessageTime(messageDetail.creation_date)
            : ''}
        </div>
        {messageDetail?.photo_url && (
          <div className="content_image_container">
            <Image
              className="content_image"
              src={messageDetail.photo_url}
              placeholder="/inbox/placeholder_image.png"
            />
          </div>
        )}
        <div className="content_content field-text">{messageDetail?.content}</div>
        {messageDetail?.url && (
          <Button
            className="content_button button-large"
            onClick={() => this.linkButtonDidClicked(messageDetail.url, messageDetail.click_track_url)}
          >
            {i18n.t('seeMore')}
          </Button>
        )}
      </div>
    );
  }
}

export default withRouter(InboxContent);
