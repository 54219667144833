import i18n from 'I18n';

import { CouponAPIHelper } from 'services/CouponAPIHelper';

import { createAction, delay, getTranslationData } from 'utils';
import { subHeaderLink } from 'utils/CampaignUtil';
import {
  COUPON_FORMATS,
  COUPON_REDEMPTION_METHOD,
  COUPON_TEMPLATE_TYPE,
} from 'utils/CustomEnums';
import {
  convertDateToStamp,
  getCouponDetailExpireDate,
  getCouponEffectiveDate,
  getCouponListExpireDate,
  getCurrentDate,
  getLeftDays,
} from 'utils/DateTimeUtils';

// const COUPON_EXPIRE_DATE_FIXED_TIME = "T23:59:59+08:00";
export const USE_COUPON_STATUS = {
  TYPING: 0,
  SUCCESS: 1,
  FAILED: 2,
  NO_NETWORK: 3,
  INVALID: 4,
  BLACKOUT: 5,
  USED: 6,
  INACTIVE: 7,
  EXPIRED: 8,
  UNAVAIABLE: 9,
  STORE_ERROR: 10,
};
export const COUPON_STATUS_INFO = {
  [USE_COUPON_STATUS.SUCCESS]: {
    titleKey: 'coupon:useSuccessTitle',
    contentKey: 'coupon:useSuccessContent',
  },
  [USE_COUPON_STATUS.FAILED]: {
    titleKey: 'coupon:useFailedTitle',
    contentKey: 'coupon:useFailedContent',
  },
  [USE_COUPON_STATUS.USED]: {
    titleKey: 'coupon:useFailedTitle',
    contentKey: 'coupon:couponIsUsed',
  },
  [USE_COUPON_STATUS.INACTIVE]: {
    titleKey: 'coupon:useFailedTitle',
    contentKey: 'coupon:couponIsInactive',
  },
  [USE_COUPON_STATUS.EXPIRED]: {
    titleKey: 'coupon:useFailedTitle',
    contentKey: 'coupon:couponIsExpired',
  },
  [USE_COUPON_STATUS.UNAVAIABLE]: {
    titleKey: 'coupon:useFailedTitle',
    contentKey: 'coupon:couponIsUnavaiable',
  },
  [USE_COUPON_STATUS.BLACKOUT]: {
    titleKey: 'coupon:useFailedTitle',
    contentKey: 'coupon:useFailedBlackoutContent',
  },
  [USE_COUPON_STATUS.STORE_ERROR]: {
    titleKey: 'coupon:useFailedTitle',
    contentKey: 'coupon:storeError',
  },
  [USE_COUPON_STATUS.NO_NETWORK]: {
    titleKey: 'coupon:useFailedTitle',
    contentKey: 'ticket_no_internet_connection',
  },
  [USE_COUPON_STATUS.INVALID]: {
    titleKey: 'coupon:useFailedTitle',
    contentKey: 'coupon:useFailedContent',
  },
};
const COUPON_USE_RESULT = [
  {
    statusKey: USE_COUPON_STATUS.INVALID,
    errorDetail: 'Invalid staff code',
  },
  {
    statusKey: USE_COUPON_STATUS.USED,
    errorDetail: 'Coupon is used.',
  },
  {
    statusKey: USE_COUPON_STATUS.INACTIVE,
    errorDetail: 'Coupon has been disabled',
  },
  {
    statusKey: USE_COUPON_STATUS.BLACKOUT,
    errorDetail: 'The Coupon cannot be used today.',
  },
  {
    statusKey: USE_COUPON_STATUS.EXPIRED,
    errorDetail: 'Coupon is expired.',
  },
  {
    statusKey: USE_COUPON_STATUS.UNAVAIABLE,
    errorDetail: 'Coupon is not effective yet',
  },
  {
    statusKey: USE_COUPON_STATUS.STORE_ERROR,
    errorDetail: 'Invalid store QR code',
  },
]
const getCardData = (coupon, now) => {
  const effectiveDate = convertDateToStamp(coupon.effective_date);
  const expiryDate = convertDateToStamp(
    coupon.expiry_date
      ? // ? `${coupon.expiry_date}${COUPON_EXPIRE_DATE_FIXED_TIME}`
        `${coupon.expiry_date}`
      : '',
  );
  const redemptionDate = convertDateToStamp(
    coupon.date_of_earning || coupon.date_of_purchase || coupon.date_of_grant,
  );
  const translatedData = getTranslationData(coupon.template);

  const data = {
    id: coupon.id,
    coverPhotoUrl: translatedData.cover_photo_url,
    name: translatedData.name,
    brandIconUrl: coupon.template.brand?.icon_url,
    isNew: now - redemptionDate < 3600 * 1000 * 24,
    isUsed: coupon.is_used,
    unavailable: effectiveDate === null ? false : now < effectiveDate,
    displayDate:
      effectiveDate && now < effectiveDate
        ? getCouponEffectiveDate(effectiveDate)
        : getCouponListExpireDate(now, expiryDate),
    lessThan6Days:
      expiryDate === null ? false : getLeftDays(now, expiryDate) <= 6,
    isExpired: coupon.is_expired,
    ...getCouponOMOData(coupon),
  };
  return data;
};

const getStore = (coupon) => {
  const shown = 'store' in coupon && coupon.store !== null;
  if (!shown) {
    return {
      shown: false,
    };
  }
  const baseData = getTranslationData(coupon.store);
  return {
    shown: true,
    id: baseData.id,
    name: baseData.name,
    address: baseData.address,
    isOnlineStore: baseData?.is_online_store,
  };
};

const getBrand = (coupon) => {
  const { brand } = coupon.template;
  if (brand === null) {
    return { shown: false };
  }
  const baseData = getTranslationData(brand);
  console.log('@@94: ', baseData);
  return {
    shown: true,
    iconUrl: coupon.template.brand.icon_url,
    name: baseData.name,
    description: baseData.description,
  };
};

const getSerialNumber = (coupon) => {
  const brandID = coupon.template.brand ? coupon.template.brand.id : 0;
  const storeID = coupon.store ? coupon.store.id : 0;
  return `${coupon.template.id}-${brandID}-${storeID}-${coupon.id}`;
};

const getCouponOMOData = (coupon) => {
  let usageMedium = coupon.usage_medium;
  const userSelectedUsageMedium = coupon.user_selected_usage_medium;
  if (
    usageMedium === 'NOT_LIMIT' &&
    userSelectedUsageMedium !== 'NOT_SELECT' &&
    coupon?.template?.should_select_usage_medium
  ) {
    usageMedium = userSelectedUsageMedium;
  }
  const templateMediumType = coupon?.template?.medium_type;
  if (
    usageMedium === 'NOT_LIMIT' &&
    ['ONLINE', 'OFFLINE'].includes(templateMediumType)
  ) {
    usageMedium = templateMediumType;
  }
  let lookupFieldPrefix = '';
  if (usageMedium === 'ONLINE') {
    lookupFieldPrefix = 'online_';
  } else if (usageMedium === 'OFFLINE') {
    lookupFieldPrefix = 'offline_';
  }
  const isVirtualCoupon =
    coupon?.virtual_real_type === 'VIRTUAL' && coupon?.real_coupon === null;
  const shouldSelectStore =
    isVirtualCoupon && coupon?.template?.should_use_in_store;
  const shouldSelectOnlineOrOffline =
    isVirtualCoupon &&
    !shouldSelectStore &&
    coupon?.template?.show_online_offline_selection;
  const shouldCallSelectUsageMedium =
    shouldSelectOnlineOrOffline && coupon?.template?.should_select_usage_medium;
  return {
    usageMedium,
    lookupFieldPrefix,
    isVirtualCoupon,
    shouldSelectStore,
    shouldSelectOnlineOrOffline,
    shouldCallSelectUsageMedium,
  };
};

const getCouponDetailData = (coupon, now) => {
  const expiryDate = convertDateToStamp(coupon.expiry_date);
  const baseData = getTranslationData(coupon.template);
  const hasDetail = (baseData.detail_section_content || '').length > 0;
  const hasInstruction =
    (baseData.instruction_section_content || '').length > 0;
  const { lookupFieldPrefix, ...others } = getCouponOMOData(coupon);
  const redemptionMethod =
    coupon?.template?.[`${lookupFieldPrefix}redemption_method`];
  const couponTemplateType =
    coupon?.template?.[`${lookupFieldPrefix}coupon_template_type`];
  const inBlackoutPeriod = coupon?.template?.is_within_blackout_period;
  const isExpired = coupon.is_expired;
  const isUsed = coupon.is_used;
  const showMerchantScanQRCode =
    redemptionMethod ===
      COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE &&
    !isUsed &&
    !isExpired;
  const couponCodeFormat = coupon?.template?.[`${lookupFieldPrefix}formats`];
  let showScanableCouponCode = false;
  let showCopiableString =
    coupon?.template?.[`${lookupFieldPrefix}show_copiable_string`];
  if (couponTemplateType === COUPON_TEMPLATE_TYPE.IMPORT_EXISTING_COUPON) {
    if (redemptionMethod === COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE) {
      showScanableCouponCode = couponCodeFormat !== COUPON_FORMATS.TEXT;
    } else if (
      isUsed &&
      redemptionMethod !==
        COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE
    ) {
      showScanableCouponCode = couponCodeFormat !== COUPON_FORMATS.TEXT;
    }
    if (lookupFieldPrefix === 'online_') {
      showCopiableString = true;
    }
  }

  const data = {
    id: coupon.id,
    base: {
      images: baseData?.photos_url || [],
      name: baseData.name,
      serialNumber: getSerialNumber(coupon),
      displayDate: getCouponDetailExpireDate(now, expiryDate),
      code: coupon?.code,
      isUsed,
      isExpired,
      redemptionMethod,
      couponTemplateType,
      inBlackoutPeriod,
      showMerchantScanQRCode,
      showScanableCouponCode,
      couponCodeFormat,
      showCopiableString,
      barcodeFormat: coupon?.template?.[`${lookupFieldPrefix}barcode_format`],
      ...others,
    },
    store: getStore(coupon),
    brand: getBrand(coupon),
    instruction: {
      shown: hasInstruction,
      title:
        baseData.instruction_section_title || i18n.t('campaignDetail:howToUse'),
      content: baseData.instruction_section_content,
    },
    detail: {
      shown: hasDetail,
      title: baseData.detail_section_title || i18n.t('campaignDetail:details'),
      content: baseData.detail_section_content,
    },
  };
  return data;
};

const processCouponCardData = (item) => {
  const now = getCurrentDate();
  return {
    original: { ...item },
    card: getCardData(item, now),
  };
};

const processCouponDetailData = (coupon) => {
  const now = getCurrentDate();
  return getCouponDetailData(coupon, now);
};

const processCouponList = (couponList) => {
  const processedData = [];
  couponList.forEach((item, index) => {
    if (!item.is_forced_inactive) {
      processedData.push(processCouponCardData(item));
    }
  });
  return processedData;
};

export default {
  namespace: 'myCoupon',
  state: {
    coupons: [],
    nextLink: null,
    currentTabIndex: 0,
    detailDataMap: {},
    couponApplicableStoreMap: {},
    useCouponStatus: USE_COUPON_STATUS.TYPING, // 0: typing, 1: success, 2:falied, 3: no network, 4: invalid
    selectedTestCase: null,
    selectedStore: {},
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    addDetailData(state, { payload }) {
      const { couponID, data } = payload;
      return {
        ...state,
        detailDataMap: { ...state.detailDataMap, [couponID]: data },
      };
    },
    addCouponStores(state, { payload }) {
      const { couponID, stores } = payload;
      return {
        ...state,
        couponApplicableStoreMap: {
          ...state.couponApplicableStoreMap,
          [couponID]: stores,
        },
      };
    },
    resetState(state, { payload }) {
      return {
        coupons: [],
        currentTabIndex: 0,
        detailDataMap: {},
        useCouponStatus: USE_COUPON_STATUS.TYPING,
      };
    },
    updateUseCouponStatus(state, { payload }) {
      return {
        ...state,
        useCouponStatus: payload.status,
      };
    },
    updateCurrentTabIndex(state, { payload }) {
      console.log('@175 update tab key');
      return {
        ...state,
        currentTabIndex: payload,
      };
    },
    selectStore(state, { payload }) {
      return {
        ...state,
        selectedStore: payload,
      };
    },
  },
  effects: {
    refreshMyCoupon: [
      function* ({ payload }, { select, call, put }) {
        const currentTabIndex = yield select(
          (state) => state.myCoupon.currentTabIndex,
        );
        const selectedTestCase = yield select(
          (state) => state.myCoupon.selectedTestCase,
        );
        const customerID = yield select((state) => state.users.userInfo.uid);
        const startTime = Date.now();
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            MyCouponIsRefreshing: true,
            MyCouponHasMoreData: true,
          }),
        );
        const response = yield call(
          CouponAPIHelper.getMyCoupon,
          selectedTestCase,
          currentTabIndex,
          customerID,
        );
        const endTime = Date.now();
        if (endTime - startTime < 1500) {
          yield call(delay, 1500 - endTime + startTime);
        }
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            MyCouponIsRefreshing: false,
          }),
        );
        if (response.status === 200) {
          const { data, headers } = response;
          const nextLink = subHeaderLink(headers.link);
          if (nextLink) {
            yield put(
              createAction('pullrefreshandloadmore/updateState')({
                MyCouponHasMoreData: true,
              }),
            );
          } else {
            yield put(
              createAction('pullrefreshandloadmore/updateState')({
                MyCouponHasMoreData: false,
              }),
            );
          }

          yield put(
            createAction('updateState')({
              coupons: processCouponList(data),
              nextLink,
            }),
          );
        } else {
          yield put(
            createAction('pullrefreshandloadmore/updateState')({
              MyCouponHasMoreData: false,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    loadMoreCoupon: [
      function* ({ payload }, { select, put, call }) {
        const nextLink = yield select((state) => state.myCoupon.nextLink);
        if (nextLink === null || nextLink === '') {
          return;
        }
        const currentCoupons = yield select((state) => state.myCoupon.coupons);
        const currentTabIndex = yield select(
          (state) => state.myCoupon.currentTabIndex,
        );
        const selectedTestCase = yield select(
          (state) => state.myCoupon.selectedTestCase,
        );
        const customerID = yield select((state) => state.users.userInfo.uid);
        const startTime = Date.now();
        const response = yield call(
          CouponAPIHelper.getMyCoupon,
          selectedTestCase,
          currentTabIndex,
          customerID,
          nextLink,
        );
        const endTime = Date.now();
        if (endTime - startTime < 1000) {
          yield call(delay, 1000 - endTime + startTime);
        }
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            MyCouponIsLoadingMore: false,
          }),
        );
        if (response.status === 200) {
          const { data, headers } = response;
          const nextLink = subHeaderLink(headers.link);
          if (data.length >= 20) {
            yield put(
              createAction('pullrefreshandloadmore/updateState')({
                MyCouponHasMoreData: true,
              }),
            );
          } else {
            yield put(
              createAction('pullrefreshandloadmore/updateState')({
                MyCouponHasMoreData: false,
              }),
            );
          }
          const coupons = [...currentCoupons, ...processCouponList(data)];
          yield put(createAction('updateState')({ coupons, nextLink }));
        }
      },
      { type: 'takeLatest' },
    ],
    changeTab: [
      function* ({ payload }, { select, put }) {
        const currentTabIndex = yield select(
          (state) => state.myCoupon.currentTabIndex,
        );
        const { index } = payload;
        if (currentTabIndex === index) {
          return;
        }
        yield put(
          createAction('updateState')({
            coupons: [],
            currentTabIndex: index,
            nextLink: null,
          }),
        );
        yield put(createAction('refreshMyCoupon')());
      },
      { type: 'takeLatest' },
    ],
    prepareDetailData: [
      function* ({ payload }, { select, put, call }) {
        // using list data first, then fetch detail data from coupon detail api
        const { couponID } = payload;
        const customerID = yield select((state) => state.users.userInfo.uid);
        // const selectedTestCase = yield select((state) => state.testCase.selectedTestCase);
        const selectedTestCase = yield select(
          (state) => state.myCoupon.selectedTestCase,
        );
        let coupon = null;
        const allCoupons = yield select((state) => state.myCoupon.coupons);
        allCoupons.forEach((item, index) => {
          if (item.original.id === couponID) {
            coupon = processCouponDetailData({ ...item.original });
          }
        });
        if (coupon !== null) {
          // yield put(createAction("addDetailData")({ couponID, data: coupon }));
          yield put({
            type: 'addDetailData',
            payload: { couponID, data: coupon },
          });
        }

        const response = yield call(
          CouponAPIHelper.getCouponDetail,
          selectedTestCase,
          customerID,
          couponID,
        );
        if (response.status === 200) {
          coupon = processCouponDetailData(response.data);
          // yield put(createAction("addDetailData")({ couponID, data: coupon }));
          yield put({
            type: 'addDetailData',
            payload: { couponID, data: coupon },
          });
        }
      },
      { type: 'takeEvery' },
    ],
    getCouponApplicableStores: [
      function* ({ payload }, { select, put, call }) {
        const { couponID } = payload;
        const customerID = yield select((state) => state.users.userInfo.uid);
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            couponApplicableStoreIsLoading: true,
          }),
        );
        const response = yield call(
          CouponAPIHelper.getCouponApplicableStores,
          customerID,
          couponID,
        );

        if (response.status === 200) {
          const { data } = response;
          yield put({
            type: 'addCouponStores',
            payload: { couponID, stores: data },
          });
        }
        yield put(
          createAction('pullrefreshandloadmore/updateState')({
            couponApplicableStoreIsLoading: false,
          }),
        );
      },
      { type: 'takeLatest' },
    ],
    convertVirtualToReal: [
      function* ({ payload }, { select, put, call }) {
        const { couponID, storeID, usageMedium, afterSuccessAction } = payload;
        const response = yield call(
          CouponAPIHelper.convertCouponVirtualToReal,
          couponID,
          storeID,
          usageMedium,
        );
        if (response.status < 300) {
          const { data } = response;
          afterSuccessAction && afterSuccessAction(data);
        } else {
          const errorMessage = response.response?.data?.detail;
          yield put({
            type: 'navBars/updateState',
            payload: {
              toastShowing: {
                value: true,
                content: errorMessage,
              },
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],
    useCoupon: [
      function* ({ payload }, { put, call }) {
        const { couponID, code, qrCode } = payload;
        let result = null;
        try {
          const response = yield call(
            CouponAPIHelper.useCoupon,
            couponID,
            code,
            qrCode,
          );

          const { status } = response;
          if (status < 300) {
            result = USE_COUPON_STATUS.SUCCESS;
          }
        } catch (error) {
          if ('response' in error) {
            const errorDetail = error.response.data?.detail;
            const statusKey = COUPON_USE_RESULT.find(
              (item) => item.errorDetail === errorDetail,
            )?.statusKey;
            result = statusKey || USE_COUPON_STATUS.FAILED;
          } else {
            result = USE_COUPON_STATUS.FAILED;
          }
        }
        if (result !== null) {
          yield put(
            createAction('updateUseCouponStatus')({
              status: result,
            }),
          );
        }
        const afterActions = payload.afterActions || (() => {});
        yield afterActions();
      },
      { type: 'takeLatest' },
    ],
    fetchAllCoupons: [
      function* ({ payload }, { put, call, select }) {
        const customerID = yield select((state) => state.users.userInfo.uid);
        console.log('@416', customerID);
        const response = yield call(
          CouponAPIHelper.getAllMyCoupons,
          customerID,
        );
        if (response.status === 200) {
          const { data } = response;
          const coupons = processCouponList(data);
          yield put(createAction('updateState')({ coupons }));
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
