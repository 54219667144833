import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import "./BuyCouponModal.scss";

import { createAction } from "utils";
import PointRequiredSection from "./PointRequiredSection";

@connect(({ campaignDetail }) => ({ ...campaignDetail }))
class BuyCouponModal extends Component {

  componentDidMount() {
    this.props.dispatch(
      createAction("campaignDetail/getCampaignDetail")({
        campaignID: this.props.campaignID,
      })
    );
  }

  componentWillUnmount() {
  }

  render() {
    const campaginData = this.props?.[this.props.campaignID]?.data || {};
    const getModalContent = () => {
      return (
        <div>
          <PointRequiredSection
            coupon={campaginData?.coupon}
            hasStore={false}
            selectedStore={""}
            campaignID={this.props.campaignID}
            onClickSuccessBtn={() => {
              this.props.onHide()
            }}
          />
        </div>
      )
    }

    return (
      <Modal
        show={this.props.show || false}
        className={'buy-coupon-modal'}
        onHide={this.props.onHide || (() => { this.props.onHide() })}
        scrollable={false}
      >
        {getModalContent()}
      </Modal>
    );
  }
}

export default BuyCouponModal;
