import React, { useState, useEffect } from 'react';
import {  Image } from 'react-bootstrap';
import { QrReader } from 'fork-react-qr-reader';
import './Scanner.scss';
import i18n from '../../I18n';
// import closeIcon from '../../assets/images/close.png';

const Scanner = ({ show, handleClose, setQRCodeString, handleLoading, onError }) => {
  const [isScanSuccess, setIsScanSuccess] = useState(false)
  const [ableToClose, setAbleToClose] = useState(false)
  const [scanedCodeString, setScanedCodeString] = useState(null)
  const delayToClose = 1000

  useEffect(()=> {
    setTimeout(() => {
      setAbleToClose(true)
    }, delayToClose)
  },[])


  useEffect(()=> {
    if (isScanSuccess && scanedCodeString && ableToClose) {
      setTimeout(() => {
        setQRCodeString && setQRCodeString(scanedCodeString);
        handleClose();
        handleLoading && handleLoading();
      }, 500)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanSuccess, ableToClose, scanedCodeString])

  return (
    <>
      <div className="scanner-modal" style={{ display: show ? 'block' : 'none' }}>
        <QrReader
          constraints={{
            facingMode: 'environment',
          }}
          videoId={'video'}
          scanDelay={1500}
          videoStyle={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: show ? 'block' : 'none',
            objectFit: 'cover',
          }}
          onResult={(result, error) => {
            console.log('@54 scannerData: ', result?.text, error?.message);
            if (error?.message) {
              onError && onError(error?.message)
              handleClose();
            }
            const codeString = result?.text || '';
            if (!!codeString && show ) {
              setIsScanSuccess(true)
              setScanedCodeString(codeString)
            }
          }}
        />
        <div className="scanner-mask-container">
          <div className="scanner-vertical-mask">
            <button
              className={`scanner_close_btn`}
              onClick={handleClose}
            >
              <Image src={'/scanner/close.png'} />
            </button>
            <div className='scanner-text-container'>
              <label className='scanner-title title-small'>{i18n.t("campaignDetail:scanQrCode")}</label>
              <label className='scanner-subtitle field-text'>{i18n.t("campaignDetail:scannerDesc")}</label>
              <label className='scanner-subtitle scan-state field-text'>
                {isScanSuccess ? i18n.t("campaignDetail:scanSuccess") : i18n.t("campaignDetail:scanning")}
              </label>
            </div>
          </div>
          <div className="scanner-container">
            <div className="scanner-horizontal-mask"></div>
            <div className="scanner-box"></div>
            <div className="scanner-horizontal-mask"></div>
          </div>
          <div className="scanner-vertical-mask"></div>
        </div>
      </div>
    </>
  );
};

export default Scanner;
