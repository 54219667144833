import React, { Component } from "react";
import { Nav, NavDropdown, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import i18n from "I18n";

import "./CustomNavDropdown.scss"

export const navDropdownContainerHeight = 80
export const pageTopNavHeight = 69
export const pageTopAppBannerHeight = 66

@connect(({ users }) => ({
  showOpenAppBanner: users.showOpenAppBanner,
}))
class CustomNavDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLastScrollTop: 0,
      showNavDropdown: false,
      navStickyPeriodStart: 0,
      navContainerStyleTop: null,
      pageTopSectionHeight: props.showOpenAppBanner ? pageTopNavHeight + pageTopAppBannerHeight : pageTopNavHeight,
    };
    this.dropdownNavElement = ''
  }
  componentDidMount() {
    this.dropdownNavElement = document.getElementsByClassName("custom-nav-dropdown-container")[0]
    window.addEventListener("scroll", this.handlePageScroll)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handlePageScroll)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.showOpenAppBanner !== this.props.showOpenAppBanner) {
      this.setState((oldState) => {
        return this.props.showOpenAppBanner ? {
          pageTopSectionHeight: oldState.pageTopSectionHeight + pageTopAppBannerHeight,
          navContainerStyleTop: oldState.navContainerStyleTop + pageTopAppBannerHeight,
        } : {
          pageTopSectionHeight: oldState.pageTopSectionHeight - pageTopAppBannerHeight,
          navContainerStyleTop: oldState.navContainerStyleTop - pageTopAppBannerHeight,
        }
      }
      )
    }
  }

  handlePageScroll = () => {
    if (this.state.showNavDropdown) {
      this.setShowNavDropdown(false)
    }
    const currentPageScrollTop = window.scrollY
    let newState = { pageLastScrollTop: currentPageScrollTop }
    if (currentPageScrollTop === 0) {
      this.dropdownNavElement.classList.remove('dropdown-nav-sticky')
      newState = { ...newState, navStickyPeriodStart: 0, navContainerStyleTop: null }
    }
    if (currentPageScrollTop <= navDropdownContainerHeight) {
      newState = { ...newState, pageLastScrollTop: currentPageScrollTop }
      this.setState(newState)
      return;
    }

    this.dropdownNavElement.classList.add('dropdown-nav-sticky')
    if (currentPageScrollTop < this.state.pageLastScrollTop) {
      if (this.state.navStickyPeriodStart === 0) {
        newState = {
          ...newState,
          navStickyPeriodStart: currentPageScrollTop - navDropdownContainerHeight,
          navContainerStyleTop: this.state.pageTopSectionHeight - navDropdownContainerHeight
        }
      } else if (currentPageScrollTop < this.state.navStickyPeriodStart + navDropdownContainerHeight) {
        newState = {
          ...newState,
          navContainerStyleTop: Math.min(
            this.state.pageTopSectionHeight,
            this.state.pageTopSectionHeight + this.state.navStickyPeriodStart - currentPageScrollTop
          )
        }
      }
    }
    if (this.state.navStickyPeriodStart <= 0) {
      this.setState(newState)
      return;
    }


    if (currentPageScrollTop > this.state.navStickyPeriodStart &&
      currentPageScrollTop < this.state.navStickyPeriodStart + navDropdownContainerHeight) {
      newState = {
        ...newState,
        navContainerStyleTop: (
          this.state.pageTopSectionHeight - (currentPageScrollTop - this.state.navStickyPeriodStart)
        )
      }
    } else if (currentPageScrollTop > this.state.navStickyPeriodStart + navDropdownContainerHeight) {
      newState = {
        ...newState,
        navContainerStyleTop: null,
        navStickyPeriodStart: 0
      }
    } else if (currentPageScrollTop < this.state.navStickyPeriodStart) {
      newState = {
        ...newState,
        navContainerStyleTop: this.state.pageTopSectionHeight,
        navStickyPeriodStart: currentPageScrollTop
      }
    }
    this.setState(newState)
  }

  setShowNavDropdown = (value) => {
    this.setState({ showNavDropdown: value })
  }

  render() {
    return (
      <div
        style={
          this.state.navContainerStyleTop === null
            || this.state.navContainerStyleTop < (this.state.pageTopSectionHeight - navDropdownContainerHeight)
            ? {} : { top: this.state.navContainerStyleTop }
        }
        className={`custom-nav-dropdown-container ${this.props.className || ''}`}
      >
      <Nav
        activeKey={this.props.activeKey}
          className={`customer-nav-dropdown`}
      >
        {this.state.showNavDropdown ?
          <Image
            src="/myAccount/up-arrow.png"
            srcSet="/myAccount/up-arrow@2x.png 2x, /myAccount/up-arrow@3x.png 3x"
              className="custom-nav-dropdown-arrow-img"
          /> :
          <Image
            src="/myAccount/down-arrow.png"
            srcSet="/myAccount/down-arrow@2x.png 2x, /myAccount/down-arrow@3x.png 3x"
              className="custom-nav-dropdown-arrow-img"
          />
        }
        <NavDropdown
          id="nav-dropdown"
          title={this.props.navDropdownTitle}
          className="subheading"
          show={this.state.showNavDropdown}
          onToggle={(show) => {
            this.setShowNavDropdown(show)
          }}
        >
          {this.props.menuList.map((navItem, index) => {
            return (
              <LinkContainer to={navItem.pathname}>
                <NavDropdown.Item
                  key={index}
                  eventKey={navItem.activeKey}
                  onSelect={()=> {
                    if(navItem.external) {
                      window.open(`${navItem.pathname}?lang=${i18n.language}`, "_blank", "noopener noreferrer")
                    }
                  }}
                >
                  <div className={this.props.activeKey === navItem.activeKey ? "subheading" : "field-text"}>{navItem.title}</div>
                </NavDropdown.Item>
              </LinkContainer>
            );
          })}
        </NavDropdown>
      </Nav>
      </div>
    )
  }
}

export default CustomNavDropdown;
