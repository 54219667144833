import React from "react";
import CustomAlert from "../base/CustomAlert";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from "../../I18n";
import { ACTION_BUTTON_STATUS } from "./CheckInSection";

const mapStateToProps = (state, ownProps) => {
  return {
    actionButtonStatus: state.campaignDetail.actionButtonStatus,
  }
};


const StampCampaignCouponAlert = ({
  show,
  onHide,
  campaignID,
  rewardID,
  couponName,
  quantity,
  actionButtonStatus,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = actionButtonStatus === ACTION_BUTTON_STATUS.LOADING;
  const isAcquired = actionButtonStatus === ACTION_BUTTON_STATUS.MYCOUPON;

  const closeAlert = () => {
    onHide();
  };

  const leftBtnAction = () => {
    closeAlert();
  };

  const rightBtnAction = () => {
    if (isAcquired) {
      history.push('/user/myCoupon');
      closeAlert()
    } else {
      dispatch({
        type: 'campaignDetail/customerAcquireStampReward',
        payload: {
          campaignID,
          rewardID,
          stampRewardCouponQuantity: quantity,
        }
      });
    }
  }

  return (
    <CustomAlert
      backdrop="static"
      show={show}
      onHide={closeAlert}
      title={
        isAcquired
          ? i18n.t('campaignDetail:successfullyAcquired')
          : i18n.t('campaignDetail:confirmToAcquireCoupon', { count: quantity })
      }
      message={
        isAcquired
          ? ''
          : couponName
      }
      leftbtn={
        isAcquired
          ? i18n.t('campaign_ok')
          : i18n.t('message_cancel')
      }
      leftAction={leftBtnAction}
      rightbtn={
        isAcquired
          ? i18n.t('profile_my_coupons')
          : i18n.t('campaign_confirm')
      }
      rightAction={rightBtnAction}
      isLoading={isLoading}
    ></CustomAlert>
  )
}

export default connect(mapStateToProps)(StampCampaignCouponAlert);