import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import MyAccountChangePassword from 'component/account/MyAccountChangePassword';
import MyAccountDeleteAccount from 'component/account/MyAccountDeleteAccount';
import MyAccountDirectMarketing from 'component/account/MyAccountDirectMarketing';
import MyAccountInfoSection from 'component/account/MyAccountInfoSection';
import MyAccountMobile from 'component/account/MyAccountMobile';
import OfferFloatActionButton from 'component/offer/OfferFloatActionButton';

import { createAction } from 'utils';
import { isPadOrMobile } from 'utils/ScreenUtils';

import './MyAccount.scss';

@connect(({ users }) => ({
  language: users.language,
  updateUserInfoSuccess: users.updateUserInfoSuccess,
  updateDirectMarketing: users.updateDirectMarketing,
  resetPasswordSuccess: users.resetPasswordSuccess,
}))
class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
    };
    props.dispatch(createAction('users/fetchUserInfo')());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.props.dispatch(createAction('users/fetchUserInfo')());
    }
    if (
      prevProps.updateUserInfoSuccess !== this.props.updateUserInfoSuccess ||
      prevProps.updateDirectMarketing !== this.props.updateDirectMarketing ||
      prevProps.resetPasswordSuccess !== this.props.resetPasswordSuccess
    ) {
      this.props.dispatch({
        type: 'navBars/updateState',
        payload: {
          toastShowing: {
            value: true,
            toastIcon: '/coupon/copyCodeSuccessIcon/check.svg',
            content: this.props.t('successfullyUpdated'),
          },
        },
      });
    }
  }

  render() {
    return (
      <>
        {isPadOrMobile() ? (
          <MyAccountMobile />
        ) : (
          <Container className="user-section-container">
            <div>
              <MyAccountInfoSection />
              <MyAccountDirectMarketing />
              <MyAccountChangePassword />
              <MyAccountDeleteAccount />
            </div>
          </Container>
        )}
        <OfferFloatActionButton />
      </>
    );
  }
}

export default withTranslation()(MyAccount);
