// export { default as Storage } from '../Storage';

import i18n from "../I18n";
import { checkLanguages } from "./LanguageCheckUtil";
import numeral from "numeral";

export const delay = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const camelCase = (value) =>
  value.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

// export const createAction = (type) => (payload) => ({ type, payload });

const tokenRequiredAction = [
  "campaignList/homeFeatureListRefresh",
  "campaignList/homeFeatureListLoadMore",
  "campaignList/homeBannnerRefresh",
  "campaignList/homeOnlyForYouListRefresh",
  "users/fetchAvaiablePoint",
  "users/resetPassword",
  "users/updateUserInfo",
  "users/fetchUserInfo",
  "users/deleteUserAccount",
  "offerContainer/getOfferTabConfig",
  "myCoupon/refreshMyCoupon",
  "myCoupon/loadMoreCoupon",
  "myCoupon/useCoupon",
  "campaignDetail/getCampaignDetail",
  "inboxMessages/refreshMessages",
  "inboxMessages/messageLoadMore",
  "inboxMessages/markMessageRead",
  "activityLogs/fetchUserActivityLogs",
  "activityLogs/uerActivityLogsLoadMore",
  "transaction/refreshRewardClaimList",
  "transaction/loadMoreRewardClaimList",
  "transaction/deleteRewardClaim",
  "transaction/createRewardClaim",
  "transaction/uploadFile",
];
export const createAction = (type) => (payload) => {
  if (
    tokenRequiredAction.includes(type) &&
    !((payload || {}).fromRefresh || false)
  ) {
    console.log("catch refresh token");
    const newPayload = payload || {};
    newPayload.action = type;
    console.log("catch refresh token: ", newPayload);
    return { type: "users/refreshTokenIfNeed", payload: newPayload };
  }
  return { type, payload };
};

export const nFormatter = (num, digits) => {
  const si = {
    1: "",
    2: "K",
    3: "M",
    4: "G",
    5: "T",
    6: "P",
    7: "E",
  };

  // const numList = num.split(',');
  const demicalNum = num.split(".")[1].replace(/(0+)\b/gi, "");
  const numList = num.split(".")[0].split(",");

  let formatdNum;
  if (numList.length === 2 && numList[0].length === 1) {
    formatdNum = demicalNum ? num.replace(/0+?$/gi, "") : num.split(".")[0];
  } else {
    formatdNum = `${numList[0]}.${numList.slice(1).join("")}`;
    if (demicalNum) {
      formatdNum += demicalNum;
    } else {
      formatdNum = formatdNum.replace(/(0+)\b/gi, "");
    }
  }
  formatdNum = formatdNum.replace(/[.]$/gi, "");
  if (digits) {
    formatdNum = Math.floor(formatdNum * 100) / Math.pow(10, digits);
  }
  return formatdNum + si[numList.length];
};

export const numberFormatter = (num) => {
  if (num >= 1000000000) {
    return `${Math.floor(num / 1000000000)
      .toString()
      .replace(/\.0$/, "")}G`;
  }
  if (num >= 1000000) {
    return `${Math.floor(num / 1000000)
      .toString()
      .replace(/\.0$/, "")}M`;
  }
  if (num >= 10000) {
    return `${Math.floor(num / 1000)
      .toString()
      .replace(/\.0$/, "")}K`;
  }
  if (num >= 1000) {
    return Math.floor(num)
      .toString()
      .replace(/^(.{1})/, "$1,");
  }
  return num;
};

const EN_NAME_CHECKER = /[!a-zA-Z]+/;
export const getDisplayName = ({ nickname, firstName, lastName }) => {
  if (nickname !== undefined && nickname !== null) {
    return nickname;
  }
  if (EN_NAME_CHECKER.test(firstName)) {
    return `${firstName}`;
  }
  return `${lastName} ${firstName}`;
};

export const getTranslationData = (data, language = null) => {
  let currentLanguage = language;
  if (!language) {
    currentLanguage = i18n.language;
  }
  let baseData = data;

  (data?.translations || []).forEach((item, index) => {
    const translation = item;
    delete translation.id;
    if (currentLanguage === checkLanguages(item.language)) {
      // baseData = item;

      Object.keys(translation).forEach((key) => {
        const originalValue = baseData[key];
        const translationValue = translation[key];

        baseData[key] =
          translationValue && translationValue?.length
            ? translationValue ?? originalValue
            : originalValue;

        if (
          ["instruction_section_title", "detail_section_title"].indexOf(key) >=
          0
        ) {
          baseData[key] = translationValue || "";
        }
      });
    }
  });

  return baseData;
};

export const enLocaleNumberFormatter = (num) => {
  if (!num) {
    return 0;
  }
  const stringNumber = num.toString();
  const commaIndex = stringNumber.indexOf(".");
  if (commaIndex === -1) {
    // return i18n.toNumber(stringNumber, { precision: 0 });
    return numeral(num).format("0,0");
  }
  return numeral(num).format("0,0.0");
  // const stringNeedFormatting = stringNumber.substring(0, commaIndex);
  // const stringEnd = stringNumber.substring(commaIndex);
  // const stringFormated = i18n.toNumber(stringNeedFormatting, { precision: 0 });
  // return `${stringFormated}${stringEnd}`;
};

export const enLocaleTransactionNumberFormatter = (num) => {
  if (!num) {
    return '0.00';
  }
  return numeral(num).format("0,0.00");
};

export const enLocaleDollarFormatter = (s) => {
  const num = Number(s);
  if (num < 0) {
    return `-HK$ ${enLocaleTransactionNumberFormatter(Math.abs(num))}`;
  }
  return `HK$ ${enLocaleTransactionNumberFormatter(num)}`;
};

export const detectIsMobile = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const maxTouchPoints = navigator.maxTouchPoints;
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  const matchMobileResult = toMatch.some((matchItem) => {
    return userAgent.match(matchItem);
  });
  if (matchMobileResult || (platform === "MacIntel" && maxTouchPoints > 1)) {
    return true;
  } else {
    return false;
  }
};

export const getFileName = (url) => {
  const uri = new URL(url);
  return uri.pathname.match(/\/media\/(.*)/)[1];
};

export const arrayEqual = (arr1, arr2) => {
  if (
    arr1?.length === arr2?.length
    && arr1.every(function(u, i) {
        return u === arr2[i];
    })
  ) {
     return true;
  } else {
     return false;
  }
};

export const generateTakeLeading = (namespace) => {
  // Source: https://github.com/dvajs/dva/issues/1872
  return (patternOrChannel, saga) => [function* (effects) {
    const { call, take, put } = effects
    while (true) {
      const action = yield take(`${namespace}/${patternOrChannel}`);
      yield put({ type: '@@DVA_LOADING/SHOW', payload: { namespace, actionType: patternOrChannel } });
      yield call(saga, action, effects);
      yield put({ type: '@@DVA_LOADING/HIDE', payload: { namespace, actionType: patternOrChannel } });
    }
  }, { type: 'watcher' }]
}

export const getOuterSiteOrigin = (defaultOrigin = "") => {
  let outerOrigin = "";
  try {
    outerOrigin = window.parent.location.href;
  } catch (e) {
    outerOrigin = document.referrer;
  }
  return outerOrigin || defaultOrigin;
}
