import { MessageAPIHelper } from 'services/MessageAPIHelper';

import { createAction } from 'utils';
import { subHeaderLink } from 'utils/CampaignUtil';
import { getMessageTime } from 'utils/DateTimeUtils';
import { switchOneOfSelectedList } from 'utils/MultiChoiceUtils';
import {
  getDataWithDelay,
  hasMoreData,
  refresh,
} from 'utils/PullAndRefreshUtil';

const MESSAGE_LIST_KEY = 'MessageList';

function markMessage(messageForMark, message) {
  if (messageForMark.messageId === message.messageId) {
    return messageForMark;
  }
  return message;
}

function checkIsAllMessageRead(messages) {
  return messages.every((message) => message.isMessageRead);
}

function parseMessage(message) {
  const data = {
    messageId: message.id,
    messageTitle: message.title,
    messageContent: message.content,
    messageDate: getMessageTime(message.creation_date),
    isMessageRead: message.is_read,
    messageLink: message.url,
    messagePhotoUrl: message?.photo_url,
    messageOpenTrackUrl: message.open_track_url,
    messageClickTrackUrl: message.click_track_url,
  };
  return data;
}

function getMessageList(messages) {
  return messages.map((message) => parseMessage(message));
}

export default {
  namespace: 'inboxMessages',
  state: {
    isAllMessageRead: true,
    messageList: [],
    messageDetail: {},
    messageDetailLoading: false,
    activeMessageId: null,
    pageLink: '',
    unReadCount: -1,
    inboxIsEditing: false,
    inboxCheckedList: [],
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    resetMessage(state, { payload }) {
      return {
        ...state,
        ...payload,
        messageList: [],
        activeMessageId: null,
        pageLink: '',
        isAllMessageRead: true,
      };
    },

    assembleMessage(state, { payload }) {
      const messageList = getMessageList(payload.data);
      const { pageLink } = payload;
      const isAllMessageRead = checkIsAllMessageRead(messageList);
      return {
        ...state,
        ...payload,
        messageList,
        pageLink,
        isAllMessageRead,
      };
    },

    messageLoadMoreAssemble(state, { payload }) {
      const messageList = getMessageList(payload.data);
      const { pageLink } = payload;
      let { isAllMessageRead } = state;
      if (isAllMessageRead) {
        isAllMessageRead = checkIsAllMessageRead(messageList);
      }
      return {
        ...state,
        ...payload,
        messageList: [...state.messageList, ...messageList],
        pageLink,
        isAllMessageRead,
      };
    },

    markMessageAsRead(state, { payload }) {
      const { message } = payload;
      message.isMessageRead = true;
      const oldMessages = state.messageList;
      const newMessageList = oldMessages.map((item) =>
        markMessage(message, item),
      );
      const isAllMessageRead = checkIsAllMessageRead(newMessageList);
      return {
        ...state,
        ...payload,
        messageList: newMessageList,
        isAllMessageRead,
      };
    },

    switchMessageChecked(state, { payload }) {
      const { messageId } = payload;
      const { inboxCheckedList } = state;
      const newList = switchOneOfSelectedList(inboxCheckedList, messageId);
      return {
        ...state,
        inboxCheckedList: newList,
      };
    },

    clearMessageChecked(state, { payload }) {
      return {
        ...state,
        inboxCheckedList: [],
      };
    },
  },

  effects: {
    refreshMessages: [
      function* ({ payload }, { call, put, select }) {
        const userId = yield select((state) => state.users.userInfo.uid);
        const oldData = yield select(
          (state) => state.inboxMessages.messageList,
        );
        const hasOldData = oldData && oldData.length;
        yield put(createAction('resetMessage')());
        const messageApiServiceWithArgs = [
          MessageAPIHelper.getMessages,
          {
            userId,
            pageLink: null,
          },
        ];
        function* onGetDataSuccess(response, nextLink) {
          const { data } = response;
          yield put(
            createAction('assembleMessage')({
              data,
              pageLink: nextLink,
            }),
          );
        }
        yield refresh(
          MESSAGE_LIST_KEY,
          hasOldData,
          messageApiServiceWithArgs,
          onGetDataSuccess,
        );
        yield put(createAction('getUnreadMessageCount')());
      },
      { type: 'takeLatest' },
    ],

    messageLoadMore: [
      function* (_action, { put, select }) {
        const pageLink = yield select((state) => state.inboxMessages.pageLink);
        const data = yield getDataWithDelay(
          MessageAPIHelper.getMessages,
          { pageLink },
          MESSAGE_LIST_KEY,
          true,
        );
        let nextLink = '';
        if (data?.status === 200) {
          nextLink = subHeaderLink(data.headers.link);
          yield put(
            createAction('messageLoadMoreAssemble')({
              data: data.data,
              pageLink: nextLink,
            }),
          );
        }
        yield hasMoreData(MESSAGE_LIST_KEY, nextLink);
        yield put(createAction('getUnreadMessageCount')());
      },
      { type: 'takeLatest' },
    ],

    getMessageDetail: [
      function* ({ payload }, { call, put, select }) {
        const userID = yield select((state) => state.users.userInfo.uid);
        const { messageID } = payload;
        yield put(
          createAction('updateState')({
            messageDetail: {},
            messageDetailLoading: true,
          }),
        );
        const response = yield call(MessageAPIHelper.getMessageDetail, {
          userID,
          messageID,
        });
        console.log(response);
        if (response.status === 200) {
          yield put(
            createAction('updateState')({
              messageDetail: response.data,
              messageDetailLoading: false,
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    markMessageRead: [
      function* ({ payload }, { call, put, select }) {
        const userId = yield select((state) => state.users.userInfo.uid);
        const { message } = payload;
        yield call(MessageAPIHelper.markMessageRead, {
          userId,
          messageId: message.messageId,
        });

        yield put(createAction('markMessageAsRead')({ message }));
        yield put(createAction('getUnreadMessageCount')());
      },
      { type: 'takeEvery' },
    ],

    removeSelectedMessages: [
      function* ({ payload }, { call, put, select }) {
        const userId = yield select((state) => state.users.userInfo.uid);
        const inboxCheckedList = yield select(
          (state) => state.inboxMessages.inboxCheckedList,
        );
        if (inboxCheckedList.length === 0) {
          yield put(
            createAction('updateState')({
              inboxIsEditing: false,
            }),
          );
          yield put(createAction('clearMessageChecked')());
          return;
        }
        const response = yield call(MessageAPIHelper.deleteMessages, {
          userId,
          messageIds: inboxCheckedList,
        });
        if (response.status === 204) {
          yield put(
            createAction('updateState')({
              inboxIsEditing: false,
            }),
          );
          yield put(createAction('clearMessageChecked')());
          yield put(createAction('refreshMessages')());
          yield put(
            createAction('navBars/updateState')({
              toastShowing: {
                value: true,
                content: 'Delete successfully',
                toastIcon: '/coupon/copyCodeSuccessIcon/check.svg',
              },
            }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    getUnreadMessageCount: [
      function* (_action, { put, select, call }) {
        const userId = yield select((state) => state.users.userInfo.uid);
        const response = yield call(MessageAPIHelper.getUnreadMessageCount, {
          userId,
        });

        if (response.status <= 200) {
          yield put({
            type: 'updateState',
            payload: { unReadCount: response.data.unread_count },
          });
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
