import React, { useState } from 'react';

import './CustomButton.scss';

const CustomButton = ({
  btnClicked = () => {},
  disabled,
  text,
  className = '',
}) => {
  return (
    <button
      className={`update-button button-large ${className || ''}`}
      onClick={btnClicked}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default CustomButton;
