export const campaignValidation = {
  type: "array",
  default: [],
  items: {
    type: "object",
    default: {},
    required: [
      "id",
      "type",
      "cover_photo_url",
      "name",
      "translations",
      "end_date",
      "coupon_campaign_type_required_points",
      "brand_section",
    ],
    properties: {
      id: {
        type: "string",
        default: "",
      },
      type: {
        type: ["string"],
        default: "",
      },
      cover_photo_url: {
        type: ["string"],
        default: "",
      },
      is_featured: {
        type: "boolean",
        default: false,
      },
      is_only_for_you: {
        type: "boolean",
        default: false,
      },
      square_cover_photo: {
        type: ["string"],
        default: "",
      },
      name: {
        type: ["string"],
        default: "",
      },
      translations: {
        type: "array",
        default: [],
      },
      end_date: {
        type: "string",
        default: "",
      },
      brand_section: {
        type: "object",
        default: { brand: {} },
        properties: {
          should_show_brand_section: {
            type: "boolean",
            default: false,
          },
          brand: {
            type: "object",
            default: {},
          },
        },
      },
      coupon_campaign_type_required_points: {
        type: "number",
        default: 0,
      },
    },
  },
};

export const campaignCheck = {
  type: "array",
  items: {
    type: "object",
    required: [
      "id",
      "type",
      "cover_photo_url",
      "name",
      "translations",
      "end_date",
      "coupon_campaign_type_required_points",
    ],
    properties: {
      id: {
        type: "string",
      },
      type: {
        type: ["string"],
      },
      cover_photo_url: {
        type: ["string"],
      },
      is_featured: {
        type: "boolean",
      },
      is_only_for_you: {
        type: "boolean",
      },
      square_cover_photo: {
        type: ["string"],
      },
      name: {
        type: ["string"],
      },
      translations: {
        type: "array",
      },
      end_date: {
        type: "string",
      },
      brand_section: {
        type: "object",
        properties: {
          should_show_brand_section: {
            type: "boolean",
          },
          brand: {
            type: "object",
          },
        },
      },
      coupon_campaign_type_required_points: {
        type: "number",
      },
    },
  },
};
