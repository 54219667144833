export const WEB_DOMAIN =
  process.env.REACT_APP_WEB_DOMAIN || 'https://web.aillia.motherapp.com';
export const DOWNLOAD_APP_DOMAIN =
  process.env.REACT_APP_DOWNLOAD_APP_DOMAIN ||
  'https://download.aillia.motherapp.cn';
export const APP_NAME = process.env.REACT_APP_NAME || 'MA-Loyalty';
export const NO_LEVEL = process.env.REACT_APP_NO_LEVEL === 'true';
export const ONLY_HAVE_WEBSITE =
  process.env.REACT_APP_ONLY_HAVE_WEBSITE === 'true';
export const NO_SHOPIFY = process.env.REACT_APP_NO_SHOPIFY === 'true';
export const NO_WEB_APP = process.env.REACT_APP_NO_WEBAPP === 'true';
export const QRCODE_SHOW_RAW_MEMBERSHIP_ID =
  process.env.REACT_APP_QRCODE_SHOW_RAW_MEMBERSHIP_ID === 'true';
export const SHOPIFY_API_HOST =
  process.env.REACT_APP_SHOPIFY_API_HOST || 'https://ailliademo.myshopify.com';
export const NO_TRANSACTION_HISTORY = process.env.REACT_APP_NO_TRANSACTION_HISTORY === 'true';
