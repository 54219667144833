import React, { Component, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';

import i18n from 'I18n';

import './GenderPicker.scss';

const GenderPicker = ({ customClass = '', sections, value, selectValue }) => {
  const [selectedGender, setSelectedGender] = useState(value);
  const [showGenderOptions, setShowGenderOptions] = useState(false);

  const displayValue = sections.filter(
    (item) => item.pk === selectedGender
  ).map(
    (item) => item?.name
  )?.[0];

  const GenderInput = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className={`gender-picker-box ${customClass}`}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <input
        autoComplete={false}
        className="account-input-with-downarrow"
        placeholder={i18n.t('personal_info_i_am')}
        value={displayValue}
      />
      {showGenderOptions ? (
        <img
          alt="down"
          className="account-down-arrow"
          src="/myAccount/up-arrow.png"
        />
      ) : (
        <img
          alt="down"
          className="account-down-arrow"
          src="/myAccount/down-arrow.png"
          srcSet="/myAccount/down-arrow@2x.png 2x, /myAccount/down-arrow@3x.png 3x"
        />
      )}
    </div>
  ));
  return (
    <div>
      <Dropdown
        style={{ width: '100%' }}
        onToggle={(isOpen) => {
          setShowGenderOptions(isOpen);
        }}
        onSelect={(gender) => {
          if (gender === selectedGender) {
            return;
          }
          selectValue(gender);
          setSelectedGender(gender);
        }}
      >
        <Dropdown.Toggle as={GenderInput} />
        <Dropdown.Menu className="gender-dropdown">
          {sections?.map((item) => (
            <Dropdown.Item
              className="gender-dropdown-item field-text"
              eventKey={item?.pk || ''}
              active={selectedGender === item?.pk}
            >
              {item?.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default GenderPicker;
