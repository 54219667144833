import React, { Component } from 'react';
import { Container, Modal } from 'react-bootstrap';
import ReactCodeInput from 'react-code-input';
import { connect } from 'react-redux';

import i18n from 'I18n';

import { COUPON_STATUS_INFO, USE_COUPON_STATUS } from 'models/MyCoupon';

import { createAction } from 'utils';
import { detectIsMobile } from 'utils';

import './UseCoupon.scss';
import CustomAlert from 'component/base/CustomAlert';

@connect(({ myCoupon }) => ({ ...myCoupon }))
class UseCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      isFocusInput: false,
    };
    this.couponID = props.location.state.couponID;
    this.isMobile = detectIsMobile();
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.isMobile) {
      document.body.addEventListener('mouseup', this.handleMouseUp);
    }
  };

  componentWillUnmount = () => {
    if (this.isMobile) {
      document.body.removeEventListener('mouseup', this.handleMouseUp);
    }
  };

  handleMouseUp = () => {
    const focusElement = document.activeElement;
    if (focusElement.tagName.toLowerCase() === 'input') {
      this.setState({ isFocusInput: true });
    } else if (this.state.isFocusInput) {
      window.scrollTo(0, 0);
      this.setState({ isFocusInput: false });
    }
  };

  codeInputOnChange = (text) => {
    if (text.length === 6) {
      this.props.dispatch(
        createAction('myCoupon/useCoupon')({
          couponID: this.couponID,
          code: text,
          afterActions: () =>
            this.setState({
              modalShow: true,
            }),
        }),
      );
    }
  };

  modalBody = (titleKey, contentKey, buttonAction = () => {}) => {
    return (
      <CustomAlert
        show={this.state.modalShow}
        onHide={() => this.setState({ modalShow: false })}
        title={i18n.t(titleKey)}
        message={i18n.t(contentKey)}
        leftbtn={''}
        isLoading={!titleKey && !contentKey}
        rightbtn={i18n.t('campaign_ok')}
        rightAction={() => {
          buttonAction();
        }}
      />
    );
  };

  resetCodeInput = () => {
    this.props.dispatch(
      createAction('myCoupon/updateUseCouponStatus')({
        status: USE_COUPON_STATUS.TYPING,
      }),
    );
    this.setState({ modalShow: false });
  };

  showUseCouponStatusModal = () => {
    const statusKey = this.props.useCouponStatus;
    const titleKey = COUPON_STATUS_INFO?.[statusKey]?.titleKey;
    const contentKey = COUPON_STATUS_INFO?.[statusKey]?.contentKey;
    let buttonAction = null;
    if (statusKey === USE_COUPON_STATUS.SUCCESS) {
      buttonAction = () => {
        this.props.dispatch(createAction('myCoupon/changeTab')({ index: 2 }));
        this.resetCodeInput();
        this.props.history.push('/user/myCoupon');
      };
    } else {
      buttonAction = () => {
        this.resetCodeInput();
        this.props.history.goBack();
      };
    }
    return this.modalBody(titleKey, contentKey, buttonAction);
  };

  render() {
    return (
      <Container className="use_couopn_container">
        {/* <Row className="use_coupon_back_row">
          <button
            onClick={() => this.props.history.goBack()}
            className="coupon_back_btn"
          >
            <img
              alt="back"
              src="/campaignDetail/backBtn/btn-back.png"
              srcSet="/campaignDetail/backBtn/btn-back@2x.png 2x, /campaignDetail/backBtn/btn-back@3x.png 3x,"
            />
          </button>
        </Row> */}
        <div>
          <p className="use_coupon_title title-medium-2">{i18n.t('coupon:useCoupon')}</p>
          <p className="use_coupon_message body-1">
            {i18n.t('campaign_simply_allow_enter_pin')}
          </p>
          <ReactCodeInput
            autoFocus={false}
            type="number"
            inputMode="numeric"
            fields={6}
            inputStyle={{
              color: '$greyG3',
            }}
            className={`code_input_container`}
            onChange={(text) => {
              this.codeInputOnChange(text);
            }}
          />
        </div>
        {this.showUseCouponStatusModal()}
      </Container>
    );
  }
}

export default UseCoupon;
