import React, { Component } from 'react';
import './LoadMoreSpinner.scss'


class LoadMoreSpinner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alertStatus: props.status
        }
    }
    render() {
        return (
            <div className="spinner_container">
                <div className="ball_bounce1"></div>
                <div className="ball_bounce2"></div>
                <div className="ball_bounce3"></div>
            </div>
        )    
    }
}

export default LoadMoreSpinner;