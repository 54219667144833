import React, { Component } from "react";
import PointRequiredSection from "./PointRequiredSection";
import { Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import "./DetailRedeem.scss";
import { withRouter } from "react-router-dom";
import { createAction } from "utils";

@connect((campaignDetail) => ({ ...campaignDetail }))
class DetailRedeem extends Component {
  constructor(props) {
    super(props);
    this.campaignID = this.props.history.location.state?.campaignID
      || this.props.match.params?.id;

  }
  componentDidMount() {
    this.props.dispatch(
      createAction("campaignDetail/getCampaignDetail")({
        campaignID: this.campaignID,
      })
    );
  }

  componentWillUnmount() {
  }

  render() {
    const campaign = this.props.location.state.campaign
      || this.props.campaignDetail?.[this.campaignID]?.data;

    return (
      <Container className="detail_container">
        {/* <Row className="detail_row">
          <button
            className="back_btn"
            onClick={() => this.props.history.goBack()}
          >
            <img
              alt="go back"
              src="/campaignDetail/backBtn/btn-back.png"
              srcSet="/campaignDetail/backBtn/btn-back@2x.png 2x, /campaignDetail/backBtn/btn-back@3x.png 3x"
            />
          </button>
        </Row> */}
        <Row className="detail_row">
          <PointRequiredSection
            coupon={campaign?.coupon}
            campaignID={this.campaignID}
            hasStore={false}
            fromPath={this.props.location.state?.fromPath}
            goBackOnSuccess={this.props.location.state?.goBackOnSuccess}
          ></PointRequiredSection>
        </Row>
      </Container>
    );
  }
}

export default withRouter(DetailRedeem);
