import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import "./LevelRuleMobile.scss";
import { Table, Container } from "react-bootstrap";
import { connect } from "react-redux";

@connect(({ users }) => ({
  levelConfig: users.levelConfig,
}))
class LevelRuleMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getSplitLine = () => {
    return <div className="split-line"/>
  }

  render() {
    return (
      <Container className="level-rule-mobile-page-container">
        <div className="level-rule-mobile-page">
          <div className="level-rule-title title-small">{this.props.t("levelRules")}</div>
          <div>
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesIntroductionTitle")}
              </div>
              <div className="level-rule-detail-message body-1">
                {this.props.t("levelRulesIntroductionText")}
              </div>
            </div>
            {this.getSplitLine()}
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesValidityTitle")}
              </div>
              <div className="level-rule-detail-message body-1">
                {this.props.t("levelRulesValidityText")}
              </div>
            </div>
            {this.getSplitLine()}
            <div className="level-rule-item-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesRetainDowngradeTitle")}
              </div>
              <div className="level-rule-detail-message body-1">
                {this.props
                  .t("levelRulesRetainDowngradeText")
                  .split("\n")
                  .map((content) => (
                    <p>{content}</p>
                  ))}
              </div>
            </div>
            {this.getSplitLine()}
            <div className="level-rule-item-section last-section">
              <div className="level-rule-second-title subheading">
                {this.props.t("levelRulesTableTitle")}
              </div>
              {/* <div className="level-rule-detail-message body-1">
                {this.props.t("levelRulesTableText")}
              </div> */}
              <div className="level-rule-table">
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="tag-menu-2">{this.props.t("level")}</th>
                      <th className="tag-menu-2">{"Total spending"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.levelConfig.map((levelRule) => {
                      return (
                        <tr key={levelRule.id}>
                          <td className="level-name tag-menu-1">{levelRule.name}</td>
                          <td className="tag-menu-1">{this.props.t("HKD", { TPE: levelRule.targetTPE })}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Container>

    );
  }
}

export default withRouter(withTranslation()(LevelRuleMobile));
