import React, { Component } from 'react';
import { Container, Media, Modal } from 'react-bootstrap';
import ReactCodeInput from 'react-code-input';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import i18n from 'I18n';

import Scanner from 'component/campaign/Scanner';
import { COUPON_STATUS_INFO, USE_COUPON_STATUS } from 'models/MyCoupon';

import { createAction, detectIsMobile } from 'utils';
import { COUPON_REDEMPTION_METHOD } from 'utils/CustomEnums';

import './StaffUse.scss';
import CustomAlert from 'component/base/CustomAlert';
import { isMobile, isPadOrMobile } from 'utils/ScreenUtils';

@connect(({ myCoupon }) => ({ ...myCoupon }))
class StaffUse extends Component {
  constructor(props) {
    super(props);
    this.couponID = this.props.match.params?.id;
    this.couponDetail = this.props.detailDataMap[this.couponID];
    this.redemptionMethod =
      this.props.detailDataMap[this.couponID].base.redemptionMethod;
    this.state = {
      modalShow: false,
      useCouponStatusModal: false,
      inputCouponCode: '',
      showNoCameraModal: false,
      showScanner: false,
      qrCodeString: null,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.props.dispatch(
        createAction('myCoupon/prepareDetailData')({ couponID: this.couponID }),
      );
    }
    if (this.state.qrCodeString !== null) {
      const qrCodeString = this.state.qrCodeString;
      this.setState({
        qrCodeString: null,
      });
      this.props.dispatch(
        createAction('myCoupon/useCoupon')({
          couponID: this.couponID,
          qrCode: qrCodeString,
          afterActions: () =>
            this.setState({
              useCouponStatusModal: true,
            }),
        }),
      );
    }
  }

  useButtonClicked = () => {
    if (this.couponDetail.base.inBlackoutPeriod) {
      this.props.dispatch(
        createAction('myCoupon/updateUseCouponStatus')({
          status: USE_COUPON_STATUS.BLACKOUT,
        }),
      );
      this.setState({
        modalShow: false,
        useCouponStatusModal: true,
      });
      return;
    }
    this.props.dispatch(
      createAction('myCoupon/updateUseCouponStatus')({
        status: USE_COUPON_STATUS.TYPING,
      }),
    );
    if (isPadOrMobile()) {
      this.props.history.push('/use_coupon', {
        couponID: this.couponID,
      });
    } else {
      this.setState({
        modalShow: true,
      });
    }
  };

  // codeInputOnChange = (text) => {
  //   if (text.length === 6) {
  //     this.props.dispatch(
  //       createAction("myCoupon/useCoupon")({
  //         couponID: this.couponID,
  //         code: text,
  //       })
  //     );
  //     this.setState({
  //       modalShow: false,
  //       useCouponStatusModal: true,
  //     });
  //   }
  // };

  handleUseCoupon = () => {
    if (this.state.inputCouponCode.length === 6) {
      this.props.dispatch(
        createAction('myCoupon/useCoupon')({
          couponID: this.couponID,
          code: this.state.inputCouponCode,
        }),
      );
      this.setState({
        modalShow: false,
        useCouponStatusModal: true,
      });
    }
  };

  modalBody = (titleKey, contentKey, buttonAction = () => {}) => {
    return (
      <CustomAlert
        show={this.state.useCouponStatusModal}
        onHide={() => this.setState({ useCouponStatusModal: false })}
        title={i18n.t(titleKey)}
        message={i18n.t(contentKey)}
        leftbtn={''}
        isLoading={!titleKey && !contentKey}
        rightbtn={i18n.t('campaign_ok')}
        rightAction={() => {
          buttonAction();
        }}
      />
    );
  };

  resetCodeInput = () => {
    this.props.dispatch(
      createAction('myCoupon/updateUseCouponStatus')({
        status: USE_COUPON_STATUS.TYPING,
      }),
    );
    this.setState({ useCouponStatusModal: false, inputCouponCode: '' });
  };

  showUseCouponStatusModal = () => {
    const statusKey = this.props.useCouponStatus;
    const titleKey = COUPON_STATUS_INFO?.[statusKey]?.titleKey;
    const contentKey = COUPON_STATUS_INFO?.[statusKey]?.contentKey;
    let buttonAction = null;
    if (statusKey === USE_COUPON_STATUS.SUCCESS) {
      buttonAction = () => {
        this.props.dispatch(createAction('myCoupon/changeTab')({ index: 2 }));
        this.resetCodeInput();
        this.props.history.push('/user/myCoupon');
      };
    } else {
      buttonAction = () => {
        this.resetCodeInput();
      };
    }
    return this.modalBody(titleKey, contentKey, buttonAction);
  };

  noCameraModal = () => {
    return (
      <Modal
        show={this.state.showNoCameraModal}
        onHide={() => this.setState({ showNoCameraModal: false })}
        className="use_coupon_modal"
        centered
      >
        <Modal.Body className="use_coupon_modal_content d-flex-column">
          <div>
            <p className="use_coupon_title title-medium-1">
              {i18n.t('campaignDetail:noCamera')}
            </p>
          </div>
          <div className="button_group">
            <button
              className="right_btn button-large"
              onClick={() => {
                this.setState({ showNoCameraModal: false });
                this.props.dispatch(
                  createAction('myCoupon/updateUseCouponStatus')({
                    status: USE_COUPON_STATUS.TYPING,
                  }),
                );
              }}
            >
              {i18n.t('campaign_ok')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    const couponStore = !this.couponDetail?.store;
    const showStaffUse =
      !couponStore?.isOnlineStore &&
      this.couponDetail?.base?.usageMedium !== 'ONLINE';

    if (
      this.redemptionMethod !==
        COUPON_REDEMPTION_METHOD.USERS_SCAN_STORE_QR_CODE &&
      !showStaffUse
    ) {
      return <></>;
    }
    return (
      <Container className="staff_use_container">
        {this.redemptionMethod ===
        COUPON_REDEMPTION_METHOD.USERS_SCAN_STORE_QR_CODE ? (
          <>
            <div className="use_coupon_btn_group">
              <button
                className="staff_use_btn button-large"
                onClick={() => {
                  if (detectIsMobile()) {
                    this.setState({ showScanner: true });
                  } else {
                    this.setState({
                      showNoCameraModal: true,
                    });
                  }
                }}
                disabled={this.props.useCouponStatus}
              >
                {i18n.t('coupon:scanQRCode')}
              </button>
              {showStaffUse && (
                <button
                  className="staff_use_btn bottom_btn button-large"
                  onClick={this.useButtonClicked}
                >
                  {i18n.t('coupon:useCouponText')}
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <Media>
              <img
                alt="icon"
                src="/coupon/staffUseIcon/btn-staff-use-copy.png"
                srcSet="/coupon/staffUseIcon/btn-staff-use-copy@2x.png 2x, /coupon/staffUseIcon/btn-staff-use-copy@3x.png 3x"
                className="staff_use_icon"
              ></img>
              <Media.Body>
                <p className={`staff_use_message ${isMobile() ? 'caption' : 'field-text'}`}>
                  {i18n.t('campaign_show_coupon_to_staff')}
                </p>
              </Media.Body>
            </Media>
            <div>
              <button className="staff_use_btn button-large" onClick={this.useButtonClicked}>
                {i18n.t('coupon:useCouponText')}
              </button>
            </div>
          </>
        )}
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          className="use_coupon_modal"
          centered
        >
          {/* <Modal.Header>
            <button
              onClick={() => this.setState({ modalShow: false })}
              className="use_coupon_close_btn"
            >
              <img
                alt="close"
                src="/coupon/closeBtn/close-2.png"
                srcSet="/coupon/closeBtn/close-2@2x.png 2x, /coupon/closeBtn/close-2@3x.png 3x"
              />
            </button>
          </Modal.Header> */}
          <Modal.Body className="use_coupon_modal_content d-flex-column">
            <div>
              <p className="use_coupon_title title-medium-1">{i18n.t('coupon:useCoupon')}</p>
              <p className="use_coupon_message field-text">
                {i18n.t('campaign_simply_allow_enter_pin')}
              </p>
            </div>
            <ReactCodeInput
              className="title-medium-1"
              type="number"
              inputMode="numeric"
              fields={6}
              inputStyle={{
                color: '$greyG3',
              }}
              onChange={(text) => {
                this.setState({ inputCouponCode: text });
              }}
              autoFocus
            />
            <div className="button_group">
              <button
                className="left_btn button-large"
                onClick={() => {
                  this.setState({ modalShow: false });
                }}
              >
                {i18n.t('message_cancel')}
              </button>
              <button
                className="right_btn button-large"
                onClick={() => {
                  this.handleUseCoupon();
                }}
              >
                {i18n.t('campaign_confirm')}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {this.noCameraModal()}
        {this.showUseCouponStatusModal()}
        {this.state.showScanner ? (
          <Scanner
            show={this.state.showScanner}
            setQRCodeString={(qrCodeString) => {
              this.setState({ qrCodeString: qrCodeString });
            }}
            handleClose={() => {
              this.setState({
                showScanner: false,
              });
            }}
            onError={(errorMessage) => {
              this.props.dispatch(
                createAction('navBars/updateState')({
                  toastShowing: {
                    value: true,
                    content: errorMessage,
                  },
                }),
              );
            }}
          />
        ) : null}
      </Container>
    );
  }
}

export default withRouter(StaffUse);
