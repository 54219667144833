import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import MyProfileContentSection from "component/profile/MyProfileContentSection";
import { createAction } from "utils";

@connect(({ users }) => ({
  isShowLevel: users.isShowLevel,
}))
class MyProfile extends Component {
  componentDidMount() {
    this.props.dispatch(createAction("users/fetchAvailablePoints")());
    this.props.dispatch(createAction("users/fetchLevelConfig")());
  }

  // componentWillUnmount() {
  //   this.props.dispatch({
  //     type: "users/updateState",
  //     payload: { loginedLevelAnimated: true },
  //   });
  // }
  render() {
    return (
      <Container className="user-section-container">
        <MyProfileContentSection />
      </Container>
    );
  }
}

export default MyProfile;
