import React, { useEffect } from 'react';
import Markdown from 'react-markdown';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import i18n from 'I18n';

import DiscountTags from 'component/records/DiscountTags';
import ProductItem from 'component/records/ProductItem';

import { createAction, enLocaleDollarFormatter } from 'utils';

import './TransactionDetailContent.scss';

const DisplayRow = ({ label, value }) => {
  return (
    <div className={'rowContainer'}>
      <div className={'rowKey body-1'}>{label}</div>
      <div className={'rowValue body-2'}>{value}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.users.language,
    detailLoading: state.transaction.transactionDetailLoading,
    detailData: state.transaction.transactionDetail,
    detailNetworkError: state.transaction.transactionDetailNetworkError,
  };
};

const TransactionDetailContent = ({
  transactionID,
  language,
  detailLoading,
  detailData,
  detailNetworkError,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      createAction('transaction/getTransactionDetail')({ transactionID }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionID]);

  useEffect(() => {
    console.log('detailData', detailData);
  }, [detailData]);
  return (
    <div className={'transaction_history_detail'}>
      <div className={'columnContainer'}>
        <DisplayRow
          label={i18n.t('records:transaction_invoice')}
          value={detailData.receiptNumber}
        />
        <DisplayRow
          label={i18n.t('records:transaction_date')}
          value={detailData.receiptDateTime}
        />
        <DisplayRow
          label={i18n.t('records:transaction_order_status')}
          value={i18n.t(detailData.orderStatus)}
        />
      </div>
      <div className={'cardView'}>
        <div className={'columnLabel subheading'}>
          {i18n.t('records:transaction_order_summary')}
        </div>
        {detailData?.purchasedItems?.map((itemData) => (
          <ProductItem extraStyle={'defaultRowSpace'} data={itemData} />
        ))}
        <DisplayRow
          label={i18n.t('records:transaction_shipping_fee')}
          value={enLocaleDollarFormatter(detailData.shoppingFee)}
        />
        <DisplayRow
          label={i18n.t('records:transaction_other_charge')}
          value={enLocaleDollarFormatter(detailData.otherCharge)}
        />
        <div className={'divider'} />
        <DisplayRow
          label={i18n.t('records:transaction_subtotal')}
          value={enLocaleDollarFormatter(detailData.subtotal)}
        />
        <DisplayRow
          label={i18n.t('records:cash_dollar')}
          value={enLocaleDollarFormatter(detailData.pointToCash)}
        />
        <DisplayRow
          label={i18n.t('records:transaction_discount')}
          value={enLocaleDollarFormatter(detailData.discountAmount)}
        />
        {detailData.discounts?.length > 0 && (
          <DiscountTags
            extraStyle={'defaultRowSpace'}
            discounts={detailData.discounts}
          />
        )}
        <div className={'divider'} />
        <div className={'rowContainer'}>
          <div className={'rowKey totalPriceColor body-1'}>
            {!!detailData.paidAmount
              ? i18n.t('records:transaction_total_eligible')
              : i18n.t('records:transaction_total')}
          </div>
          <div className={'rowValue totalPriceColor totalPriceText headline'}>
            {enLocaleDollarFormatter(detailData.total)}
          </div>
        </div>
        {!!detailData.paidAmount && (
          <div className={'rowContainer'}>
            <div className={'rowKey totalPriceColor body-1'}>
              {i18n.t('records:transaction_total')}
            </div>
            <div className={'rowValue totalPriceColor totalPriceText  headline'}>
              {enLocaleDollarFormatter(detailData.paidAmount)}
            </div>
          </div>
        )}
        {!!detailData.refund && (
          <>
            <div className={'divider'} />
            <div className={'rowContainer'}>
              <div className={'rowKey refundColor body-1'}>
                {i18n.t('records:transaction_refunded')}
              </div>
              <div className={'rowValue refundColor body-2'}>
                {enLocaleDollarFormatter(detailData.refund)}
              </div>
            </div>
          </>
        )}
      </div>
      {detailData.remarks && (
        <div className={'cardView'}>
          <div className={'columnLabel subheading'}>
            {i18n.t('records:transaction_remark')}
          </div>
          <Markdown className={'columnContent body-1'}>
            {detailData.remarks}
          </Markdown>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(TransactionDetailContent);
