import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { createAction } from "utils";

import "./CookieBar.scss";

@connect(({ users }) => ({ users: users }))
class CookieBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeButtonDidClick: false,
      alertShow: false,
    };
  }

  _closeButtonDidClick = () => {
    this.setState({
      closeButtonDidClick: true,
    });
  };

  _agreeButtonDidClick = () => {
    if (navigator.cookieEnabled) {
      this.props.dispatch(createAction("users/userAgreeCookiePolicy")({}));
    } else {
      this._alertShow();
    }
  };

  _cookieBarShouldShow = () => {
    if (this.props.users?.isOpenInOtherSite) {
      return false
    }
    return !navigator.cookieEnabled || !this.props.users.agreeCookiePolicy;
  };

  _alertClose = () => {
    this.setState({
      alertShow: false,
    });
  };

  _alertShow = () => {
    this.setState({
      alertShow: true,
    });
  };

  _alertOkDidClicked = () => {
    this._closeButtonDidClick();
    this._alertClose();
  };

  render() {
    return (
      this._cookieBarShouldShow() && (
        <div className="cookieBar_container">
          <div
            className="home_cookie"
            style={{
              display: this.state.closeButtonDidClick ? "none" : "flex",
            }}
          >
            <div className="home_cookie_textArea field-text">
              <div className="home_cookie_text">
                <label>
                  <Trans i18nKey={"cookie_text"}>
                    <Link className="text_link" to={{pathname: `${process.env.REACT_APP_PRIVACY_LINK}?lang=${this.props.i18n.language}`}} target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </Link>
                  </Trans>
                </label>
              </div>
            </div>
            <div className="home_cookie_buttonArea">
              <Button
                className="buttonArea_reject button-large"
                onClick={this._closeButtonDidClick}
              >
                {this.props.t("rejectNonEssential")}
              </Button>
              <Button
                className="buttonArea_accept button-large"
                onClick={this._agreeButtonDidClick}
              >
                {this.props.t("acceptAll")}
              </Button>
            </div>
          </div>
          <Modal
            className="cookie_alert"
            show={this.state.alertShow}
            onHide={this._alertClose}
            centered
          >
            <div className="alert_textArea field-text">
              {this.props.t("cookie_settings_alert")}
            </div>
            <div className="alert_buttonArea">
              <Button className="button_cancel button-large" onClick={this._alertClose}>
                {this.props.t("message_cancel")}
              </Button>
              <Button className="button_ok button-large" onClick={this._alertOkDidClicked}>
                {this.props.t("campaign_ok")}
              </Button>
            </div>
          </Modal>
        </div>
      )
    );
  }
}

export default withTranslation()(withRouter(CookieBar));
