import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import LoadMoreSpinner from 'component/common/LoadMoreSpinner';

import { createAction, enLocaleNumberFormatter } from 'utils';

import './ActivityLogMobile.scss';

@connect(({ users, activityLogs, pullrefreshandloadmore }) => ({
  availablePoints: users.availablePoints,
  activityLogOriginList: activityLogs.activityLogOriginList,
  activityLogPageLink: activityLogs.activityLogPageLink,
  isLoadingLogs: activityLogs.isLoading,
  ...pullrefreshandloadmore,
  language: users.language,
}))
class ActivityLogMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(createAction('activityLogs/fetchUserActivityLogs')());
  }

  componentWillUnmount() {
    this.props.dispatch(createAction('activityLogs/clearLogs')());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.props.dispatch(createAction('activityLogs/fetchUserActivityLogs')());
    }
  }

  loadMore = () => {
    if (this.props.activityLogPageLink) {
      this.props.dispatch(
        createAction('pullrefreshandloadmore/updateState')({
          ActivityLogLoadingMore: true,
        }),
      );
      this.props.dispatch(
        createAction('activityLogs/uerActivityLogsLoadMore')(),
      );
    }
  };

  parseActicityLog = () => {
    let parsedActicityLogList = [];
    if (
      this.props.activityLogOriginList &&
      this.props.activityLogOriginList.length > 0
    ) {
      parsedActicityLogList = this.props.activityLogOriginList.map((item) => {
        let content = [];
        if (!!item.points) {
          content.push(
            item?.points > 0
              ? `+${enLocaleNumberFormatter(item.points)} pts`
              : `${enLocaleNumberFormatter(item.points)} pts`,
          );
        }
        if (!!item.tpe) {
          content.push(
            item?.tpe > 0
              ? `+${enLocaleNumberFormatter(item.tpe)} pp`
              : `${enLocaleNumberFormatter(item.tpe)} pp`,
          );
        }
        if (item?.coupon) {
          content.push(`+${item.coupon} ${this.props.t('home_coupons')}`);
        }
        if (item.stamps != null) {
          content.push(
            item?.stamps > 0
              ? `+${item?.stamps} stamps`
              : `${item?.stamps} stamps`,
          );
        }
        return {
          name: this.props.t(item.title),
          date: item.activityTime,
          content: content,
          icon: item.icon,
          srcSet: item?.srcSet,
          subTitle: item.subTitle,
        };
      });
    }
    return parsedActicityLogList;
  };

  renderTableData = ({
    dataList,
    isLoading = false,
    isLoadingMore = false,
  }) => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <LoadMoreSpinner />
          </tr>
        </tbody>
      );
    }
    if (dataList && dataList.length > 0) {
      return (
        <tbody>
          {dataList.map((item, index) => {
            return (
              <tr key={index + item}>
                <th className="item_name">
                  <div className="type-icon">
                    {item?.icon && (
                      <img
                        alt="type-icon"
                        src={item?.icon}
                        srcSet={item?.srcSet}
                      />
                    )}
                  </div>
                  <div>
                    <div className="body-3">{item.name}</div>
                    {item?.subTitle && (
                      <div className={`item-sub-title ${this.isMobile ? "tag-menu-1" : "body-2"}`}>{item?.subTitle}</div>
                    )}
                    <div className="item-sub-title body-1">{item?.date}</div>
                  </div>
                </th>
                <th className="item-content field-text">
                  {item?.content?.map((content) => {
                    return <div>{content}</div>;
                  })}
                </th>
              </tr>
            );
          })}
          {isLoadingMore && (
            <tr>
              <LoadMoreSpinner />
            </tr>
          )}
        </tbody>
      );
    }
    return (
      <tbody>
        <tr className="no-record-tr">
          <td>
            <p className="no_record_message subheading">
              {this.props.t('no_record_click')}{' '}
              <Link to="/offer/LATEST">{this.props.t('here')}</Link>{' '}
              {this.props.t('to_earn_points')}
            </p>
          </td>
        </tr>
      </tbody>
    );
  };

  tableView = (title, itemList, isLoading = false, isLoadingMore = false) => {
    return (
      <div className="reward_status_table_view activity-log-table-view">
        <div className="reward_status_table_head">
          <div className="reward_status_table_head_item subheading">{title}</div>
          <div className="reward_status_table_head_item subheading">
            {this.props.t('earn_burn')}
          </div>
        </div>
        <div
          className="reward-status-table-body activity-log-table-content scroll-table"
          onScroll={(event) => {
            if (
              event.nativeEvent.target.scrollTop +
                event.nativeEvent.target.clientHeight >
                event.nativeEvent.target.scrollHeight * 0.9 &&
              !isLoadingMore
            ) {
              this.loadMore();
            }
          }}
        >
          <Table className="reward-status-table activity-log-table" borderless>
            {this.renderTableData({
              dataList: itemList,
              isLoading,
              isLoadingMore,
            })}
          </Table>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Container className="activity-log-mobile-page-container reward-status-mobile-page-container">
        <Row>
          <Col>
            {this.tableView(
              this.props.t('activity'),
              this.parseActicityLog(),
              this.props.isLoadingLogs,
              this.props.ActivityLogLoadingMore || false,
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(ActivityLogMobile);
