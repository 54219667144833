import React, { Component } from "react";
import { Container } from "react-bootstrap";
import i18n from "../../I18n";
import "./OnlyForYouMobile.scss";

import OnlyForYouMobileCard from "./OnlyForYouMobileCard";
class OnlyForYouMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _getOnlyForYouItems = () => {
    if (this.props.data) {
      const items = this.props.data.map((itemData, index) => {
        let isLastItem = false;
        if (index === this.props.data.length - 1) {
          isLastItem = true;
        }
        return (
          <OnlyForYouMobileCard
            data={itemData}
            lastItem={isLastItem}
            key={index}
          />
        );
      });
      return items;
    }
  };

  render() {
    return (
      <Container className="onlyForYou_mobile_container d-md-none">
        <div className="onlyForYou_mobile_background_blue" />
        <div className="onlyForYou_mobile_title">
          {i18n.t("home_only_for_you")}
        </div>
        <div className="onlyForYou_mobile_slider_container">
          {this._getOnlyForYouItems()}
        </div>
      </Container>
    );
  }
}

export default OnlyForYouMobile;
