import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Markdown from 'react-markdown';

import i18n from '../../I18n';
import { isPadOrMobile } from 'utils/ScreenUtils';

class Instruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fold: this.props.instructions?.content?.length > 200,
    };
  }

  render() {
    const isPadOrMobileView = isPadOrMobile();
    return (
      <Container className="instruction_container">
        <div className={`instruction_sections ${isPadOrMobileView ? "title-small" : "headline"}`}>
          <Markdown>{this.props.instructions.title}</Markdown>
        </div>
        <div
          className={`instruction_step ${
            this.props.renderMask & this.state.fold
              ? 'instruction_step_masked'
              : ''
          } ${
            isPadOrMobileView ? "body-1" : "field-text"
          }`}
        >
          <Markdown>{this.props.instructions.content}</Markdown>
        </div>
        {this.props.renderMask & this.state.fold ? (
          <>
            <div class="mask" />
            <span
              className={`read-more ${isPadOrMobileView ? "button-small" : "button-large"}`}
              onClick={() => {
                this.setState({ fold: false });
              }}
            >
              {i18n.t('readMore')}
            </span>
          </>
        ) : null}
      </Container>
    );
  }
}

export default Instruction;
